import { GeoSyncComune } from './../interfaces/geo-sync-comune';
import { GeoSyncProvincia } from './../interfaces/geo-sync-provincia';
import { GeoSyncRegione } from './../interfaces/geo-sync-regione';
import { initializerEnvironment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResult } from '@shared/interfaces/api-result';
import { catchError, map, tap } from 'rxjs/operators';
import { GeoSyncAll } from '@shared/geo-sync/interfaces/geo-sync-all';
import { GeoSyncCoordinates } from '@shared/geo-sync/interfaces/geo-sync-coordinates';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ToastrService } from 'ngx-toastr';
import { BASE_URL } from '@shared/models/urls-constants';

interface IFilter {
    key: string;
    value: string;
}

@Injectable()
export class GeoSyncService {

    get showGeoJSON(): boolean {
        return this._showGeoJSON;
    }

    set showGeoJSON( value: boolean ) {
        this._showGeoJSON = value;
    }

    private readonly REGIONI_URL = this.baseUrl + initializerEnvironment.geoSync.endpoints.regioni;
    private readonly PROVINCE_URL = this.baseUrl + initializerEnvironment.geoSync.endpoints.province;
    private readonly COMUNI_URL = this.baseUrl + initializerEnvironment.geoSync.endpoints.comuni;

    get considerZona(): boolean {
        return this._considerZona;
    }

    set considerZona( value: boolean ) {
        this._considerZona = value;
    }

    set selectedRegione( v: string ) {
        this.selectedRegioneSubject.next( v );
    }

    get selectedRegione(): string {
        return this.selectedRegioneSubject.getValue();
    }

    set selectedProvincia( v: string ) {
        this.selectedProvinciaSubject.next( v );
    }

    get selectedProvincia(): string {
        return this.selectedProvinciaSubject.getValue();
    }

    set selectedComune( v: string ) {
        this.selectedComuneSubject.next( v );
    }

    // noinspection JSUnusedGlobalSymbols
    get selectedComune(): string {
        return this.selectedComuneSubject.getValue();
    }

    set selectedZona( v: string ) {
        this.selectedZonaSubject.next( v );
    }

    get selectedZona(): string {
        return this.selectedZonaSubject.getValue();
    }

    selectedRegioneObj: GeoSyncRegione;
    selectedProvinciaObj: GeoSyncProvincia;
    selectedComuneObj: GeoSyncComune;

    private selectedComuneSubject: BehaviorSubject<string> = new BehaviorSubject<string>( null );
    selectedComune$: Observable<string> = this.selectedComuneSubject.asObservable();

    private selectedProvinciaSubject: BehaviorSubject<string> = new BehaviorSubject<string>( null );
    selectedProvincia$: Observable<string> = this.selectedProvinciaSubject.asObservable();

    private selectedRegioneSubject: BehaviorSubject<string> = new BehaviorSubject<string>( null );
    selectedRegione$: Observable<string> = this.selectedRegioneSubject.asObservable();

    private selectedZonaSubject: BehaviorSubject<string> = new BehaviorSubject<string>( null );
    selectedZona$: Observable<string> = this.selectedZonaSubject.asObservable();

    private _considerZona = true;

    private _showGeoJSON: boolean;

    constructor( private http: HttpClient, private toastr: ToastrService, @Inject( BASE_URL ) private baseUrl: string ) {
    }

    /**
     * Select the regione. This is called when the user select a regione from the autocomplete list
     *
     * @param regione  The selected regione
     */
    selectRegione( regione: string ) {
        const termFilter = this.calculateRegioneFilter( regione );
        let params = this._httpsParamsRegioni( termFilter );
        params = this.addShowGeoJSON( params );
        return this.http.get<ApiResult>( this.REGIONI_URL, { params } ).pipe(
            tap( res => {
                const regioni = res.data as GeoSyncRegione[];
                const tempRegione = regioni.find( r => r.regione.toLowerCase().trim() === regione.toLowerCase().trim() );
                if( tempRegione ) {
                    this.selectedRegione = tempRegione.regione;
                    this.selectedRegioneObj = tempRegione;
                    this.selectedProvincia = null;
                    this.selectedComune = null;
                }
            } ),
        );
    }

    /**
     * Select the provincia. This is called when the user select a provincia from the autocomplete list
     *
     * @param provincia  The selected provincia
     */
    selectProvincia( provincia: string ) {
        const termFilter = this.calculateProvinciaFilter( provincia );
        let params = this._httpsParamsProvince( termFilter );
        params = this.addShowGeoJSON( params );
        return this.http.get<ApiResult>( this.PROVINCE_URL, { params } ).pipe(
            tap( res => {
                const province = res.data as GeoSyncProvincia[];
                const tempProvincia = province.find( p => p.provincia.toLowerCase().trim() === provincia.toLowerCase().trim() );

                if( tempProvincia ) {
                    this.selectedZona = tempProvincia.zona.toString();
                    this.selectedRegione = tempProvincia.regione;
                    this.selectedProvincia = tempProvincia.provincia;
                    this.selectedProvinciaObj = tempProvincia;
                    this.selectedComune = null;
                }
            } ),
        );
    }

    /**
     * Select the comune. This is called when the user select a comune from the autocomplete list
     *
     * @param comune  The selected comune
     */
    selectComune( comune: string ) {
        this.selectedComune = comune;
        const { term, termFilters } = this.calculateComuneFilters( comune );
        let params = this._httpsParamsComuni( termFilters );
        params = this.addShowGeoJSON( params );
        return this.http.get<ApiResult>( this.COMUNI_URL, { params } ).pipe(
            tap( res => {
                const comuni = res.data as GeoSyncComune[];
                const tempComune = comuni.find( c => c.comune.toLowerCase().trim() === term.toLowerCase().trim() );
                if( tempComune ) {
                    this.selectedZona = tempComune.zona.toString();
                    this.selectedRegione = tempComune.regione;
                    this.selectedProvincia = tempComune.provincia;
                    this.selectedComune = tempComune.comune;
                    this.selectedComuneObj = tempComune;
                }
            } ),
        );
    }

    selectInitial( comune: string, provincia: string, regione: string ) {
        if( comune ) {
            this.selectedComune = comune;
            this.selectedRegione = regione;
            this.selectedProvincia = provincia;
            const { term, termFilters } = this.calculateComuneFilters( comune );
            let params = this._httpsParamsComuni( termFilters );
            params = this.addShowGeoJSON( params );
            return this.http.get<ApiResult>( this.COMUNI_URL, { params } ).pipe(
                tap( res => {
                    const comuni = res.data as GeoSyncComune[];
                    const tempComune = comuni.find( c => c.comune.toLowerCase().trim() === term.toLowerCase().trim() );
                    if( tempComune ) {
                        this.selectedComuneObj = tempComune;
                    }
                } ),
            );
        } else {
            return of( null );
        }
    }

    selectZona( zona: string ) {
        this.selectedZona = zona;
        this.selectedComune = null;
        this.selectedProvincia = null;
    }

    getGeoFromCoordinates( coords: GeoSyncCoordinates ): Observable<GeoSyncAll> {
        return this.http.get<GeoSyncAll>( this.getCoordinatesURL( coords.longitude, coords.latitude ) ).pipe(
            tap( res => {
                this.selectedComuneObj = res as GeoSyncComune;
                this.selectedRegione = res.regione;
                this.selectedProvincia = res.provincia;
                this.selectedComune = res.comune;
                this.selectedZona = res.zona.toString();
            } ),
            catchError( err => {
                console.error( 'GeoSync error in searching coordinates', err );
                this.toastr.error( 'Punto fuori dei confini nazionali' );
                return of( null );
            } ),
        );
    }

    private calculateZonaFilter( zona: string ) {
        return {
            key: 'zona',
            value: zona,
        };
    }

    private getCoordinatesURL = ( lng: number, lat: number ) => ( this.baseUrl + initializerEnvironment.geoSync.endpoints.coordinates( lng, lat ) );

    private _setZonaFilter( termFilter: IFilter[] ) {
        if( this.considerZona && this.selectedZona ) {
            termFilter.push( this.calculateZonaFilter( this.selectedZona ) );
        }
        return termFilter;
    }

    private _setPageSize( params: HttpParams ) {
        return params.set( 'pageSize', initializerEnvironment.geoSync.pageSize );
    }

    private setQuery( params: HttpParams, _filters: IFilter[] ) {
        params = params.set( 'filterColumns', _filters.map( f => f.key.trim() ).join( initializerEnvironment.querySeparator ) );
        params = params.set( 'filterQueries', _filters.map( f => f.value.trim() ).join( initializerEnvironment.querySeparator ) );
        return this._setPageSize( params );
    }

    private addShowGeoJSON( params: HttpParams ): HttpParams {
        if( this.showGeoJSON ) {
            return params.append( 'showGeoJson', 'true' );
        }
        return params;
    }

    private _httpsParamsProvince( termFilter: IFilter ) {
        const params = new HttpParams();
        const _filters: IFilter[] = [];
        _filters.push( termFilter );
        if( this.selectedRegione ) {
            _filters.push( this.calculateRegioneFilter( this.selectedRegione ) );
        }
        this._setZonaFilter( _filters );
        return this.setQuery( params, _filters );
    }

    private _httpsParamsComuni( termFilter: IFilter[] ) {
        const params = new HttpParams();
        const _filters: IFilter[] = [];
        termFilter.forEach( f => _filters.push( f ) );
        this._setZonaFilter( _filters );
        if( this.selectedRegione ) {
            _filters.push( this.calculateRegioneFilter( this.selectedRegione ) );
        }
        if( this.selectedProvincia ) {
            _filters.push( this.calculateProvinciaFilter( this.selectedProvincia ) );
        }
        return this.setQuery( params, _filters );
    }

    private _httpsParamsRegioni( termFilter: IFilter ) {
        const params = new HttpParams();
        const _filters: IFilter[] = [];
        _filters.push( termFilter );
        return this.setQuery( params, _filters );
    }

    /**
     * This is called from the regione autocomplete component when the user changes the input text control.
     */
    get regioneFilter(): ( term: string ) => Observable<GeoSyncRegione[]> {
        return ( term ) => {
            if( !term ) {
                this.selectedProvincia = null;
                this.selectedComune = null;
                this.selectedRegione = null;
                return of( [] );
            } else {
                const termFilter = this.calculateRegioneFilter( term );
                return this.http.get<ApiResult>( this.REGIONI_URL, { params: this._httpsParamsRegioni( termFilter ) } ).pipe(
                    map( res => res.data as GeoSyncRegione[] ),
                );
            }
        };
    }

    /**
     * Calculate a filter for regione
     *
     * @param term
     * @private
     */
    private calculateRegioneFilter( term: string ) {
        return {
            key: 'regione',
            value: term,
        };
    }

    /**
     * This is called from the provincia autocomplete component when the user changes the input text control.
     */
    get provinciaFilter(): ( term: string ) => Observable<GeoSyncProvincia[]> {
        return ( term ) => {
            if( !term ) {
                this.selectedProvincia = null;
                this.selectedComune = null;
                return of( [] );
            } else {
                const termFilter = this.calculateProvinciaFilter( term );
                return this.http.get<ApiResult>( this.PROVINCE_URL, { params: this._httpsParamsProvince( termFilter ) } ).pipe(
                    map( res => res.data as GeoSyncProvincia[] ),
                );
            }
        };
    }


    /**
     * Calculate a filter for provincia
     *
     * @param term
     * @private
     */
    private calculateProvinciaFilter( term: string ) {
        return {
            key: 'provincia',
            value: term,
        };
    }

    /**
     * This is called from the comune autocomplete component when the user changes the input text control.
     */
    get comuneFilter(): ( comune: string ) => Observable<GeoSyncComune[]> {
        return ( comune ) => {
            if( !comune ) {
                this.selectedComune = null;
                return of( [] );
            } else {
                const { termFilters } = this.calculateComuneFilters( comune );

                return this.http.get<ApiResult>( this.COMUNI_URL, { params: this._httpsParamsComuni( termFilters ) } ).pipe(
                    map( res => res.data as GeoSyncComune[] ),
                );
            }
        };
    }

    /**
     * Calculate a filter for the comune
     *
     * @param term
     * @private
     */
    private calculateComuneFilters( term: string ) {
        const re = /\([a-zA-Z]{2}\)/;
        const prs = re.exec( term );
        const termFilters: IFilter[] = [];
        if( prs && prs.length > 0 ) {
            const pr = prs[ 0 ].substring( 1, 3 );
            term = term.replace( re, '' ).trim();

            termFilters.push( {
                key: 'siglaProv',
                value: pr,
            } );
        }

        termFilters.push( {
            key: 'comune',
            value: term,
        } );
        return { term, termFilters };
    }

}
