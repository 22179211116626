import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WndGridService } from '@shared/components/wonder-grid/services/wnd-grid.service';
import { TableExportService } from '../../services/table-export.service';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component( {
    selector: 'wnd-excel-export',
    templateUrl: './excel-export.component.html',
    styleUrls: [ './excel-export.component.scss' ]
} )
export class ExcelExportComponent implements OnInit {
    @Input() gridService: WndGridService;
    @Input() filename: string;
    @Input() customExport: boolean;
    @Input() customExportTitle = 'Esportazione custom';
    @Input() customWorking = false;

    @Output() customExportClick = new EventEmitter<void>();

    working$ = new BehaviorSubject<boolean>( false );

    formato: string;

    constructor( private exportService: TableExportService ) {
    }

    ngOnInit(): void {
    }

    exportExcel() {
        const fileToExport = this.exportService.normalizeFilename( this.filename, this.formato );
        if( fileToExport ) {
            this.working$.next( true );
            this.gridService.downloadExport( fileToExport, this.formato ).pipe(
                tap( () => this.working$.next( false ) )
            ).subscribe();
        }
    }

    exportCustomExcel() {
        this.customExportClick.emit();
    }
}
