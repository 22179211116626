import { Component, Input, OnInit } from '@angular/core';
import { IOfficePlan } from '@piani/models/office-plan';

@Component( {
    selector: 'wnd-elements-planned',
    templateUrl: './elements-planned.component.html',
    styleUrls: [ './elements-planned.component.scss' ],
} )
export class ElementsPlannedComponent implements OnInit {
    @Input() plans: IOfficePlan[];

    constructor() {
    }

    ngOnInit(): void {
        // console.log( 'Element planned', this.plans );
    }

}
