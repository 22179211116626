import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IGetContrattiListResponse } from '../models/get-contratti-list-response';
import { map } from 'rxjs/operators';
import { IContrattoElement } from '../models/contratto-element';
import { IGetContrattoResponse } from '../models/get-contratto-response';
import { CONTRATTI_URL } from '@shared/models/urls-constants';

@Injectable( {
    providedIn: 'root',
} )
export class ApiContrattiService {

    constructor( private http: HttpClient, @Inject( CONTRATTI_URL ) private baseUrl: string ) {
    }

    getListaContratti( office: string, status: 'ATTIVO' | 'NON_ATTIVO' | 'STORICO' = 'ATTIVO' ): Observable<IContrattoElement[]> {
        const url = `${ this.baseUrl }api/v1/contratti/${ office }?status=${ status }`;

        return this.http.get<IGetContrattiListResponse>( url ).pipe(
            map( res => res.data ),
        );
    }

    getContratto( idContratto: number ): Observable<IContrattoElement> {
        const url = `${ this.baseUrl }api/v1/contratti/${ idContratto }/contratto`;

        return this.http.get<IGetContrattoResponse>( url ).pipe(
            map( res => res.data ),
        );
    }

}
