import { Component, Input, OnInit } from '@angular/core';

@Component( {
    selector: 'app-collapsable-display',
    templateUrl: './collapsable-display.component.html',
    styleUrls: [ './collapsable-display.component.scss' ],
} )

export class CollapsableDisplayComponent implements OnInit {

    @Input() title: string;
    panelOpenState: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

}
