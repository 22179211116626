import { IDriver } from '@piani/models/dashboard/driver.interface';
import { IForecastRequest } from '@piani/models/dashboard/forecast-request';
import { IPanel } from '@piani/models/dashboard/panel.interface';
import { IForecastValueDTO } from '@piani/models/dashboard/forecast-value-dto';

export interface State {
    dashboardElements: IDriver[] | undefined;
    dashboardDrivers: IDriver[];
    distinctPanels: IPanel[];
    panels: IPanel[];
    panelVisibility: { [ key: number ]: boolean };
    selectedCategory: number;
    selectedZone?: number;
    forecastElements: IDriver[];
    forecastValuesToBeSaved: IForecastValueDTO[];
    forecastSaving: boolean;
    selectedForecastZone?: number;
    selectedForecastMonth?: number;
    // selectedYear: number;
    forecastRequest?: IForecastRequest;
    approvalRequests: IForecastRequest[];
    refusedRequests: IForecastRequest[];
    forecastLoading: boolean;
    canEdit: boolean;
    viewSelectedMonth: boolean;
}

export const initialState: State = {
    dashboardElements: undefined,
    dashboardDrivers: [],
    distinctPanels: [],
    panels: [],
    panelVisibility: { 4: true, 5: true, 6: true },
    selectedCategory: 0,
    forecastElements: [],
    // selectedYear: 3,
    approvalRequests: [],
    refusedRequests: [],
    forecastLoading: false,
    forecastValuesToBeSaved: [],
    forecastSaving: false,
    canEdit: false,
    viewSelectedMonth: false,
};

export const featureKey = 'dashboard-piani';
