import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GridColumnsState } from '@store/grid-columns-store/index';

export const selectGridColumnsState = createFeatureSelector<GridColumnsState.State>( GridColumnsState.featureKey );
export const selectGrids = createSelector( selectGridColumnsState, ( state ) => state.grids );
export const selectGridsUpdatedAt = createSelector( selectGridColumnsState, ( state ) => state.gridsUpdatedAt );
export const selectGridColumns = ( gridName: string ) => createSelector( selectGrids, ( grids ) => grids[ gridName ] );
export const selectUpdatedAt = ( gridName: string ) => createSelector( selectGridsUpdatedAt, ( grids ) => grids[ gridName ] );

export const selectSelected = createSelector( selectGridColumnsState, ( state ) => state.selected );
export const selectSelectedColumnsSet = ( gridName: string ) => createSelector( selectSelected, ( selected ) => selected[ gridName ] );

export const selectSelectedSets = ( gridName: string ) => createSelector( selectGridColumnsState, ( state ) => state.selectedSets[ gridName ]?.sets );

export const selectGridsColumns = createSelector( selectGridColumnsState, ( state ) => state.gridsColumns );
export const selectAllGridColumns = ( gridName: string ) => createSelector( selectGridsColumns, ( grids ) => grids[ gridName ] );
export const selectAllSets = ( gridName: string ) => createSelector( selectGrids, grids => grids[ gridName ].map( s => s.name ) );
