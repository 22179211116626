import { IPlanDTO } from '@piani/models/plan-dto';

export interface PlanElementsFilterState {
    plan?: IPlanDTO;
    zone?: string;
    subZone?: string;
    year?: number;
}

export interface IBaselineFilterState {
    element?: IPlanDTO;
    zone?: string;
    subZone?: string;
    year?: number;
}

export interface State {
    planElementsFilter: PlanElementsFilterState | null;
    baselineFilter: IBaselineFilterState | null;
}

export const initialState: State = {
    planElementsFilter: null,
    baselineFilter: null,
};

export const featureKey = 'plans';
