import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TusSingleFileUploader } from '@shared/services/upload.service';
import { UploaderConfig } from '@shared/directives/tus-uploader.directive';
import {
    IInputFileElement
} from '../../../../../routes/nd/lavorazioni/step-edit/custom-field/single-file/single-file.component';
import { UploadingFileStatuses } from '@shared/models/uploading-file-statuses';
import { MtxDialog } from '@ng-matero/extensions/dialog';

@Component( {
    selector: 'wnd-dynamic-columns-drawer',
    templateUrl: './dynamic-columns-drawer.component.html',
    styleUrls: [ './dynamic-columns-drawer.component.scss' ]
} )
export class DynamicColumnsDrawerComponent implements OnInit {
    @Input() gridName?: string;

    @ViewChild( 'inputFile' ) inputFileView: ElementRef<IInputFileElement>;

    working = false;

    tusConfig: UploaderConfig = {
        metadata: { test: 'test' },
        allowedTypes: [ '.xlsx', '.xlsm', '.xls' ],
        lazyStart: false,
    };


    constructor( private router: Router, private dialogs: MtxDialog ) {
    }

    ngOnInit(): void {
        this.tusConfig.metadata = { entity: 'dynamic-column-import', ...( this.gridName ) && { entityId: this.gridName } };
    }

    onTemplateClick() {
        if( this.gridName ) {
            void this.router.navigate( [ 'dynamic-columns', 'generate-template', this.gridName ] );
        }
    }

    onUploadEvent( event: { source: TusSingleFileUploader; uploaders: TusSingleFileUploader[] } ) {
        this.inputFileView.nativeElement.value = '';

        if ( event.source.fileStatus.state === UploadingFileStatuses.COMPLETED ) {
            this.dialogs.alert( 'Upload', 'Upload completato correttamente.\nRiceverai una notifica al termine dell\'operazione di import.' );
        }
    }

    onSelectionError( _: string ) {
        this.inputFileView.nativeElement.value = '';
    }
}
