import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Notification, NotificationEventType } from '@core/notifications/models';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DashboardPianiActions } from '@store/dashboard-piani-store';
import { CommonsActions } from '@store/commons-store';
import { SidebarStateService } from '@shared/services/sidebar-state.service';

@Component( {
    selector: 'wnd-notification-dialog',
    templateUrl: 'notification-dialog.component.html',
    styleUrls: [ 'notification-dialog.component.scss' ],
    standalone: true,
    imports: [ CommonModule, MatDialogModule, MatButtonModule ],
} )
export class NotificationDialogComponent implements OnInit {
    urlToJump: string = null;

    constructor( public dialogRef: MatDialogRef<NotificationDialogComponent>, @Inject( MAT_DIALOG_DATA ) public data: Notification, private router: Router, private store: Store, private serviceSidebar: SidebarStateService ) {
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        console.log( 'Notification', this.data, this.data.content.eventType, this.data.content.eventData );

        switch( this.data.content.eventType ) {
        case NotificationEventType.FORECAST_REQUEST_CREATION:
            const year = Number.parseInt( this.data.content.eventData.fiscal_year.substring( 2 ), 10 ) + 2000;
            this.urlToJump = `Click per andare alla pagina del forecast for ${ this.data.content.eventData.month_name } ${ year }`;
            break;

        case NotificationEventType.BASELINE_PREVIEW:
            this.urlToJump = `Click per andare alla pagina della baseline ${ this.data.content.eventData.baseline_type } ${ this.data.content.eventData.baseline_fy }/${ this.data.content.eventData.baseline_name } V${ this.data.content.eventData.baseline_version }`;
            break;

        case NotificationEventType.NEW_OFFICE_EVALUATION_REFUSED:
        case NotificationEventType.NEW_OFFICE_EVALUATED:
        case NotificationEventType.NEW_OFFICE_SENT:
        case NotificationEventType.NEW_OFFICE_IN_EVALUATION:
            this.urlToJump = 'Click per andare alla pagina della richiesta';
            break;

        case NotificationEventType.NEW_OFFICE_IN_CLASSIFICATION:
        case NotificationEventType.NEW_OFFICE_NEW_CLASSIFICATION:
        case NotificationEventType.NEW_OFFICE_CLASSIFIED:
        case NotificationEventType.NEW_OFFICE_CLASSIFICATION_REFUSED:
            this.urlToJump = 'Click per andare alla pagina della classificazione';
            break;

        case NotificationEventType.TOOLTX_ALTERNATIVE_PROPOSAL:
        case NotificationEventType.TOOLTX_IN_APPROVED:
        case NotificationEventType.TOOLTX_APPROVED_PROJECT:
        case NotificationEventType.TOOLTX_IN_EVALUATION:
        case NotificationEventType.TOOLTX_MODIFIED_PROPOSAL:
        case NotificationEventType.TOOLTX_PROJECT_IN_EVALUATION:
        case NotificationEventType.TOOLTX_REFUSED_BASELINE:
        case NotificationEventType.TOOLTX_REFUSED:
        case NotificationEventType.TOOLTX_REFUSED_PROPOSAL:
            this.urlToJump = 'Click per andare alla pagina della richiesta tool tx';
            break;

        }
    }

    jumpToPage() {
        this.close();
        switch( this.data.content.eventType ) {
        case NotificationEventType.FORECAST_REQUEST_CREATION:
            const fiscalYear = Number.parseInt( this.data.content.eventData.fiscal_year.substring( 2 ), 10 ) + 2000;
            const idZone = this.data.content.eventData.zone_id != null ? Number.parseInt( this.data.content.eventData.zone_id, 10 ) : null;
            const idMonth = this.data.content.eventData.month_id != null ? Number.parseInt( this.data.content.eventData.month_id, 10 ) : null;
            this.store.dispatch( DashboardPianiActions.changeSelectedForecastFilter( { idZone, idMonth } ) );
            this.store.dispatch( CommonsActions.setFiscalYear( { fiscalYear } ) );
            void this.router.navigate( [ 'planning', 'zone-forecast' ] ).then( () => this.serviceSidebar.closeNotificationPanel() );
            break;

        case NotificationEventType.BASELINE_PREVIEW:
            console.log( 'Navigare to baseline', this.data.content.eventData.baseline_type );
            switch( this.data.content.eventData.baseline_type ) {
            case 'rx':
                void this.router.navigate( [ 'piani', 'nominali' ] ).then( () => this.serviceSidebar.closeNotificationPanel() );
                break;
            case 'strutture':
                void this.router.navigate( [ 'piani', 'new-offices-plans' ] ).then( () => this.serviceSidebar.closeNotificationPanel() );
                break;
            case 'tx':
                void this.router.navigate( [ 'piani', 'tratte' ] ).then( () => this.serviceSidebar.closeNotificationPanel() );
                break;
            }
            break;

        case NotificationEventType.NEW_OFFICE_EVALUATION_REFUSED:
            void this.router.navigate( [ 'new-site', 'new-request', this.data.content.eventData.requestId ] ).then( () => this.serviceSidebar.closeNotificationPanel() );
            break;

        case NotificationEventType.NEW_OFFICE_EVALUATED:
        case NotificationEventType.NEW_OFFICE_SENT:
        case NotificationEventType.NEW_OFFICE_IN_EVALUATION:
            void this.router.navigate( [ 'new-site', 'evaluation', this.data.content.eventData.requestId ] ).then( () => this.serviceSidebar.closeNotificationPanel() );
            break;

        case NotificationEventType.NEW_OFFICE_IN_CLASSIFICATION:
        case NotificationEventType.NEW_OFFICE_NEW_CLASSIFICATION:
        case NotificationEventType.NEW_OFFICE_CLASSIFIED:
        case NotificationEventType.NEW_OFFICE_CLASSIFICATION_REFUSED:
            void this.router.navigate( [ 'new-site', 'classification', this.data.content.eventData.requestId ] ).then( () => this.serviceSidebar.closeNotificationPanel() );
            break;

        case NotificationEventType.TOOLTX_ALTERNATIVE_PROPOSAL:
        case NotificationEventType.TOOLTX_IN_APPROVED:
        case NotificationEventType.TOOLTX_APPROVED_PROJECT:
        case NotificationEventType.TOOLTX_IN_EVALUATION:
        case NotificationEventType.TOOLTX_MODIFIED_PROPOSAL:
        case NotificationEventType.TOOLTX_PROJECT_IN_EVALUATION:
        case NotificationEventType.TOOLTX_REFUSED_BASELINE:
        case NotificationEventType.TOOLTX_REFUSED:
        case NotificationEventType.TOOLTX_REFUSED_PROPOSAL:
            void this.router.navigate( [ 'tool-tx', 'request', this.data.content.eventData.requestId ] ).then( () => this.serviceSidebar.closeNotificationPanel() );
            break;
        }
    }
}
