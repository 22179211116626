import { PlansActions, PlansState } from './index';
import { Action, createReducer, on } from '@ngrx/store';

const plansReducer = createReducer( PlansState.initialState,
    on( PlansActions.setPlanElementsFilter, ( state, { plan, zone, subZone, year } ) => ( { ...state, planElementsFilter: { plan, zone, subZone, year } } ) ),
    on( PlansActions.setBaselineElementsFilter, ( state, { element, zone, subZone, } ) => ( { ...state, baselineFilter: { element, zone, subZone } } ) ),
);


export const reducers = ( state: PlansState.State | undefined, action: Action ): PlansState.State => plansReducer( state, action );
