import { Component } from '@angular/core';
import { MenuService, SettingsService, TokenService, User } from '@core';
import { filter, map } from 'rxjs/operators';

@Component( {
    selector: 'app-user',
    template: `
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <span>Last login {{ lastTime$ | async | date: 'E d, MMM yyyy hh:mm' }}</span>
            <!--
                        <button
                                class="matero-toolbar-button matero-avatar-button"
                                mat-button
                                [matMenuTriggerFor]="menu"
                        >
                            <img class="matero-avatar" [src]="getAvatar$() | async" width="32" alt="avatar"/>
                            <span class="matero-username" fxHide.lt-sm>{{ user.name || user.username }}</span>
                        </button>
            -->

            <mat-menu #menu="matMenu">
                <button routerLink="/usermanagement/settings" mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>{{ 'user.profile' | translate }}</span>
                </button>
                <button mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{ 'user.logout' | translate }}</span>
                </button>
            </mat-menu>
        </div>
    `,
    styles: [ `
        span {
            font-size: small;
        }
    ` ]
} )
export class UserComponent {
    user: User;

    lastTime$ = this.tokenService.loginTime$.pipe( filter( res => !!res ), map( res => res.secondLastLoginDate ) );

    constructor(
        private settings: SettingsService,
        private menu: MenuService,
        private tokenService: TokenService,
    ) {
        this.user = settings.user;
    }

    getAvatar$() {
        return this.settings.getAvatar$();
    }

    logout() {
        this.settings.removeUser();
        this.menu.reset();
        this.settings.logout();
    }
}
