import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardValueDTO } from '@piani/models/dashboard/dashboard-value-dto';
import { IDriver } from '@piani/models/dashboard/driver.interface';
import { FORECAST_URL, PIANI_URL } from '@shared/models/urls-constants';
import { map, switchMap, tap } from 'rxjs/operators';
import { ITimePeriod } from '@piani/models/dashboard/time-period';
import { IForecastValueDTO } from '@piani/models/dashboard/forecast-value-dto';
import { IGetForecastRequestResponse } from '@piani/models/dashboard/get-forecast-request-response';
import { IForecastRequest } from '@piani/models/dashboard/forecast-request';
import { IGetApprovalRequestsResponse } from '@piani/models/dashboard/get-approval-requests-response';
import { Store } from '@ngrx/store';
import { CommonsSelectors } from '@store/commons-store';
import { ToastrService } from 'ngx-toastr';

@Injectable( {
    providedIn: 'root',
} )
export class ApiDashboardPianiService {
    baseUrl: string;
    forecastUrl: string;

    constructor( private http: HttpClient, @Inject( PIANI_URL ) pianiUrl: string, @Inject( FORECAST_URL ) forecastUrl: string, private store: Store, private toaster: ToastrService ) {
        this.baseUrl = `${ pianiUrl }api/v1/plansdashboard`;
        this.forecastUrl = `${ forecastUrl }forecast`;
    }

    getDrivers( idCategory: number, idZona: number, fy: number ): Observable<IDriver[]> {
        const url = `${ this.baseUrl }?idCategory=${ idCategory }&idZone=${ idZona }&fy=${ fy }`;
        return this.http.get<IDriver[]>( url );
    }

    updateValue( value: DashboardValueDTO ): Observable<void> {
        const url = `${ this.baseUrl }/target`;
        return this.http.put<void>( url, value );
    }

    updateMultipleValues( idCategory: number, idZona: number, fy: number, values: DashboardValueDTO[] ): Observable<IDriver[]> {
        const url = `${ this.baseUrl }/multipleTargets`;
        const body = { idCategory, idZone: idZona, fy, targets: values.map( v => ( { ...v, idZona } ) ) };
        return this.http.put<IDriver[]>( url, body );
    }

    getForecastDriver( idZona: number, year: number, idMonth: number ): Observable<IDriver[]> {
        const url = `${ this.forecastUrl }?idCategory=0&idZone=${ idZona }&fy=${ year }&idMonth=${ idMonth }`;
        return this.http.get<{ data: IDriver[] }>( url ).pipe( map( res => res.data ) );
    }

    getMonths( year?: number ): Observable<ITimePeriod[]> {
        const url = `${ this.baseUrl }/months/${ year }`;
        return this.http.get<{ data: ITimePeriod[] }>( url ).pipe( map( res => res.data ) );
    }

    updateForecastValue( value: IForecastValueDTO ): Observable<void> {
        const url = `${ this.forecastUrl }/element`;
        return this.http.put<void>( url, value );
    }

    updateMultipleForecastValues( idZone: number, idMonth: number, fy: number, values: IForecastValueDTO[] ): Observable<IDriver[]> {
        const url = `${ this.forecastUrl }/multipleTargets`;
        const body = { idZone, idMonth, fy, targets: values.map( v => ( { ...v, idZona: idZone } ) ) };
        return this.http.put<{ data: IDriver[] }>( url, body ).pipe( map( res => res.data ) );
    }

    getRequest( idZone: number, idMonth: number ): Observable<IForecastRequest> {
        const url = `${ this.forecastUrl }/request?idZone=${ idZone }&idMonth=${ idMonth }`;
        return this.http.get<IGetForecastRequestResponse>( url ).pipe( map( res => res.data ) );
    }

    forecastRequestApproval( idZone: number, idMonth: number ): Observable<IForecastRequest> {
        const url = `${ this.forecastUrl }/request/approval?idZone=${ idZone }&idMonth=${ idMonth }`;
        return this.http.put<IGetForecastRequestResponse>( url, {} ).pipe( map( res => res.data ) );
    }

    getApprovalRequests(): Observable<IForecastRequest[]> {
        const url = `${ this.forecastUrl }/request/approval`;
        return this.http.get<IGetApprovalRequestsResponse>( url ).pipe( map( res => res.data ) );
    }

    forecastRequestRefuse( idZone: number, idMonth: number, reason: string ): Observable<IForecastRequest> {
        const url = `${ this.forecastUrl }/request/refuse?idZone=${ idZone }&idMonth=${ idMonth }&reason=${ reason }`;
        return this.http.put<IGetForecastRequestResponse>( url, {} ).pipe( map( res => res.data ) );
    }

    forecastRequestApprove( idZone: number, idMonth: number ): Observable<IForecastRequest> {
        const url = `${ this.forecastUrl }/request/approve?idZone=${ idZone }&idMonth=${ idMonth }`;
        return this.http.put<IGetForecastRequestResponse>( url, {} ).pipe( map( res => res.data ) );
    }

    getRefusedRequests( idZone: number ): Observable<IForecastRequest[]> {
        const url = `${ this.forecastUrl }/request/refused?idZone=${ idZone }`;
        return this.http.get<IGetApprovalRequestsResponse>( url ).pipe( map( res => res.data ) );
    }

    updateTotals() {
        this.store.select( CommonsSelectors.selectFiscalYear ).pipe(
            switchMap( fy => this.http.put( `${this.baseUrl}/recalculate`, {}, { params: { fy } } ) ),
            tap( () => this.toaster.success( 'Ricalcolo avviato. Durerà alcuni minuti' ) )
        ).subscribe();
    }
}
