/* eslint-disable @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument */
import { ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServerCommunicationService } from 'app/routes/nd/services/server-communication.service';
import { UploadFile, UploadInput } from 'ngx-uploader';
import { Subject, Subscription } from 'rxjs';
import { UploaderConfig } from '@shared/directives/tus-uploader.directive';
import { FilesContainerService, RemoteFile } from '@shared/services/files-container.service';
import { TusSingleFileUploader } from '@shared/services/upload.service';
import { UploadingFileStatuses } from '@shared/models/uploading-file-statuses';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { BASE_URL } from '@shared/models/urls-constants';


@Component( {
    selector: 'wnd-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: [ './file-uploader.component.scss' ],
    providers: [ FilesContainerService ],
} )
export class FileUploaderComponent implements OnInit, OnDestroy {
    savedFilesColumns: MtxGridColumn[] = [
        { header: 'Name', field: 'filename', width: '220px' },
        { header: 'Size', field: 'length' },
        { header: 'Owner', field: 'createUser' },
        {
            header: 'Uploaded',
            field: 'uploadDate',
            formatter: ( data: any ) => new Date( data.uploadDate ).toLocaleDateString(),
            type: 'date',
        },
        {
            header: 'Operation',
            field: 'operation',
            width: '115px',
            pinned: 'right',
            right: '0px',
            type: 'button',
            buttons: [
                {
                    type: 'icon',
                    text: 'download',
                    icon: 'cloud_download',
                    click: ( file ) => this.download( file ),
                },
                {
                    type: 'icon',
                    text: 'delete',
                    icon: 'delete',
                    color: 'warn',
                    pop: { title: 'Confirm delete?' },
                    click: ( file ) => this.delete( file ),
                },
            ],
        },
    ];
    comId: number;
    url = this.baseUrl + 'wonder-src-workflow-api/Documents/Upload';
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;

    /*
    */
    tusConfig: UploaderConfig;
    remoteFile: RemoteFile;
    allFiles: RemoteFile[] = [];
    canUpload = false;
    isUploading = false;

    private metadata: any;
    private subscriptions: Subscription[] = [];
    private refreshSubject = new Subject<void>();
    private tusUploader: TusSingleFileUploader;

    constructor(
        private serverCommunicationService: ServerCommunicationService,
        private toastr: ToastrService,
        public dialogRef: MatDialogRef<FileUploaderComponent>,
        @Inject( MAT_DIALOG_DATA ) public data: any,
        private cdr: ChangeDetectorRef,
        @Inject( BASE_URL ) private baseUrl: string,
        public filesContainerService: FilesContainerService,
        private dialog: MtxDialog,
    ) {
        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
    }

    get controlDisabled(): boolean {
        return this.data?.disabled;
    }

    get maxFilesNumber(): number {
        const filePropeMaxNumberOfFile = !!this.data?.fileProperties?.maxNumberOfFile ? this.data?.fileProperties?.maxNumberOfFile : 20;
        const value = filePropeMaxNumberOfFile - this.allFiles.length;
        return value > 0 ? value : 0;
    }

    ngOnInit(): void {
        this.comId = this.data.comId;
        this.metadata = {
            entity: 'step',
            stepId: this.comId,
            fieldKey: 'file',
        };
        this.tusConfig = {
            metadata: this.metadata,
            allowedTypes: this.data?.fileProperties?.allowedExstension,
            lazyStart: false,
        };
        this.filesContainerService.setFilter( this.metadata );
        this.subscriptions.push(
            this.refreshSubject.subscribe( () =>
                this.filesContainerService.refreshCollection()
                    .subscribe( res => {
                        if( res.length > 0 ) {
                            this.remoteFile = res[ 0 ];
                            this.allFiles = Array.from( new Set( res.map( a => a.id ) ) )
                                .map( id => res.find( a => a.id === id ) );
                            this.canUpload = false;
                        }
                    } ),
            ),
        );
        this.refreshSubject.next();
    }

    ngOnDestroy(): void {
        while( this.subscriptions.length ) {
            this.subscriptions.pop().unsubscribe();
        }
    }

    handleError( msg: string ) {
        this.toastr.warning( msg );
    }

    onUploadEvent( event: { source: TusSingleFileUploader; uploaders: TusSingleFileUploader[] } ) {
        this.tusUploader = event.source;
        switch( event.source.fileStatus.state ) {
        case UploadingFileStatuses.PAUSED: {
            this.isUploading = false;
            break;
        }
        case UploadingFileStatuses.SERVER_ERROR: {
            this.toastr.warning( 'Upload fallito, riprovare in seguito.' );
            this.isUploading = false;
            this.canUpload = true;
            break;
        }
        case UploadingFileStatuses.RUNNING: {
            // this.fileNameControl.setValue(event.source.fileStatus.filename);
            this.isUploading = true;
            this.canUpload = false;
            break;
        }
        case UploadingFileStatuses.CANCELED: {
            break;
        }
        case UploadingFileStatuses.COMPLETED: {
            this.isUploading = false;
            this.canUpload = false;
            this.refreshSubject.next();
            break;
        }
        default: {
            break;
        }
        }
    }

    delete( file ) {
        this.dialog.confirm( 'Confirm delete?', null, () => {
            this.isUploading = true;
            file.delete().subscribe( () => {
                this.remoteFile = undefined;
                this.allFiles = this.allFiles.filter( f => f.id !== file.id );
                this.isUploading = false;
                this.canUpload = true;
            }, error => {
                console.error( 'Error in deleting file: ', error );
                this.toastr.error( 'Cannot delete the file. Problem with the server. Try again later.' );
            } );
            this.cdr.detectChanges();
        } );
    }

    // File download
    download( file ) {
        file.download();
    }

    onSelectionError( message: string ) {
        this.toastr.error( message );
    }
}
