// noinspection SpellCheckingInspection,JSUnusedLocalSymbols,DuplicatedCode

import { ActivityConfig } from './activity_config';
import { okKo } from './arrayXSelect';
import { extraAccessiSito, extraFlowNPlus, extraMotivazioneRecessoInwit } from './extraFlow';

// workFlow
export const dismissioneInwit: ActivityConfig = {
    extraFlowConfig: [
        {
            name: 'Ext_Motivazione Recesso Inwit',
            displayName: 'Motivazione Recesso Inwit',
            fields: extraMotivazioneRecessoInwit,
        },
        {
            name: 'Ext_Accessi al sito',
            displayName: 'Accessi al sito',
            fields: extraAccessiSito,
        },
        {
            name: 'Ext_Legame NPlus',
            displayName: 'Legame NPlus',
            fields: extraFlowNPlus,
        },
    ],
    name: 'dismissioneInwit',
    displayName: 'dismissioneInwit',
    stepsConfig: [
        {
            displayName: 'Richiesta e Ottenimento Permessi',
            name: '200_Richiesta e Ottenimento Permessi',
            isEntryStep: true,
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data di Riferimento',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'DocDataRiferimento',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'Documento Comunicazione Data di Riferimento',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data di Permesso Ottenuto',
                            },
                        },
                        // {
                        //   className: 'col-sm-6',
                        //   type: 'singleFile',
                        //   key: 'DocPermesso ttenuto',
                        //   templateOptions: {
                        //     //disableIfCommited: true,
                        //     custom: true,
                        //     label: 'Upload file Permesso Ottenuto',
                        //     //required: true,
                        //     fileProperties: {
                        //       id: '1',
                        //       allowedExstension: ['.pdf', '.p7m', '.doc', '.docx', '.xlsx']
                        //     },
                        //   },
                        // },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Data di Spegnimento',
                            },
                        },

                    ],
                },
            ],
            nextStep: m => {
                return '400_Dismissione e Cantiere';
            },
        },
        {
            displayName: 'Dismissione e Cantiere',
            name: '400_Dismissione e Cantiere',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Data di Dismissione (effettivo rilascio)',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'DocSiteDisinstallationReport',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'Site Disinstallation Report',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'communication',
                            key: 'communication',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                required: true,
                                label: 'Comunicazioni Accettazione Lavorazione',
                                communicationConfig: {
                                    fields: [
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'select',
                                                    key: 'string1',
                                                    templateOptions: {
                                                        // required: true,
                                                        label: 'Esito',
                                                        options: okKo,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            fieldGroupClassName: 'row',
                                            validators: {
                                                validation: [
                                                    {
                                                        name: 'fieldDateMatch',
                                                        options: {
                                                            fieldTocheck: [
                                                                {
                                                                    fromDate: 'date1',
                                                                    toDate: 'date2',
                                                                    errorText: 'Data Risposta > Data Richiesta'
                                                                },
                                                            ]
                                                        }
                                                    }
                                                ],
                                            },
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'datepicker',
                                                    key: 'date1',
                                                    templateOptions: {
                                                        required: true,
                                                        label: 'Data Invio INWIT',
                                                    },
                                                },
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'datepicker',
                                                    key: 'date2',
                                                    templateOptions: {
                                                        // required: true,
                                                        label: 'Data Risposta INWIT',
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-12',
                                                    type: 'textarea',
                                                    key: 'string2',
                                                    templateOptions: {
                                                        required: false,
                                                        label: 'Note',
                                                        rows: 3,
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },

                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data di Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InizioLavori',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'InizioLavori',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data di Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FineLavori',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'FineLavori',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
        {
            displayName: 'Chiusura attività',
            name: '999_Chiusura attività',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Data chiusura attività',
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
