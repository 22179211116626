import { FieldType } from '@ngx-formly/core';
import { Component, OnInit } from '@angular/core';
import { StepService } from '../../step.service';
import { Observable, Subject } from 'rxjs';

@Component( {
    selector: 'wnd-custom-field-type',
    templateUrl: './custom-field-type.component.html',
    styleUrls: [ './custom-field-type.component.scss' ],
} )
export abstract class CustomFieldTypeComponent extends FieldType implements OnInit {
    constructor( public stepService: StepService ) {
        super();
    }

    formlyKey: string;

    abstract defaultLabel(): string;

    abstract onCommit(): Observable<void>;

    abstract onDraftSaving(): Observable<void>;

    ngOnInit(): void {
        setTimeout( () => {
            this._init();
        } );
    }


    getFormControlLabel(): string {

        const label = this.field?.templateOptions?.label ? this.field.templateOptions.label : this.defaultLabel();
        return this.field?.templateOptions?.required ? label + ' *' : label;
    }


    private _init() {
        this.formlyKey = this.field.key.toString();
        this.stepService.step.childSaveEvents.push( {
            key: this.formlyKey,
            event: new Subject(),
        } );
        this.stepService.step.saveRequest.subscribe( m => {
            if( m === 'save' ) {
                this.onDraftSaving().subscribe( () => {
                    const childSalvedSub = this.stepService.step.childSaveEvents.filter(
                        c => c.key === this.formlyKey,
                    )[ 0 ].event;
                    childSalvedSub.next();
                } );
            }
            if( m === 'commit' ) {
                this.onCommit().subscribe( () => {
                    const childSalvedSub = this.stepService.step.childSaveEvents.filter(
                        c => c.key === this.formlyKey,
                    )[ 0 ].event;
                    childSalvedSub.next();
                } );
            }
        } );
    }
}
