import { Subscription } from 'rxjs';
import { BaseFilterComponent } from './../base-filter/base-filter.component';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WndGridColumnService } from '@shared/components/wonder-grid/services/wnd-grid-column-service';
import { DOCUMENT } from '@angular/common';

@Component( {
    selector: 'wnd-text-filter',
    templateUrl: './text-filter.component.html',
    styleUrls: [ './text-filter.component.scss' ]
} )
export class TextFilterComponent extends BaseFilterComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild( 'menutrigger' ) menuTrigger: ElementRef;

    openingPosition: { x: number; y: number } = { x: 0, y: 0 };
    private filterMenuSpaceRequired = 0;
    private mustOpen = false;
    private isOpenConfirmed = false;
    private closeDisabled = false;
    private subscriptions: Subscription[] = [];
    clientWidth = 0;
    clientHeight = 0;
    opened = false;


    constructor( public columnService: WndGridColumnService, @Inject( DOCUMENT ) private document: Document ) {
        super( columnService );
        this.registerClosing();
    }


    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach( s => s.unsubscribe() );
    }

    ngAfterViewInit(): void {
        setTimeout( () => {
            this.filterMenuSpaceRequired = +window.getComputedStyle( document.documentElement ).getPropertyValue( '--filter-text-pop-up' ).replace( 'px', '' );
            const element = this.menuTrigger.nativeElement as { clientWidth: number; clientHeight: number };
            this.clientWidth = element.clientWidth;
            this.clientHeight = element.clientHeight;
        } );

    }

    onClick() {
        if( !this.opened ) {
            this.mustOpen = true;
            this.openMenu();
        }

    }

    mouseEnter() {
        this.mustOpen = true;
        setTimeout( () => {
            if( this.mustOpen ) {
                this.openMenu();
            }

        }, 400 );

    }

    mouseLeave() {
        this.mustOpen = false;
        setTimeout( () => {
            this.opened = this.isOpenConfirmed;
        }, 200 );
    }


    onOutClick( event: MouseEvent ) {

        if( ( event.target as unknown as { id: string } ).id === 'menutrigger' || this.closeDisabled ) {

        } else {
            this.opened = false;
            this.mustOpen = false;
            this.isOpenConfirmed = false;
        }

    }

    onBackClick() {

    }

    cardMouseLeave() {

    }

    cardMouseEnter() {
        this.isOpenConfirmed = true;
    }

    preventCloseHandler( val: boolean ) {
        this.preventClose.next( val );
    }


    private registerClosing() {
        this.subscriptions.push( this.preventClose.subscribe( val => {
            this.closeDisabled = val;
        } ) );
    }


    private calculatePosition() {
        const materoContainer = this.document.getElementsByClassName( 'matero-content' )[ 0 ].getBoundingClientRect();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        const trig = this.menuTrigger.nativeElement.getBoundingClientRect() as DOMRect;
        const right = materoContainer.right - trig.right;
        const left = trig.left - materoContainer.left;

        if( right >= this.filterMenuSpaceRequired + 5 ) {
            this.openingPosition = {
                x: this.clientWidth - 3,
                y: -41.2
            };
        } else {
            if( left >= this.filterMenuSpaceRequired + 5 ) {
                this.openingPosition = {
                    x: -this.filterMenuSpaceRequired + 3,
                    y: -41.2
                };
            } else {
                this.openingPosition = {
                    x: ( trig.width - this.filterMenuSpaceRequired ) / 2,
                    y: 0
                };
            }
        }


    }

    private openMenu() {
        this.calculatePosition();
        this.opened = true;
    }

}
