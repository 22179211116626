import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { ChartEvent, ChartOptions } from 'chart.js';
import * as FileSaver from 'file-saver';

@Component( {
    selector: 'wnd-base-chart',
    templateUrl: './base-chart.component.html',
    styleUrls: [ './base-chart.component.scss' ],
} )
export class BaseChartComponent implements OnInit {
    @Input() chartType: 'bar' | 'line' | 'polarArea' | 'radar' = 'bar';
    @Input() chartData: any[];
    @Input() chartLabels: string[];

    @ViewChild( 'theChart' ) theChart: BaseChartDirective;

    data: any[];

    public chartOptions: ( ChartOptions & { annotation: any } ) = {
        // legend: { labels: { fontColor: 'white' } },
        responsive: true,
        scales: {},
        // We use this empty structure as a placeholder for dynamic theming.
        /*
        xAxes: [
          {
            ticks: { fontColor: 'white' },
          },
        ],
        yAxes: [
          {
            id: 'y-axis-0',
            position: 'left',
            ticks: {
              fontColor: 'white',
              beginAtZero: true,
            },
            gridLines: {
              color: 'rgba(255,0,0,0.3)',
            },
          },
          */
        /*
                {
                  id: 'y-axis-1',
                  position: 'right',
                  gridLines: {
                    color: 'rgba(255,0,0,0.3)',
                  },
                  ticks: {
                    fontColor: 'coral',
                  },
                },
        */
        annotation: {
            annotations: [
                {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    value: 'March',
                    borderColor: 'orange',
                    borderWidth: 2,
                    label: {
                        enabled: true,
                        fontColor: 'orange',
                        content: 'LineAnno',
                    },
                },
            ],
        },
    };
    /*
  public chartColors: Color[] = [
        { // grey
          backgroundColor: 'rgba(148,159,177,0.2)',
          borderColor: 'rgba(148,159,177,1)',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        },
        { // dark grey
          backgroundColor: 'rgba(77,83,96,0.2)',
          borderColor: 'rgba(77,83,96,1)',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,1)',
        },
        { // red
          backgroundColor: 'rgba(255,0,0,0.3)',
          borderColor: 'red',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        },
    ];
    */
    public chartLegend = true;
    // public lineChartPlugins = [pluginAnnotations];

    @ViewChild( BaseChartDirective, { static: true } ) chart: BaseChartDirective;

    constructor() {
    }

    ngOnInit(): void {
        this.data = this.chartData;
    }

    chartHovered( _$event: { event: ChartEvent; active: {}[] } ) {

    }

    chartClicked( _$event: { event?: ChartEvent; active?: {}[] } ) {

    }

    b64toBlob( b64Data: string, sliceSize: number = 512 ): null | Blob {
        const dataArray = b64Data.split( ',' );
        let contentType = '';
        if( dataArray.length <= 0 ) {
            console.warn( 'Invalid input to b64toBlob', b64Data );
            return null;
        }
        if( dataArray.length > 1 ) {
            const bits = dataArray[ 0 ].split( /:;/ );
            console.log( 'Content type', bits );
            contentType = bits[ 1 ];
        }

        b64Data = dataArray[ dataArray.length - 1 ];

        const byteCharacters = atob( b64Data );
        const byteArrays = [];

        for( let offset = 0; offset < byteCharacters.length; offset += sliceSize ) {
            const slice = byteCharacters.slice( offset, offset + sliceSize );

            const byteNumbers = new Array( slice.length );
            for( let i = 0; i < slice.length; i++ ) {
                byteNumbers[ i ] = slice.charCodeAt( i );
            }

            const byteArray = new Uint8Array( byteNumbers );

            byteArrays.push( byteArray );
        }

        return new Blob( byteArrays, { type: contentType } );
    }

    export() {
        console.log( 'Starting export' );
        const image = this.theChart.chart.toBase64Image();
        console.log( 'image: ', image );
        const imageBlob = this.b64toBlob( image );
        FileSaver.saveAs( imageBlob, 'chart.png' );
    }
}
