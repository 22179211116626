/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return */
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MtxSelectComponent } from '@ng-matero/extensions/select';
import { FieldType } from '@ngx-formly/core';


@Component( {
    selector: 'formly-field-combobox',
    template: `
        <mtx-select
                #select
                [formControl]='formControl'
                [items]='props.options | toObservable | async'
                [bindLabel]='props.labelProp'
                [bindValue]='bindValue'
                [multiple]='props.multiple'
                [placeholder]='props.placeholder'
                [required]='props.required'
                [closeOnSelect]='props.multiple'
                [compareWith]='props.compareWith'
        >
        </mtx-select>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class FormlyFieldComboboxComponent extends FieldType {
    @ViewChild( 'select', { static: true } ) select: MtxSelectComponent;

    get bindValue() {
        return typeof this.props.valueProp === 'string' ? this.props.valueProp : undefined;
    }

    // The original `onContainerClick` has been covered up in FieldType, so we should redefine it.
    onContainerClick( event: MouseEvent ) {
        const target = event.target as HTMLElement;
        if( /mat-form-field|mtx-select/g.test( target.parentElement?.classList[ 0 ] || '' ) ) {
            this.select.focus();
            this.select.open();
        }
    }
}
