import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { environment } from '@env/environment';

@Component( {
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: [ './sidebar.component.scss' ],
    encapsulation: ViewEncapsulation.None,
} )
export class SidebarComponent {
    @Input() showToggle = true;
    @Input() showUser = true;
    @Input() showHeader = true;
    @Input() toggleChecked = false;

    @Output() toggleCollapsed = new EventEmitter<void>();

    version = environment.version;

    constructor() {
    }
}
