<!--        [style.height]="mapHeight"-->
<div  fluidHeight [padding]="heightPadding"
        (window:resize)="onResize($event)"
        class="map-container"
        [style.max-width]="maxWidth"
        leaflet
        [leafletOptions]="options"
        (leafletMapReady)="onMapReady($event)"
        (leafletMapZoomEnd)="onMapZoomEnd($event)"
        (leafletMapMoveEnd)="onMapMoveEnd($event)"
        [leafletMarkerCluster]="markerClusterData"
        [leafletMarkerClusterOptions]="markerClusterOptions"
        (leafletMarkerClusterReady)="markerClusterReady($event)"
        [leafletLayer]="drawItemGroup"
        [leafletLayersControl]="layersControl"
>
    <div fluidHeight [padding]="heightPadding"
            *ngIf="drawConfig.showControls"
            leafletDraw
            [leafletDrawOptions]="drawConfig.drawOptions"
            [leafletDrawLocal]="drawConfig.drawLocal"
            (leafletDrawCreated)="onDrawCreated($event)"
            (leafletDrawEdited)="onDrawEdited($event)"
            (leafletDrawDeleted)="onDrawDeleted($event)"
            (leafletDrawStart)="onChangingMode($event)"
            (leafletDrawDeleteStart)="onChangingMode($event)"
            (leafletDrawEditStart)="onChangingMode($event)"
            (leafletDrawStop)="onChangingMode($event)"
            (leafletDrawDeleteStop)="onChangingMode($event)"
            (leafletDrawEditStop)="onChangingMode($event)"
            (leafletDrawReady)="onDrawReady($event)"
    ></div>
</div>

<wnd-coords-marker-input
        *ngIf="showCoordInput"
        (drawingEnd)="markerInputEnd()"
        [drawControl]="drawControl"
        [position]="'bottomleft'"
        [map]="map"
>
</wnd-coords-marker-input>

<app-map-search *ngIf='showGeoSearch' [map]='map'></app-map-search>
