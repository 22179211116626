// noinspection SpellCheckingInspection,JSUnusedLocalSymbols,DuplicatedCode

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivityConfig } from './activity_config';

import { ambitoNormativo, okKo, strutturaDiInwit, tagliaSito, tipologiaApparati } from './arrayXSelect';
import { extraAccessiSito, extraAdeguamentoPassive, extraFlowAnagraficaNew, extraFlowAnagraficaTIM, extraFlowForecast, extraFlowNPlus } from './extraFlow';


//extraFlow
//sono stati inseriti su extraflow.ts
export const commitment: ActivityConfig = {
    extraFlowConfig: [
        {
            name: 'Ext_New Forecast',
            displayName: 'New Forecast',
            fields: extraFlowForecast,
        },
        {
            name: 'Ext_Anagrafica New',
            displayName: 'Anagrafica New',
            fields: extraFlowAnagraficaNew,
        },
        {
            name: 'Ext_Anagrafica TIM',
            displayName: 'Anagrafica TIM',
            fields: extraFlowAnagraficaTIM,
        },
        // {
        //   name: 'Ext_Anagrafica Old',
        //   displayName: 'Ext_Anagrafica Old',
        //   fields: extraFlowAnagraficaOld,
        // },
        {
            name: 'Ext_Legame NPlus',
            displayName: 'Legame NPlus',
            fields: extraFlowNPlus,
        },
        {
            name: 'Ext_Accessi al sito',
            displayName: 'Accessi al sito',
            fields: extraAccessiSito,
        },
        {
            name: 'Ext_Adeguamento Passive',
            displayName: 'Adeguamento Passive',
            fields: extraAdeguamentoPassive,
        },
        // {
        //   name: 'Ext_Rinegoziazione Inwit',
        //   displayName: 'Ext_Rinegoziazione Inwit',
        //   fields: extraRinegoziazioneInwit,
        // },
    ],
    name: 'commitment',
    displayName: 'commitment',
    stepsConfig: [
        {
            displayName: 'New Stand Alone',
            name: '010_New Stand Alone',
            isEntryStep: true,
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Tipologia Sito',
                                options: [
                                    {
                                        label: '', value: null,
                                    },
                                    {
                                        label: 'Nuovo Sito con Area di Ricerca',
                                        value: 'Nuovo Sito con Area di Ricerca',
                                    },
                                    {
                                        label: 'Nuovo Sito su struttura INWIT già concordata',
                                        value: 'Nuovo Sito su struttura INWIT già concordata',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                if( m.string1 === 'Nuovo Sito con Area di Ricerca' ) {
                    return '020_Emissione Area di Ricerca';
                } else {
                    return '040_Registrazione Struttura';
                }
            },
        },
        {
            displayName: 'Emissione Area di Ricerca',
            name: '020_Emissione Area di Ricerca',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            name: 'searchArea',
                            key: 'searchArea',
                            type: 'searchArea',
                            templateOptions: {
                                required: true,
                                disableIfCommited: true,
                                custom: true,
                                map: {
                                    errorMessage: 'You can\'t submit more than one area.',
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '030_Invio SARF INWIT';
            },
        },
        {
            displayName: 'Registrazione Struttura',
            // inserire constraints ricezione SAR NO
            name: '040_Registrazione Struttura',
            fields: [
                // {
                //   fieldGroupClassName: 'row',
                //   fieldGroup: [
                //     {
                //       className: 'col-sm-12',
                //       type: 'datepicker',
                //       key: 'date1',
                //       templateOptions: {
                //         //disableIfCommited: true,
                //         required: true,
                //         label: 'Data registrazione struttura',
                //       },
                //     },
                //   ],
                // },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            name: 'registrazioneStruttura',
                            key: 'candidate',
                            type: 'candidate',
                            templateOptions: {
                                structureOwners: strutturaDiInwit,
                                required: true,
                                disableIfCommited: true,
                                custom: true,
                                scope: [ 'population', 'selection' ],
                                map: {
                                    numberOfItems: {
                                        min: 1,
                                        max: 1,
                                    },
                                    errorMessage: 'You can\'t submit more than 1 candidates.',
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                // da valutare se next step con TIM
                return '050_Emissione Scheda Radio';
            },
        },
        {
            displayName: 'Invio SARF INWIT',
            name: '030_Invio SARF INWIT',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data Invio SARF',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InvioSarfInwit',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'Upload Invio SARF INWIT',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '040_Ricezione SAR';
            },
        },
        {
            displayName: 'Ricezione SAR',
            // inserire constraints ricezione SAR NO
            name: '040_Ricezione SAR',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            name: 'registrazioneStruttura',
                            key: 'candidate',
                            type: 'candidate',
                            templateOptions: {
                                structureOwners: strutturaDiInwit,
                                required: true,
                                disableIfCommited: true,
                                custom: true,
                                scope: [ 'population', 'selection' ],
                                map: {
                                    numberOfItems: {
                                        min: 1,
                                        max: 3,
                                    },
                                    errorMessage: 'You can\'t sumbit more than 3 candidates.',
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'communication',
                            key: 'communication',
                            templateOptions: {
                                custom: true,
                                required: false,
                                label: 'Comunicazione KO candidato a Inwit',
                                communicationConfig: {
                                    fields: [
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-6',
                                                    type: 'input',
                                                    key: 'string1',
                                                    templateOptions: {
                                                        required: false,
                                                        label: 'Candidato',
                                                        rows: 1,
                                                    },
                                                },
                                                {
                                                    className: 'col-6',
                                                    type: 'datepicker',
                                                    key: 'date1',
                                                    templateOptions: {
                                                        required: true,
                                                        label: 'Data Invio INWIT',
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-12',
                                                    type: 'textarea',
                                                    key: 'string2',
                                                    templateOptions: {
                                                        required: false,
                                                        label: 'Note',
                                                        rows: 3,
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '050_Emissione Scheda Radio';
            },
        },
        {
            displayName: 'Emissione Scheda Tecnica',
            name: '050_Emissione Scheda Radio',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data Emissione Scheda Tecnica',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'EmissioneSchedaRadio',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload Emissione Scheda Tecnica',
                                custom: true,
                                required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '060_Comunicazione INWIT';
            },
        },
        {
            displayName: 'Comunicazione INWIT',
            name: '060_Comunicazione INWIT',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data Comunicazione Approvazione Candidato e Invio Scheda Tecnica',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'ComunicazioneApprovazioneCandidato',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload Comunicazione Approvazione Candidato',
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '100_Sopralluogo';
            },
        },
        {
            displayName: 'Sopralluogo',
            name: '100_Sopralluogo',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Data primo sopralluogo',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '200_Predisposizione pacchetto e progettazione';
            },
        },
        {
            // progettazione in carico a INWIT
            displayName: 'Predisposizione pacchetto e progettazione',
            name: '200_Predisposizione pacchetto e progettazione',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string2',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Tipologia struttura',
                                options: [
                                    { label: '', value: '' },
                                    { label: 'Esistente', value: 'Esistente' },
                                    { label: 'Nuova', value: 'Nuova' },
                                ],
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'flag2',
                            templateOptions: {
                                disableIfCommited: true,
                                //required: true,
                                label: 'Sito vincolato',
                            },
                        },
                    ],
                },

                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Tipologia presentazione con INWIT',
                                change: m => {
                                    if( m.model.string1 !== 'Congiunta' ) {
                                        ( ( m.parent.parent.formControl as UntypedFormGroup ).controls.date1 as UntypedFormControl ).setValue( null );
                                        ( ( m.parent.parent.formControl as UntypedFormGroup ).controls.date2 as UntypedFormControl ).setValue( null );
                                    }
                                },
                                options: [
                                    { label: '', value: '' },
                                    { label: 'Congiunta', value: 'Congiunta' },
                                    { label: 'Non Congiunta', value: 'Non Congiunta' },
                                ],
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'flag1',
                            templateOptions: {
                                disableIfCommited: true,
                                //required: true,
                                label: 'Presentazione con TIM',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'select',
                            key: 'string3',
                            templateOptions: {
                                disableIfCommited: true,
                                //required: true,
                                label: 'Indicazione della potenza massima ai connettori d\'antenna ',
                                options: [
                                    { label: '', value: '' },
                                    { label: 'Potenza come da Scheda Tecnica', value: 'Potenza come da Scheda Tecnica' },
                                    { label: 'Potenza inferiore a Scheda Tecnica', value: 'Potenza inferiore a Scheda Tecnica' },
                                ],
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione AIE Vodafone > Ricezione AIE da INWIT'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Approvazione AIE Vodafone > Ricezione PDM da INWIT'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                //required: true,
                                label: 'Ricezione AIE da INWIT',
                            },
                            //hideExpression: 'model.string1 != "Congiunta"',
                            expressionProperties: {
                                'templateOptions.disabled': 'model.string1 != "Congiunta"',
                                'templateOptions.required': 'model.string1 === "Congiunta"',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                //required: true,
                                label: 'Approvazione AIE Vodafone',
                            },
                            // hideExpression: 'model.string1 != "Congiunta"',
                            expressionProperties: {
                                'templateOptions.disabled': 'model.string1 != "Congiunta"',
                                'templateOptions.required': 'model.string1 === "Congiunta"',
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        //disableIfCommited: true,
                        //required: true,
                        custom: true,
                        //label: 'Valutazione PDM',
                        label: 'Richiesta modifiche PDM',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                //label: 'Approvazione',
                                                label: 'Motivazione',
                                                options: [
                                                    // {
                                                    //   label: 'OK',
                                                    //   value: 'OK',
                                                    // },
                                                    {
                                                        label: 'Reinvio per richiesta modifiche lievi',
                                                        value: 'Reinvio per richiesta modifiche lievi',
                                                    },
                                                    {
                                                        label: 'Reinvio per richiesta modifiche rilevanti',
                                                        value: 'Reinvio per richiesta modifiche rilevanti',
                                                    },
                                                ]
                                            }
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta a Inwit > Data Ricezione PDM da INWIT'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Ricezione PDM da INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta a Inwit',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },

                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },

                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ricezione PDM definitivo da INWIT',
                            },
                            // hideExpression: 'model.string1 != "Congiunta"',
                            expressionProperties: {
                                //'templateOptions.disabled': 'model.string1 != "Congiunta"',
                                //'templateOptions.required': 'model.string1 === "Congiunta"',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                // required: true,
                                label: 'Approvazione PDM definitivo Vodafone',
                            },
                            // hideExpression: 'model.string1 === "Congiunta"',
                            expressionProperties: {
                                //'templateOptions.disabled': 'model.string1 != "Congiunta"',
                                //'templateOptions.required': 'model.string1 === "Congiunta"',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'singleFile',
                            key: 'ComunicazioneApprovazionePDM',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload Comunicazione Approvazione PDM',
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                if( m.string1 === 'Congiunta' ) {
                    return '210_Presentazione Permessi Congiunta';
                } else {
                    return '210_Presentazione Permessi INWIT';
                }
            },
        },
        {
            displayName: 'Presentazione Permessi Congiunta',
            name: '210_Presentazione Permessi Congiunta',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione AIE Vodafone > Ricezione AIE da INWIT'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Approvazione AIE Vodafone > Ricezione PDM da INWIT'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione AIE',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso AIE',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione PDM',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso PDM',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '300_Room Ready INWIT';
            },
        },
        {
            displayName: 'Presentazione Permessi INWIT',
            name: '210_Presentazione Permessi INWIT',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Ottenimento Permesso AIE Inwit > Presentazione AIE Inwit'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Ottenimento Permesso PDM Inwit > Presentazione PDM Inwit'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione AIE Inwit',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso AIE Inwit',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione PDM Inwit',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso PDM Inwit',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '220_Progettazione_operatore';
            },
        },
        {
            displayName: 'Progettazione operatore',
            name: '220_Progettazione_operatore',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione scheda radio > Assegnazione scheda radio'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Approvazione PDM > Ricezione PDM'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                label: 'Assegnazione scheda radio (AIE)',
                                disableIfCommited: false,
                                required: true,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                label: 'Approvazione scheda radio (AIE)',
                                disableIfCommited: false,
                                required: true,
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                label: 'Predisposizione PDM',
                                disableIfCommited: true,
                                required: true,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                label: 'Approvazione PDM',
                                disableIfCommited: true,
                                required: true,
                            },
                        },
                    ],
                },

                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                label: 'Data di Assenso alla Presentazione',
                                disableIfCommited: false,
                                required: true,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'VerbaleAssensoPresentazione',
                            templateOptions: {
                                label: 'Verbale di Assenso alla Presentazione',
                                disableIfCommited: false,
                                custom: true,
                                required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },

            ],
            nextStep: m => {
                return '230_Presentazione_permessi_operatore';
            },
        },
        {
            displayName: 'Presentazione permessi operatore',
            name: '230_Presentazione_permessi_operatore',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                label: 'Presentazione AIE',
                                disableIfCommited: false,
                                required: true,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                label: 'Presentazione PDM',
                                disableIfCommited: false,
                                required: true,
                            },
                        },
                    ],
                },

            ],
            nextStep: m => {
                return '300_Ottenimento_Permessi_Room_Ready_e_VIC';
            },
        },
        {
            // Room Ready in forecast extraflow + separazione step presentazione permessi e Room Ready
            displayName: 'Room Ready Inwit & VIC',
            name: '300_Room Ready INWIT',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'VIC - Verbale Inizio Condivisione > Room Ready Inwit'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Room Ready Inwit',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'VIC - Verbale Inizio Condivisione',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'singleFile',
                            key: 'VerbaleInizioCondivisione',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'Upload VIC - Verbale Inizio Condivisione',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '400_Selezione Ambito Normativo';
            },
        },
        {
            displayName: 'Ottenimento Permessi, Room Ready e VIC',
            name: '300_Ottenimento_Permessi_Room_Ready_e_VIC',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso AIE Vodafone',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso PDM Vodafone',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Room Ready Inwit',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'VIC - Verbale Inizio Condivisione',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'singleFile',
                            key: 'VerbaleInizioCondivisione',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'Upload VIC - Verbale Inizio Condivisione',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '400_Selezione Ambito Normativo';
            },
        },
        {
            displayName: 'Selezione Ambito Normativo',
            name: '400_Selezione Ambito Normativo',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Ambito Normativo',
                                options: ambitoNormativo,
                            },
                        },

                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data definizione ambito normativo',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                if( m.string1 === 'Tit. IV' ) {
                    //return 'Progetto Esecutivo (Tit. IV)';
                    return '410_Progetto Esecutivo (Tit. IV)';
                } else {
                    //return 'Progetto Esecutivo (Art.26)';
                    return '410_Progetto Esecutivo (Art.26)';
                }
            },
        },
        {
            displayName: 'Progetto Esecutivo (Tit. IV)',
            name: '410_Progetto Esecutivo (Tit. IV)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione PE a Ufficio Tecnico > Consegna PE a Ufficio Tecnico'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Consegna PE a Ufficio Tecnico',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Approvazione PE a Ufficio Tecnico',
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        required: true,
                        //disableIfCommited: true,
                        custom: true,
                        label: 'Richiesta Approvazione progetto a INWIT',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Parere INWIT',
                                                options: okKo,
                                            }
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta > Data Richiesta'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },

                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            ],
            nextStep: m => {
                return '420_Cantiere (Tit. IV)';
            },
        },
        {
            displayName: 'Progetto Esecutivo (Art.26)',
            name: '410_Progetto Esecutivo (Art.26)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Consegna PE a NI',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Approvazione PE a NI',
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        //disableIfCommited: true,
                        custom: true,
                        label: 'Richiesta Approvazione progetto a INWIT',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Parere INWIT',
                                                options: okKo,
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta > Data Richiesta'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            ],
            nextStep: m => {
                return '420_Cantiere (Art.26)';
            },
        },
        {
            displayName: 'Cantiere (Tit. IV)',
            name: '420_Cantiere (Tit. IV)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Scelta Tipologia Apparati',
                                options: tipologiaApparati,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'flag1',
                            templateOptions: {
                                disableIfCommited: false,
                                //required: true,
                                label: 'Variante in corso d\'opera',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Data Inizio Lavori > Data Consegna Area'
                                        },
                                        {
                                            fromDate: 'date2',
                                            toDate: 'date3',
                                            errorText: 'Data Fine Lavori > Data Inizio Lavori'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Data Chiusura Enti > Data Fine Lavori'
                                        },
                                        {
                                            fromDate: 'date4',
                                            toDate: 'date5',
                                            errorText: 'Data Riconsegna Area > Data Chiusura Enti'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Consegna Area',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FileConsegnaArea',
                            templateOptions: {
                                label: 'Upload File Consegna Area',
                                //disableIfCommited: true,
                                custom: true,
                                required: false,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InizioLavori',
                            templateOptions: {
                                label: 'Upload File Inizio Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FineLavori',
                            templateOptions: {
                                label: 'Upload File Fine Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'communication',
                            key: 'communication',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                required: true,
                                label: 'Comunicazioni Collaudo Tecnico Amministrativo',
                                communicationConfig: {
                                    fields: [
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'select',
                                                    key: 'string1',
                                                    templateOptions: {
                                                        // required: true,
                                                        label: 'Esito',
                                                        options: okKo,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            fieldGroupClassName: 'row',
                                            validators: {
                                                validation: [
                                                    {
                                                        name: 'fieldDateMatch',
                                                        options: {
                                                            fieldTocheck: [
                                                                {
                                                                    fromDate: 'date1',
                                                                    toDate: 'date2',
                                                                    errorText: 'Data Risposta > Data Richiesta'
                                                                },
                                                            ]
                                                        }
                                                    }
                                                ],
                                            },
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'datepicker',
                                                    key: 'date1',
                                                    templateOptions: {
                                                        required: true,
                                                        label: 'Data Invio INWIT',
                                                    },
                                                },
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'datepicker',
                                                    key: 'date2',
                                                    templateOptions: {
                                                        // required: true,
                                                        label: 'Data Risposta INWIT',
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-12',
                                                    type: 'textarea',
                                                    key: 'string2',
                                                    templateOptions: {
                                                        required: false,
                                                        label: 'Note',
                                                        rows: 3,
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Chiusura cantieri Enti',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'ChiusuraCantieri',
                            templateOptions: {
                                label: 'Upload File Chiusura cantieri Enti',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Riconsegna Area e Accettazione',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'RiconsegnaArea',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload File Riconsegna Area e Accettazione',
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],

            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
        {
            displayName: 'Cantiere (Art.26)',
            name: '420_Cantiere (Art.26)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Scelta Tipologia Apparati',
                                options: [
                                    { label: '', value: '' },
                                    { label: 'Indoor', value: 'Indoor' },
                                    { label: 'Outdoor', value: 'Outdoor' },
                                ],
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'flag1',
                            templateOptions: {
                                disableIfCommited: false,
                                //required: true,
                                label: 'Variante in corso d\'opera',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Consegna Area',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FileConsegnaArea',
                            templateOptions: {
                                label: 'Upload File Consegna Area',
                                //disableIfCommited: true,
                                custom: true,
                                required: false,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InizioLavori',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload File Inizio Lavori',
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FineLavori',
                            templateOptions: {
                                label: 'Upload File Fine Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        custom: true,
                        label: 'Comunicazioni Collaudo Tecnico Amministrativo',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                disableIfCommited: true,
                                                label: 'Esito',
                                                options: okKo,
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Inizio Lavori > Consegna Area'
                                                        },
                                                        {
                                                            fromDate: 'date3',
                                                            toDate: 'date24',
                                                            errorText: 'Inizio Lavori > Consegna Area'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                disableIfCommited: true,
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                disableIfCommited: true,
                                                required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                disableIfCommited: true,
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Chiusura cantieri Enti',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'ChiusuraCantieri',
                            templateOptions: {
                                label: 'Upload File Chiusura cantieri Enti',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Riconsegna Area e Accettazione',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'RiconsegnaArea',
                            templateOptions: {
                                label: 'Upload File Riconsegna Area e Accettazione',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],

            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
        {
            displayName: 'Chiusura attività',
            name: '999_Chiusura attività',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Tipologia Office',
                                options: tagliaSito,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Data On Air attività',
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
