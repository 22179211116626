<div class="m-b-16">
    <!-- <button (click)="prova()" mat-icon-button>
      <mat-icon class="icon-24">check</mat-icon>
    </button> -->
    <mat-form-field (click)="menuMapOpened()"
                    class="example-full-width map-form-input cursor-pointer"
                    [ngClass]="{ 'mat-form-field-invalid': !formControl.valid && mapTouched }"
    >
        <mat-label>{{getFormControlLabel()}}</mat-label>
        <input type="mapInput" readonly class="map-form-input cursor-pointer" matInput/>
        <button matSuffix mat-icon-button>
            <mat-icon class="icon-18" id="mapIconId">map</mat-icon>
        </button>
    </mat-form-field>
    <mat-expansion-panel
            class="map-input"
            (opened)="mapOpened()"
            (closed)="mapClosed()"
            [expanded]="panelOpenState"
            (afterExpand)='mapExpanded()'
            hideToggle
    >
        <osm-draw-map
                *ngIf="show"
                [maxWidth]="maxWidth"
                [mapHeight]="mapHeight"
                #map
                (click)="$event.stopPropagation()"
                [featureCollection]="searchAreaImpl.featureGroup"
                (featureGroupChange)="drawItemGroupChange($event)"
                [drawConfig]="drawConfig"
                [center]="currentCenter"

        ></osm-draw-map>
    </mat-expansion-panel>
</div>
