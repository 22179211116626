<div style="display: flex; justify-content: center">
    <form class="form">
        <mat-form-field class="full-width">
            <input type="text" class="cursor"
                   placeholder="{{getSelectedItems()}}"
                   aria-label="Number"
                   matInput
                   [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option disabled>Seleziona la baseline</mat-option>
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <mat-tree-node [ngClass]="checklistSelection.isSelected(node)? 'selected-node' : ''"
                                   *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding class="cursor selectable" (click)="todoLeafItemSelectionToggle(node)">
                        <button mat-icon-button disabled class="cursor"></button>
                        <span [ngStyle]="{ 'font-weight': node.level==2? '800' : ''}"
                              (click)="todoLeafItemSelectionToggle(node); checklistSelection.isSelected(node)">{{node.item}}</span>
                    </mat-tree-node>
                    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.filename">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <span>{{node.item}}</span>
                    </mat-tree-node>
                </mat-tree>
            </mat-autocomplete>
        </mat-form-field>
    </form>
</div>
