import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MenuService, SettingsService } from '@core';

@Component( {
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html',
    styleUrls: [ './sidemenu.component.scss' ],
    encapsulation: ViewEncapsulation.None,
} )
export class SidemenuComponent {
    constructor( private menuSrv: MenuService, private setting: SettingsService ) {
    }

    // NOTE: Ripple effect make page flashing on mobile
    @Input() ripple = false;
    buildRoute = this.menuSrv.buildRoute;
    menu$ = this.menuSrv.getAll();
    // menu$: Observable<Menu[]> = this.menuSrv.getAll().pipe(
    //   map(menuList => {
    //     const userMan = menuList.find(m => m.route === 'usermanagement');
    //     if (userMan && userMan.children) {
    //       const setting = userMan.children.filter(m => m.route !== 'settings');
    //       const newList = menuList.filter(m => m.route !== 'usermanagement');
    //       const result = [...newList, { ...userMan, children: setting }];
    //       return result;
    //     } else {
    //       return menuList;
    //     }
    //   }),
    //   map( resMenu => {
    //     // if(this.setting.user.roles.indexOf('Administrator') < 0) {
    //     //   return resMenu.filter( m => m.route !== 'usermanagement');
    //     // } else {
    //     //   return resMenu;
    //     // }
    //     return resMenu;
    //   })
    // );

}

