<div style="min-width: 150px">
    <div class='leaflet-mat-card' *ngIf='office'>
        <div class="title">{{ office.name }}</div>
        <div class="sub-title">{{ office.office }}</div>
        <div>
            <div style='margin: 0 8px'>{{ office.address }}</div>
            <div style='margin: 0 8px'>{{ office.city }} ({{ office.pr}})</div>
            <div style='margin: 0 8px'>
                <wnd-active-frequencies [frequencies]='office.frequencies'></wnd-active-frequencies>
            </div>
            <div style='margin: 0 8px'>
                <wnd-elements-planned [plans]='plans'></wnd-elements-planned>
            </div>
        </div>
    </div>
</div>
