import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { initializerEnvironment } from '@env/environment';
import * as FileSaver from 'file-saver';

export interface GridFsFile {
    metadata: { name: string; value: string }[];
    id: string;
    uploadDate: Date;
    length: number;
    createUser: string;
    filename: string;
}

// noinspection JSUnusedGlobalSymbols
export interface FileEvent {
    source: RemoteFile;
    type: string;
}

export class RemoteFile {
    gridFsFile: GridFsFile;
    metadata: { [ name: string ]: string } = {};
    id: string;
    uploadDate: Date;
    length: number;
    createUser: string;
    filename: string;

    constructor( private fileContainer: FilesContainerService, _: GridFsFile ) {
        this.gridFsFile = _;
        this.id = _.id;
        this.length = _.length;
        this.uploadDate = _.uploadDate;
        this.createUser = _.createUser;
        this.filename = _.filename;
        _.metadata.forEach( e => ( this.metadata[ e.name ] = e.value ) );
    }

    delete() {
        return this.fileContainer.http.delete( this.fileContainer.mainEndPoint + '/' + this.id, {
            headers: {
                'Tus-Resumable': '1.0.0',
            },
        } ).pipe(
            tap( () => {
                this.fileContainer.filesCollection = this.fileContainer.filesCollection.filter( f => f.id !== this.id );
            } ),
        );
    }

    download() {
        this.getSrc().subscribe( ( res: Blob ) => {
            FileSaver.saveAs( res, this.filename );
        } );
    }

    getSrc() {
        return this.fileContainer.http.get( this.fileContainer.mainEndPoint + '/' + this.id, { responseType: 'blob' } );
    }
}

@Injectable()
export class FilesContainerService {
    filesCollection: RemoteFile[] = [];
    public mainEndPoint = initializerEnvironment.tusServer.endpoint + 'files';
    private searchEndPoint = initializerEnvironment.tusServer.endpoint + 'filter';
    private searchMetadata: any;

    constructor( public http: HttpClient ) {
    }

    setFilter( metadata: any ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.searchMetadata = metadata;
    }

    refreshCollection() {
        const header = new HttpHeaders().set(
            'metadata',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/restrict-plus-operands,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            Object.keys( this.searchMetadata ).map( k => k.toString() + ' ' + this.searchMetadata[ k ].toString() ).join( ',' ),
        );
        return this.http.get<GridFsFile[]>( this.searchEndPoint, { headers: header } ).pipe(
            map( f => {
                this.filesCollection = [];
                f.forEach( g => this.addFile( g ) );
                return this.filesCollection;
            } ),
        );
    }

    addFile( _: GridFsFile ) {
        this.filesCollection.push( new RemoteFile( this, _ ) );
    }

    // noinspection JSUnusedLocalSymbols
    private decodeUTF8( encoded: string ) {
        let decoded: string;
        if( !!encoded && encoded.trim() !== '' ) {
            decoded = decodeURI( encoded );
        }
        return decoded;
    }

}
