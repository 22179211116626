import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OfficeDetailsDialogComponent } from '@shared/components/office-details-dialog/office-details-dialog.component';
import { StrutturaLight } from '../../routes/nd/interfaces/struttura_light';
import { StrutturaDetailsDialogComponent } from '@shared/components/struttura-details-dialog/struttura-details-dialog.component';
import { CommonsSelectors } from '@store/commons-store';
import { combineLatest, map, switchMap, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiOfficesService } from './api-offices.service';
import { ApiPlansService } from '@piani/services/api-plans.service';
import { IOfficeView } from '@shared/models/office-view';

@Injectable( {
    providedIn: 'root'
} )
export class OfficesService {

    constructor(
        private dialog: MatDialog,
        private store: Store,
        private apiOffice: ApiOfficesService,
        private apiPlans: ApiPlansService
    ) {}

    showOfficesDetails( o: StrutturaLight ): void {
        if( o.officeType === 'STRUTTURE' || o.officeType === 'TRASFERIMENTI' ) {
            const ref = this.dialog.open<StrutturaDetailsDialogComponent, { id: string; type: string }>( StrutturaDetailsDialogComponent,
                { disableClose: false, data: { ...o, id: o.office, type: o.officeType }, maxWidth: '50%', autoFocus: false, width: '50%' } );
            ref.backdropClick().subscribe( () => ref.close() );
        } else {
            let ref: MatDialogRef<OfficeDetailsDialogComponent, any>;
            this.getOfficeViewAndTabs( o.office ).pipe( switchMap(
                ( { officeView } ) => {
                    ref = this.dialog.open<OfficeDetailsDialogComponent, { officeView: IOfficeView }>( OfficeDetailsDialogComponent, { disableClose: false, data: { officeView }, maxWidth: '50%', autoFocus: false, width: '50%' } );
                    return ref.backdropClick();
                }
            ) ).subscribe( () => ref.close() );
        }
    }

    getOfficeViewAndTabs( id: string ) {
        return combineLatest( [
            this.store.select( CommonsSelectors.selectFiscalYear ).pipe( switchMap( year => this.apiPlans.getOfficePlans( year, id ) ) ),
            this.apiOffice.getOffice( id )
        ] ).pipe(
            take( 1 ),
            map( ( [ plans, office ] ) => ( {
                officeTabs: office.tabs,

                officeView: {
                    ltePresente: office.frequencies.filter( f => f.technology.toUpperCase() !== '5G' ).map( f => f.frequency ).join( ' ' ),
                    fiveGPresente: office.frequencies.filter( f => f.technology.toUpperCase() === '5G' ).map( f => f.frequency ).join( ' ' ),
                    topAnpo: office.topANPO,
                    connettivita: office.connettivita,
                    fibra: office.fibra,
                    nomeOffice: office.name,
                    office: office.office,
                    indirizzo: office.address,
                    regione: office.region,
                    comune: office.city,
                    provincia: office.province,
                    proprietarioStruttura: office.owner,
                    tipoStruttura: office.structureType,
                    tipoHousing: office.housingType,
                    zona: office.zone,
                    microMacro: office.officeType,
                    ltePiano: plans.data.filter( p => p.technology.toUpperCase() !== '5G' ).map( p => `${ p.driver } ${ p.frequency }` ).join( ' ' ),
                    fiveGPiano: plans.data.filter( p => p.technology.toUpperCase() === '5G' ).map( p => `${ p.driver } ${ p.frequency }` ).join( ' ' )
                }
            } ) )
        );
    }
}
