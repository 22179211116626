import { Pipe, PipeTransform } from '@angular/core';

@Pipe( { name: 'cell' } )
export class CellPipe implements PipeTransform {
    transform( value: unknown ) {
        switch( typeof value ) {
        case 'undefined':
            return '-';

        case 'object':
            if( value === null ) return '-';
            if( value instanceof Date ) return value.toLocaleString();
            return JSON.stringify( value );

        case 'boolean':
            return value ? 'true' : 'false';

        default:
            return value.toString();
        }
    }
}
