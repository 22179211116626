import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { GridFiltersState } from './index';

@NgModule( {
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature( GridFiltersState.featureKey, reducers ),
        // EffectsModule.forFeature( [ GridColumnsStoreEffects ] ),
    ],
} )
export class GridFiltersStoreModule {
}
