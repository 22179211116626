import { DateTime } from 'luxon';

export const featureKey = 'commons';

const getCurrentYear = () => {
    const now = DateTime.now();
    let year = now.year;
    if( now.month >= 4 ) {
        year++;
    }
    return year;
};

export interface State {
    fiscalYear: number;
}

export const initialState: State = {
    fiscalYear: getCurrentYear()
};
