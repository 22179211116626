import { IRole } from '../../routes/user-manager/interfaces/role';
import { IPolicy } from '../../routes/user-manager/interfaces/policy';

export interface State {
    roles: { [ key: string ]: IRole };
    policies: IPolicy[];
    selectedRole: string | null;
}

export const initState: State = {
    roles: {},
    policies: [],
    selectedRole: null,
};

export const featureKey = 'roles';
