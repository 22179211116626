import { Injectable } from '@angular/core';

@Injectable( {
    providedIn: 'root',
} )
export class LocalStorageService {
    get( key: string ) {
        const val: unknown = JSON.parse( localStorage.getItem( key ) || '{}' ) || {};
        return Object.keys( val ).length === 0 ? undefined : val;
    }

    set( key: string, value: any ): boolean {
        localStorage.setItem( key, JSON.stringify( value ) );
        return true;
    }

    remove( key: string ) {
        localStorage.removeItem( key );
    }

    clear() {
        localStorage.clear();
    }
}
