import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RoutesRoutingModule } from './routes-routing.module';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { LandingComponent } from './landing/landing.component';
import { FlexModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@NgModule( {
    imports: [ SharedModule, RoutesRoutingModule, FlexModule, MatCardModule, MatButtonModule, MatInputModule, TranslateModule, MatIconModule ],
    declarations: [
        LoginComponent,
        RegisterComponent,
        LandingComponent,
    ],
} )
export class RoutesModule {
}
