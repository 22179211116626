import { BaseMessage } from '@core/services/stream-craft-client/models/BaseMessage';
import { SocketMessage } from '@core/services/stream-craft-client/models/SocketMessage';

interface MessageContent {
    id: number;
    fieldName: string;
    value: string;
    username: string;
}

export class DetailsFieldUpdatedMessage extends BaseMessage {
    private msg: MessageContent;
    get id() { return this.msg.id; }
    get fieldName() { return this.msg.fieldName; }
    get value()  { return this.msg.value; }
    get username()  { return this.msg.username; }

    constructor( msg: SocketMessage ) {
        super( msg.source, msg.type, msg.content );

        this.msg = JSON.parse( msg.content ) as MessageContent;
    }
}

