<mat-card class="example-card">
    <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Work in progress</mat-card-title>
        <mat-card-subtitle>This function is not ready yet</mat-card-subtitle>
    </mat-card-header>
    <img style='padding: 0 2em' mat-card-image src="assets/images/work_in_progress.png" alt="Work in progress image">
    <mat-card-content>
        <p>
            Here will be soon a new great functionality. Stay tuned for new announcement and application releases.
        </p>
    </mat-card-content>
</mat-card>
