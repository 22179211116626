<div class="wnd-grid-text-filter-wrapper">
    <div
            id="menutrigger"
            #menutrigger
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
            (click)="onClick()"
            [ngClass]="{
      'wnd-grid-menu-trigger': true,
      'wnd-grid-text-filter-disabled-trigger': columnService.columnOptions.isFilterActive
    }"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
    >
        <p>Filtro testo</p>
        <mat-icon>arrow_right</mat-icon>
    </div>

    <div class="wnd-grid-text-filter-divider"></div>

    <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="opened"
            (backdropClick)="onBackClick()"
            (overlayOutsideClick)="onOutClick($event)"
            [cdkConnectedOverlayOffsetX]="openingPosition.x"
            [cdkConnectedOverlayOffsetY]="openingPosition.y"
    >
        <mat-card
                class="wnd-grid-filter-pop-up"
                (mouseenter)="cardMouseEnter()"
                (mouseleave)="cardMouseLeave()"
        >
            <mat-card-content>
                <wnd-filter-menu (preventClose)="preventCloseHandler($event)"></wnd-filter-menu>
            </mat-card-content>
        </mat-card>
    </ng-template>


    <wnd-options-list (preventClose)="preventCloseHandler($event)"></wnd-options-list>
</div>
