<mat-form-field class="example-full-width  cursor-pointer" (click)="onMenuToggle()">
    <mat-label>{{getFormControlLabel()}}</mat-label>
    <input matInput readonly class="file-label cursor-pointer" [formControl]="fileNameControl"/>
    <div matSuffix fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner *ngIf="isUploading" class="m-b-4" [diameter]="24"></mat-spinner>
        <button mat-icon-button [matMenuTriggerFor]="uploadMenu" (click)="void($event)">
            <mat-icon id="clickMe">insert_drive_file</mat-icon>
        </button>
    </div>
</mat-form-field>

<mat-menu #uploadMenu="matMenu">
    <button *ngIf="!remoteFile" mat-menu-item (click)="inputFileClick()" [disabled]='controlDisabled || !allowUpdate'>
        Upload
    </button>
    <input
        #inputFile
        id="inputFile"
        hidden
        type="file"
        [tus-uploader]="tusConfig"
        (uploadEvents)="onUploadEvent($event)"
        (selectionError)='onSelectionError( $event )'
    />
    <button *ngIf="!!remoteFile" mat-menu-item (click)="download()">Download</button>
    <button *ngIf="!!remoteFile" mat-menu-item (click)="delete()" [disabled]='controlDisabled || !allowUpdate'>Delete</button>
</mat-menu>
