<div class="customizer-handle" *ngIf="visible">
    <button mat-raised-button color="primary" (click)="openPanel($event)">
        <mat-icon>cloud_upload</mat-icon>
    </button>
</div>

<div [ngClass]="{ 'customizer-panel': true, 'mat-elevation-z12 opened': opened }">
    <h2 fxLayout="row" fxLayoutAlign="space-between center" class="f-w-400">
        <span>Upload Manager</span>
        <button mat-icon-button (click)="closePanel()">
            <mat-icon>close</mat-icon>
        </button>
    </h2>

    <mat-list>
        <div *ngFor="let element of elements$ | async; let i = index;">
            <mat-divider class="uploading-divider" *ngIf="i >= 0"></mat-divider>
            <app-uploading-element class="uploading-element" [file]="element"
                                   (abort)="abortDownload($event)"
                                   (togglePause)="pauseResumeDownload($event)"
                                   (complete)="closePanelWhenEmpty()"
                                   (running)="setVisible()">
            </app-uploading-element>
        </div>
    </mat-list>
</div>

<div
        [ngClass]="{ 'customizer-panel-overlay': true, 'opened': opened }"
        (click)="closePanel()"
></div>
