/* eslint-disable @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-assignment */
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WndGridColumn } from './../../interfaces/wnd-grid-column';
import { WndGridColumnCalculation } from './../../helpers/wnd-grid-column-search';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { WndGridColumnService } from '../../services/wnd-grid-column-service';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';


@Component( {
    selector: 'wnd-filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: [ './filter-menu.component.scss' ]
} )
export class FilterMenuComponent implements OnInit, OnDestroy {

    @Output() preventClose = new EventEmitter<boolean>();
    filters: WndGridColumnCalculation[] = [];
    column: WndGridColumn;
    firstArg = new UntypedFormControl();
    filterSelection = new UntypedFormControl();
    secondArg = new UntypedFormControl();

    private subscriptions: Subscription[] = [];
    private startTypedDate: any;
    private endTypedDate: any;

    constructor( public columnService: WndGridColumnService ) {
    }

    ngOnInit(): void {
        this.filters = this.columnService.columnSearch.supportedCalculation;
        this.column = this.columnService.column;
        this.registerSource();
        this.reactiveClosing();
    }


    ngOnDestroy(): void {
        this.columnService.columnSearch.neverOpenedBefore = false;
        this.subscriptions.forEach( s => s.unsubscribe() );
    }


    registerSource() {


        this.subscriptions.push( this.filterSelection.valueChanges.subscribe( val => {
            this.columnService.columnSearch.filterSource.next( val );
        } ) );

        this.subscriptions.push(
            this.columnService.columnSearch.filterSource.asObservable().subscribe( val => {

                this.filterSelection.setValue( this.filters.find( f => f.type === val.type ), { onlySelf: true, emitEvent: false } );


            } )
        );


        this.subscriptions.push(
            this.firstArg.valueChanges.pipe( distinctUntilChanged() ).subscribe( val => this.columnService.columnSearch.firstArgSource.next( val ) )
        );
        this.subscriptions.push(
            this.columnService.columnSearch.firstArgSource.asObservable().subscribe( val => {
                this.firstArg.setValue( val, { onlySelf: true, emitEvent: false } );

            } )
        );


        this.subscriptions.push(
            this.secondArg.valueChanges.pipe( distinctUntilChanged() ).subscribe( val => this.columnService.columnSearch.secondArgSource.next( val ) )
        );
        this.subscriptions.push(
            this.columnService.columnSearch.secondArgSource.asObservable().subscribe( val => {
                this.secondArg.setValue( val, { onlySelf: true, emitEvent: false } );
            } )
        );

    }

    // noinspection JSUnusedGlobalSymbols
    dub() {
        this.filters = this.columnService.columnSearch.supportedCalculation;
    }


    argsPlaceHolder(): { first: string; second: string } {
        const temp = { first: '', second: '' };
        if( this.columnService.columnSearch.inRange && this.column?.type === 'number' ) {
            temp.first = 'Da';
            temp.second = 'A';
        } else {
            temp.first = 'Filtro';
        }
        return temp;
    }


    preventCloseHandler( val: boolean ) {
        this.preventClose.next( val );
    }


    openedChange( val: boolean ) {
        this.preventClose.next( val );
    }

    open() {
        this.preventClose.next( true );
    }

    close() {
        this.preventClose.next( false );
    }


    dateChange( target: string, event: MatDatepickerInputEvent<Moment> ) {
        if( event?.value ) {
            if( target === 'start' ) {
                this.firstArg.setValue( event.value );
            }
            if( target === 'end' ) {
                this.secondArg.setValue( event.value );
            }
        } else {
            if( target === 'start' ) {
                this.firstArg.setValue( '' );
                setTimeout( () => {
                    this.firstArg.setValue( null );
                } );
            }
            if( target === 'end' ) {
                this.secondArg.setValue( '' );
                setTimeout( () => {
                    this.secondArg.setValue( null );
                } );
            }
        }
    }


    onDateInput( target: string, value: any ) {
        if( target === 'start' ) {
            this.startTypedDate = value;

            if( !value ) {
                this.firstArg.setValue( null );
            }
        }
        if( target === 'end' ) {
            this.endTypedDate = value;

            if( !value ) {
                this.secondArg.setValue( null );
            }
        }
    }


    onDateBlur( _target: string ) {

        // const dateStart = moment(this.startTypedDate);
        // const dateEnd = moment(this.endTypedDate);
        // if (!dateStart.unix() || !dateEnd.unix()) {
        //   this.startTypedDate = undefined;
        //   this.firstArg.setValue('', { onlySelf: true, emitEvent: false });
        //   setTimeout(() => {
        //     this.firstArg.setValue(null);
        //   });
        //   this.endTypedDate = undefined;
        //   this.secondArg.setValue('', { onlySelf: true, emitEvent: false });
        //   setTimeout(() => {
        //     this.secondArg.setValue(null);
        //   });
        // }

        this.close();
    }

    private reactiveClosing() {
        this.subscriptions.push(
            this.filterSelection.valueChanges.subscribe( () => this.preventClose.next( false ) )
        );
    }

}




