import { Action, createReducer, on } from '@ngrx/store';
import { GridColumnsActions, GridColumnsState } from './index';
import produce from 'immer';

const gridColumnsReducer = createReducer( GridColumnsState.initialState,
    on( GridColumnsActions.setGridColumns, ( state, { name, set } ) => produce( state, draft => {
        draft.grids[ name ] = set;
        const selectedName = draft.selected[ name ] ? draft.selected[ name ].name : 'default';
        draft.selected[ name ] = set.find( s => s.name === selectedName );
        draft.selectedSets[ name ] = { sets: [ selectedName ] };
    } ) ),
    on( GridColumnsActions.selectColumnsSet, ( state, { gridName, setNames } ) => produce( state, draft => {
        draft.selectedSets[ gridName ] = { sets: setNames };
        draft.selected[ gridName ].columns = [];
        setNames.forEach( ( name ) => {
            const found = draft.grids[ gridName ].find( s => s.name === name ).columns;
            draft.selected[ gridName ].columns = draft.selected[ gridName ].columns.concat( found );
        } );
        draft.selected[ gridName ].columns = [ ...new Set( draft.selected[ gridName ].columns ) ];
    } ) ),
    on( GridColumnsActions.setAllGridColumns, ( state, { name, columns } ) => produce( state, draft => {
        draft.gridsColumns[ name ] = columns;
    } ) ),
    on( GridColumnsActions.setGridUpdateAt, ( state, { name, updatedAt } ) => produce( state, draft => {
        draft.gridsUpdatedAt[ name ] = updatedAt;
    } ) ),
);


export const reducers = ( state: GridColumnsState.State | undefined, action: Action ): GridColumnsState.State => gridColumnsReducer( state, action );
