/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return */
// noinspection JSUnusedGlobalSymbols

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService, User } from '@core';
import { UploaderConfig } from '@shared/directives/tus-uploader.directive';
import { UploadingFileStatuses } from '@shared/models/uploading-file-statuses';
import { TusSingleFileUploader } from '@shared/services/upload.service';
import { IInputFileElement, } from 'app/routes/nd/lavorazioni/step-edit/custom-field/single-file/single-file.component';
import { ToastrService } from 'ngx-toastr';
import { RolesRequest, UserRoleRequests } from '../../../routes/user-manager/interfaces/request';
import { UserManagerService } from '../../../routes/user-manager/services/user-manager.service';
import { Guid } from 'guid-typescript';

@Component( {
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: [ './user-settings.component.scss' ],
} )
export class UserSettingsComponent implements OnInit {
    @ViewChild( 'inputFile' ) inputFileView: ElementRef<IInputFileElement>;
    user: User;
    //appRoles = [];
    selectedRoles = [];
    //avatar: any = 'assets/images/userIcons.svg';

    private metadata: any;
    tusConfig: UploaderConfig;
    canUpload = false;
    isUploading = false;
    private tusUploader: TusSingleFileUploader;
    //private subscriptions: Subscription[] = [];
    //remoteFile: RemoteFile;

    //fileNameControl = new FormControl();

    constructor(
        private userManagerService: UserManagerService,
        public settings: SettingsService,
        private toastr: ToastrService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.user = this.settings.user;
        // this.userManagerService.getAllAppRoles('no-ldap').subscribe(role => {
        //   this.appRoles = role;
        //   this.selectableRoles = this._difference(this.appRoles, this.user.policies);
        //   this.selectableRoles = this.selectableRoles.filter(role => role !== 'Administrator');
        // });
        this.metadata = () => ( {
            entity: 'avatar',
            username: this.user.username,
            guid: Guid.create().toString(),
        } );
        this.tusConfig = {
            metadata: this.metadata,
            allowedTypes: [ '.jpg', '.png' ],
            lazyStart: false,
        };

    }

    onUploadEvent( event: { source: TusSingleFileUploader; uploaders: TusSingleFileUploader[] } ) {
        this.tusUploader = event.source;
        switch( event.source.fileStatus.state ) {
        case UploadingFileStatuses.PAUSED: {
            this.isUploading = false;
            break;
        }
        case UploadingFileStatuses.SERVER_ERROR: {
            this.toastr.warning( 'Upload fallito, riprovare in seguito.' );
            this.isUploading = false;
            this.canUpload = true;
            this.inputFileView.nativeElement.value = '';
            break;
        }
        case UploadingFileStatuses.RUNNING: {
            this.isUploading = true;
            this.canUpload = false;
            break;
        }
        case UploadingFileStatuses.CANCELED: {
            this.isUploading = false;
            this.canUpload = true;
            this.inputFileView.nativeElement.value = '';
            break;
        }
        case UploadingFileStatuses.COMPLETED: {
            this.isUploading = false;
            this.canUpload = false;
            this.settings.refreshAvatarFromRemote();
            break;
        }
        default: {
            break;
        }
        }
    }

    onSelectionError( message: string ) {
        this.toastr.error( message );
    }

    inputFileClick() {
        const inputFile = document.getElementById( 'inputFile' );
        inputFile.click();
    }


    delete() {
        this.isUploading = true;
        this.canUpload = false;
        this.settings.deleteRemoteAvatarFile().subscribe( {
            next: () => {
                this.isUploading = false;
                this.canUpload = true;
                this.inputFileView.nativeElement.value = '';
            }, error: ( error ) => {
                console.error( 'Error in cancelling the file', error );
                this.toastr.error( 'Error in deleting uploading file' );
            }
        } );
    }

    getRoles(): string[] {
        return this.user.authzData.roles.map( r => r.label );
    }

    isAdmin(): boolean {
        return this.getRoles().indexOf( 'Administrator' ) > -1;
    }

    goToManagement() {
        void this.router.navigateByUrl( 'usermanagement/permission' ).then();
    }

    onClick() {
        const mappedRoles = this.selectedRoles.map( role => ( {
            role,
            action: 'add',
            issuerUser: this.user.username,
        } as RolesRequest ) );
        const body: UserRoleRequests = {
            userName: this.user.username,
            rolesRequests: mappedRoles,
        };
        this.userManagerService.addRoleRequest( body ).subscribe( {
            next: () => {
                this.toastr.success( 'Richiesta inviata con Successo, aspetta l\'approvazione di un Amministratore' );
            }, error: () => {
                this.toastr.error( 'Richiesta già Inviata, aspetta che venga approvata la precedente' );
            }
        } );
    }

    onChange( event ) {
        this.selectedRoles = event.value;
    }
}
