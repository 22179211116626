/* eslint-disable @typescript-eslint/member-ordering,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment */
import { WndGridColumnSearchType } from './../../helpers/wnd-grid-column-search';
import { buffer, debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { WndGridColumn } from '../../interfaces/wnd-grid-column';
import { WndGridColumnService } from '../../services/wnd-grid-column-service';
import { WndGridService } from '../../services/wnd-grid.service';


@Component( {
    selector: 'wnd-column-header',
    templateUrl: './column-header.component.html',
    styleUrls: [ './column-header.component.scss' ],
    providers: [ WndGridColumnService ],
} )
export class ColumnHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() gridName?: string;
    private closingRequest = new Subject<CloseEvent>();
    private subscriptions: Subscription[] = [];
    private allowed = true;
    clientWidth = 0;
    sortState = 0;
    private detachingCalculation = [ WndGridColumnSearchType.INLIST, WndGridColumnSearchType.INRANGE ];
    opened = false;
    firstArg = new FormControl<string>( '' );
    openPosition = {
        x: 0,
        y: 0,
    };

    placeholder: string;
    @ViewChild( 'field', { read: ElementRef } ) menuTrigger: ElementRef;
    @ViewChild( 'container', { read: ElementRef } ) container: ElementRef;

    constructor( public columnService: WndGridColumnService, public gridService: WndGridService, private cdr: ChangeDetectorRef ) {

    }


    private detached = false;
    @Input() col: WndGridColumn;
    @Input() headerBgColor: string;
    @Input() headerFontColor: string;
    @Input() setColor: string;
    control = new UntypedFormControl();

    ngOnInit(): void {
        this.columnService.init( this.col, this.gridName );
        this.gridService.addColumn( this.columnService );
        this.registerClosing();
        this.registerFirstArgSync();
        this.registerSortEvent();

    }

    ngAfterViewInit(): void {
        setTimeout( () => {
            this.openPosition.x = -5;
            this.openPosition.y = -this.menuTrigger.nativeElement.clientHeight / 2 - 10;
            this.clientWidth = this.container.nativeElement.clientWidth;
            this.cdr.detectChanges();


        } );

    }


    registerSortEvent() {
        this.subscriptions.push(
            this.gridService.sortChange.subscribe( c => {
                if( c.field !== this.col.fieldName ) {
                    this.sortState = 0;
                }
                if( c.field === this.col.fieldName && c.sort === 'ASC' ) {
                    this.sortState = 1;
                }
                if( c.field === this.col.fieldName && c.sort === 'DESC' ) {
                    this.sortState = 2;
                }
            } ),
        );

    }

    ngOnDestroy(): void {
        this.gridService.removeColumn( this.columnService );
        this.subscriptions.forEach( s => s.unsubscribe() );
    }

    nextSortState() {
        this.sortState++;
        if( this.sortState > 2 ) {
            this.sortState = 0;
        }
        if( this.sortState === 0 ) {
            this.gridService.updateSort( { field: '', sort: undefined } );
        }
        if( this.sortState === 1 ) {
            this.gridService.updateSort( { field: this.columnService.fieldName, sort: 'ASC' } );
        }
        if( this.sortState === 2 ) {
            this.gridService.updateSort( { field: this.columnService.fieldName, sort: 'DESC' } );
        }
    }

    registerFirstArgSync() {

        this.subscriptions.push( this.firstArg.valueChanges
            .subscribe( val => {
                console.log( 'Search input changed', val, this.detached );
                if( !this.detached ) {
                    this.columnService.columnSearch.firstArgSource.next( val );
                }

            } ) );

        this.subscriptions.push(
            this.columnService.columnSearch.firstArgSource.asObservable().subscribe( val => {
                if( !this.detachingCalculation.includes( this.columnService.columnSearch.filterSource.value.type ) ) {
                    this.firstArg.setValue( val?.toString(), { onlySelf: true, emitEvent: false } );
                } else {
                    this.firstArg.reset( null, { onlySelf: true, emitEvent: false } );
                    this.detached = true;
                }
                switch( this.columnService.columnSearch.filterSource.value.type ) {
                case WndGridColumnSearchType.INLIST:
                    this.placeholder = 'Lista impostata';
                    break;
                case WndGridColumnSearchType.INRANGE:
                    this.placeholder = 'Intervallo impostato';
                    break;
                default:
                    this.placeholder = 'Cerca...';
                    break;
                }
            } ),
        );

    }


    private registerClosing() {
        const debounceAddProduct$ = this.closingRequest.pipe( debounceTime( 20 ) );

        this.subscriptions.push( this.closingRequest.pipe(
            buffer( debounceAddProduct$ ) ).subscribe( evs => {
            if( evs.filter( e => e.requested ).length > 0 && this.allowed ) {
                this.opened = false;
                this.cdr.detectChanges();
            }
            const allowEv = evs.find( e => !e.requested );
            if( allowEv ) {
                this.allowed = allowEv.allowed;
            }
        } ) );
    }

    onFocus() {
        if( this.detached ) {
            this.columnService.columnSearch.filterSource.next( this.columnService.columnSearch.supportedCalculation[ 0 ] );
            this.detached = false;
        }

    }

    closeEventHandler( event: string ) {
        if( event === 'close' ) {
            this.closingRequest.next( { requested: true, allowed: false } );
        }
        if( event === 'forbidden' ) {
            this.closingRequest.next( { requested: false, allowed: false } );
        }
        if( event === 'allowed' ) {
            this.closingRequest.next( { requested: false, allowed: true } );
        }
    }


    onOutClick( _: any ) {
        this.closingRequest.next( { requested: true, allowed: false } );
    }


    resetFilters() {
        if( this.columnService.anyFilters ) {
            this.columnService.columnSearch.resetFilters();
        } else {
            this.nextSortState();
        }

    }

    onBackClick() {

    }

    help() {
        console.log( this.firstArg );
    }
}


interface CloseEvent {
    requested: boolean;
    allowed: boolean;
}
