/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Moment } from 'moment';
import { UntypedFormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WndGridColumn } from '../../interfaces/wnd-grid-column';
import { WndGridColumnService } from '../../services/wnd-grid-column-service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


@Component( {
    selector: 'wnd-filter-main-field',
    templateUrl: './filter-main-field.component.html',
    styleUrls: [ './filter-main-field.component.scss' ]
} )
export class FilterMainFieldComponent implements OnInit {
    @Input() firstArg: UntypedFormControl;
    @Input() placeholder: string;
    @Input() inputType: string;
    @Input() inRangeActive: boolean;
    @Output() preventClose = new EventEmitter<boolean>();
    @Output() focus = new EventEmitter<void>();

    column: WndGridColumn;

    private lastTypedDate: any;


    constructor( public columnService: WndGridColumnService ) {
    }


    ngOnInit(): void {
        this.column = this.columnService.column;

    }


    // noinspection JSUnusedGlobalSymbols
    prevent() {
        this.preventClose.next( true );
    }

    onDateBlur() {
        // const date = moment(this.lastTypedDate);
        // if (!date.isValid()) {
        //   this.lastTypedDate = undefined;
        //   const a = this.firstArg.value;
        //   this.firstArg.setValue('', { onlySelf: true, emitEvent: false });
        //   setTimeout(() => {
        //     this.setField(null);
        //   });
        // }
        this.allow();
    }

    allow() {
        this.preventClose.next( false );
    }

    open() {
        this.focus.next();
        this.preventClose.next( true );
    }

    close() {
        this.preventClose.next( false );
    }

    dateChange( event: MatDatepickerInputEvent<Moment> ) {
        if( event?.value ) {
            this.setField( event?.value );
        } else {
            this.firstArg.setValue( '' );
            setTimeout( () => {
                this.firstArg.setValue( null );
            } );
        }

    }

    onDateInput( event: any ) {
        this.lastTypedDate = event;

        if( !event ) {
            this.setField( null );
        }
    }

    boolSelectOpenEvent( e: boolean ) {
        if( e ) {
            this.focus.next();
        }
        this.preventClose.next( e );
    }

    private setField( val ) {
        this.firstArg.setValue( val );
    }

}
