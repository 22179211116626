import { createAction, props } from '@ngrx/store';
import { ContrattoStatus } from '../../routes/contratti/models/contratto-status';
import { IContrattoElement } from '../../routes/contratti/models/contratto-element';


export const loadContratti = createAction( '[contratti] load contratti' );
export const contrattiLoaded = createAction( '[contratti] contratti loaded', props<{ contratti: IContrattoElement[] }>() );
export const contrattiLoadFailure = createAction( '[contratti] contratti load failure' );

export const listFilterChanged = createAction( '[contratti] list filter changed', props<{ listFilter: ContrattoStatus }>() );

export const setViewingOffice = createAction( '[contratti] set viewing office', props<{ office: string }>() );

