<div class='title-wrapper' *ngIf="office; else loading">
    <div class='title'>
        <p>{{office?.nomeOffice }}</p>
    </div>
    <div class='office'>
        <p><b>{{office?.office }}</b></p>
    </div>
    <div class='sub-title'>
        <p>{{office?.indirizzo }}</p>
        <p>{{office?.regione + ' - ' +office?.comune + ' (' +office?.provincia + ')' }}
        </p>
    </div>
</div>
<div class='content' fxLayout='row wrap' *ngIf="office">
    <div
         [ngClass]="{
        'list-item': true,
        'right-element': i % 3 === 2,
        'center-element': i % 3 === 1,
        'left-element': i % 3 === 0,
        'first-row-element': i < 3
      }"
         fxFlex='33'
         *ngFor="let col of columns; index as i">
        <p class='field'>{{ col.displayName }}</p>
        <p class='value' [matTooltip]="office[col.fieldName]">{{ office[col.fieldName] ||
            (col.displayName ? '-' : ' ') }}</p>
    </div>
</div>

<ng-template #loading>
    <div fxLayout="column" fxLayoutGap="5px">
        <div style="width: 780px; height: 130px" class="loading-rect pulse"></div>
        <div style="width: 780px; height: 285px" class="loading-rect pulse"></div>
    </div>
</ng-template>