import { AbstractControl, ValidatorFn } from '@angular/forms';

export const autocompleteCheck = ( entering: { list: string[] } ): ValidatorFn  =>  ( formControl: AbstractControl ): { [ error: string ]: any } => {
    const list = entering.list;
    const actualValue = formControl.value as unknown;
    if( !actualValue || list.find( v => {
        const str = v.match( /([^()]+[^ ()])( \([a-zA-Z]{2}\))?/ );
        return str[ 1 ] === actualValue;
    } ) ) {
        return null;
    } else {
        return { notInList: true };
    }
};
