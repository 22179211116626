import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import { WonderLog } from '@shared/interfaces/log';
import { EMPTY, catchError, take, tap } from 'rxjs';
import { LOGGER_URL } from '@shared/models/urls-constants';

@Injectable( {
    providedIn: 'root',
} )
export class LoggerService {
    constructor( private storageService: LocalStorageService, private http: HttpClient, @Inject( LOGGER_URL ) private baseUrl: string ) {
        setInterval( () => this.sendLogs(), 1000 * 60 * 5 ); // Every 5 minutes
    }

    saveLog( log: WonderLog ) {
        const errors: WonderLog[] = this.storageService.get( 'errors' ) as WonderLog[] || [];
        errors.push( log );
        this.storageService.set( 'errors', errors );
    }

    sendLogs() {
        const errors: WonderLog[] = this.storageService.get( 'errors' ) as WonderLog[] || [];
        if( errors.length > 0 ) {
            return this.http.post( `${this.baseUrl}frontendlog`, errors ).pipe(
                take( 1 ),
                tap( () => this.storageService.remove( 'errors' ) ),
                catchError( () => {
                    this.storageService.set( 'errors', errors );
                    return EMPTY;
                } ),
            ).subscribe();
        }
    }
}
