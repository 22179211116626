import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '@core/bootstrap/menu.service';

export interface INavElement {
    address?: string;
    name: string;
}


@Component( {
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: [ './breadcrumb.component.scss' ],
    encapsulation: ViewEncapsulation.None,
} )
export class BreadcrumbComponent implements OnInit {
    @Input() navs: INavElement[] = [];

    constructor( private router: Router, private menu: MenuService ) {
    }

    ngOnInit() {
        this.navs = Array.isArray( this.navs ) ? this.navs : [];

        if( this.navs.length === 0 ) {
            this.genBreadcrumb();
        }
        this.navs.unshift( { name: 'home', address: '/' } );
    }

    trackByNavLink( index: number, navLink: INavElement ): string {
        return navLink.name;
    }

    genBreadcrumb() {
        const routes = this.router.url.slice( 1 ).split( '/' );
        this.navs = this.menu.getMenuLevel( routes ).map( n => ( { name: n } ) );
    }
}
