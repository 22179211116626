import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification } from '@core/notifications/models';

@Component( {
    selector: 'app-sidebar-notice',
    templateUrl: './sidebar-notice.component.html',
    styleUrls: [ './sidebar-notice.component.scss' ],
} )
export class SidebarNoticeComponent {
    @Input() notifications: Notification[] = [];

    @Output() readNotification = new EventEmitter<number>();
    @Output() readAllNotifications = new EventEmitter<void>();
    @Output() openNotification = new EventEmitter<Notification>();

    allNotifications = false;

    get list(): Notification[] {
        return this.allNotifications ? this.notifications : this.notifications?.filter( ( notification ) => !notification.receptionConfirmed ) ?? [];
    }

    trackByNotificationId( _index: number, notification: Notification ) {
        return notification.id;
    }
}
