import { SettingsService, TokenService } from '@core';
import { TusPendingRequest } from './../models/uploading-file-statuses';
import { Injectable } from '@angular/core';
import { initializerEnvironment } from '@env/environment';
import { UploadingFileStatuses } from '@shared/models/uploading-file-statuses';
import { Subject } from 'rxjs';
import { Upload, UploadOptions } from 'tus-js-client';
import { ToastrService } from 'ngx-toastr';

export interface FileStatus {
    filename: string;
    progress: number;
    hash: string;
    uuid: string;
    state: UploadingFileStatuses;
}

@Injectable( {
    providedIn: 'root',
} )
export class UploadService {
    cnt = 0;
    counterCluster = 1;
    activeUploaders: TusSingleFileUploader[] = [];
    activeUploadersSub = new Subject<{
        source: TusSingleFileUploader;
        uploaders: TusSingleFileUploader[];
    }>();
    uploadersChanges = this.activeUploadersSub;
    currentToken: string;
    currentUserName: string;

    constructor( public toastr: ToastrService, private tokenService: TokenService, private settings: SettingsService ) {
        this.tokenService.currentTokenModel.subscribe( t => this.currentToken = t.accessToken );
        this.settings.userChange().subscribe( u => this.currentUserName = u.username );
    }

    addFileUploader( file: File, filename: string, clusterUid = 0, options: UploadOptions = null ) {
        const temp = new TusSingleFileUploader( this, file, filename, clusterUid, options );
        this.activeUploaders.push( temp );
        this.activeUploadersSub.next( { source: temp, uploaders: this.activeUploaders } );
        return temp;
    }

    startAll() {
        this.activeUploaders.forEach( u => u.startUpload() );
    }

    startAllInCluster( clusterUid: number ) {
        this.activeUploaders.filter( u => u.clusterUid === clusterUid ).forEach( u => u.startUpload() );
    }

    getNewClusterUid() {
        return this.counterCluster++;
    }
}

export class TusSingleFileUploader {
    private _lastUsedToken: string;
    fileStatus: FileStatus;
    public tusUpload: Upload;
    public readonly uid: number;
    public readonly clusterUid: number;
    pendingRequest = TusPendingRequest.NOTHING;

    constructor(
        private _uploadService: UploadService,
        private _file: File,
        filename: string,
        clusterUid: number,
        options: any = null,
    ) {
        this.uid = _uploadService.cnt++;
        this.clusterUid = clusterUid;
        this.fileStatus = {
            filename,
            progress: 0,
            hash: '',
            uuid: '',
            state: UploadingFileStatuses.PAUSED,
        };
        if( !options ) {
            options = {
                endpoint: initializerEnvironment.tusServer.endpoint + 'files',
                retryDelays: [ 0, 3000, 6000 ],
                chunkSize: initializerEnvironment.tusServer.chunkSizeMB * Math.pow( 2, 20 ),
                removeFingerprintOnSuccess: true,

                metadata: {
                    filename,
                    contentType: _file.type,
                    user: _uploadService.currentUserName
                },
                onError: async( _error: any ) => {

                    console.error( 'Error in uploading:', _error );
                    console.error( 'Error in uploading:', _error.originalRequest );
                    console.error( 'Error in uploading:', _error.originalResponse );
                    this.fileStatus.state = UploadingFileStatuses.SERVER_ERROR;
                    setTimeout( () => this.destroy(), 4000 );
                    return false;

                },
                onShouldRetry: ( err, retryAttempt, options ) => {
                    const status = err.originalResponse ? err.originalResponse.getStatus() : 0;
                    // Do not retry if the status is a 401.
                    if( status === 401 || status === 403 ) {
                        if( this._lastUsedToken !== this._uploadService.currentToken ) {
                            return true;
                        } else {
                            return false;
                        }
                    }

                    // For any other status code, we retry.
                    return true;
                },
                onProgress: ( bytesAccepted, bytesTotal ) => {
                    this.fileStatus.progress = Math.max( ( Math.floor( ( bytesAccepted / bytesTotal ) * 100 ) ), ( this.fileStatus.progress || 0 ) );
                    this.fileStatus.uuid = this.tusUpload.url.split( '/' ).slice( -1 )[ 0 ];
                    this.fileStatus.state = UploadingFileStatuses.RUNNING;
                    this._uploadService.activeUploadersSub.next( {
                        source: this,
                        uploaders: this._uploadService.activeUploaders,
                    } );
                },
                fingerprint: ( file, _options ) => {
                    return Promise.resolve( file.name + file.lastModified + file.size );
                },
                onSuccess: async() => {
                    this.fileStatus.progress = 100;
                    this.fileStatus.state = UploadingFileStatuses.COMPLETED;
                    setTimeout( () => this.destroy(), 4000 );
                    return true;
                },
            };
        }
        this.tusUpload = new Upload( _file, options );
        this.setHeaders();
    }

    async startUpload() {
        this.pendingRequest = TusPendingRequest.UPLOAD;
        const previousUploads = await this.tusUpload.findPreviousUploads();
        if( previousUploads.length > 0 ) {
            this.tusUpload.resumeFromPreviousUpload( previousUploads[ 0 ] );
        }

        this.tusUpload.start();
        this.fileStatus.state = UploadingFileStatuses.RUNNING;
        this._uploadService.activeUploadersSub.next( {
            source: this,
            uploaders: this._uploadService.activeUploaders,
        } );
    }

    addMetadata( metadata: any ) {
        if( metadata ) {
            this.tusUpload.options.metadata = {
                ...this.tusUpload.options.metadata,
                ...metadata,
            };
        }
    }

    pause() {
        this.pendingRequest = TusPendingRequest.PAUSE;
        this.fileStatus.state = UploadingFileStatuses.WAITING;
        this.tusUpload.abort( false ).then( () => {
            this.fileStatus.state = UploadingFileStatuses.PAUSED;
            this._uploadService.activeUploadersSub.next( {
                source: this,
                uploaders: this._uploadService.activeUploaders,
            } );
        } );
    }

    resume() {
        this.pendingRequest = TusPendingRequest.RESUME;
        this.tusUpload.start();
        this.fileStatus.state = UploadingFileStatuses.RUNNING;
        this._uploadService.activeUploadersSub.next( {
            source: this,
            uploaders: this._uploadService.activeUploaders,
        } );
    }

    async abort() {
        this.pendingRequest = TusPendingRequest.DELETE;
        const oldState = this.fileStatus.state;
        this.fileStatus.state = UploadingFileStatuses.WAITING;
        if( oldState === UploadingFileStatuses.RUNNING ) {
            await this.tusUpload.abort( false );
        }
        this.tusUpload.abort( true ).then( () => {
            this.fileStatus.state = UploadingFileStatuses.CANCELED;
            this._uploadService.activeUploadersSub.next( {
                source: this,
                uploaders: this._uploadService.activeUploaders,
            } );
            this.destroy();
        } );
    }

    async terminate() {
        const oldState = this.fileStatus.state;
        this.fileStatus.state = UploadingFileStatuses.WAITING;
        if( oldState === UploadingFileStatuses.RUNNING ) {
            await this.tusUpload.abort( false );
        }
        Upload.terminate( this.tusUpload.url ).then( () => {
            this.fileStatus.state = UploadingFileStatuses.CANCELED;
        } ).catch( reason => {
            console.error( 'Error in terminating the uploading', reason );
            this._uploadService.toastr.error( 'Error in terminating the upload.' );
        } ).finally( () => this.destroy() );
    }

    destroy() {
        this._uploadService.activeUploaders = this._uploadService.activeUploaders.filter(
            u => u !== this,
        );
        this._uploadService.activeUploadersSub.next( {
            source: this,
            uploaders: this._uploadService.activeUploaders,
        } );
    }


    setHeaders() {
        this._lastUsedToken = this._uploadService.currentToken;
        this.tusUpload.options.headers = {
            Authorization: `Bearer ${ this._uploadService.currentToken }`,
        };
    }

}
