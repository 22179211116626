import { AfterContentChecked, AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

/**
 * FluidHeight directive
 *
 * This directive calc the viewport height and set the element height so that it fills the remaining screen height.
 * It support minimum height, and bottom padding.
 *
 * It listen for size changes and content check to resize the element if needed.
 *
 * by Benem
 */
@Directive( {
    selector: '[fluidHeight]',
} )
export class FluidHeightDirective implements AfterViewInit, AfterContentChecked, OnDestroy {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input( 'fluidHeight' ) topOffset?: number = null;
    @Input() minHeight: number;
    @Input() padding = 16;

    lastTop = 0;

    private domElement: HTMLElement;
    private subscriptions = new Subscription();

    constructor( private renderer: Renderer2, private elementRef: ElementRef ) {
        this.domElement = this.elementRef.nativeElement as HTMLElement;
        // register on window resize event
        this.subscriptions.add( fromEvent( window, 'resize' )
            .pipe( throttleTime( 500 ), debounceTime( 500 ) )
            .subscribe( () => this.setHeight() ) );
    }

    ngAfterViewInit() {
        this.setHeight();
    }

    ngAfterContentChecked(): void {
        const newTop = this.domElement.getBoundingClientRect().top;
        if( newTop !== this.lastTop ) {
            this.setHeight();
            this.lastTop = newTop;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private calcTopOffset(): number {
        try {
            const rect = this.domElement.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            return rect.top + scrollTop;
        } catch( e ) {
            console.warn( 'Offset calc exception', e );
            return 0;
        }
    }

    private setHeight() {
        const windowHeight = window?.innerHeight;
        const topOffset = this.topOffset || this.calcTopOffset();
        let height = windowHeight - topOffset - this.padding;
        // console.debug( 'Fluid Height', height, windowHeight, topOffset, this.padding );
        // set min height instead of the calculated
        if( this.minHeight && height < this.minHeight ) {
            height = this.minHeight;
        }
        this.renderer.setStyle( this.domElement, 'height', `${ height }px` );
    }

}
