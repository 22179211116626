import { AfterViewInit, Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { proprietarioTowerCo, proprietariStrutture, tipiProprietario, tipologiaStrutture, } from 'app/routes/nd/lavorazioni/process-template/arrayXSelect';
import { WorkflowHelper } from 'app/routes/nd/lavorazioni/workflow-helper';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { startWith, tap } from 'rxjs/operators';

@Component( {
    selector: 'wnd-candidate-dialog',
    templateUrl: './candidate-dialog.component.html',
    styleUrls: [ './candidate-dialog.component.scss' ],
} )
export class CandidateDialogComponent implements OnInit, AfterViewInit, OnDestroy {

    private subscriptions: Subscription[] = [];


    // This subject and observable are used to set the structureOwners select options.
    // The list can be set in the template of the activity/step as templateOptions.structureOwners
    structureOwners: BehaviorSubject<any[]> = new BehaviorSubject<any[]>( proprietariStrutture );
    structureOwners$ = this.structureOwners.asObservable().pipe( startWith( proprietariStrutture ), tap( opt => console.log( 'Option called', opt ) ) );

    ownerTypes: BehaviorSubject<any[]> = new BehaviorSubject<any[]>( tipiProprietario );
    ownerTypes$ = this.ownerTypes.asObservable().pipe( startWith( tipiProprietario ) );

    fields: FormlyFieldConfig[] = [
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    className: 'col-sm-4',
                    type: 'select',
                    key: 'tipoGestore',
                    expressionProperties: {
                        'templateOptions.disabled': ( model, formState ) =>
                            formState.isStruttura || formState.disabled,
                    },
                    templateOptions: {
                        required: true,
                        label: 'Owner Type',
                        options: this.ownerTypes$,
                        change: m => {
                            if( m.model.tipoGestore === 'privato' ) {
                                ( ( m.parent.parent.formControl as UntypedFormGroup ).controls.owner as UntypedFormControl ).setValue( null );
                                ( ( m.parent.parent.formControl as UntypedFormGroup ).controls.gestore_cod as UntypedFormControl ).setValue( null );
                            }
                        },
                    },
                },
                {
                    className: 'col-sm-4',
                    type: 'select',
                    key: 'owner',
                    expressionProperties: {
                        'templateOptions.disabled': ( model, formState ) =>
                            formState.isStruttura || formState.disabled,
                        'templateOptions.required': 'model.tipoGestore != "privato"',
                    },
                    hideExpression: 'model.tipoGestore === "privato"',
                    templateOptions: {
                        required: true,
                        label: 'Proprietario',
                        options: this.structureOwners$,
                    },
                },
                {
                    className: 'col-sm-4',
                    type: 'input',
                    key: 'gestore_cod',
                    hideExpression: 'model.tipoGestore === "privato"',
                    expressionProperties: {
                        'templateOptions.disabled': ( model, formState ) =>
                            formState.isStruttura || formState.disabled,
                        'templateOptions.required': 'model.owner != "Other" && model.tipoGestore != "privato"',
                    },
                    templateOptions: {
                        required: true,
                        label: 'codice gestore',
                    },
                },
            ],
        },
        // {
        //   fieldGroupClassName: 'row',
        //   fieldGroup: [
        //     {
        //       className: 'col-sm-6',
        //       type: 'input',
        //       key: 'state',
        //       templateOptions: {
        //         label: 'Stato',
        //       },
        //     },
        //     {
        //       className: 'col-sm-6',
        //       type: 'input',
        //       key: 'description',
        //       // hideExpression: ( m, fs ) => {
        //       //   return !fs.isValutazioneMSA;
        //       // },
        //       templateOptions: {
        //         required: true,
        //         label: 'Descrizione',
        //       },
        //     },
        //   ],
        // },
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    className: 'col-sm-6',
                    type: 'datepicker',
                    key: 'dataRicezioneSAR',
                    templateOptions: {
                        label: 'dataRicezioneSAR',
                    },
                },
                {
                    className: 'col-sm-6',
                    type: 'select',
                    key: 'tipo',
                    // hideExpression: ( m, fs ) => {
                    //   return !fs.isValutazioneMSA;
                    // },
                    templateOptions: {
                        required: true,
                        label: 'tipo',
                        options: tipologiaStrutture,
                        // options: [
                        //   { label: '', value: '' },
                        //   { label: 'rawland', value: 'rawland' },
                        //   { label: 'rooftop', value: 'rooftop' },
                        //   { label: 'tunnel', value: 'tunnel' },
                        //   { label: 'other', value: 'other' },
                        // ],
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    className: 'col-sm-12',
                    type: 'input',
                    key: 'indirizzo',
                    // hideExpression: (m, fs) => {
                    //   return !fs.isValutazioneMSA;
                    // },
                    templateOptions: {
                        label: 'indirizzo',
                    },
                },
                // {
                //   className: 'col-sm-6',
                //   type: 'input',
                //   key: 'comune',
                //   // hideExpression: (m, fs) => {
                //   //   return !fs.isValutazioneMSA;
                //   // },
                //   templateOptions: {
                //     label: 'comune',
                //   },
                // },
            ],
        },
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    className: 'col-sm-12',
                    type: 'geoSync',
                    key: 'locazione',
                    templateOptions: {
                        label: 'Locazione',
                        custom: true,
                        required: true,
                        invalidCoords: () => {
                            //console.log('########## invalid coords')
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': ( model, formState ) => formState.disabled,
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                // {
                //   className: 'col-sm-4',
                //   type: 'input',
                //   key: 'provincia',
                //   // hideExpression: (m, fs) => {
                //   //   return !fs.isValutazioneMSA;
                //   // },
                //   templateOptions: {
                //     // required: true,
                //     label: 'provincia',
                //   },
                // },
                {

                    className: 'col-sm-6',
                    type: 'input',
                    key: 'foglio',
                    // hideExpression: (m, fs) => {
                    //   return !fs.isValutazioneMSA;
                    // },
                    templateOptions: {
                        // required: true,
                        label: 'foglio',
                    },
                },
                {
                    className: 'col-sm-6',
                    type: 'input',
                    key: 'mappale',
                    // hideExpression: (m, fs) => {
                    //   return !fs.isValutazioneMSA;
                    // },
                    templateOptions: {
                        // required: true,
                        label: 'mappale',
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    className: 'col-sm-6',
                    type: 'input',
                    key: 'tempoRealizzazione',
                    // hideExpression: (m, fs) => {
                    //   return !fs.isValutazioneMSA;
                    // },
                    templateOptions: {
                        type: 'number',
                        label: 'tempo di Realizzazione [giorni]',
                    },
                },
                {
                    className: 'col-sm-6',
                    type: 'input',
                    key: 'offertaEconomica',
                    hideExpression: ( m, fs ) => {
                        return !fs.isValutazioneMSA;
                    },
                    templateOptions: {
                        type: 'number',
                        label: 'offerta Economica [€]',
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    className: 'col-sm-6',
                    type: 'datepicker',
                    key: 'dataValutazioneRAN',
                    hideExpression: ( m, fs ) => {
                        return !fs.isValutazioneMSA;
                    },
                    templateOptions: {
                        label: 'dataValutazioneRAN',
                    },
                },
                {
                    className: 'col-sm-6',
                    type: 'datepicker',
                    key: 'dataValutazioneMSA',
                    hideExpression: ( m, fs ) => {
                        return !fs.isValutazioneMSA;
                    },
                    templateOptions: {
                        label: 'dataValutazioneMSA',
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    className: 'col-sm-12',
                    type: 'textarea',
                    key: 'notes',
                    // hideExpression: (m, fs) => {
                    //   return !fs.isValutazioneMSA;
                    // },
                    templateOptions: {
                        label: 'Notes',
                        rows: 3,
                    },
                },
            ],
        },
        {
            type: 'input',
            key: 'codiciAltroOperatore',
            hide: true,
        },
    ];
    form = new UntypedFormGroup( {} );
    model: any = {};
    listaImpiantiOspitati: string[];

    options: FormlyFormOptions = {
        formState: {
            isStruttura: false,
        },
    };

    constructor(
        private dialogRef: MatDialogRef<CandidateDialogComponent>,
        @Inject( MAT_DIALOG_DATA ) public data: any,
        private ngZone: NgZone,
        private router: Router,
    ) {
        if( data?.disabled ) {
            this.options.formState.disabled = data.disabled;
        } else {
            this.options.formState.disabled = false;
        }
        if( data?.candidate?.idStruttura ) {
            this.options.formState.isStruttura = true;
            this.listaImpiantiOspitati = ( data.candidate.codiciAltroOperatore as string ).split( '/' );
        }
        this.options.formState.isValutazioneMSA = data?.isValutazioneMSA;

        console.log( 'Candidate edit, opened', data );
        if( data?.structureOwners ) {
            this.structureOwners.next( data.structureOwners );
            if( data.structureOwners.length === 1 ) {
                this.ownerTypes.next( proprietarioTowerCo );
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach( s => s.unsubscribe() );
    }

    ngAfterViewInit(): void {
        console.log( 'Candidate Dialog', this.data.candidate, this.fields );
        if( this.data.candidate ) {
            if( !this.data.candidate.dataRicezioneSAR ) {
                this.data.candidate.dataRicezioneSAR = new Date();
            }
            setTimeout( () => {
                this.form.controls.locazione.setValue( {
                    comune: this.data.candidate.comune,
                    provincia: this.data.candidate.provincia,
                    regione: this.data.candidate.regione,
                    coordinates: this.data.checkCoordinates ? {
                        longitude: this.data.candidate.group.features[ 0 ].geometry.coordinates[ 0 ],
                        latitude: this.data.candidate.group.features[ 0 ].geometry.coordinates[ 1 ],
                    } : null,
                } );
                WorkflowHelper.scriptFields( this.fields, this.form, this.data.candidate );
                if( this.data.structureOwners?.length === 1 ) {
                    this.form.controls.owner.setValue( this.data.structureOwners[ 0 ].value );
                    this.form.controls.tipoGestore.setValue( proprietarioTowerCo[ 0 ].value );
                }
            } );
        }
        if( this.options.formState.disabled ) {
            this.form.disable();
        }
    }


    private registerRouterEvent() {
        this.subscriptions.push( this.router.events.subscribe( _ev => {
            this.cancel();
        } ) );
    }

    submit() {
        this.ngZone.run( () => this.dialogRef.close( {
            metaData: {
                ...this.model,
                comune: this.model.locazione?.comune,
                provincia: this.model.locazione?.provincia,
                regione: this.model.locazione?.regione,
                siglaProvincia: this.model.locazione?.siglaProv,
            },
        } ) );
    }

    cancel(): void {
        this.ngZone.run( () => this.dialogRef.close( { metaData: null } ) );
    }

    ngOnInit(): void {
        this.registerRouterEvent();
    }
}
