export const BASE_URL = 'BASE_URL';
export const OFFICES_URL = 'OFFICES_URL';
export const CONTRATTI_URL = 'CONTRATTI_URL';
export const PIANI_URL = 'PIANI_URL';
export const TRATTE_URL = 'TRATTE_URL';
export const BASELINES_URL = 'BASELINES_URL';
export const FIBRA_URL = 'FIBRA_URL';
export const FORECAST_URL = 'FORECAST_URL';
export const AUTHZ_URL = 'AUTHZ_URL';
export const NOTIFICATIONS_URL = 'NOTIFICATIONS_URL';
export const DUST_URL = 'DUST_URL';
export const LISTA_SITI_URL = 'LISTA_SITI_URL';
export const GRID_COLUMNS_URL = 'GRID_COLUMNS_URL';
export const REPORTING_URL = 'REPORTING_URL';
export const TRACKING_URL = 'TRACKING_URL';
export const NEW_SITE_URL = 'NEW_SITE_URL';
export const CACHE_HANDLER_URL = 'CACHE_HANDLER_URL';
export const LOGGER_URL = 'LOGGER_URL';
export const TOOL_TX_URL = 'TOOL_TX_URL';
