import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { autocompleteCheck } from './Validators/autocomplete-validator';
import { AnimatedFormComponent } from '../animated-form/animated-form.component';

@Component( {
    selector: 'wonder-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: [ './autocomplete.component.scss' ],
    encapsulation: ViewEncapsulation.None
} )
export class AutocompleteComponent extends AnimatedFormComponent implements OnInit {
    @ViewChild( MatInput ) formFieldControl: MatInput;
    @ViewChild( MatAutocompleteTrigger ) autocomplete: MatAutocompleteTrigger;

    @Input() formGroup: UntypedFormGroup;
    @Input() filter: ( term: any ) => Observable<string[]>;
    @Input() name: string;
    @Input() fullWidth = false;
    @Input() addControl = true;
    @Input() set control( control: UntypedFormControl ) {
        if ( control ){
            this.formControl = control;
        }
    };
    @Input() formName: string;

    @Input() set initialValue( _value: string ) {

    }

    @Input() isRequired = false;
    @Input() emitEvent = true;

    @Input() set disabled( v: boolean ) {
        if( v ) {
            this.formControl?.disable( { emitEvent: this.emitEvent } );
        } else {
            this.formControl?.enable( { emitEvent: this.emitEvent } );
        }
    }

    @Output() choiceChange = new EventEmitter<string>();

    formControl: UntypedFormControl = new UntypedFormControl( '' );
    innerFilter: Observable<any>;
    autoCompleteList: string[] = [];
    listRef = { list: this.autoCompleteList };

    get label(): string {
        return !!this.name ? this.name.replace( /^\w/, ( c ) => c.toUpperCase() ) : '';
    }

    ngOnInit() {
        if( this.isRequired ) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            this.formControl.setValidators( [ autocompleteCheck( this.listRef ), Validators.required ] );
        }
        if ( this.addControl ){
            this.formControl = new UntypedFormControl( '', [ autocompleteCheck( this.listRef ) ] );
            this.formGroup.addControl( this.name, this.formControl );
        }
        this.innerFilter = this.formControl.valueChanges
            .pipe(
                debounceTime( 400 ),
                startWith( '' ),
                distinctUntilChanged(),
                switchMap( term => this.filter( term ) ),
            );
        this.innerFilter.subscribe( list => {
            this.listRef.list = list as string[];
            this.autoCompleteList = list as string[];
            this.formControl.updateValueAndValidity( { emitEvent: true } );
        } );
    }


    emit( event: MatAutocompleteSelectedEvent ) {
        // console.log( event );
        this.choiceChange.emit( event.option.value as string );
    }

}
