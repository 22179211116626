import { Component, Input, OnInit } from '@angular/core';
import { IOfficeFrequency } from '@shared/interfaces/office-frequency';

@Component( {
    selector: 'wnd-active-frequencies',
    templateUrl: './active-frequencies.component.html',
    styleUrls: [ './active-frequencies.component.scss' ],
} )
export class ActiveFrequenciesComponent implements OnInit {
    @Input() frequencies: IOfficeFrequency[];

    constructor() {
    }

    ngOnInit(): void {
        // console.log( 'Active frequencies', this.frequencies );
    }

}
