<div class="w-full h-full" fxLayout="row wrap">
    <mat-card class="mat-elevation-z4 m-auto" style="max-width: 380px;">
        <mat-card-title class="text-center m-b-24">
            <img mat-card-image src="../../../../assets/images/wonder_logo_black.svg"
                 alt="Wonder logo" class="img-card"/>
        </mat-card-title>

        <form class="form-field-full" [formGroup]="loginForm">

            <mat-form-field appearance="outline">
                <mat-label>{{'login.username' | translate}}: UserName</mat-label>
                <input matInput formControlName="username" required>
                <!-- <mat-error *ngIf="username.invalid">
                  {{'login.please_enter' | translate}} <strong>ng-matero</strong>
                </mat-error> -->
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'login.password' | translate}}: Password</mat-label>
                <input matInput type="password"
                       formControlName="password" required>
                <!-- <mat-error *ngIf="password.invalid">
                  {{'login.please_enter' | translate}} <strong>ng-matero</strong>
                </mat-error> -->
            </mat-form-field>


            <button class="w-full m-b-16" mat-raised-button color="primary" id="login-btn"
                    (click)="login()">{{'login.login' | translate}}</button>
            <hr size="1" class="m-b-16"/>
            <button mat-raised-button id="register-btn"
                    (click)="register()">Sign Up
            </button>


        </form>

    </mat-card>
</div>
