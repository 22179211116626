<div class="add-reason-dialog">
    <h3>{{title}}</h3>
    <mat-form-field>
        <mat-label>{{label}}</mat-label>
        <textarea matInput [formControl]="control"></textarea>
    </mat-form-field>
    <div class="actions">
        <button mat-button (click)="cancel()">Annulla</button>
        <button mat-button color="primary" (click)="submitReason()" [disabled]="control.invalid">Salva</button>
    </div>
</div>
