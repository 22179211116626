import { ActivityConfig } from './activity_config';
import { ambitoNormativo, okKo } from './arrayXSelect';
import { extraAdeguamentoPassive, extraFlowAnagraficaNew, extraFlowAnagraficaOld, extraFlowForecast, extraFlowNPlus } from './extraFlow';


//extraFlow
//sono stati inseriti su extraflow.ts

// workFlow
export const remedies: ActivityConfig = {
    extraFlowConfig: [
        {
            name: 'Ext_New Forecast',
            displayName: 'New Forecast',
            fields: extraFlowForecast,
        },
        {
            name: 'Ext_Anagrafica New',
            displayName: 'Anagrafica New',
            fields: extraFlowAnagraficaNew,
        },
        {
            name: 'Ext_Anagrafica Old',
            displayName: 'Anagrafica Old',
            fields: extraFlowAnagraficaOld,
        },
        {
            name: 'Ext_Legame NPlus',
            displayName: 'Legame NPlus',
            fields: extraFlowNPlus,
        },
        {
            name: 'Ext_Adeguamento Passive',
            displayName: 'Adeguamento Passive',
            fields: extraAdeguamentoPassive,
        },
    ],
    name: 'remedies',
    displayName: 'Remedies',
    stepsConfig: [
        {
            displayName: 'New Stand Alone',
            name: '01_New Stand Alone',
            isEntryStep: true,
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                required: true,
                                label: 'Tipologia Sito',
                                options: [
                                    {
                                        label: 'Nuovo Sito con Area di Ricerca',
                                        value: 'Nuovo Sito con Area di Ricerca',
                                    },
                                    {
                                        label: 'Nuovo Sito su struttura INWIT esistente',
                                        value: 'Nuovo Sito su struttura INWIT esistente',
                                    },
                                ],
                            },
                        },
                        //     {
                        //     className: 'col-sm-4',
                        //     type: 'input',
                        //     key: 'string2',
                        //     templateOptions: {
                        //       required: true,
                        //       label: 'Nuovo Office',
                        //       minLength: 8,
                        //       maxLength: 8,
                        //     },
                        // },
                        //     {
                        //     className: 'col-sm-4',
                        //     type: 'input',
                        //     key: 'string3',
                        //     templateOptions: {
                        //         required: true,
                        //         label: 'Nuovo Candidato',
                        //         maxLength: 1,
                        //         },
                        //   },
                    ],
                },
                {
                    key: 'searchArea',
                    type: 'searchArea',
                    templateOptions: {
                        required: true,
                        disableIfCommited: true,
                        custom: true,
                        map: {
                            errorMessage: 'You can\'t submit more than one area.',
                        },
                    },
                },
            ],
            //componentName: 'SearchAreaComponent',
            nextStep: m => {
                if( m.string1 === 'Nuovo Sito con Area di Ricerca' ) {
                    return '02_Invio SARF INWIT';
                } else {
                    return '03_Registrazione Struttura';
                }
            },
        },
        {
            displayName: 'Registrazione Struttura',
            // inserire constraints ricezione SAR NO
            name: '03_Registrazione Struttura',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: true,
                                label: 'Data',
                            },
                        },
                    ],
                },
                {
                    key: 'registrazioneStruttura',
                    type: 'candidate',
                    templateOptions: {
                        required: true,
                        disableIfCommited: true,
                        custom: true,
                        scope: [ 'population', 'selection' ],
                        map: {
                            numberOfItems: {
                                min: 1,
                                max: 3,
                            },
                            errorMessage: 'You can\'t submit more than 3 candidates.',
                        },
                    },
                },
                // {
                //   fieldGroupClassName: 'row',
                //   // inserire punto mappa con tempo di realizzazione e offerta economica multipli
                //   fieldGroup: [
                //     {
                //       key: 'custom_map',
                //       className: 'col-sm-12',
                //       type: 'mapInput',
                //       templateOptions: {
                //         custom: true,
                //         map: {
                //           typeOfService: 'search_area',
                //           errorMessage: 'You can\'t sumbit more than one area.',
                //         },
                //       },
                //     },
                //     // {
                //     //   className: 'col-sm-4',
                //     //   type: 'input',
                //     //   key: 'string2',
                //     //   templateOptions: {
                //     //     required: true,
                //     //     label: 'Tempi di realizzazione',
                //     //   },
                //     // },
                //     // {
                //     //   className: 'col-sm-4',
                //     //   type: 'input',
                //     //   key: 'string3',
                //     //   templateOptions: {
                //     //     required: true,
                //     //     label: 'Offerta Economica',
                //     //   },
                //     // },
                //   ],
                // },
            ],
            nextStep: m => {
                // da valutare se next step con TIM
                return '05_Comunicazione INWIT ed Emissione Scheda Radio';
            },
        },
        {
            displayName: 'Invio SARF INWIT',
            name: '02_Invio SARF INWIT',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: true,
                                label: 'Data Invio SARF',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '03_Ricezione SAR';
            },
        },
        {
            displayName: 'Ricezione SAR',
            // inserire constraints ricezione SAR NO
            name: '03_Ricezione SAR',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                required: true,
                                label: 'Ricezione SAR',
                                options: [
                                    { label: 'SI', value: 'SI' },
                                    { label: 'NO', value: 'NO' },
                                ],
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: true,
                                label: 'Data ricezione SAR',
                            },
                        },
                    ],
                },
                {
                    key: 'registrazioneStruttura',
                    type: 'candidate',
                    templateOptions: {
                        required: true,
                        disableIfCommited: true,
                        custom: true,
                        scope: [ 'population', 'selection' ],
                        map: {
                            numberOfItems: {
                                min: 1,
                                max: 3,
                            },
                            errorMessage: 'You can\'t submit more than 3 candidates.',
                        },
                    },
                },
                {
                    fieldGroupClassName: 'row',
                    // inserire punto mappa con tempo di realizzazione e offerta economica multipli
                    fieldGroup: [
                        // {
                        //   key: 'custom_map',
                        //   className: 'col-sm-4',
                        //   type: 'mapInput',
                        //   templateOptions: {
                        //     custom: true,
                        //     map: {
                        //       typeOfService: 'search_area',
                        //       errorMessage: 'You can\'t sumbit more than one area.',
                        //     },
                        //   },
                        // },
                        {
                            className: 'col-sm-6',
                            type: 'input',
                            key: 'string2',
                            templateOptions: {
                                required: true,
                                label: 'Tempi di realizzazione',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'input',
                            key: 'string3',
                            templateOptions: {
                                required: true,
                                label: 'Offerta Economica',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                if( m.string1 === 'SI' ) {
                    return '05_Comunicazione INWIT ed Emissione Scheda Radio';
                } else {
                    return '88_KO Lavorazione';
                }
            },
        },
        {
            displayName: 'KO Lavorazione',
            name: '88_KO Lavorazione',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'string1',
                            templateOptions: {
                                required: true,
                                label: 'Conferma KO Lavorazione Area di Ricerca',
                            },
                        },
                    ],
                },
            ],
        },
        {
            displayName: 'Bocciatura Candidato',
            name: '88_Bocciatura Candidato',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'string1',
                            templateOptions: {
                                required: true,
                                label: 'Conferma KO Candidato INWIT',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'string1',
                            templateOptions: {
                                required: true,
                                label: 'Conferma KO Candidato INWIT',
                            },
                        },
                        {
                            type: 'communication',
                            key: 'communication',
                            templateOptions: {
                                custom: true,
                                label: 'Comunicazione INWIT',
                                communicationConfig: {
                                    fields: [
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'datepicker',
                                                    key: 'date1',
                                                    templateOptions: {
                                                        required: true,
                                                        label: 'Data Invio INWIT',
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            ],
        },
        {
            displayName: 'Comunicazione INWIT ed Emissione Scheda Radio',
            name: '05_Comunicazione INWIT ed Emissione Scheda Radio',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: true,
                                label: 'Data Comunicazione Approvazione',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                required: true,
                                label: 'Data Emissione scheda radio',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '06_Sopralluogo';
            },
        },
        {
            displayName: 'Sopralluogo',
            name: '06_Sopralluogo',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: true,
                                label: 'Data primo sopralluogo',
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        custom: true,
                        label: 'Comunicazioni Sopralluogo',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Tipologia Comunicazione',
                                                options: [
                                                    { label: 'Accesso Sito', value: 'Accesso Sito' },
                                                    // {
                                                    //   label: 'Certificazione Impianto di Risalita',
                                                    //   value: 'Certificazione Impianto di Risalita',
                                                    // },
                                                ],
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'input',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Ricezione',
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            ],
            nextStep: m => {
                return '07_Predisposizione pacchetto e progettazione';
            },
        },
        {
            displayName: 'Predisposizione pacchetto e progettazione',
            name: '07_Predisposizione pacchetto e progettazione',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: true,
                                label: 'Ricezione AIE da INWIT',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                required: true,
                                label: 'Ricezione PDM da INWIT',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                required: true,
                                label: 'Approvazione AIE Vodafone',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                required: true,
                                label: 'Approvazione PDM Vodafone',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '08_Presentazione permessi';
            },
        },
        {
            displayName: 'Presentazione permessi',
            name: '08_Presentazione permessi',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: true,
                                label: 'Presentazione AIE',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                required: true,
                                label: 'Presentazione PDM',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                required: true,
                                label: 'Ottenimento Permesso AIE',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                required: true,
                                label: 'Ottenimento Permesso PDM',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '09_Room Ready';
            },
        },
        {
            displayName: 'Room Ready',
            name: '09_Room Ready',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: true,
                                label: 'Pronto Locale',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '10_Selezione Ambito Normativo';
            },
        },
        {
            displayName: 'Selezione Ambito Normativo',
            name: '10_Selezione Ambito Normativo',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                required: true,
                                label: 'Ambito Normativo',
                                options: ambitoNormativo,
                            },
                        },

                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: true,
                                label: 'Data definizione ambito normativo',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                if( m.string1 === 'Tit. IV' ) {
                    // return 'Progetto Esecutivo (Tit. IV)';
                    return '11_Cantiere (Tit. IV)';
                } else {
                    // return 'Progetto Esecutivo (Art.26)';
                    return '11_Cantiere (Art.26)';
                }
            },
        },
        {
            displayName: 'Cantiere (Tit. IV)',
            name: '11_Cantiere (Tit. IV)',

            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: true,
                                label: 'Consegna Area',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                    ],
                    // {
                    //   className: 'col-sm-6',
                    //   type: 'datepicker',
                    //   key: 'date2',
                    //   templateOptions: {
                    //     required: true,
                    //     label: 'Chiusura cantieri Enti',
                    //   },
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        custom: true,
                        label: 'Comunicazioni Collaudo Tecnico Amministrativo',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Esito',
                                                options: okKo,
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta > Data Richiesta'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-12',
                                            type: 'input',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                required: true,
                                label: 'Chiusura cantieri Enti',
                            },
                        },

                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                required: true,
                                label: 'Riconsegna Area e Accettazione',
                            },
                        },
                    ],
                },
            ],

            nextStep: m => {
                return '99_Chiusura attività';
            },
        },

        {
            displayName: 'Cantiere (Art.26)',
            name: '11_Cantiere (Art.26)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '99_Chiusura attività';
            },
        },
        {
            displayName: 'Chiusura attività',
            name: '99_Chiusura attività',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: true,
                                label: 'Chiusura attività',
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
