import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityCounterDto } from './models/activity-counter-dto';
import { ZonaStrutturaDTO } from './models/zona-struttura-dto';
import { ZoneStepsTotals } from './models/zone-steps-totals';
import { BASE_URL } from '@shared/models/urls-constants';

@Injectable( {
    providedIn: 'root',
} )


export class DashboardAPIService {

    constructor( private http: HttpClient, @Inject( BASE_URL ) private baseUrl: string ) {
    }

    getStrutture( id: string ): Observable<ZonaStrutturaDTO> {
        const url = this.baseUrl + `wonder-src-offices-api/api/v1/offices/summary?zone=${ id }`;
        return this.http.get<ZonaStrutturaDTO>( url );
    }

    getActivitiesReport( id: string ): Observable<ActivityCounterDto[]> {
        const url = this.baseUrl + `wonder-src-workflow-api/Activities/widget/${ id }`;
        return this.http.get<ActivityCounterDto[]>( url );
    }

    getStepsStatistics( activityType: string ): Observable<ZoneStepsTotals[]> {
        const url = this.baseUrl + `wonder-src-workflow-api/Statistical/activities-statuses?type=${ activityType }`;
        return this.http.get<ZoneStepsTotals[]>( url );
    }

}
