/* tslint:disable:no-string-literal */
import * as L from 'leaflet';
import { featureGroup, Marker } from 'leaflet';
import { CustomMapIcon } from './icons-svg';

export interface FeaturesGroup {
    id?: string;
    group: L.FeatureGroup;
    typeOfService: string;
    idStep: string;
}

export function transformLayerToJson( featGroup: L.FeatureGroup<any> ) {
    const group = featGroup.toGeoJSON();
    let cnt = 0;
    featGroup.getLayers().forEach( l => {
        if( l instanceof L.Circle ) {
            group[ 'features' ][ cnt ].properties.radius = l.getRadius();
        }
        cnt++;
    } );

    return group;
}

export function transformJsonToLayer( json ): L.FeatureGroup<any> {
    const group = featureGroup();

    json[ 'features' ].forEach( f =>
        L.geoJSON( f, {
            pointToLayer: ( feature, latlng ) => {
                if( feature.properties.radius ) {
                    return new L.Circle( latlng, feature.properties.radius );
                } else {
                    return new L.Marker( latlng );
                }
            },
            onEachFeature: ( feature, layer ) => {
                group.addLayer( layer );
            },
        } )
    );

    return group;
}


export function fixMarker( ing: L.FeatureGroup<any> ): L.FeatureGroup<any> {
    ing.getLayers().forEach( l => {
        if( l instanceof Marker ) {
            ( l as Marker ).setIcon( CustomMapIcon.newPlaceIcon );
        }
    } );
    return ing;
}
