import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UploaderConfig } from '@shared/directives/tus-uploader.directive';
import { TusSingleFileUploader } from '@shared/services/upload.service';
import { IInputFileElement } from '../../../routes/nd/lavorazioni/step-edit/custom-field/single-file/single-file.component';

@Component( {
    selector: 'wnd-import-button',
    templateUrl: './import-button.component.html',
    styleUrls: [ './import-button.component.scss' ],
} )
export class ImportButtonComponent implements OnInit {
    @Input() entity: string;
    @Input() entityId: string | undefined;
    @Input() toolTip = '';

    @ViewChild( 'inputFile' ) inputFileView: ElementRef<IInputFileElement>;

    tusConfig: UploaderConfig = {
        metadata: {},
        allowedTypes: [ '.xlsx', '.xlsm', '.xls' ],
        lazyStart: false,
    };

    constructor() {
    }

    ngOnInit(): void {
        this.tusConfig.metadata = { entity: this.entity, ...( this.entityId ) && { entityId: this.entityId } };
    }

    onUploadEvent( $event: { source: TusSingleFileUploader; uploaders: TusSingleFileUploader[] } ) {
        console.log( 'On upload', $event );
        this.inputFileView.nativeElement.value = '';
    }

    onSelectionError( $event: string ) {
        console.log( 'On selection error', $event );
        this.inputFileView.nativeElement.value = '';
    }
}
