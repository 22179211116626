<div id='geosearch' #geosearch class='map-search-control'>
    <button mat-button mat-icon-button (click)='toggleDrawer($event)'>
        <mat-icon class='black-icon'>location_searching</mat-icon>
    </button>
    <mat-drawer class='cassetto' mode='side' position='end' [opened]='showSearch' [disableClose]='true'>
        <div class='interno-cassetto row'>
            <geo-sync #geoSyncComponent class='col' [showZone]='false' [showGeoJSON]='true' (valueChanges)='changed($event)'></geo-sync>
            <button *ngIf='lastGeoJSON' class='annulla-ricerca' mat-icon-button (click)='cleanSearch()'>
                <mat-icon>location_disabled</mat-icon>
            </button>
        </div>
    </mat-drawer>
</div>
