import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WndGridColumnService } from '@shared/components/wonder-grid/services/wnd-grid-column-service';

@Component( {
    selector: 'wnd-base-filter',
    templateUrl: './base-filter.component.html',
    styleUrls: [ './base-filter.component.scss' ]
} )
export class BaseFilterComponent implements OnInit {

    constructor( public columnService: WndGridColumnService ) {
    }

    @Output() preventClose = new EventEmitter<boolean>();

    ngOnInit(): void {

    }

}
