<div class="m-b-16">
    <mat-form-field (click)="menuOpened()"
                    class="example-full-width map-form-input cursor-pointer"
                    [ngClass]="{ 'mat-form-field-invalid': !formControl.valid && touched }"
    >
        <mat-label>{{getFormControlLabel()}}</mat-label>
        <input type="mapInput" readonly class="map-form-input cursor-pointer" matInput/>
        <button matSuffix mat-icon-button>
            <mat-icon class="icon-18">sms</mat-icon>
        </button>
    </mat-form-field>
    <mat-expansion-panel
            class="map-input"
            (opened)="opened()"
            (closed)="closed()"
            [expanded]="panelOpenState"
            hideToggle
    >
        <button
                type="button"
                mat-raised-button
                color="primary"
                (click)="addCommunication()"
                class="m-8 m-t-24"
                matTooltip="Add new communication"
                [disabled]='controlDisabled'
        >
            Add new communication
            <mat-icon>add</mat-icon>
        </button>
        <div>
            <mtx-grid class="grid" [data]="list" [columns]="dataColumns" [showPaginator]="false" [cellTemplate]="{operation: operationTpl}"></mtx-grid>

            <ng-template #operationTpl let-row let-index="index" let-col="colDef">
                <button mat-icon-button (click)="openFileUploader(row)" aria-label="Upload on or more files" color='primary'>
                    <mat-icon>attach_file</mat-icon>
                </button>
                <button mat-icon-button (click)="edit(row)" [disabled]="controlDisabled" aria-label="Edit the communication" color='primary'>
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button (click)="delete(row)" [disabled]="controlDisabled" aria-label="Delete the communication" color='warn'>
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </div>
    </mat-expansion-panel>
</div>
