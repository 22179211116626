import { WndGridColumn } from './../../interfaces/wnd-grid-column';
import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component( {
    selector: 'wnd-grid-row-panel',
    templateUrl: './wnd-grid-row-panel.component.html',
    styleUrls: [ './wnd-grid-row-panel.component.scss' ],
} )
export class WndGridRowPanelComponent implements OnInit {

    @Input() row: any;
    @Input() columns: WndGridColumn[];
    @Input() closeRequestedSource: Subject<void>;

    constructor() {
    }

    ngOnInit(): void {
        console.log( this.row, this.columns );
    }

}
