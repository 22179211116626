import { Injectable } from '@angular/core';
import { Processo } from './models/processo';
import { ActivityCounterDto } from './models/activity-counter-dto';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { DashboardAPIService } from './dashboard-api.service';
import { TipoAttivita } from './models/tipo-attivita';

export interface PeriodicElement {
    name: string;
    activities: number;
    finalized: number;
    killed: number;
}

export interface ZoneData {
    id: string;
    title: string;
    img: string;
    color: string;

}


const ELEMENT_DATA: PeriodicElement[] = [];


@Injectable()
export class DashboardService {
    zone: ZoneData[] = [
        {
            id: '1',
            title: ' Nord Ovest',
            img: 'assets\\images\\zona\\zona1_b.png',
            color: '#c44129',
        },
        {
            id: '2',
            title: ' Nord Est',
            img: 'assets\\images\\zona\\zona2_b.png',
            color: '#3e8410',
        },
        {
            id: '3',
            title: ' Centro',
            img: 'assets\\images\\zona\\zona3_b.png',
            color: '#ec9332',
        },
        {
            id: '4',
            title: ' Sud',
            img: 'assets\\images\\zona\\zona4_b.png',
            color: '#0560bd',
        },
    ];


    processi: Processo[] = [
        {
            nome: 'Workflow Management Core',
            label: 'WORKFLOW MANAGEMENT CORE',
            imgFlusso: 'General',
            descrizioneBreve:
                `Il completamento di un\'attività prevede la compilazione di due sezioni, una di Avanzamento (step temporizzati) e una di Extraflow (step asincroni).<br>
  In qualsiasi momento la lavorazione potrà essere mandata in KO.
  `,
            descrizioneLunga: `La sezione Avanzamento prevede degli step customizzati per ogni tipologia di processo.<br>
  Ciascun processo può prevedere delle ramificazioni e la strada da intraprendere è funzione della compilazione da parte dell\'utente di una o più variabili dello step precedente.<br>
  L\'ultimo step di Avanzamento verrà identificato dalla presenza del tasto "Finalize" al posto del tasto "Commit".<br>
  La sezione Extraflow prevede degli step asincroni che possono essere compilati in qualsiasi momento da parte dell\'utente.<br>
  Ove non si ritenesse necessario inserire le informazioni richieste, l\'utente dovrà comunque premere il tasto "Commit" per abilitare l\'azione Completa Attività.<br>
  La lavorazione potrà essere mandata in KO tramite l\'Azione KO Attività in qualsiasi momento, a prescindere dallo stato di compilazione delle sezioni Avanzamento ed Extraflow.
  `,
        },
        {
            nome: 'Commitment',
            label: 'COMMITMENT',
            imgFlusso: 'commitment',
            descrizioneBreve:
                'Il processo prevede una interlocuzione esclusivamente con Inwit per la realizzazione di nuovi siti o trasferimenti.',
            descrizioneLunga:
                `Le lavorazioni dei siti sono identificate con un Office fittizio in caso di nuovi siti (Activities List - colonna Codice Office) e tramite Office Old in caso di trasferimento (Activities List - colonna Codice Office Old).<br><br>
Appendice:<br>
<h4>SITI DI COMMITMENT (art 5.3)</h4>
<ul>
<li>2 mesi prima rispetto all’anno previsto in cui devono essere richiesti i servizi garantiti occorre fornire un 120% di fatturato da lavorare (es se chiedo 100 traslochi, devo fornire 120 aree di ricerca).</li>
<li>INWIT ha un anno di tempo per realizzarli, passato il tempo puo’ chiedere ulteriori 6 mesi per lavorare ancora un 20% dell’obbiettivo dell’anno (nell’esempio di prima, 20 siti).</li>
</ul>
<h4>SITI DI REMEDIATION</h4>
<ul>
<li>Siti nuovi realizzati per adempiere ai dettami della commissione europea.</li>
<li>È prevista una fornitura di bacino di lavorazione ampia il doppio rispetto agli obbiettivi di siti nuovi della Remediation e sono previsti degli impegni nella fornitura della scheda radio (per l’operatore) e degli obbiettivi di presentazione e realizzazione per INWIT.</li>
</ul>`,
        },
        {
            nome: 'Fornitore privilegiato',
            label: 'FORNITORE PRIVILEGIATO ',
            imgFlusso: 'fornitorePrivilegiato',
            descrizioneBreve:
                `Il processo prevede una interlocuzione con Inwit ed eventualmente con una terza parte per la realizzazione di nuovi siti o trasferimenti.`,
            descrizioneLunga:
                `Le lavorazioni dei siti sono identificate con un Office fittizio in caso di nuovi siti (Activities List - colonna Codice Office)
e tramite Office Old in caso di trasferimento (Activities List - colonna Codice Office Old).<br><br>
Appendice:<br>
<h4>FORNITORE PRIVILEGIATO (art 4.1)</h4>
<ul>
<li>Qualsiasi nuovo punto di presenza che vogliamo realizzare (escludendo quelli su siti già esistenti) deve essere affidato ad INWIT.</li>
<li>Inwit ha 30 giorni lavorativi per portare offerta economica in termini di Canoni e tempi.</li>
<li>La realizzazione del sito e’ sottoposta a penali nel caso non riescano a traguardare quanto presente in offerta.</li>
<li>Se l’offerta viene rifiutata (o non viene fornita) l’operatore si puo’ muovere in autonomia.</li>
<li>Su offerta di terzi INWIT ha diritto di Last Call, matchando tempi e costi.</li>
</ul>`,
        },
        {
            nome: 'Common Grid',
            label: 'COMMON GRID',
            imgFlusso: 'commonGridB',
            descrizioneBreve:
                'Il processo prevede la realizzazione di nuovi siti o trasferimenti all\'interno del progetto di Common Grid (siti B e siti D1).',
            descrizioneLunga: `
Le lavorazioni dei siti sono identificate con il nuovo Office di Common Grid, analogamente a quanto avviene già in N+ (Activities List - colonna Codice Office).<br>
Fanno parte della Common Grid i siti previsti nel piano di Sharing SSI 5.0.
      `,
        },
        /*
            {
              nome: 'fornitorePrivilegiatoTrasferimento',
              label: 'fornitorePrivilegiatoTrasferimento label',
              imgFlusso: 'fornitorePrivilegiatoTrasferimento',
              descrizioneBreve: 'descrizioneBreve',
              descrizioneLunga: 'descrizioneLunga',
            },
            {
              nome: 'commitmentTrasferimento',
              label: 'commitmentTrasferimento label',
              imgFlusso: 'commitmentTrasferimento',
              descrizioneBreve: 'descrizioneBreve',
              descrizioneLunga: 'descrizioneLunga',
            },
            {
              nome: 'commonGridD1',
              label: 'commonGridD1 label',
              imgFlusso: 'commonGridD1',
              descrizioneBreve: 'descrizioneBreve',
              descrizioneLunga: 'descrizioneLunga',
            },
        */
        {
            nome: 'Upgrade Inwit',
            label: 'UPGRADE',
            imgFlusso: 'upgradeInwit',
            descrizioneBreve: 'Il processo include qualsiasi tipologia di upgrade su struttura Inwit ad eccezione delle lavorazioni di Predisposizione Infra pro Ospitalità.',
            descrizioneLunga: `
Il processo include le tre diverse tipologie di interventi:<br>
<ul>
<li>Tipologia A: Upgrades senza modifica delle aree occupate e senza compromissione del carico strutturale.</li>
<li>Tipologia B: Upgrades con modifica delle aree occupate, con possibile aumento del carico strutturale, ma senza oneri aggiuntivi da prevedersi. </li>
<li>Tipologia C: Upgrades che prevedono interventi onerosi di adeguamento infrastrutturale.</li>
</ul>
      `,
        },
        {
            nome: 'Predisposizione Infra',
            label: 'PREDISPOSIZIONE INFRASTRUTTURA',
            imgFlusso: 'predisposizioneInfra',
            descrizioneBreve:
                'Il processo prevede l\'adeguamento infrastrutturale pro Ospitalità di TIM. Alla chiusura dell\'attività verrà richiesto di fornire il Room Ready comunicato/da comunicare all\'operatore Ospitato.',
            descrizioneLunga: `
Il processo include le tre diverse tipologie di interventi:<br>
<ul>
<li>Tipologia A: Upgrades senza modifica delle aree occupate e senza compromissione del carico strutturale.</li>
<li>Tipologia B: Upgrades con modifica delle aree occupate, con possibile aumento del carico strutturale, ma senza oneri aggiuntivi da prevedersi. </li>
<li>Tipologia C: Upgrades che prevedono interventi onerosi di adeguamento infrastrutturale.</li>
</ul>
      `,
        },
        {
            nome: 'Dismissione Inwit',
            label: 'DISMISSIONE SITO INWIT',
            imgFlusso: 'dismissioneInwit',
            descrizioneBreve:
                'Il processo prevede la dismissione di un office Vodafone su struttura di proprietà INWIT.',
            descrizioneLunga: `
Le lavorazioni dei siti sono identificate tramite l\'office che deve essere dismesso, in caso di trasferimenti la relativa attività di nuovo sito può essere trovata mediante la ricerca di Office Old nella lista delle attività.<br>
Il processo prevede la Comunicazione della Data di Riferimento (DDR), la condivisione del Site Disinstallation Report (SDR) e l'accettazione dei lavori da parte di Inwit.`
            ,
        },
        {
            nome: 'Dismissione',
            label: 'DISMISSIONE SITO',
            imgFlusso: 'dismissione',
            descrizioneBreve:
                'Il processo prevede la dismissione di un office Vodafone su struttura di proprietà NON INWIT.',
            descrizioneLunga: `
Le lavorazioni dei siti sono identificate tramite l\'office che deve essere dismesso, in caso di trasferimenti la relativa attività di nuovo sito può essere trovata mediante la ricerca di Office Old nella lista delle attività.<br>
`,
        },
        {
            nome: 'Extraflow',
            label: 'EXTRAFLOW',
            imgFlusso: 'Extraflow',
            descrizioneBreve:
                'Gli step di Extaflow possono essere compilati in qualsiasi momento a prescindere dallo stato di compilazione della sezione Avanzamento.',
            descrizioneLunga: `
  Ciascun Extraflow rappresenta una fase del processo asincrona rispetto a quanto presente nella sezione Avanzamento. Gli extraflow sono customizzati rispetto a ciascun workflow da gestire.
  `,
        },
    ];


    constructor( private dashboardApi: DashboardAPIService ) {
    }

    getData() {
        return ELEMENT_DATA;
    }

    getProcessi() {
        return this.processi;
    }

    getZone(): ZoneData[] {
        return this.zone;
    }

    /**
     * Calcola i totali aggregati ricevuti dal server e li trasforma in un dizionario le cui chiavi sono
     * i nomi dei tipi ed i valori i dati aggregato calcolati.
     * @param activities  Le attività provenienti dal server.
     * @private
     */
    private transformActivitiesData( activities: ActivityCounterDto[] ) {
        const activitiesTotals = activities.map( a => ( {
            type: a.type,
            records: a.records.reduce( ( accumulator, currentValue ) => ( accumulator + currentValue.record ), 0 ),
        } ) );
        const activitiesArray: [] = [];
        activitiesTotals.forEach( a => activitiesArray[ a.type ] = a.records );
        return activitiesArray;
    }

    /**
     * Trasforma un oggetto in un vettore in modo che ci sia un elemento per ognuna delle labels
     * @param labels  Le possibili labels
     * @param data    I dati in forma di dizionario da cui prendere i valori per il vettore.
     * @private
     */
    private getArrayValues( labels: string [], data: [] ): any[] {
        return labels.map( l => data[ l ] || 0 );
    }

    /**
     * Questa funzione restituisce i dati aggregati per le attività raggruppate per tipo di attività e zona.
     * Il formato restituito è utilizzabile nella libreria chartjs per visualizzare grafici riassuntivi.
     */
    public getActivitiesTypeStat(): Observable<{ data: { data: any[], label: string }[]; labels: string[] }> {
        let labels: string[] = [];
        const zone = this.getZone();
        const activitiesData = zone.map( z => this.dashboardApi.getActivitiesReport( z.id ).pipe( map( a => ( {
            idZona: z.id,
            titoloZona: z.title,
            activities: a,
        } ) ) ) );
        return forkJoin( activitiesData ).pipe(
            map( res => {
                console.group( 'Activities chart' );
                console.log( 'api returns: ', res );
                const activitiesList = res.map( data => ( {
                        data: this.transformActivitiesData( data.activities ),
                        label: `Zona ${ data.idZona }`,
                    } ),
                );

                console.log( 'transformed with zone', activitiesList );

                for( const act of activitiesList ) {
                    console.log( 'object keys', act, Object.keys( act.data ) );
                    labels = labels.concat( Object.keys( act.data ).filter( i => labels.indexOf( i ) < 0 ) );
                }

                console.log( 'extracted labels', labels );
                console.groupEnd();

                return activitiesList.map( z => ( {
                    data: this.getArrayValues( labels, z.data ),
                    label: z.label,
                } ) );

            } ),
            map( res => ( { data: res, labels } ) ),
        );
    }

    /**
     * Questa funzione restituisce i dati aggregati per le attività raggruppate per stato o step.
     * Il formato restituito è utilizzabile nella libreria chartjs per visualizzare grafici riassuntivi.
     */
    getStepsStatistics( activityType: string ): Observable<{ data: { data: any[], label: string }[], labels: string[] }> {
        let labels: string[] = [];
        return this.dashboardApi.getStepsStatistics( activityType ).pipe(
            tap( res => {
                console.log( 'Steps statistics', res );
            } ),
            map( res => {
                console.group( 'Steps chart' );
                console.log( 'api returns: ', res );
                const stepsList = res.map( z => ( {
                    data: this.transformStepsData( z.steps ),
                    label: `Zona ${ z.zona }`,
                } ) );

                console.log( 'transformed with zone', stepsList );

                for( const st of stepsList ) {
                    console.log( 'object keys', st, Object.keys( st.data ) );
                    labels = labels.concat( Object.keys( st.data ).filter( i => labels.indexOf( i ) < 0 ) );
                }

                console.log( 'extracted labels', labels );
                console.groupEnd();

                return stepsList.map( z => ( {
                    data: this.getArrayValues( labels, z.data ),
                    label: z.label,
                } ) ).sort( ( a, b ) => ( a.label < b.label ? -1 : ( a.label > b.label ? 1 : 0 ) ) );

            } ),
            map( res => ( { data: res, labels } ) ),
        );
    }

    /**
     * Trasforma il vettore ricevuti dal server in un oggetto dove ogni attributo ha il nome dello step e
     * il valore, il totale delle attività nello step stesso.
     * @param steps
     * @private
     */
    private transformStepsData( steps: { name: string, total: number }[] ) {
        const stepsArray: [] = [];
        steps.forEach( s => stepsArray[ s.name ] = s.total );
        return stepsArray;
    }

    /**
     * Restituisce tutti i tipi di attività possibili.
     * @TODO prenderli dal server
     */
    public getTipiAttivita(): Observable<TipoAttivita[]> {
        return of( [
            { valore: 'commitment', descrizione: 'Commitment' },
            { valore: 'commitmentTrasferimento', descrizione: 'Commitment trasferimento' },
            { valore: 'commonGridB', descrizione: 'Common grid B' },
            { valore: 'commonGridD1', descrizione: 'Common grid D1' },
            { valore: 'fornitorePrivilegiato', descrizione: 'Fornitore privilegiato' },
            {
                valore: 'fornitorePrivilegiatoTrasferimento',
                descrizione: 'Fornitore privilegiato trasferimento',
            },
            { valore: 'predisposizioneInfra', descrizione: 'Predisposizione INFRA' },
            { valore: 'upgradeInwit', descrizione: 'Upgrade Inwit' },
        ] );
    }
}
