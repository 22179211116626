<div class="w-full">
    <mat-form-field
            floatLabel="never"
            class="wnd-grid-input-wrapper"
            *ngIf="column?.type !== 'date' && column?.type !== 'boolean'"
    >
        <mat-label>{{ placeholder }}</mat-label>
        <input (focus)="open()" (blur)="allow()" [formControl]="firstArg" [type]="inputType" matInput/>
    </mat-form-field>

    <mat-form-field
            *ngIf="!inRangeActive && column?.type === 'date'"
            class="wnd-grid-input-wrapper"
            floatLabel="never"
    >
        <mat-label>{{ placeholder }}</mat-label>
        <input
                (focus)="open()"
                (blur)="onDateBlur()"
                (dateChange)="dateChange($event)"
                matInput
                [matDatepicker]="picker"
                [value]="firstArg.value"
                (input)="onDateInput($event.target.value)"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker (opened)="open()" (closed)="close()" #picker></mat-datepicker>

    </mat-form-field>

    <mat-form-field floatLabel="never" class="wnd-grid-input-wrapper" *ngIf="column?.type === 'boolean'">
        <mat-label>{{ placeholder }}</mat-label>
        <mat-select
                (openedChange)="boolSelectOpenEvent($event)"
                [formControl]="firstArg"
                disableOptionCentering
                panelClass="filter-type-panel"
        >
            <mat-option *ngFor="let opt of ['TRUE', 'FALSE']" [value]="opt">
                {{ opt }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
