<mat-card>
    <mat-card-header>
        <div fxFlex></div>
        <button mat-icon-button (click)='closeMe()'>
            <mat-icon>close</mat-icon>
        </button>
    </mat-card-header>

    <wnd-struttura-details *ngIf="!!strutturaId" [strutturaId]="strutturaId" [officeType]="officeType"></wnd-struttura-details>
</mat-card>
