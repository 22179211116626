import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { GridColumnsState } from './index';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { GridColumnsStoreEffects } from '@store/grid-columns-store/effects';


@NgModule( {
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature( GridColumnsState.featureKey, reducers ),
        EffectsModule.forFeature( [ GridColumnsStoreEffects ] ),
    ],
} )
export class GridColumnsStoreModule {
}
