import { CandidateActivity } from './candidate-activity';
import { CandidateMetaData } from './models/candidate-meta-data';
import { featureGroup, Layer, Marker } from 'leaflet';
import { transformJsonToLayer, transformLayerToJson, } from '@shared/components/osm-draw-map/custom-leaflet-function';
import { CandidateDialogComponent } from './candidate-dialog/candidate-dialog.component';
import { map } from 'rxjs/operators';
import { MtxDialog } from '@ng-matero/extensions/dialog';

export class CandidateBox {
    private constructor( private candidateActivity: CandidateActivity, candidate?: CandidateMetaData ) {
        if( candidate ) {
            this.candidate = candidate;
        } else {
            this.candidate = {
                owner: null,
                gestore_cod: null,
                accepted: false,
                idActivity: this.candidateActivity.activity.id,
                group: null,
            };
        }
        this._uniqueId = candidateActivity.getUniqueId;
        // noinspection JSUnusedLocalSymbols
        const temp = this.feature;
        if( !this.candidate.idActivity || this.candidate.idActivity === '' ) {
            this.candidate.idActivity = this.candidateActivity.activity.id;
        }
        if( !this.candidate.priority && !this.candidate.id ) {
            this.candidate.priority = this.candidateActivity.candidatesBoxes.length + 1;
        }
        if( !this.candidate.state ) {
            this.candidate.state = 'OK';
        }
    }

    private dialog: MtxDialog = this.candidateActivity.injector.get( MtxDialog );
    public clusterMarker: Marker;
    public candidate: CandidateMetaData;
    private _oldFeature: Layer;
    private _feature: Layer;
    private readonly _uniqueId;

    static crateCandidateBoxInstance(
        candidateActivity: CandidateActivity,
        candidate?: CandidateMetaData,
    ): CandidateBox {
        const maxNumber = candidateActivity.templateOptions?.map?.numberOfItems?.max;
        if( maxNumber && maxNumber === candidateActivity.candidatesBoxes.length ) {
            candidateActivity.toastr.warning( candidateActivity.templateOptions.map.errorMessage );
            candidateActivity.cdr.detectChanges();
            return null;
        } else {
            return new CandidateBox( candidateActivity, candidate );
        }
    }

    get uniqueId() {
        return this._uniqueId;
    }

    get leafletId() {
        return this.candidateActivity._featureGroup.getLayerId( this._feature );
    }

    get feature() {
        if( !this._feature && this.candidate.group ) {
            this._feature = transformJsonToLayer( this.candidate.group ).getLayers()[ 0 ];
            this._oldFeature = new Marker( ( this._feature as Marker ).getLatLng() );
            this.candidateActivity._featureGroup.addLayer( this._feature );
        }
        return this._feature;
    }

    set feature( layer: Layer ) {
        if( layer === null ) {
            this.candidateActivity._featureGroup.removeLayer( this._feature );
            this.candidateActivity.candidatesBoxes = this.candidateActivity.candidatesBoxes.filter(
                c => c.uniqueId !== this.uniqueId,
            );
        } else {
            this._oldFeature = new Marker( ( layer as Marker ).getLatLng() );
            if( this.feature ) {
                this.candidateActivity._featureGroup.removeLayer( this._feature );
            }
            this.candidateActivity._featureGroup.addLayer( layer );
            this._feature = layer;
            this.candidate.group = transformLayerToJson( featureGroup().addLayer( layer ) );
        }
    }

    revertFeature() {
        if(
            this._oldFeature &&
            ( this.feature as Marker ).getLatLng() !== ( this._oldFeature as Marker ).getLatLng()
        ) {
            this.feature = this._oldFeature;
            return true;
        } else {
            return false;
        }
    }

    get state() {
        return this.candidate.state;
    }

    set state( val: string ) {
        if( val === 'KO' ) {
            this.candidate.accepted = false;
            this.priority = undefined;
        } else {
            if( this.candidate.state === 'KO' ) {
                this.candidate.priority =
                    this.candidateActivity.candidatesBoxes.filter( c => c.candidate.state === 'OK' ).length + 1;
            }
        }
        this.candidate.state = val;
        this.candidateActivity.validate();
    }

    get priority() {
        return this.candidate.priority;
    }

    set priority( val: number ) {
        this._refactorPrioOnManualChange( this.candidate.priority, val );
        this.candidate.priority = val;
        this.candidateActivity.refreshCandidatesList();
    }

    private _refactorPrioOnManualChange( oldValue: number, newValue: number ) {
        if( !newValue ) {
            newValue = 10e9;
        }
        const oldPrios = this.candidateActivity.candidatesBoxes
            .filter( d => d.state !== 'KO' )
            .map( d => {
                return { uniqueId: d.uniqueId, prio: d.priority };
            } )
            .filter( d => d.uniqueId !== this.uniqueId );
        let middle: { uniqueId: number; prio: number }[];
        if( oldValue > newValue ) {
            middle = oldPrios.filter( p => p.prio >= newValue && p.prio <= oldValue );
            middle.forEach( m => m.prio++ );
        } else {
            middle = oldPrios.filter( p => p.prio >= oldValue && p.prio <= newValue );
            middle.forEach( m => m.prio-- );
        }
        middle.forEach( m => {
            this.candidateActivity.candidatesBoxes.filter(
                c => c.uniqueId === m.uniqueId,
            )[ 0 ].candidate.priority = m.prio;
        } );
    }

    get accepted() {
        return this.candidate.accepted;
    }

    set accepted( val: boolean ) {
        this.candidate.accepted = val;
        if( val ) {
            this.candidateActivity.candidatesBoxes
                .filter( c => c.uniqueId !== this.uniqueId )
                .forEach( c => ( c.candidate.accepted = false ) );
        }
        this.candidateActivity.validate();
    }

    addMarkerClick() {
        if( !this.feature.hasEventListeners( 'click' ) ) {
            this.feature.on( 'click', () => this.openPopUp().subscribe( () => {
                this.candidateActivity.refreshCandidatesList();
            } ) );
        }
    }

    removeMarkerClick() {
        this.feature.off( 'click' );
    }

    openPopUp( checkCoordinates = false ) {
        if( checkCoordinates ) {
            const puntoLng = this.candidate.group.features[ 0 ].geometry.coordinates[ 0 ];
            const puntoLat = this.candidate.group.features[ 0 ].geometry.coordinates[ 1 ];
            console.log( ' lat=', puntoLat );
            console.log( ' lng=', puntoLng );
        }
        console.log( 'Opening candidates dialog', this.candidateActivity.templateOptions );
        const dialogRef = this.dialog.originalOpen( CandidateDialogComponent, {
            width: '600px',
            hasBackdrop: false,
            disableClose: true,
            data: {
                candidate: this.candidate,
                disabled: this.candidateActivity.disableInput,
                isValutazioneMSA: this.candidateActivity.templateOptions.isValutazioneMSA,
                checkCoordinates,
                structureOwners: this.candidateActivity.templateOptions.structureOwners,
            },
        } );
        return dialogRef.afterClosed().pipe(
            map( ( result: { metaData: CandidateMetaData } ) => {
                if( result.metaData ) {
                    this.candidate = { ...this.candidate, ...result.metaData };
                }
                return !!result.metaData;
            } ),
        );
    }

    post() {
    }
}

