import { IOffice } from '@shared/interfaces/office';

export const MOCKED_DATA: IOffice = {
    office: '1OF04296',
    name: 'Monterosso Grana',
    address: 'San Pietro Chiesa S. Croce Fg 13 Mapp 256',
    city: 'Monterosso Grana',
    province: 'CN',
    pr: 'CN',
    region: 'PIEMONTE',
    zone: 'Nord Ovest',
    owner: 'OTHER',
    structureType: 'RAW LAND',
    housingType: 'OUTDOOR',
    officeType: 'MACRO',
    ko: null,
    frequencies: [
        {
            technology: '2G',
            frequency: '900',
            activated: new Date( '2003-05-21T00:00:00' )
        },
        {
            technology: '4G',
            frequency: '800',
            activated: new Date( '2021-03-13T00:00:00' )
        },
        {
            technology: '4G',
            frequency: '2100',
            activated: new Date( '2020-03-30T00:00:00' )
        }
    ],
    topANPO: '<5k',
    connettivita: 'BEP IP',
    fibra: '-',
    tabs: [
        {
            name: 'Trasferimenti',
            sections: [
                {
                    name: 'Trasferimento a',
                    columns: [
                        {
                            header: 'Nuovo office',
                            column: 'office_vodafone_new',
                            type: 'string',
                            value: '1RM01367'
                        },
                        {
                            header: 'Stato',
                            column: 'stato_new',
                            type: 'string',
                            value: 'PP_INWIT'
                        },
                        {
                            header: 'Tipo piano',
                            column: 'tipo_piano_new',
                            type: 'string',
                            value: 'COMMITMENT'
                        },
                        {
                            header: 'Dettagli piano',
                            column: 'dettaglio_piano_new',
                            type: 'string',
                            value: 'COMMITMENT'
                        },
                        {
                            header: 'Sintesti piano',
                            column: 'sintesi_piano_new',
                            type: 'string',
                            value: 'MSA'
                        },
                        {
                            header: 'Tipologia struttura',
                            column: 'tipologia_struttura_new',
                            type: 'string',
                            value: null
                        },
                        {
                            header: 'Proprietario struttura',
                            column: 'proprietario_struttura_new',
                            type: 'string',
                            value: null
                        },
                        {
                            header: 'Nuovo office',
                            column: 'office_vodafone_new',
                            type: 'string',
                            value: '1RM01367'
                        },
                        {
                            header: 'Stato',
                            column: 'stato_new',
                            type: 'string',
                            value: 'PP_INWIT'
                        },
                        {
                            header: 'Tipo piano',
                            column: 'tipo_piano_new',
                            type: 'string',
                            value: 'COMMITMENT'
                        },
                        {
                            header: 'Dettagli piano',
                            column: 'dettaglio_piano_new',
                            type: 'string',
                            value: 'COMMITMENT'
                        },
                        {
                            header: 'Sintesti piano',
                            column: 'sintesi_piano_new',
                            type: 'string',
                            value: 'MSA'
                        },
                        {
                            header: 'Tipologia struttura',
                            column: 'tipologia_struttura_new',
                            type: 'string',
                            value: null
                        },
                        {
                            header: 'Proprietario struttura',
                            column: 'proprietario_struttura_new',
                            type: 'string',
                            value: null
                        }
                    ],
                    showsAsTab: true,
                    columnsHeaders: [
                        'Nuovo office',
                        'Stato',
                        'Tipo piano',
                        'Dettagli piano',
                        'Sintesti piano',
                        'Tipologia struttura',
                        'Proprietario struttura'
                    ]
                }
            ]
        }
    ]
};
