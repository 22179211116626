// array per select
export const tagliaSito = [
    { label: '', value: null },
    { label: 'Micro', value: 'Micro' },
    { label: 'Macro', value: 'Macro' },
    { label: 'Small', value: 'Small' },
];

export const tipologiaApparati = [
    { label: '', value: null },
    { label: 'Indoor', value: 'Indoor' },
    { label: 'Outdoor', value: 'Outdoor' },
];

export const tipiProprietario = [
    { label: 'TowerCo', value: 'TowerCo' },
    { label: 'privato', value: 'privato' },
];

export const proprietarioTowerCo = [
    { label: 'TowerCo', value: 'TowerCo' },
];

export const proprietariStrutture = [
    { label: '', value: null },
    { label: 'Cellnex', value: 'Cellnex' },
    { label: 'Inwit', value: 'Inwit' },
    { label: 'Rai', value: 'Rai' },
    { label: 'Rfi', value: 'Rfi' },
    { label: 'Towertel', value: 'Rfi' },
    { label: 'Other', value: 'Other' },
];

export const strutturaDiInwit = [
    { label: 'Inwit', value: 'Inwit' }
];

export const tipologiaStrutture = [
    { label: '', value: null },
    { label: 'Rawland', value: 'Rawland' },
    { label: 'Rooftop', value: 'Rooftop' },
    { label: 'Colocated', value: 'Colocated' },
    { label: 'Tunnel', value: 'Tunnel' },
    { label: 'Other', value: 'Other' },
];

export const ambitoNormativo = [
    { label: '', value: null },
    { label: 'Tit. IV', value: 'Tit. IV' },
    { label: 'Art.26', value: 'Art.26' },
];

export const okKo = [
    { label: '', value: null },
    { label: 'OK', value: 'OK' },
    { label: 'KO', value: 'KO' },
];

export const koActivity = [
    { group: '', label: '', value: null },


    {
        group: 'RF',
        label: 'Mancanza spazio elettromagnetici',
        value: 'Mancanza spazio elettromagnetici',
    }, //RF - Mancanza spazio elettromagnetici
    {
        group: 'RF',
        label: 'Mancanza spazio per futuri sviluppi',
        value: 'Mancanza spazio per futuri sviluppi',
    }, //Mancanza spazio per futuri sviluppi
    {
        group: 'RF',
        label: 'Mancata ricezione SAR',
        value: 'Mancata ricezione SAR',
    }, //nuovo
    {
        group: 'RF',
        label: 'Bocciatura Candidati',
        value: 'Bocciatura Candidati',
    }, //nuovo
    { group: 'RF', label: 'Altri RF', value: 'Altri RF' }, //RF - Altri RF


    {
        group: 'NI',
        label: 'Mancanza spazio fisici ed elettromagnetici',
        value: 'Mancanza spazio fisici ed elettromagnetici',
    }, //Mancanza spazio fisici ed elettromagnetici
    { group: 'NI', label: 'Mancanza spazio fisico', value: 'Mancanza spazio fisico' }, //Mancanza spazio fisico
    {
        group: 'NI',
        label: 'Mancanza spazio per presenza altri operatori',
        value: 'Mancanza spazio per presenza altri operatori',
    }, //Mancanza spazio per presenza altri operatori
    { group: 'NI', label: 'Siti dismessi', value: 'Siti dismessi' }, //Siti dismessi

    { group: 'SPCM', label: 'A.Q.,National agreement', value: 'A.Q.,National agreement' }, //A.Q.,National agreement
    { group: 'SPCM', label: 'Bocciatura Offerta', value: 'Bocciatura Offerta' }, //nuovo

    {
        group: 'Motivi di natura tecnica',
        label: 'Conformità Room / Shelter',
        value: 'Conformità Room / Shelter',
    }, //Motivi di natura tecnica - Conformità Room / Shelter
    { group: 'Motivi di natura tecnica', label: 'Conformità Palo', value: 'Conformità Palo' }, //Motivi di natura tecnica - Conformità Palo
    { group: 'Motivi di natura tecnica', label: 'Altro', value: 'Altro' }, //Motivi di natura tecnica - Altro


    {
        group: 'Altro prevedibile',
        label: 'proprietà altri operatori, struttura provvisoria, etc.',
        value: 'proprietà altri operatori, struttura provvisoria, etc.',
    }, //Altro prevedibile (proprietà altri operatori, struttura provvisoria, etc.)


    {
        group: 'Altro non prevedibile',
        label: 'Riverificato da ospitato',
        value: 'Riverificato da ospitato',
    }, //Riverificato da ospitato
    {
        group: 'Altro non prevedibile',
        label: 'problemi con P.A., amministrativi',
        value: 'problemi con P.A., amministrativi',
    }, //Altro non prevedibile (problemi con P.A., amministrativi)
    {
        group: 'Altro non prevedibile',
        label: 'Disdetta non negoziabile',
        value: 'Disdetta non negoziabile',
    }, //Altro non prevedibile - Disdetta non negoziabile
    { group: 'Altro non prevedibile', label: 'Contenzioso Legale', value: 'Contenzioso Legale' }, //Altro non prevedibile - Contenzioso Legale
    { group: 'Altro non prevedibile', label: 'KO Rinegoziazione', value: 'KO Rinegoziazione' }, //Altro non prevedibile - KO Rinegoziazione
    {
        group: 'Altro non prevedibile',
        label: 'Altri problemi con la proprietà',
        value: 'Altri problemi con la proprietà',
    }, //Altro non prevedibile - Altri problemi con la proprietà
];
