export const environment = {
    SERVER_ORIGIN: '',
    production: false,
    useHash: false,
    enableRouteTracing: false,
    hmr: false,
    isLocalDev: false,
    version: ( require( '../../package.json' ) as { version: string } ).version  + ' - stag'
  };
  
  export const initializerEnvironment = {
    production: false,
    resourceServer: 'https://api.wonder-test.vodafone.it/',
    wsServer: 'wss://api.wonder-test.vodafone.it/',
    apiHost: 'api.wonder-test.vodafone.it',
    identitySettings: {
      baseUrl: 'https://api.wonder-test.vodafone.it',
    },
    idpSettings: {
      apiPrefix: '/wonder-src-authz/authz/',
      refreshUrl: 'https://api.wonder-test.vodafone.it/wonder-src-authz/authz/token/refresh',
      codeGrantUrl: 'https://api.wonder-test.vodafone.it/wonder-src-authz/authz/token/grant',
      userUrl: 'https://api.wonder-test.vodafone.it/wonder-src-authz/authz/user',
      clientId: '2m7d3qdkkor7j6cdhfriec1lpu',
      clientSecret: '13rtl6eun7cferi5fkun640bdaq6470o7ii4gekcn11ra9gqgdmm',
      redirectUrl: 'https://wonder.auth.eu-central-1.amazoncognito.com/login?client_id=' +
          '2m7d3qdkkor7j6cdhfriec1lpu&response_type=code&scope=email+openid+profile&redirect_uri=https%3A%2F%2Fwonder-test.vodafone.it',
      logoutUrl: 'https://wonder.auth.eu-central-1.amazoncognito.com/logout?client_id=' +
          '2m7d3qdkkor7j6cdhfriec1lpu' + '&logout_uri=' + 'https://wonder-test.vodafone.it',
  },
    tusServer: {
      endpoint: 'https://api.wonder-test.vodafone.it/wonder-src-tusserver-api/',
      chunkSizeMB: 5
    },
    lockingServer: {
      controllerUrl: 'activitylocker/api/LockActivity',
      lockRefreshTimer: 60,
      errorsCount: 2,
    },
    querySeparator: '|',
    geoSync: {
      pageSize: '50',
      endpoints: {
        comuni: 'api/v1/geocodes/area/search/comune',
        province: 'api/v1/geocodes/area/search/provincia',
        regioni: 'api/v1/geocodes/area/search/regione',
        coordinates: ( lng: number, lat: number ) => `api/v1/geocodes/lng/${lng}/lat/${lat}`,
      },
    },
  };
  