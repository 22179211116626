import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { featureKey } from '@store/roles-store/state';
import { reducers } from '@store/roles-store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { RolesEffects } from '@store/roles-store/effects';


@NgModule( {
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature( featureKey, reducers ),
        EffectsModule.forFeature( [ RolesEffects ] ),
    ]
} )
export class RolesStoreModule {
}
