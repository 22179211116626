import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatChip, MatChipList, MatChipListChange } from '@angular/material/chips';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component( {
    selector: 'wnd-chips-multi-select',
    templateUrl: './chips-multi-select.component.html',
    styleUrls: [ './chips-multi-select.component.scss' ],
    providers: [ {
        provide: NG_VALUE_ACCESSOR,
        useExisting: ChipsMultiSelectComponent,
        multi: true,
    }, ],
} )
export class ChipsMultiSelectComponent implements OnInit, ControlValueAccessor, AfterViewInit {
    @Input() options: string[] = [];

    @ViewChild( MatChipList ) chipList!: MatChipList;

    value: string[] = [];

    disabled = false;

    onChange!: ( value: string[] ) => void;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.selectChips( this.value );

        this.chipList.chipSelectionChanges.pipe(
            untilDestroyed( this ),
            map( ( event ) => event.source )
        ).subscribe( ( chip ) => {
            console.log( 'Chip selected:', chip );
            if( chip.selected ) {
                this.value = [ ...this.value, chip.value as string ];
            } else {
                this.value = this.value.filter( ( o ) => o !== chip.value );
            }

            this.propagateChange( this.value );
        } );
    }

    toggleSelection( chip: MatChip ) {
        console.log( 'Toggling chip', chip, this.disabled );
        if( !this.disabled ) chip.toggleSelected( true );
    }

    propagateChange( value: string[] ) {
        if( this.onChange ) {
            this.onChange( value );
        }
    }

    selectChips( value: string[] ) {
        this.chipList.chips.forEach( ( chip ) => chip.deselect() );

        const chipsToSelect = this.chipList.chips.filter( ( c ) =>
            value.includes( c.value as string ),
        );

        chipsToSelect.forEach( ( chip ) => chip.select() );
    }


    //region interface ControlValueAccessor
    registerOnChange( fn: ( value: string[] ) => void ): void {
        this.onChange = fn;
    }

    registerOnTouched( _fn: any ): void {
    }

    setDisabledState( isDisabled: boolean ): void {
        this.disabled = isDisabled;
    }

    writeValue( value: string[] ): void {
        // When form value set when chips list initialized
        if( this.chipList && value ) {
            this.selectChips( value );
        } else if( value ) {
            // When chips not initialized
            this.value = value;
        }
    }

    //endregion
    chipsChanged( $event: MatChipListChange ) {
        console.log( 'Chip list changed', $event );
        this.value = $event.value as string[];
        this.propagateChange( this.value );
    }
}
