import { cloneDeep } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { StepEvent } from '../../steps-collection.service';
import { StepConfig } from '../../step_config';
import { UntypedFormGroup } from '@angular/forms';


export class Step {
    childSaveEvents: { key: string; event: Subject<void> }[] = [];
    private _saveRequest = new Subject<string>();
    form = new UntypedFormGroup( {} );

    private constructor(
        idActivity: string,
        private _parentStepsEvent: Subject<StepEvent>,
        public index: number,
        public data: any,
        public config: StepConfig,
        private http: HttpClient,
        private baseUrl: string,
        private toastr: ToastrService
    ) {
    }

    static createStep(
        idActivity: string,
        parentStepsEvent: Subject<StepEvent>,
        index: number,
        data: any,
        config: StepConfig,
        http: HttpClient,
        baseUrl: string,
        toastr: ToastrService
    ): Observable<Step> {
        const temp = new Step( idActivity, parentStepsEvent, index, data, config, http, baseUrl, toastr );
        if( !data ) {
            temp.data = {};
            temp.idActivity = idActivity;
            temp.nome = config.name;
            return temp._saveItem( temp.data, false ).pipe(
                map( id => {
                    if( id ) {
                        temp.id = id;
                    }
                    return temp;
                } )
            );
        } else {
            return of( temp );
        }
    }

    set id( val: string ) {
        this.data.id = val;
    }

    get id() {
        return this.data.id;
    }

    set nome( val: string ) {
        this.data.nome = val;
    }

    get nome() {
        return this.data.nome;
    }

    set idActivity( val: string ) {
        this.data.idActivity = val;
    }

    get idActivity() {
        return this.data.idActivity;
    }

    set isCommited( val: boolean ) {
        this.data.isCommited = val;
    }

    get isCommited() {
        return this.data.isCommited;
    }

    set commissionDate( val: Date ) {
        this.data.commissionDate = val;
    }

    get commissionDate() {
        return this.data.commissionDate;
    }

    get isFinalStep() {
        return this.config.nextStep ? false : true;
    }

    get saveRequest() {
        return this._saveRequest;
    }

    get collectionSaveEvent() {
        return this._parentStepsEvent;
    }

    get fields() {
        return cloneDeep( this.config.fields );
    }

    save() {
        this.nome = this.config.name;
        this.isCommited = this.isCommited ? true : false;
        this._waitForChild( 'save' );
    }

    commit() {
        this.nome = this.config.name;
        this.isCommited = true;
        this.commissionDate = new Date();
        this._waitForChild( 'commit' );
    }

    private _waitForChild( saveType: string ) {
        if( this.childSaveEvents.length > 0 ) {
            combineLatest( this.childSaveEvents.map( c => c.event ) )
                .pipe( take( 1 ) )
                .subscribe( () => {
                    this._saveItem( this.data, true ).subscribe( id => {
                        if( id ) {
                            this.id = id;
                        }
                        this._parentStepsEvent.next( {
                            source: this,
                            type: saveType,
                        } );
                    } );
                } );
        } else {
            this._saveItem( this.data, true ).subscribe( id => {
                if( id ) {
                    this.id = id;
                }
                this._parentStepsEvent.next( {
                    source: this,
                    type: saveType,
                } );
            } );
        }

        this._saveRequest.next( saveType );
    }

    private _saveItem( tempItem: any, showToastr: boolean ): Observable<any> {
        const url = this.baseUrl + 'wonder-src-workflow-api/Steps';
        if( !this.id ) {
            return this.http.post<any>( url, tempItem ).pipe(
                map( res => {
                    if( showToastr ) {
                        this.toastr.success( 'Your changes has been saved successfully' );
                    }
                    return res.id;
                } ),
                catchError( error => {
                    if( showToastr ) {
                        this.toastr.error( 'An error has occurred' );
                    }
                    return of( undefined );
                } )
            );
        } else {
            return this.http.put<any>( url + '/' + tempItem.id, tempItem ).pipe(
                map( () => {
                    if( showToastr ) {
                        this.toastr.success( 'Your changes has been saved successfully' );
                    }
                    return undefined;
                } ),
                catchError( error => {
                    if( showToastr ) {
                        this.toastr.error( 'An error has occurred' );
                    }
                    return of( undefined );
                } )
            );
        }
    }
}
