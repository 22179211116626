<div class='container'>
    <div class='title'>Choose a columns set</div>
    <span class='list-section'>
        <ul *ngIf='selectedSet$ | async as selectedSet'>
            <li *ngFor='let set of columnsCheckSets'>
                <div class="set-wrapper">
                    <mat-checkbox [disabled]="set.name=='default'" [(ngModel)]='set.checked' (ngModelChange)='setChanged()'>
                            {{set.name}}
                    </mat-checkbox>
                    <div *ngIf="set.setColor" class="color-box" [ngStyle]="{ 'background-color': set.setColor }"></div>
                </div>
            </li>
        </ul>
    </span>
</div>
