import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Menu, MenuService } from './menu.service';
import { SettingsService } from './settings.service';
import { catchError, firstValueFrom, of, switchMap, tap } from 'rxjs';


@Injectable( {
    providedIn: 'root',
} )
export class StartupService {
    constructor(
        private menu: MenuService,
        private http: HttpClient,
        private settings: SettingsService
    ) {
    }


    load() {
        return firstValueFrom(
            this.http
                .get<{ menu: Menu[] }>( `assets/data/menu.json?_t=${Date.now()}` )
                .pipe(
                    tap( ( r ) => { this.menu.SetMenuFromJson( r.menu ); } ),
                    switchMap( () => this.settings.userChange() ),
                    catchError( error => of( error ) )
                )
        );
    }
}
