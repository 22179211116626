import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


export interface MenuTag {
    color: string; // background color
    value: string;
}

export interface Menu {
    route: string;
    name: string;
    type: 'link' | 'sub' | 'extLink' | 'extTabLink';
    icon: string;
    label?: MenuTag;
    badge?: MenuTag;
    children?: Menu[];
    requiredPolicy?: string;
    tail?: boolean;
}

interface ITraversingMenu {
    parentNamePathList: string[];
    item: Menu;
    realRouteArr: string[];
}

@Injectable( {
    providedIn: 'root',
} )
export class MenuService {
    private menu$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>( [] );
    private _rawMenu: Menu[];

    constructor() {
    }

    public SetMenuFromJson( rawMenu: Menu[] ) {
        rawMenu.forEach( m => this._addTailProperty( m ) );
        this._rawMenu = rawMenu;
    }

    filterByPolicy( policies: string[] ) {
        let rawMenu: Menu[] = this.deepClone( this._rawMenu );
        rawMenu = rawMenu.filter( m => ( !m.requiredPolicy ) || policies.includes( m.requiredPolicy ) );
        rawMenu.forEach( m => {
            MenuService.propagatePolicy( m );
            MenuService.removeNotAllowedChildren( m, policies );
        } );
        rawMenu = MenuService.removeSubWithoutChildren( rawMenu );
        this.set( rawMenu );
    }

    getAll(): Observable<Menu[]> {
        return this.menu$.asObservable();
    }

    set( menu: Menu[] ): Observable<Menu[]> {
        //console.log( menu );
        this.menu$.next( menu );
        return this.menu$.asObservable();
    }

    add( menu: Menu ) {
        const tmpMenu = this.menu$.value;
        tmpMenu.push( menu );
        this.menu$.next( tmpMenu );
    }

    reset() {
        this.menu$.next( [] );
    }

    // Delete empty values and rebuild route
    buildRoute( routeArr: string[] ): string {
        let route = '';
        routeArr.forEach( item => {
            if( item && item.trim() ) {
                route += '/' + item.replace( /^\/+|\/+$/g, '' );
            }
        } );
        return route;
    }

    // noinspection JSUnusedGlobalSymbols
    getMenuItemName( routeArr: string[] ): string {
        return this.getMenuLevel( routeArr )[ routeArr.length - 1 ];
    }


    getMenuLevel( routeArr: string[] ): string[] {
        let tmpArr: string[] = [];
        this.menu$.value.forEach( item => {
            // breadth first traverse modified
            let unhandledLayer: ITraversingMenu[] = [ { item, parentNamePathList: [], realRouteArr: [] } ];
            while( unhandledLayer.length > 0 ) {
                let nextUnhandledLayer: typeof unhandledLayer = [];
                for( const ele of unhandledLayer ) {
                    const eachItem = ele.item;
                    const currentNamePathList = this.deepClone( ele.parentNamePathList ).concat( eachItem.name );
                    const currentRealRouteArr = this.deepClone( ele.realRouteArr ).concat( eachItem.route );
                    // compare the full Array for expandable
                    if( this.isRouteEqual( routeArr, currentRealRouteArr ) ) {
                        tmpArr = currentNamePathList;
                        break;
                    }
                    if( !this.isLeafItem( eachItem ) ) {
                        const wrappedChildren = eachItem.children.map( child => ( {
                            item: child,
                            parentNamePathList: currentNamePathList,
                            realRouteArr: currentRealRouteArr,
                        } ) );
                        nextUnhandledLayer = nextUnhandledLayer.concat( wrappedChildren );
                    }
                }
                unhandledLayer = nextUnhandledLayer;
            }
        } );
        return tmpArr;
    }

    /** Menu for translation */

    recursMenuForTranslation( menu: Menu[], namespace: string ) {
        menu.forEach( menuItem => {
            menuItem.name = `${ namespace }.${ menuItem.name }`;
            if( menuItem.children && menuItem.children.length > 0 ) {
                this.recursMenuForTranslation( menuItem.children, menuItem.name );
            }
        } );
    }

    /** Menu level */

    private isLeafItem( item: Menu ): boolean {
        const cond0 = item.route === undefined;
        const cond1 = item.children === undefined;
        const cond2 = !cond1 && item.children.length === 0;
        return cond0 || cond1 || cond2;
    }

    // Deep clone object could be jsonized
    private deepClone<T>( obj: T ): T {
        return JSON.parse( JSON.stringify( obj ) ) as T;
    }

    // Whether two objects could be jsonized equal
    private isJsonObjEqual( obj0: any, obj1: any ): boolean {
        return JSON.stringify( obj0 ) === JSON.stringify( obj1 );
    }

    // Whether routeArr equals realRouteArr (after remove empty route element)
    private isRouteEqual( routeArr: Array<string>, realRouteArr: Array<string> ): boolean {
        realRouteArr = this.deepClone( realRouteArr );
        realRouteArr = realRouteArr.filter( r => r !== '' );
        return this.isJsonObjEqual( routeArr, realRouteArr );
    }

    private _addTailProperty( rawMenu: Menu ) {
        if( rawMenu.children && rawMenu.children.length > 0 ) {
            rawMenu.children.forEach( m => this._addTailProperty( m ) );
        } else {
            rawMenu.tail = true;
        }
    }

    private static propagatePolicy( rawMenu: Menu ) {
        if( rawMenu.children && rawMenu.children.length > 0 ) {
            if( rawMenu.requiredPolicy ) {
                rawMenu.children.forEach( c => {
                    if( !c.requiredPolicy ) {
                        c.requiredPolicy = rawMenu.requiredPolicy;
                    }
                } );
            }
            rawMenu.children.forEach( c => this.propagatePolicy( c ) );
        }

    }


    private static removeNotAllowedChildren( rawMenu: Menu, policies: string[] ) {
        if( rawMenu.children && rawMenu.children.length > 0 ) {
            rawMenu.children = rawMenu.children.filter( c =>
                ( !c.requiredPolicy ) || policies.includes( c.requiredPolicy ),
            );
            rawMenu.children.forEach( c => this.removeNotAllowedChildren( c, policies ) );
        }
    }

    private static removeSubWithoutChildren( rawMenu: Menu[] ): Menu[] {
        rawMenu.filter( x => x.children && x.children.length > 0 )
            .forEach( x => x.children = MenuService.removeSubWithoutChildren( x.children ) );
        return rawMenu.filter( x => x.type !== 'sub' || ( x.children && x.children.length > 0 ) );
    }
}
