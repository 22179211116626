<!-- <div class="matero-auth-container">
  <router-outlet></router-outlet>
</div> -->
<div class="nd-auth-container">
    <router-outlet></router-outlet>
    <!-- <app-login></app-login> -->
</div>
<div class="logo_white-div">
    <img class="logo_white-img" src="assets/images/logo_white.png"/>
</div>


