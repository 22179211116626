import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardPianiState } from '@store/dashboard-piani-store/index';
import { IMonth } from '@piani/models/dashboard/month.interface';
import { CommonsSelectors } from '@store/commons-store';

export const selectDashboardPiani = createFeatureSelector<DashboardPianiState.State>( DashboardPianiState.featureKey );
export const selectPanelVisibility = createSelector( selectDashboardPiani, ( state ) => state.panelVisibility );
export const selectElements = createSelector( selectDashboardPiani, ( state ) => state.dashboardElements );
export const selectDrivers = createSelector( selectDashboardPiani, ( state ) => state.dashboardDrivers );
export const selectDistinctPanels = createSelector( selectDashboardPiani, ( state ) => state.distinctPanels );
export const selectDistinctPanelsLength = createSelector( selectDistinctPanels, ( p ) => p?.length ?? 0 );
export const selectPanels = createSelector( selectDashboardPiani, ( state ) => state.panels );
export const selectRowsForPanel = createSelector( selectDashboardPiani, ( state ) =>
    ( state.distinctPanels?.length ? state.panels.length / state.distinctPanels.length : 0 ) );

export const selectFlattenPanelMonths = ( panelId: number ) => createSelector( selectPanels, ( panels ) => {
    const flattenMonths = new Array<IMonth>();
    panels.forEach( panel => {
        if( panel.id === panelId ) {
            panel.months.forEach( month => {
                flattenMonths.push( month );
            } );
        }
    } );
    return flattenMonths;
} );

export const selectPanelMonthPeriods = ( panelId: number, monthName: string ) => createSelector( selectFlattenPanelMonths( panelId ),
    ( months ) => {
        const distinctPeriodsMonths = new Array<IMonth>();
        months.forEach( m => {
            if( m.name === monthName ) {
                m.periods.forEach( ( p: IMonth ) => {
                    if( !distinctPeriodsMonths.find( dm => dm.name === p.name ) ) {
                        distinctPeriodsMonths.push( p );
                    }
                } );
            }
        } );
        return distinctPeriodsMonths;

    }
);

export const selectMonthsLengthPerPanel = ( panelId: number ) => createSelector( selectDistinctPanels,
    ( panels ) => panels.find( p => p.id === panelId )?.months?.length ?? 0 );
export const selectMonthsFromDistinctPanel = ( panelId: number ) => createSelector( selectDistinctPanels,
    ( panels ) => panels.find( p => p.id === panelId )?.months ?? [] );
export const selectMonthsPerPanel = ( panelId: number ) => createSelector( selectPanels, ( panels ) => panels.filter( p => p.id === panelId ) );
export const selectCategory = createSelector( selectDashboardPiani, ( state ) => state.selectedCategory );
export const selectZone = createSelector( selectDashboardPiani, ( state ) => state.selectedZone );
export const selectYear = createSelector( CommonsSelectors.selectFiscalYear, ( year ) => ( year - 2020 ) );
export const selectDashboardFilter = createSelector( selectCategory, selectZone, selectYear, ( idCategory, idZone, fy ) => ( { idCategory, idZone, fy } ) );

export const selectForecastElements = createSelector( selectDashboardPiani, ( state ) => state.forecastElements );
export const selectForecastZone = createSelector( selectDashboardPiani, ( state ) => state.selectedForecastZone );
export const selectForecastMonth = createSelector( selectDashboardPiani, ( state ) => state.selectedForecastMonth );
export const selectForecastElementsDriver = ( id: number ) => createSelector( selectForecastElements, ( elements ) => elements.find( e => e.id === id ) );

export const selectForecastFilter = createSelector( selectYear, selectForecastZone, selectForecastMonth,
    ( fy, idZone, idMonth ) => ( { fy, idZone, idMonth } ) );

export const selectForecastRequest = createSelector( selectDashboardPiani, ( state ) => state.forecastRequest );

export const selectApprovalRequests = createSelector( selectDashboardPiani, ( state ) => state.approvalRequests );
export const selectRefusedRequests = createSelector( selectDashboardPiani, ( state ) => state.refusedRequests );
export const selectForecastLoading = createSelector( selectDashboardPiani, ( state ) => state.forecastLoading );

export const selectForecastValuesToBeSaved = createSelector( selectDashboardPiani, ( state ) => state.forecastValuesToBeSaved );
export const selectForecastSaving = createSelector( selectDashboardPiani, ( state ) => state.forecastSaving );
export const thereAreForecastToSave = createSelector( selectForecastValuesToBeSaved, ( values ) => values?.length > 0 );
export const noForecastToSave = createSelector( selectForecastValuesToBeSaved, ( values ) => !( values?.length > 0 ) );

export const selectCanEditDashboard = createSelector( selectDashboardPiani, ( state ) => state.canEdit );
export const selectViewSelectedMonth = createSelector( selectDashboardPiani, ( state ) => state.viewSelectedMonth );
