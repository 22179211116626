import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class Destroyable implements OnDestroy {
    private destroy: Subject<void> = new Subject<void>();

    $destroy = this.destroy.asObservable();

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }
}
