import { Injectable, OnDestroy } from '@angular/core';
import { WebSocketSubject } from 'rxjs/internal/observable/dom/WebSocketSubject';
import { SocketMessage } from '@core/services/stream-craft-client/models/SocketMessage';
import { initializerEnvironment } from '@env/environment';
import { retry, Subject, timer } from 'rxjs';
import { TokenService } from '@core';
import { filter, switchMap } from 'rxjs/operators';

@Injectable( {
    providedIn: 'root'
} )
export class StreamCraftClientService implements OnDestroy {
    //private wsUrl = 'ws://localhost:5033/ws';
    private wsUrl = `${ initializerEnvironment.wsServer }wonder-src-streamcraft/ws`;
    private socket$: WebSocketSubject<SocketMessage>;
    private stream = new Subject<SocketMessage>();

    constructor( private tokenService: TokenService ) {
        console.log( 'StreamCraft Client service started' );
        tokenService.currentTokenModel.pipe(
            filter( token => !!( token?.accessToken ) ),
            switchMap( token => {
                console.log( 'token', token );
                if( this.socket$ ) {
                    // close existing connection
                    this.socket$.complete();
                }
                this.socket$ = new WebSocketSubject<SocketMessage>( this.wsUrl + '?token=' + token.accessToken );
                return this.socket$;
            } ),
            retry( { delay: ( _, count ) => timer(  Math.log( count ) * 1000 ) } )
        ).subscribe( message => this.stream.next( message ) );
    }

    public send( message: SocketMessage ) {
        this.socket$.next( message );
    }

    public socket() {
        return this.stream.asObservable();
        // return this.socket$.pipe( retry() );
    }

    ngOnDestroy(): void {
        this.socket$.complete();
    }
}
