export interface IWndGridCellConverter {
    convertData( columnName: string, data: any, ): any;
    getClasses( columnName: string, data: any ): string[];
}

export class DefaultCellConverter implements IWndGridCellConverter {
    convertData( _columnName: string, data: any ): any {
        return data;
    }
    getClasses( _columnName: string, _data: any ): string[] {
        return [];
    }
}
