/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call,@typescript-eslint/adjacent-overload-signatures */
import { WndGridDataResponse } from './../interfaces/wnd-grid-data-response';

import { merge, Observable, Subject, Subscription, zip } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { WndGridColumn } from './../interfaces/wnd-grid-column';
import { WndGridService } from './../services/wnd-grid.service';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { WndGridSidenavConfig, WndGridSidenavItem } from '../interfaces/wnd-grid-sidenav-config';
import { MatDrawer } from '@angular/material/sidenav';
import { WndGridColumnFilterEvent } from '../services/wnd-grid-column-service';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { Store } from '@ngrx/store';
import { GridColumnsActions, GridColumnsSelectors } from '@store/grid-columns-store';
import { CommonsSelectors } from '@store/commons-store';
import { DefaultCellConverter, IWndGridCellConverter } from '@shared/components/wonder-grid/helpers/wnd-grid-cell-converter';
import { SortDirection } from '@angular/material/sort';
import { FormControl } from '@angular/forms';


@Component( {
    selector: 'wnd-grid',
    templateUrl: './wnd-grid.component.html',
    styleUrls: [ './wnd-grid.component.scss' ],
    providers: [ WndGridService ],
    changeDetection: ChangeDetectionStrategy.OnPush
} )
export class WndGridComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() minTableHeight: number; //usefully for external sidenav template
    @Input() endpoint: string;
    @Input() pageSizeOptions: { default: number; list: number[] } = { default: 15, list: [ 10, 15, 30, 50 ] };
    @Input() rowSelectionTemplate: TemplateRef<any>;
    @Input() rowSelectable = true;
    @Input() sideNavConfigs: WndGridSidenavConfig;
    @Input() externalFilters: Observable<WndGridColumnFilterEvent>[] = [];
    @Input() defaultExportFilename: string;
    @Input() gridColumnsName: string;
    @Input() expandable: boolean;
    @Input() expansionTemplate: TemplateRef<any>;
    @Input() gridName?: string;
    @Input() fullHeight = false;
    @Input() selectable = false;
    @Input() multiSelectable = false;
    @Input() heightPadding = 16;
    @Input() showColumnsChooser = true;
    @Input() showDynamicColumns = true;
    @Input() showLastUpdate = true;
    @Input() cellConverter: IWndGridCellConverter = new DefaultCellConverter();
    @Input() defaultColumnSorted = '';
    @Input() defaultColumnsSortDirection: 'ASC' | 'DESC' = undefined;
    @Input() showElasticSearch = false;
    @Input() customExport = false;
    @Input() customExportTitle = 'Esportazione custom';
    @Input() customWorking = false;

    @Output() expansionChange = new EventEmitter<any>();
    @Output() rowClick = new EventEmitter<any>();
    @Output() rowSelectionChange = new EventEmitter<any[]>();
    @Output() customExportClick = new EventEmitter<void>();

    @ViewChild( 'table' ) table: ElementRef<HTMLElement>;
    @ViewChild( 'rowSelectionTpl', { read: TemplateRef } ) defaultRowTpl: TemplateRef<any>;
    @ViewChild( 'drawer' ) public drawer: MatDrawer;
    @ViewChild( 'exportTemplate', { read: TemplateRef } ) exportTemplate: TemplateRef<any>;
    @ViewChild( 'gridColumnsSetTemplate', { read: TemplateRef } ) gridColumnsSetTemplate: TemplateRef<any>;
    @ViewChild( 'dynamicColumnsTemplate', { read: TemplateRef } ) dynamicColumnsTemplate: TemplateRef<any>;

    @Input() set columns( value: WndGridColumn[] ) {
        // console.log( 'Setting columns in input', value );
        this._columns = value.map( c => {
            if( c.isTag ) {
                c.fieldName = this._metadataAccessor + c.fieldName;
            }
            return c;
        } );
        this.actualColumns = this._columns;
    }

    private get _materoColumns() {
        return this.actualColumns.map( ( c ) => {
            const matero: MtxGridColumn = {
                field: c.fieldName,
                header: c.displayName,
                width: c.width,
                showExpand: c.showExpand,
                type: c.type === 'button' ? 'button' : undefined,
                buttons: c.buttons,
                class: c.class,
                pinned: c.pinned,
                formatter: ( field: any ) => {
                    let to = null;
                    if( field[ c.fieldName ] ) {
                        let value: any;
                        switch( c.type ) {
                        case 'date':
                            value = new Date( field[ c.fieldName ] ).toLocaleDateString( 'it-IT' );
                            break;
                        default:
                            value = field[ c.fieldName ];
                            break;
                        }
                        // to = ( !!c.prefix ? c.prefix : '' ) + value + ( !!c.suffix ? c.suffix : '' );
                        to = `${ c.prefix ?? '' }${ value as string }${ c.suffix ?? '' }`;
                    }
                    let rowFormatted = this.formatted.find( r => r.materoIndex === +field.materoIndex );
                    if( !rowFormatted ) {
                        rowFormatted = {
                            materoIndex: +field.materoIndex,
                            rowData: [],
                        };
                        this.formatted.push( rowFormatted );
                    }
                    const keyValue = rowFormatted.rowData.find( r => r.key === c.fieldName );
                    if( keyValue ) {
                        keyValue.value = to;
                    } else {
                        rowFormatted.rowData.push( {
                            key: c.fieldName,
                            value: to
                        } );
                    }
                    return to;
                },
            };
            if( c.materoColumn ) {
                for( const [ key, value ] of Object.entries( matero ) ) {
                    c.materoColumn[ key ] = value;
                }
                if( c.type === 'button' ) {
                    c.materoColumn.formatter = undefined;
                }
                return c.materoColumn;
            } else {
                if( matero.buttons ) {
                    matero.formatter = undefined;
                }
                return matero;
            }

        } );
    }


    private _actualColumns: WndGridColumn[] = [];

    get actualColumns(): WndGridColumn[] {
        return this._actualColumns;
    }

    set actualColumns( value: WndGridColumn[] ) {
        // console.log( 'Setting columns', value );
        this._actualColumns = value;
        this.materoColumns = this._materoColumns;
        this.gridService.setColumns( this._actualColumns );
    }

    get columns() {
        return this._columns;
    }


    private readonly _metadataAccessor = 'Metadata.';
    rowPanelOpen = false;
    data = [];
    totalItems = 0;
    pageIndex: number;
    pageSize: number;
    materoColumns: MtxGridColumn[] = [];
    private formatted: { materoIndex: number; rowData: { key: string; value: any }[] }[] = [];
    private subscription = new Subscription();
    private rowSelected = new Subject<Element>();
    private rowSelectedValue = new Subject<any>();
    closeRequestedSource = new Subject<void>();
    private _columns: WndGridColumn[] = [];


    private selectedSidemenu: WndGridSidenavItem;
    portals: { name: string; portal: Portal<any>; show: boolean }[] = [];
    private isSideNavChanging = false;

    updatedAt$: Observable<string>;
    selection = [];
    elasticSearchControl = new FormControl<string>( '' );
    private selectedRow: { column: string; value: any };

    constructor( public gridService: WndGridService, public overlay: Overlay, private _viewContainerNavRef: ViewContainerRef,
                 private _viewContainerRef: ViewContainerRef, private el: ElementRef, private store: Store, private cdr: ChangeDetectorRef,
                 @Inject( LOCALE_ID ) public locale: string ) {
    }


    @HostListener( 'click', [ '$event' ] )
    clickHandler( event: { target: Element } ) {
        let cond = true;
        let row = event.target;
        while( cond ) {
            if( !row?.tagName ) {
                break;
            }
            if( row.tagName === 'TD' && row.parentElement?.tagName === 'TR' && row.parentElement?.parentElement?.tagName === 'TBODY' ) {
                this.rowSelected.next( row );
                cond = false;
            } else {
                row = row.parentElement;
            }
        }
    }


    ngOnInit(): void {
        window.addEventListener( 'scroll', this.scrollEvent, true );
        this.gridService.init( this.endpoint, this.pageSizeOptions.default, this.externalFilters, this.gridName, { field: this.defaultColumnSorted || '', sort: this.defaultColumnsSortDirection } );
        this.registerDataResult();
        if( this.rowSelectable ) {
            this.registerRowSelection();
        }

        // Subscribe to column set changes
        if( this.gridColumnsName ) {
            this.subscription.add( this.store.select( GridColumnsSelectors.selectSelectedColumnsSet( this.gridColumnsName ) ).subscribe( sel => {
                if( sel ) {
                    // console.log( 'Setting actual columns from set changes', sel.columns, this.columns );
                    this.actualColumns = this.columns.filter( c => sel.columns.includes( c.fieldName ) );
                }
            } ) );
            //this.store.dispatch( GridColumnsActions.loadGridColumns( { name: this.gridColumnsName } ) );
        }
        if( this.showLastUpdate ) {
            this.updatedAt$ = this.store.select( GridColumnsSelectors.selectUpdatedAt( this.gridName ) );
        }

        this.subscription.add(
            this.store.select( CommonsSelectors.selectFiscalYear ).pipe(
                switchMap( ( fy ) => this.loadColumns( fy ) ),
                tap( () => this.gridColumnsName && this.store.dispatch( GridColumnsActions.loadGridColumns( { name: this.gridColumnsName } ) ) ),
            ).subscribe( () => this.cdr.detectChanges() )
        );

        this.subscription.add(
            this.elasticSearchControl.valueChanges.pipe(
                debounceTime( 500 ),
                tap( v => {
                    this.gridService.updateElasticSearch( v );
                } )
            ).subscribe()
        );
    }


    ngOnDestroy(): void {
        window.removeEventListener( 'scroll', this.scrollEvent, true );
        this.subscription.unsubscribe();
    }

    getNextPage( e: PageEvent ) {
        this.gridService.updatePageIndex( e.pageIndex );
        this.gridService.updatePageSize( e.pageSize );
        this.pageIndex = e.pageIndex;
        this.pageSize = e.pageSize;
    }


    get minimumTableHeight() {
        return !!this.minTableHeight ? `${ this.minTableHeight }px` : 'none';
    }

    getWndColumn( materoCol: MtxGridColumn ): WndGridColumn {
        return this.columns.find( c => c.fieldName === materoCol.field );
    }

    getPortal( menuSetting: WndGridSidenavItem ) {
        return new TemplatePortal(
            menuSetting.template,
            this._viewContainerNavRef,
        );
    }

    sidenavSelection( menu: WndGridSidenavItem ) {
        if( !this.isSideNavChanging ) {
            this.isSideNavChanging = true;
            if( this.drawer.opened ) {
                if( this.isMenuSelected( menu ) ) {
                    this.selectedSidemenu = undefined;

                    this.drawer.close().then( () => this.isSideNavChanging = false, () => {
                    } );
                } else {
                    this.drawer.close().then( () => this.handleSideNavClosing( menu ), () => {
                    } );
                }

            } else {
                this.handleSideNavClosing( menu );
            }
        }
    }

    closeSideNav() {
        if( !this.isSideNavChanging ) {
            this.isSideNavChanging = true;
            this.selectedSidemenu = undefined;
            this.drawer.close().then( () => this.isSideNavChanging = false, () => {
            } );
        }
    }

    isMenuSelected( menu: WndGridSidenavItem ) {
        return this.selectedSidemenu === menu;
    }


    onRowSelected( event: any ) {
        this.rowSelectedValue.next( event );
        this.rowClick.emit( event.rowData );
    }

    onExpansionChange( event: any ) {
        this.expansionChange.emit( event );
    }

    ngAfterViewInit(): void {
        setTimeout( () => {
            if( !this.sideNavConfigs ) {
                this.sideNavConfigs = { items: [] };
            }
            if( this.defaultExportFilename ) {
                this.sideNavConfigs.items.push( {
                    name: 'Esporta',
                    icon: 'exit_to_app',
                    template: this.exportTemplate,
                } );
            }
            if( this.gridColumnsName ) {
                if( this.showColumnsChooser ) {
                    this.sideNavConfigs.items.push( {
                        name: 'Colonne',
                        icon: 'calendar_view_week',
                        template: this.gridColumnsSetTemplate,
                    } );
                }
                if( this.showDynamicColumns ) {
                    this.sideNavConfigs.items.push( {
                        name: 'Colonne aggiuntive',
                        icon: 'view_column',
                        template: this.dynamicColumnsTemplate,
                    } );
                }
            }
        } );
    }

    resetFilters(): void {
        this.gridService.resetFilters( this.gridName, this.columns.map( column => column.fieldName ) );
    }

    loadColumns( fy: number ) {
        return this.gridService.getColumns( fy ).pipe(
            tap( columns => {
                this.actualColumns = this._columns = columns;
            } )
        );
    }

    onRowSelectionChanged( $event: any[] ) {
        // console.debug( 'Row selection changed', $event );
        this.rowSelectionChange.emit( $event );
    }

    selectRow( sel: { column: string; value: any } ) {
        this.selectedRow = sel;
        if( sel ) {
            this.data.forEach( d => {
                // eslint-disable-next-line eqeqeq
                if( d[ sel.column ] == sel.value ) {
                    this.selection = [ d ];
                }
            } );
        } else {
            this.selection = [];
        }
    }

    private scrollEvent = ( event: any ): void => {
        if( event.target.nodeName !== 'CDK-VIRTUAL-SCROLL-VIEWPORT' ) {
            this.el.nativeElement.click();
        }
    };

    private flatReturnedData( resp: WndGridDataResponse ) {
        const ret = [];
        resp.data?.forEach( d => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { metadata, ...other } = d;
            // eslint-disable-next-line guard-for-in
            for( const k in d.metadata ) {
                other[ this._metadataAccessor + k ] = d.metadata[ k ];
            }

            ret.push( other );
        } );
        return ret;
    }

    private registerDataResult() {
        this.subscription.add(
            this.gridService.dataResult.subscribe( d => {
                // console.debug( 'Data result event', d );
                if( d.updatedAt ) {
                    this.store.dispatch( GridColumnsActions.setGridUpdateAt( { name: this.gridName, updatedAt: d.updatedAt } ) );
                }
                this.closeRequestedSource.next();
                this.data = this.flatReturnedData( d );
                this.selectRow( this.selectedRow );
                this.pageSize = d.pageSize;
                this.pageIndex = d.pageIndex;
                let cnt = 0;
                this.data.forEach( dd => dd.materoIndex = cnt++ );
                this.formatted = [];
                this.totalItems = d.totalCount;
                this.cdr.detectChanges();
            } ),
        );
    }


    private registerRowSelection() {
        this.subscription.add(
            zip( this.rowSelected, this.rowSelectedValue ).pipe(
                tap( ( [ element, value ] ) => {
                    this.openRowModal( element, value );
                } ),
            ).subscribe(),
        );

    }

    private openRowModal( elem: Element, event: any ) {
        const row = elem.parentElement;
        if( !this.rowPanelOpen ) {
            const hoverColor = window.getComputedStyle( row ).backgroundColor;
            ( row as any ).style.setProperty( 'background-color', hoverColor );
            // const elemRef = new ElementRef( elem );
            //const strategy = this.overlay.position().flexibleConnectedTo( elemRef ).withFlexibleDimensions( false )
            const strategy = this.overlay.position().global().centerHorizontally().centerVertically();
            /*
                .withPositions( [
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                    },
                    {
                        originX: 'center',
                        originY: 'top',
                        overlayX: 'center',
                        overlayY: 'bottom',
                    },
                ] );
            */

            const config = new OverlayConfig( { positionStrategy: strategy } );
            const overlayRef = this.overlay.create( config );
            const fixed = this.fixRowFormat( event.rowData, +event.index );
            if( this.rowSelectionTemplate ) {
                const portal = new TemplatePortal( this.rowSelectionTemplate, this._viewContainerRef,
                    {
                        $implicit: {
                            row: fixed,
                            columns: this.columns,
                            closeRequested: this.closeRequestedSource,
                        },
                    },
                );
                overlayRef.attach( portal );
                this.rowPanelOpen = true;
                const source = merge(
                    overlayRef.outsidePointerEvents(),
                    this.closeRequestedSource.asObservable().pipe( map( () => 'close' ) ),
                );
                const subs = source.subscribe( ( e: any ) => {
                    if( ( e.type === 'click' && e.target.localName !== 'wnd-grid' ) || e === 'close' ) {
                        ( row as any ).style.setProperty( 'background-color', '' );
                        overlayRef.dispose();
                        setTimeout( () => {
                            this.rowPanelOpen = false;
                        } );

                        subs.unsubscribe();
                    }
                } );
            }
        }
    }


    private fixRowFormat( obj: any, materoIndex: number ) {
        const to = {};
        for( const [ key, value ] of Object.entries( obj ) ) {
            if( value ) {
                const row = this.formatted.find( f => f.materoIndex === materoIndex )?.rowData?.find( r => r.key === key );
                to[ key ] = !!row ? row.value : value;
            }

        }
        return to;
    }

    private handleSideNavClosing( menu: WndGridSidenavItem ) {

        this.selectedSidemenu = menu;
        if( this.portals.length === 0 ) {
            this.portals = this.sideNavConfigs.items.map( s => ( { name: s.name, portal: this.getPortal( s ), show: false } ) );
        }
        this.portals.forEach( p => p.show = p.name === this.selectedSidemenu.name );

        this.drawer.open().then( () => this.isSideNavChanging = false, () => {
        } );
    }

}
