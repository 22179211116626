import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchArea } from './search-area';
import { BASE_URL } from '@shared/models/urls-constants';


@Injectable()
export class SearchAreaBackEndService {
    constructor( private http: HttpClient, @Inject( BASE_URL ) private baseUrl: string ) {
    }


    public post( searchArea: SearchArea ) {

        return this.http.post<SearchArea>( this.baseUrl + 'wonder-src-workflow-api/SearchAreas', searchArea );
    }


    public put( searchArea: SearchArea ) {
        return this.http.put( this.baseUrl + 'wonder-src-workflow-api/SearchAreas/' + searchArea.id, searchArea );
    }

    public get( id: string ) {
        return this.http.get<SearchArea>( this.baseUrl + 'wonder-src-workflow-api/SearchAreas/' + id );
    }

    public getByActivity( idActivity: string ) {
        return this.http.get<SearchArea>( this.baseUrl + 'wonder-src-workflow-api/SearchAreas/GetSearchAreaByActivity/' + idActivity );
    }
}
