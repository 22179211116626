import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MenuService } from '@core/bootstrap/menu.service';
import { Router } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { INavElement } from '@shared/components/breadcrumb/breadcrumb.component';

@Component( {
    selector: 'page-header',
    host: {
        class: 'matero-page-header',
    },
    templateUrl: './page-header.component.html',
    styleUrls: [ './page-header.component.scss' ],
    encapsulation: ViewEncapsulation.None,
} )
export class PageHeaderComponent implements OnInit {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() navs: INavElement[] = [];
    @Input() description = '';
    @Input() helpBoxTitle = '';
    @Input() showBackButton = true;
    @Input() showYearSelector = false;
    @Input() hasContent = false;
    @Input() backUrl: string;
    @ViewChild( 'dialogContent' ) dialogContent: TemplateRef<any>;

    @Input()
    get hideBreadcrumb() {
        return this._hideBreadCrumb;
    }

    set hideBreadcrumb( value: boolean ) {
        this._hideBreadCrumb = coerceBooleanProperty( value );
    }

    private _hideBreadCrumb = false;

    // private pages: PageTitleWithDescription = {
    //     'KPI': {
    //         pageTitle: 'Dashboard',
    //         description: 'In questa pagina ci sono dei report che mostrano lo stato dei dati inerenti a Wonder',
    //     },
    //     'Dashboard operativa': {
    //         pageTitle: 'Dashboard',
    //         description: 'In questa pagina ci sono dei report che mostrano lo stato dei dati inerenti all\'avanzamento dei piani. ' +
    //             'È possibile modificare i valori se si seleziona una zona. Per il forecast e l\'actual si possono modificare i valori solo per i sotto periodi. ' +
    //             'È possibile incollare più righe e colonne da una selezione di Excel, cliccando sul primo valore da inserire e battere CTRL+V.',
    //     },
    //     'Lista Office': {
    //         pageTitle: 'Lista Office',
    //         description: '...Lista Office...',
    //     },
    //     'Activities List': {
    //         pageTitle: 'Activities List',
    //         description: '...Activities List...',
    //     },
    //     'Activities Import': {
    //         pageTitle: 'Activities Import',
    //         description: 'Pagina per l`import del file xlsx delle attività [visibile solo agli amministratori]',
    //     },
    //     'Piano/consuntivi/KO': {
    //         pageTitle: 'Piano/consuntivi/KO',
    //         description: 'Pagina per la gestione dei piani, consuntivi e KO',
    //     },
    //     'Forecast': {
    //         pageTitle: 'Forecast',
    //         description: 'Pagina per la gestione del forecast. I compilatori possono inserire i dati di forecast per la loro zona globalmente o per sotto zona. Se si inserisce un valore per la zona non può essere inserito nelle sotto zone e viceversa. ' +
    //             'Una volta inseriti tutti i dati bisogna chiedere l\'autorizzazione per renderli ufficiali. È possibile incollare più righe e colonne da una selezione di Excel, cliccando sul primo valore da inserire e battere CTRL+V.',
    //     },
    //     'Sintesi': {
    //         pageTitle: 'Sintesi',
    //         description: 'Qui c\'è la sintesi delle attività di Vodafone ND. La tabella è filtrabile per zona e cliccando sui numeri si andrà alla lista dei elementi per il driver relativo. Spostando il mouse sull\'intestazione delle colonne si potranno avere maggiori informazioni sulla loro funzione',
    //     },
    //     'KO': {
    //         pageTitle: 'Piani KO',
    //         description: 'Questa pagina contiene l\'elenco dei piani la cui realizzazione è bloccata per qualche motivo.',
    //     },
    //     'Tratte': {
    //         pageTitle: 'Tratte',
    //         description: 'Questa pagina contiene la lista delle tratte di connessione on air.',
    //     },
    //     'DB Fibra': {
    //         pageTitle: 'DB Fibra',
    //         description: 'Questa pagina contiene la lista dei collegamenti in fibra in funzione.',
    //     },
    //     'Strutture': {
    //         pageTitle: 'Strutture',
    //         description: 'Questa pagina contiene la lista delle strutture censite ufficialmente.',
    //     },
    //     'Trasferimenti': {
    //         pageTitle: 'Trasferimenti',
    //         description: 'Questa pagina contiene la lista dei trasferimenti censiti ufficialmente',
    //     },
    //     'Radio': {
    //         pageTitle: 'Radio',
    //         description: 'Questa pagina contiene la lista dei siti radio on air.',
    //     },
    //     'Activities monitoring details': {
    //         pageTitle: 'Activities monitoring details',
    //         description: 'Pagina che mostra le attività relative a un sito',
    //     },
    //     'Reporting': {
    //         pageTitle: 'Reporting',
    //         description: 'Questa pagina contiene i report su BI e sharepoint.',
    //     },
    //     'Richieste office': {
    //         pageTitle: 'Richieste office',
    //         description: 'In questa pagina è possibile gestire le richieste di nuovi office.',
    //     },
    //     'Lista classificazioni': {
    //         pageTitle: 'Lista classificazioni',
    //         description: 'In questa lista si trovano le classificazioni. Le classificazioni posso essere create ex-novo con il bottone in alto a destra o generate da una valutazione.'
    //     }
    // };

    constructor( private router: Router, private menu: MenuService, private dialog: MatDialog, private location: Location ) {
    }

    ngOnInit() {
        this.navs = Array.isArray( this.navs ) ? this.navs : [];
        if( !this.helpBoxTitle ) {
            this.helpBoxTitle = this.title;
        }
    }

    open_dialog() {
        console.log( 'Titolo header', this.helpBoxTitle, this.description );

        // this.helpBoxTitle = this.pages[ this.title ]?.pageTitle ?? 'Sconosciuta';
        // this.description = this.pages[ this.title ]?.description ?? this.router.url;

        this.dialog.open( this.dialogContent, {
            width: '400px',
            hasBackdrop: true,
        } );
    }

    back() {
        if ( this.backUrl ) {
            void this.router.navigateByUrl( this.backUrl );
        } else {
            this.location.back();
        }
    }
}
