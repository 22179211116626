import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { Control, GeoJSON, LatLngTuple, Map } from 'leaflet';
import { GeoSyncEvent } from '@shared/components/geo-sync/geo-sync-event';
import { GeoSyncComune } from '@shared/geo-sync/interfaces/geo-sync-comune';
import { GeoSyncProvincia } from '@shared/geo-sync/interfaces/geo-sync-provincia';
import { GeoSyncRegione } from '@shared/geo-sync/interfaces/geo-sync-regione';
import { GeoSyncComponent } from '@shared/components/geo-sync/geo-sync.component';

@Component( {
    selector: 'app-map-search',
    templateUrl: './map-search.component.html',
    styleUrls: [ './map-search.component.scss' ],
} )
export class MapSearchComponent implements OnInit, AfterViewInit {
    private _map: Map;
    private customControl: Control;
    @ViewChild( 'geosearch', { static: false } ) searchControl: ElementRef;
    showSearch = false;
    lastGeoJSON: GeoJSON;

    @Input() set map( map: Map ) {
        this._map = map;
    }

    get map(): Map {
        return this._map;
    }

    @ViewChild( 'geoSyncComponent', { static: false } ) geoSyncComponent: GeoSyncComponent;

    constructor() {
    }

    /**
     * Add the component to the osm map
     */
    addControlToMap() {
        const searchControl = this.searchControl;
        if( this._map ) {
            const custom = Control.extend( {
                onAdd( _: Map ) {
                    return searchControl.nativeElement;
                },
                onRemove( _: Map ) {
                },
            } );
            this.customControl = new custom( {
                position: 'bottomright',
            } ).addTo( this._map );
        }
    }

    ngOnInit(): void {
    }


    toggleDrawer( _: MouseEvent ) {
        this.showSearch = !this.showSearch;
    }

    ngAfterViewInit(): void {
        this.addControlToMap();
    }

    /**
     * This is the callback called when an element is chosen in the GeoSyncComponent.
     * @param value The event raised by the component.
     */
    changed( value: GeoSyncEvent ) {
        if( value.eventType === 'comune' || value.eventType === 'provincia' || value.eventType === 'regione' ) {
            const object = ( value.object as GeoSyncComune | GeoSyncProvincia | GeoSyncRegione );

            if( this.lastGeoJSON ) {
                this.removeGeoJSONLayer( this.lastGeoJSON );
            }
            this.lastGeoJSON = this.addGeoJSONLayer( object.group );
            this.setViewBBox( object?.group?.bbox );
        }
    }

    /**
     * This is a callback called when the user clicks on the clean button.
     */
    cleanSearch() {
        if( this.lastGeoJSON ) {
            this.removeGeoJSONLayer( this.lastGeoJSON );
            this.lastGeoJSON = null;
        }
        this.geoSyncComponent.clearFilter();
    }

    /**
     * Zoom the map on the bounding box
     *
     * @param bbox The bounding box to zoom the map.
     * @private
     */
    private setViewBBox( bbox: number[] ) {
        const bounds: LatLngTuple[] = [
            [ bbox[ 1 ], bbox[ 0 ] ],
            [ bbox[ 3 ], bbox[ 2 ] ],
        ];

        this.map.fitBounds( bounds );
    }

    /**
     * Add a visual layer to highlight a region of the map based on a list of coordinates in geoJson
     * format.
     *
     * @param geoJSON The list of coordinates of the region to highlight.
     * @private
     */
    private addGeoJSONLayer( geoJSON: any ): L.GeoJSON {
        return L.geoJSON( geoJSON ).addTo( this.map );
    }

    /**
     * Remove a layer previously added with the addGeoJSONLayer function.
     *
     * @param geoJSON  The list of coordinates of the highlighted region.
     * @private
     */
    private removeGeoJSONLayer( geoJSON: any ) {
        this.map.removeLayer( geoJSON );
    }
}
