// noinspection SpellCheckingInspection,JSUnusedLocalSymbols,DuplicatedCode

import { ActivityConfig } from './activity_config';
import { ambitoNormativo, okKo, tagliaSito, tipologiaApparati } from './arrayXSelect';
import { extraAccessiSito, extraAdeguamentoPassive, extraCaricamentoDocInwit, extraFlowForecast, extraFlowNPlus, extraImpiantiRisalita, extraRinegoziazioneInwit } from './extraFlow';


//extraFlow
//sono stati inseriti su extraflow.ts


// workFlow
export const upgradeInwit: ActivityConfig = {
    extraFlowConfig: [
        {
            name: 'Ext_Rinegoziazione Inwit',
            displayName: 'Rinegoziazione Inwit',
            fields: extraRinegoziazioneInwit,
        },
        {
            name: 'Ext_New Forecast',
            displayName: 'New Forecast',
            fields: extraFlowForecast,
        },
        {
            name: 'Ext_CaricamentoDocInwit',
            displayName: 'Caricamento Doc Inwit',
            fields: extraCaricamentoDocInwit,
        },
        // {
        //   name: 'Ext_Anagrafica New',
        //   displayName: 'Ext_Anagrafica New',
        //   fields: extraFlowAnagraficaNew,
        // },
        // {
        //   name: 'Ext_Anagrafica Old',
        //   displayName: 'Ext_Anagrafica Old',
        //   fields: extraFlowAnagraficaOld,
        // },
        {
            name: 'Ext_Legame NPlus',
            displayName: 'Legame NPlus',
            fields: extraFlowNPlus,
        },
        {
            name: 'Ext_Accessi al sito',
            displayName: 'Accessi al sito',
            fields: extraAccessiSito,
        },
        {
            name: 'Ext_CertImpRisalita',
            displayName: 'Certificazione Impianto Risalita',
            fields: extraImpiantiRisalita,
        },

        {
            name: 'Ext_Adeguamento Passive',
            displayName: 'Adeguamento Passive',
            fields: extraAdeguamentoPassive,
        },

    ],
    name: 'upgradeInwit',
    displayName: 'Upgrade Inwit',
    stepsConfig: [
        {
            displayName: 'Sopralluogo e richiesta documentazione',
            name: '100_Sopralluogo e richiesta documentazione',
            isEntryStep: true,
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                // required: true,
                                //description: 'giorno in cui viene effetuato il sopralluogo',
                                label: 'Data primo sopralluogo',
                            },
                        },
                    ],
                },
                {
                    fieldGroup: [
                        {
                            fieldGroupClassName: 'row',
                            fieldGroup: [
                                {
                                    className: 'col-sm-12',
                                    type: 'communication',
                                    key: 'communication',
                                    templateOptions: {
                                        custom: true,
                                        required: false,
                                        label: 'Richiesta documentazione del Sito',
                                        communicationConfig: {
                                            fields: [
                                                {
                                                    fieldGroupClassName: 'row',
                                                    validators: {
                                                        validation: [
                                                            {
                                                                name: 'fieldDateMatch',
                                                                options: {
                                                                    fieldTocheck: [
                                                                        {
                                                                            fromDate: 'date1',
                                                                            toDate: 'date2',
                                                                            errorText: 'Data Risposta > Data Richiesta',
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    fieldGroup: [
                                                        {
                                                            className: 'col-sm-6',
                                                            type: 'datepicker',
                                                            key: 'date1',
                                                            templateOptions: {
                                                                required: true,
                                                                label: 'Data Richiesta a INWIT',
                                                            },
                                                        },
                                                        {
                                                            className: 'col-sm-6',
                                                            type: 'datepicker',
                                                            key: 'date2',
                                                            templateOptions: {
                                                                // required: true,
                                                                label: 'Data Risposta da INWIT',
                                                            },
                                                            expressionProperties: {
                                                                'templateOptions.required': 'model.string2',
                                                            },
                                                        },
                                                    ],
                                                },
                                                {
                                                    fieldGroupClassName: 'row',
                                                    fieldGroup: [
                                                        {
                                                            className: 'col-12',
                                                            type: 'textarea',
                                                            key: 'string3',
                                                            templateOptions: {
                                                                required: false,
                                                                label: 'Note',
                                                                rows: 3,
                                                            },
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '200_Predisposizione pacchetto e progettazione';
            },
        },
        {
            displayName: 'Predisposizione pacchetto e progettazione',
            name: '200_Predisposizione pacchetto e progettazione',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Consegna AIE > Assegnazione AIE'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Consegna PDM > Predisposizione PDM'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Assegnazione AIE',
                                //description: 'giorno in cui viene effetuato il sopralluogo',
                            },
                            expressionProperties: {
                                'templateOptions.required': '!model.flag1',
                            }
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Consegna AIE',
                                //description: 'giorno in cui viene effetuato il sopralluogo',
                            },
                            expressionProperties: {
                                'templateOptions.required': '!model.flag1',
                            }
                        },

                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Predisposizione PDM',
                            },
                            expressionProperties: {
                                'templateOptions.required': '!model.flag1',
                            }
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Consegna PDM',
                            },
                            expressionProperties: {
                                'templateOptions.required': '!model.flag1',
                            }
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'checkbox',
                            key: 'flag1',
                            templateOptions: {
                                disableIfCommited: true,
                                //required: true,
                                label: 'Permesso già ottenuto',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                //return '210_Presentazione permessi';
                // tslint:disable-next-line:triple-equals
                if( m.flag1 != true ) {
                    return '210_Presentazione permessi';
                } else {
                    return '400_Selezione Ambito Normativo';
                }
            },
        },
        {
            displayName: 'Presentazione permessi',
            name: '210_Presentazione permessi',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Ottenimento Permesso AIE > Presentazione AIE'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Ottenimento Permesso PDM > Presentazione PDM'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione AIE',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso AIE',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione PDM',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso PDM',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '400_Selezione Ambito Normativo';
            },
        },
        {
            displayName: 'Selezione Ambito Normativo',
            name: '400_Selezione Ambito Normativo',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Ambito Normativo',
                                options: ambitoNormativo,
                            },
                        },

                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data definizione ambito normativo',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                if( m.string1 === 'Tit. IV' ) {
                    //return 'Progetto Esecutivo (Tit. IV)';
                    return '410_Progetto Esecutivo (Tit. IV)';
                } else {
                    //return 'Progetto Esecutivo (Art.26)';
                    return '410_Progetto Esecutivo (Art.26)';
                }
            },
        },
        {
            displayName: 'Progetto Esecutivo (Tit. IV)',
            name: '410_Progetto Esecutivo (Tit. IV)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione PE a Ufficio Tecnico > Consegna PE a Ufficio Tecnico'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Consegna PE a Ufficio Tecnico',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Approvazione PE a Ufficio Tecnico',
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        //disableIfCommited: true,
                        custom: true,
                        required: true,
                        label: 'Richiesta Approvazione progetto a INWIT',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Parere INWIT',
                                                options: okKo,
                                            }
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta > Data Richiesta'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            ],
            nextStep: m => {
                return '420_Cantiere (Tit. IV)';
            },
        },
        {
            displayName: 'Progetto Esecutivo (Art.26)',
            name: '410_Progetto Esecutivo (Art.26)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione PE a NI > Consegna PE a NI'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Consegna PE a NI',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Approvazione PE a NI',
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        required: true,
                        //disableIfCommited: true,
                        custom: true,
                        label: 'Richiesta Approvazione progetto a INWIT',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Parere INWIT',
                                                options: okKo,
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta > Data Richiesta'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            ],
            nextStep: m => {
                return '420_Cantiere (Art.26)';
            },
        },
        {
            displayName: 'Cantiere (Tit. IV)',
            name: '420_Cantiere (Tit. IV)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Data Inizio Lavori > Data Consegna Area'
                                        },
                                        {
                                            fromDate: 'date2',
                                            toDate: 'date3',
                                            errorText: 'Data Fine Lavori > Data Inizio Lavori'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Data Chiusura Enti > Data Fine Lavori'
                                        },
                                        {
                                            fromDate: 'date4',
                                            toDate: 'date5',
                                            errorText: 'Data Riconsegna Area > Data Chiusura Enti'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Scelta Tipologia Apparati',
                                options: tipologiaApparati,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'flag1',
                            templateOptions: {
                                disableIfCommited: false,
                                //required: true,
                                label: 'Variante in corso d\'opera',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Consegna Area',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FileConsegnaArea',
                            templateOptions: {
                                label: 'Upload File Consegna Area',
                                //disableIfCommited: true,
                                custom: true,
                                required: false,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date2',
                                            toDate: 'date3',
                                            errorText: 'Fine Lavori > Inizio Lavori'
                                        },
                                    ]
                                }
                            },
                        ],
                    },

                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InizioLavori',
                            templateOptions: {
                                label: 'Upload File Inizio Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FineLavori',
                            templateOptions: {
                                label: 'Upload File Fine Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        //disableIfCommited: true,
                        custom: true,
                        required: true,
                        label: 'Comunicazioni Collaudo Tecnico Amministrativo',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Esito',
                                                options: okKo,
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta > Data Richiesta'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Chiusura cantieri Enti',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'ChiusuraCantieri',
                            templateOptions: {
                                label: 'Upload File Chiusura cantieri Enti',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Riconsegna Area e Accettazione',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'RiconsegnaArea',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload File Riconsegna Area e Accettazione',
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
            ],

            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
        {
            displayName: 'Cantiere (Art.26)',
            name: '420_Cantiere (Art.26)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'ccc > cc'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Scelta Tipologia Apparati',
                                options: tipologiaApparati,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'flag1',
                            templateOptions: {
                                disableIfCommited: false,
                                //required: true,
                                label: 'Variante in corso d\'opera',
                            },
                        },

                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Consegna Area',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FileConsegnaArea',
                            templateOptions: {
                                label: 'Upload File Consegna Area',
                                //disableIfCommited: true,
                                custom: true,
                                required: false,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InizioLavori',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload File Inizio Lavori',
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FineLavori',
                            templateOptions: {
                                label: 'Upload File Fine Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        custom: true,
                        required: false,
                        label: 'Comunicazioni Collaudo Tecnico Amministrativo',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                //disableIfCommited: true,
                                                label: 'Esito',
                                                options: okKo,
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta > Data Richiesta'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                //disableIfCommited: true,
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                //disableIfCommited: true,
                                                //required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                //disableIfCommited: true,
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Chiusura cantieri Enti',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'ChiusuraCantieri',
                            templateOptions: {
                                label: 'Upload File Chiusura cantieri Enti',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Riconsegna Area e Accettazione',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'RiconsegnaArea',
                            templateOptions: {
                                label: 'Upload File Riconsegna Area e Accettazione',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
            ],

            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
        {
            displayName: 'Chiusura attività',
            name: '999_Chiusura attività',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Tipologia Office',
                                options: tagliaSito,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Data On Air attività',
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
