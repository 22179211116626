import { Component, ViewEncapsulation } from '@angular/core';

@Component( {
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: [ './auth-layout.component.scss' ],
    encapsulation: ViewEncapsulation.None,
} )
export class AuthLayoutComponent {
}
