<page-header [showBackButton]='false'></page-header>
<div [fluidHeight]='null'>
    <iframe title='REP_KPI_LOCAL_XLS_BLACK' style='border: 0; width: 100%; height: 100%'
            src='https://app.powerbi.com/reportEmbed?reportId=b6ed2298-e790-4673-8a5a-95a781b882b1&autoAuth=true&ctid=68283f3b-8487-4c86-adb3-a5228f18b893'
            allowFullScreen='true'>
    </iframe>
</div>

<!--
<br />
<div class='matero-row' fxLayout='row wrap'>
  <div
    class='matero-col'
    fxFlex.gt-sm='25'
    fxFlex.gt-xs='50'
    fxFlex='100'
    *ngFor='let zona of zone'
  >
    <app-strutture-zona [zona]='zona'></app-strutture-zona>
  </div>
</div>
<div class='matero-row' fxLayout='row wrap'>
    <div class='matero-col' fxFlex.gt-sm='100' fxFlex='100'>
        <mat-card>
            <span style='color: #8793a2'>
            <i>Attention! The panels below are for example only and do not contain actual data</i>
            </span>
        </mat-card>

    </div>
</div>
<div class='matero-row' fxLayout='row wrap'>
  <div class='matero-col' fxFlex.gt-sm='40' fxFlex='100'>
    <div class='matero-row' fxLayout='row wrap'>
      <app-activities-counter style='width: 100%;'></app-activities-counter>
    </div>
    <div class='matero-row' fxLayout='row wrap'>
      <wnd-activities-chart style='width: 100%;'></wnd-activities-chart>
    </div>
    <div class='matero-row' fxLayout='row wrap'>
      <wnd-steps-totals-chart style='width: 100%;'></wnd-steps-totals-chart>
    </div>
  </div>


  <div class='matero-col' fxFlex.gt-sm='60' fxFlex='100'>
    <app-workflow-descrizione [processi]=processi></app-workflow-descrizione>
  </div>

</div>

-->
