import { Component, HostListener } from '@angular/core';

export interface FormChange {
    field: string;
    value: unknown;
    current: boolean;
}

@Component( {
    template: ''
} )
export class FormUtilsComponent {
    changes: FormChange[] = [];

    @HostListener( 'document:keydown', [ '$event' ] )
    handleKeyboardEvent( event: KeyboardEvent ): void {
        if( ( event.ctrlKey || event.metaKey ) && event.key === 'z' ) { this.undo(); }
        if( ( event.ctrlKey || event.metaKey ) && event.altKey && event.key === 'z' ) { this.redo(); }
    }

    undo(): void {
        const previous = this.changes.findIndex( change => change.current ) - 1;
        if( previous >= 0 ) {
            const change = this.changes[ previous ];
            this.changes.forEach( c => c.current = false );
            change.current = true;
            this.applyChange( change.field, change.value );
        }
    }

    redo(): void {
        const next = this.changes.findIndex( change => change.current ) + 1;
        if( next < this.changes.length ) {
            const change = this.changes[ next ];
            this.changes.forEach( c => c.current = false );
            change.current = true;
            this.applyChange( change.field, change.value );
        }
    }


    addChange( field: string, value: unknown ): void {
        const lastChangeIndex = this.changes.findIndex( change => change.current );
        this.changes = this.changes.slice( 0, lastChangeIndex + 1 );
        this.changes.forEach( c => c.current = false );
        this.changes.push( { field, value, current: true } );
    }

    applyChange( _field: string, _value: unknown ): void {}
}
