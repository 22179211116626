import { WndGridColumn } from '@shared/components/wonder-grid/interfaces/wnd-grid-column';

export interface State {
    grids: { [ key: string ]: { name: string; columns: string[] }[] };
    gridsColumns: { [ key: string ]: WndGridColumn[] };
    selected: { [ key: string ]: { name: string; columns: string[] } };
    selectedSets: { [ key: string ]: { sets: string[] } };
    gridsUpdatedAt: { [key: string]: string };
}

export const initialState: State = {
    grids: {},
    gridsColumns: {},
    selected: {},
    selectedSets: {},
    gridsUpdatedAt: {}
};

export const featureKey = 'grid-columns';
