export const panelFromType = ( idTipoDato: number ) => {
    if( idTipoDato >= 50 && idTipoDato <= 59 ) {
        return 5;
    } else if( idTipoDato >= 60 && idTipoDato <= 69 ) {
        return 6;
    } else {
        return idTipoDato;
    }
};

