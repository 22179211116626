/* eslint-disable arrow-body-style,@typescript-eslint/member-ordering */
// noinspection JSUnusedGlobalSymbols

import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GridColumnsActions } from '@store/grid-columns-store/index';
import { ApiGridColumnsService } from '@shared/services/api-grid-columns.service';
import { EMPTY, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonsSelectors } from '@store/commons-store';

@Injectable()
export class GridColumnsStoreEffects {
    constructor( private actions$: Actions, private store: Store, private api: ApiGridColumnsService ) {
    }

    loadGridsColumns = createEffect( () => {
        return this.actions$.pipe(
            ofType( GridColumnsActions.loadGridsColumns ),
        );
    } );

    loadGridColumns = createEffect( () => {
        return this.actions$.pipe(
            ofType( GridColumnsActions.loadGridColumns ),
            concatLatestFrom( () => this.store.select( CommonsSelectors.selectFiscalYear ) ),
            switchMap( ( [ { name }, fiscalYear ] ) => this.api.getGridsColumns( name, fiscalYear ).pipe(
                map( res => GridColumnsActions.setGridColumns( { name, set: res.data } ) ),
                catchError( err => {
                    console.error( `Cannot read columns for grid ${ name }`, err );
                    return EMPTY;
                } )
            ) ),
        );
    } );

}
