/* eslint-disable brace-style */
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, EMPTY, Observable } from 'rxjs';
import { WndGridColumn } from './../interfaces/wnd-grid-column';
import { WndGridColumnFilterEvent, WndGridColumnService } from './wnd-grid-column-service';
import { Injectable } from '@angular/core';
import { catchError, debounceTime, distinctUntilChanged, expand, map, reduce, switchMap, take, tap, filter } from 'rxjs/operators';
import { WndGridSearchRequest } from '../helpers/wnd-grid-search-request';
import { WndGridDataResponse } from '../interfaces/wnd-grid-data-response';
import { Store } from '@ngrx/store';
import { GridFiltersActions } from '@store/grid-filters-store';
import { GridColumnsSelectors } from '@store/grid-columns-store';
import { WndGridColumnsApiService } from '@shared/components/wonder-grid/services/wnd-grid-columns-api.service';
import { DownloadService } from '@shared/services/download.service';

@Injectable()
export class WndGridService {

    // Keeps track of the last selected filter. Needed for getting all the data from outside the service
    private lastFilter: WndGridSearchRequest = null;

    private endpoint: string;
    private endpointDistinct: string;
    private endpointRaw: string;
    private pendingReset = false;
    private columnServices: WndGridColumnService[] = [];
    private columns: WndGridColumn[];
    private defaultPageSize: number;
    private externalFilter: Observable<WndGridColumnFilterEvent>[] = [];
    private sortOrderSource: BehaviorSubject<SortEvent>;
    private pageSizeSource: BehaviorSubject<number>;
    private pageIndexSource: BehaviorSubject<number>;
    private refreshSource: BehaviorSubject<void>;
    private innerSeparator = '|';
    private separator = '||';
    private dataSourceReady = new BehaviorSubject<void>( null );
    private dataSourceFilter: Observable<WndGridSearchRequest>;
    private gridName: string;
    private elasticSearchSource: BehaviorSubject<string>;

    constructor( private http: HttpClient, private store: Store, private columnsApi: WndGridColumnsApiService, private downloadService: DownloadService ) {
        this.initDataSourceFiltered();
    }

    get lastAllFilter(): WndGridSearchRequest {
        return this.lastFilter;
    }

    init( endpoint: string, defaultPageSize: number, externalFilters: Observable<WndGridColumnFilterEvent>[] = [], gridName: string, sort: SortEvent ) {
        this.endpoint = `${ endpoint }/DataGrid/Result`;
        this.endpointDistinct = `${ endpoint }/DataGrid/Distinct`;
        this.endpointRaw = endpoint;
        this.defaultPageSize = defaultPageSize;
        this.externalFilter = externalFilters;
        this.pageSizeSource = new BehaviorSubject<number>( this.defaultPageSize );
        this.pageIndexSource = new BehaviorSubject<number>( 0 );
        this.refreshSource = new BehaviorSubject<void>( null );
        this.sortOrderSource = new BehaviorSubject( sort );
        this.elasticSearchSource = new BehaviorSubject<string>( '' );
        this.gridName = gridName;
    }

    addColumn( val: WndGridColumnService ) {
        val.initColumnOptionsService( this.endpointDistinct );
        this.columnServices.push( val );
        if( this.columnServices.length === this.columns.length ) {
            this.dataSourceReady.next();
        }
    }

    removeColumn( val: WndGridColumnService ) {
        const index = this.columnServices.findIndex( c => c.column.fieldName === val.column.fieldName );
        this.columnServices.splice( index, 1 );
    }

    setColumns( columns: WndGridColumn[] ) {
        this.columns = columns;
    }

    // noinspection JSUnusedGlobalSymbols
    getEndpoint = () => this.endpoint;
    getEndpointRaw = () => this.endpointRaw;

    setEndpoint( endpoint: string ): void {
        this.endpoint = endpoint + '/DataGrid/Result';
        this.endpointDistinct = endpoint + '/DataGrid/Distinct';
        this.endpointRaw = endpoint;

        this.columnServices.forEach( service => service.columnOptions.setEndpoint( this.endpointDistinct ) );
    }

    get dataResult() {
        return this.requestSource.pipe(
            //tap( res => console.log( 'FFilter', res ) ),
            switchMap( res => this.http.get<WndGridDataResponse>( this.endpoint, { params: this.getHttpParams( res ) } ).pipe( catchError( () => EMPTY ) ) ),
            // tap( res => console.log( 'Data result', res ) ),
        );
    }

    /**
     * Returns all the data according to the selected filter looping all the pages.
     */
    getAllDataResult(): Observable<{ data: any[]; columns: WndGridColumn[] }> {
        let params = { ...this.lastFilter, pageIndex: 0, pageSize: 50 };
        return this.getSingleDataPage( params ).pipe(
            expand( res => {
                if( res && res.data.length > 0 ) {
                    params = { ...params, pageIndex: params.pageIndex + 1 };
                    return this.getSingleDataPage( params );
                } else {
                    return EMPTY;
                }
            } ),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            reduce( ( acc, val ) => acc.concat( val.data ), [] ),
            map( data => ( { data, columns: this.columns } ) ),
        );
    }

    get pageChange() {
        return {
            index: this.pageIndexSource.pipe( distinctUntilChanged() ),
            size: this.pageSizeSource.pipe( distinctUntilChanged() ),
        };
    }

    updateSort( e: SortEvent ) {
        this.sortOrderSource.next( e );
    }

    get sortChange() {
        return this.sortOrderSource.asObservable();
    }

    updatePageIndex( e: number ) {
        this.pageIndexSource.next( e );
    }

    updatePageSize( e: number ) {
        this.pageSizeSource.next( e );
    }

    updateElasticSearch( e: string ) {
        this.elasticSearchSource.next( e );
        this.columnServices.forEach( service => service.columnOptions.setEndpoint( `${this.endpointDistinct}?elasticSearch=${e}` ) );
    }

    // noinspection JSUnusedGlobalSymbols
    resetFilters( grid: string, columns: string[] ) {
        this.store.dispatch( GridFiltersActions.clearAllTableFilters( { grid, columns } ) );

        if( !this.pendingReset ) {
            this.columnServices.forEach( s => s.resetFilters() );
            this.pendingReset = true;
        }

    }

    refresh(): void {
        this.refreshSource.next();
    }

    getColumns( year: number ): Observable<WndGridColumn[]> {
        return this.columnsApi.getColumns( year, this.gridName ).pipe(
            //tap( ( columns ) => console.debug( `Setting columns from wnd grid columns for ${this.gridName}`, this.columns, columns ) ),
            tap( columns => this.columns = columns ),
        );
    }

    downloadExport( fileToExport: string, fileFormat: string ): Observable<Blob> {
        const url = `${ this.endpointRaw }/datagrid/download`;
        const params = this.getHttpParams( { ...this.lastFilter, fileFormat } );
        return this.store.select( GridColumnsSelectors.selectSelectedColumnsSet( this.gridName ) ).pipe(
            take( 1 ),
            // tap( cols => console.log( 'Exporting...', url, params, cols ) ),
            switchMap( columns => this.http.post( url, columns, { params, responseType: 'blob' } ) ),
            tap( response => {
                if( response ) {
                    const blob = new Blob( [ response ] );
                    this.downloadService.triggerSaveAs( blob, fileToExport );
                }
            } )
        );
    }

    private getQuery( e: WndGridColumnFilterEvent ): string {
        return ( !e.event.arguments || e.event.arguments.length === 0 ) ? undefined :
            '`field`' + e.sourceField + '`searchType`' + e.event.type + '`arguments`' + e.event.arguments.join( this.innerSeparator );
    }

    private handleGridEvent( source: Observable<GridEvent[]> ) {
        return source.pipe(
            map( events => {
                // console.debug( 'Handle grid event', events );
                let filters: string;
                const tempFilter: string[] = [];
                events.filter( v => v.type === 'filter' ).forEach( v => {
                    const ev = v.event as WndGridColumnFilterEvent;
                    const query = this.getQuery( ev );
                    if( query ) {
                        tempFilter.push( query );
                    }
                } );
                if( tempFilter.length > 0 ) {
                    filters = tempFilter.join( this.separator );
                }
                const pageIndex = events.find( v => v.type === 'pageIndex' ).event as number;
                const pageSize = events.find( v => v.type === 'pageSize' ).event as number;
                const sort = events.find( v => v.type === 'sort' ).event as SortEvent;
                const elasticSearch = events.find( v => v.type === 'elasticSearch' ).event as string;
                const request: WndGridSearchRequest = {
                    pageSize,
                    pageIndex,
                    sortColumn: sort.field,
                    sortOrder: sort.sort,
                    filters,
                    elasticSearch,
                };
                return request;
            } ),
            debounceTime( 0 ),
        );
    }

    /**
     * Return an observable that resolves in a single page of data
     *
     * @param params    The input filter, page and page size.
     * @private
     */
    private getSingleDataPage( params: WndGridSearchRequest ): Observable<WndGridDataResponse> {
        return this.http.get<WndGridDataResponse>( this.endpoint, { params: this.getHttpParams( params ) } ).pipe(
            catchError( () => EMPTY ),
        );
    }

    public get requestSource() {
        return this.dataSourceFilter;
    }

    private initDataSourceFiltered() {
        this.dataSourceFilter = this.dataSourceReady.pipe(
            switchMap( () => {
                // console.debug( 'Data source ready', this.externalFilter, this.columnServices );
                const filters = [ ...this.externalFilter, ...this.columnServices.map( s => s.filterEvents ) ].map( f => f.pipe( map( v => {
                    // console.debug( 'Filter changed', v );
                    if( this.pageIndexSource.value !== 0 ) {
                        this.pageIndexSource.next( 0 );
                    }
                    if( this.pendingReset ) {
                        this.sortOrderSource.next( { field: '', sort: undefined } );
                        this.pendingReset = false;
                    }
                    const to: GridEvent = { type: 'filter', event: v };
                    return to;
                } ) ) );
                // console.debug( 'Data source ready filters', filters );

                const pageInd = this.pageIndexSource.pipe( distinctUntilChanged(), map( e => {
                    const to: GridEvent = { type: 'pageIndex', event: e };
                    return to;
                } ) );

                const pageSize = this.pageSizeSource.pipe( distinctUntilChanged(), map( e => {
                    if( this.pageIndexSource.value !== 0 ) {
                        this.pageIndexSource.next( 0 );
                    }
                    const to: GridEvent = { type: 'pageSize', event: e };
                    return to;
                } ) );

                const sort = this.sortOrderSource.pipe( map( e => {
                    if( this.pageIndexSource.value !== 0 ) {
                        this.pageIndexSource.next( 0 );
                    }
                    const to: GridEvent = { type: 'sort', event: e };
                    return to;
                } ) );

                this.refreshSource.next();
                const refresh = this.refreshSource.pipe(
                    map( e => {

                        const to: GridEvent = { type: 'refresh', event: e };
                        return to;
                    } ),
                );

                const elasticSearch = this.elasticSearchSource.pipe(
                    map( e => {
                        if( this.pageIndexSource.value !== 0 ) {
                            this.pageIndexSource.next( 0 );
                        }
                        const to: GridEvent = { type: 'elasticSearch', event: e };
                        return to;
                    } ),
                );
                // console.debug( 'Before unique source' );
                const uniqueSource = combineLatest( [ ...filters, pageInd, pageSize, sort, refresh, elasticSearch ] );
                // console.debug( 'After unique source' );
                return this.handleGridEvent( uniqueSource );
            } ),
            tap( val => {
                this.lastFilter = val;
            } ),
        );
    }

    private getHttpParams( obj: any ) {
        let params = new HttpParams();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        for( const [ key, value ] of Object.entries<string>( obj ) ) {
            if( !!key && !!value ) {
                params = params.set( key, value.toString() );
            }
        }
        return params;
    }
}


export interface SortEvent {
    field: string;
    sort: 'ASC' | 'DESC';
}

interface GridEvent {
    type: 'filter' | 'pageIndex' | 'pageSize' | 'sort' | 'refresh' | 'elasticSearch';
    event: any;
}
