import { FormlyFieldConfig } from '@ngx-formly/core';
import { okKo } from './arrayXSelect';

// extraflow Forecast
export const extraFlowForecast: FormlyFieldConfig[] = [
    {
        fieldGroupClassName: 'row',
        validators: {
            validation: [
                {
                    name: 'fieldDateMatch',
                    options: {
                        fieldTocheck: [
                            {
                                fromDate: 'date1',
                                toDate: 'date2',
                                errorText: 'Data di Permesso Ottenuto > Permesso Presentato',
                            },
                            {
                                fromDate: 'date2',
                                toDate: 'date3',
                                errorText: 'Data di Pronto Locale > Permesso Ottenuto',
                            },
                            {
                                fromDate: 'date3',
                                toDate: 'date4',
                                errorText: 'Data di On Air > Pronto Locale',
                            },
                        ],
                    },
                },
            ],
        },
        fieldGroup: [
            {
                className: 'col-sm-6',
                type: 'datepicker',
                key: 'date1',
                templateOptions: {
                    //disableIfCommited: true,
                    required: false,
                    label: 'Permesso presentato',
                },
            },
            {
                className: 'col-sm-6',
                type: 'datepicker',
                key: 'date2',
                templateOptions: {
                    //disableIfCommited: true,
                    required: false,
                    label: 'Permesso ottenuto',
                },
            },
        ],
    },
    {
        fieldGroupClassName: 'row',
        fieldGroup: [
            {
                className: 'col-sm-6',
                type: 'datepicker',
                key: 'date3',
                templateOptions: {
                    //disableIfCommited: true,
                    required: false,
                    label: 'Pronto locale',
                },
            },
            {
                className: 'col-sm-6',
                type: 'datepicker',
                key: 'date4',
                templateOptions: {
                    //disableIfCommited: true,
                    required: false,
                    label: 'On Air',
                },
            },
        ],
    },
];

// necessario per le attività che nascono prima di N+
export const extraFlowNPlus: FormlyFieldConfig[] = [
    {
        fieldGroupClassName: 'row',
        fieldGroup: [
            {
                className: 'col-sm-6',
                type: 'checkbox',
                key: 'flag1',
                templateOptions: {
                    disableIfCommited: true,
                    required: false,
                    label: 'Esiste su N+?',
                },
            },
        ],
    },
    {
        fieldGroupClassName: 'row',
        fieldGroup: [
            {
                className: 'col-sm-4',
                type: 'input',
                key: 'number1',
                templateOptions: {
                    disableIfCommited: true,
                    type: 'number',
                    required: true,
                    label: 'id NPlus',
                },
                hideExpression: 'model.flag1 != true',
                expressionProperties: {
                    //'templateOptions.disabled': 'model.flag1 != true',
                    'templateOptions.required': 'model.flag1 === true',
                },
            },
        ],
    },
];

// dedicato all'anagrafica dell'Office New
export const extraFlowAnagraficaNew: FormlyFieldConfig[] = [
    {
        fieldGroup: [
            // {
            //   fieldGroupClassName: 'row',
            //   fieldGroup: [
            //     {
            //       className: 'col-sm-4',
            //       type: 'checkbox',
            //       key: 'flag1',
            //       templateOptions: {
            //         required: true,
            //         label: 'Cambia l`anagrafica a fine attività?',
            //       },
            //     },
            //   ]
            // },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-6',
                        type: 'input',
                        key: 'string1',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'codice Office NEW',
                            minLength: 8,
                            maxLength: 8,
                            pattern: /^([1234](OF|RM)\d{1,5})$/,
                        },
                        // hideExpression: 'model.flag1 != true',
                        // expressionProperties: {
                        //   'templateOptions.disabled': 'model.flag1 != true',
                        //   'templateOptions.required': 'model.flag1 === true',
                        // },
                        validation: {
                            messages: {
                                pattern: ( error, field: FormlyFieldConfig ) => `"${ field.formControl.value }", codice office non valido  [es 1OF00374 or 1RM00347]`,
                            },
                        },
                    },
                    {
                        className: 'col-sm-6',
                        type: 'input',
                        key: 'string2',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'nome office NEW',
                        },
                        // hideExpression: 'model.flag1 != true',
                        // expressionProperties: {
                        //   'templateOptions.disabled': 'model.flag1 != true',
                        //   'templateOptions.required': 'model.flag1 === true',
                        // },
                    },
                ],
            },
        ],
    },
];

export const extraFlowAnagraficaNewCg: FormlyFieldConfig[] = [
    {
        fieldGroup: [
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-4',
                        type: 'checkbox',
                        key: 'flag1',
                        templateOptions: {
                            disableIfCommited: true,
                            //required: true,
                            label: 'Cambia l`anagrafica a fine attività?',
                        },
                    },
                ]
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-6',
                        type: 'input',
                        key: 'string1',
                        templateOptions: {
                            disableIfCommited: true,
                            //required: true,
                            label: 'codice Office NEW',
                            minLength: 8,
                            maxLength: 8,
                            pattern: /^([1234](OF|RM)\d{1,5})$/,
                        },
                        hideExpression: 'model.flag1 != true',
                        expressionProperties: {
                            'templateOptions.disabled': 'model.flag1 != true',
                            'templateOptions.required': 'model.flag1 === true',
                        },
                        validation: {
                            messages: {
                                pattern: ( error, field: FormlyFieldConfig ) => `"${ field.formControl.value }", codice office non valido  [es 1OF00374 or 1RM00347]`,
                            },
                        },
                    },
                    {
                        className: 'col-sm-6',
                        type: 'input',
                        key: 'string2',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'nome office NEW',
                        },
                        hideExpression: 'model.flag1 != true',
                        expressionProperties: {
                            'templateOptions.disabled': 'model.flag1 != true',
                            'templateOptions.required': 'model.flag1 === true',
                        },
                    },
                ],
            },
        ],
    },
];

export const extraFlowAnagraficaTIM: FormlyFieldConfig[] = [
    {
        fieldGroup: [
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-6',
                        type: 'checkbox',
                        key: 'flag1',
                        templateOptions: {
                            disableIfCommited: true,
                            required: false,
                            label: 'Devi aggiungere l`anagrafica TIM?',
                        },
                    },
                ],
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-6',
                        type: 'input',
                        key: 'string1',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'codice Office TIM',
                            minLength: 2,
                            maxLength: 8,
                        },
                        hideExpression: 'model.flag1 != true',
                        expressionProperties: {
                            //'templateOptions.disabled': 'model.flag1 != true',
                            'templateOptions.required': 'model.flag1 === true',
                        },

                    },
                    {
                        className: 'col-sm-6',
                        type: 'input',
                        key: 'string2',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'nome office TIM',
                        },
                        hideExpression: 'model.flag1 != true',
                        expressionProperties: {
                            //'templateOptions.disabled': 'model.flag1 != true',
                            'templateOptions.required': 'model.flag1 === true',
                        },
                    },
                ],
            },
        ],
    },
];


// dedicato all'anagrafica dell'Office Old da dismettere
export const extraFlowAnagraficaOld: FormlyFieldConfig[] = [
    {
        fieldGroup: [
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-4',
                        type: 'input',
                        key: 'string1',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'codice Office OLD',
                            minLength: 8,
                            maxLength: 8,
                            pattern: /^([1234](OF|RM)\d{1,5})$/,
                        },
                        // hideExpression: 'model.flag1 != true',
                        // expressionProperties: {
                        //   'templateOptions.disabled': 'model.flag1 != true',
                        //   'templateOptions.required': 'model.flag1 === true',
                        // },
                        validation: {
                            messages: {
                                pattern: ( error, field: FormlyFieldConfig ) => `"${ field.formControl.value }", codice office non valide  [es 1OF00374 or 1RM00347]`,
                            },
                        },
                    },
                ],
            },
        ],
    },
];

// dedicato alla richiesta dell'adeguamento del passive
export const extraAdeguamentoPassive: FormlyFieldConfig[] = [
    {
        fieldGroupClassName: 'row',
        fieldGroup: [
            {
                className: 'col-sm-12',
                type: 'communication',
                key: 'communication',
                templateOptions: {
                    custom: true,
                    required: false,
                    label: 'Comunicazioni Adeguamento Passive/Fornitura Elettrica a Inwit',
                    communicationConfig: {
                        fields: [
                            {
                                fieldGroupClassName: 'row',
                                validators: {
                                    validation: [
                                        {
                                            name: 'fieldDateMatch',
                                            options: {
                                                fieldTocheck: [
                                                    {
                                                        fromDate: 'date1',
                                                        toDate: 'date2',
                                                        errorText: 'Data Risposta > Data Richiesta',
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                                fieldGroup: [
                                    {
                                        className: 'col-sm-12',
                                        type: 'select',
                                        key: 'string1',
                                        defaultValue: 'Adeguamento passive/fornitura elettrica',
                                        templateOptions: {
                                            required: true,
                                            label: 'Tipologia Comunicazione',
                                            options: [
                                                {
                                                    label: '', value: '',
                                                },
                                                {
                                                    label: 'Adeguamento fornitura elettrica',
                                                    value: 'Adeguamento fornitura elettrica',
                                                },
                                                {
                                                    label: 'Adeguamento passive [A: es. aggiunta stotz, rettificatori ecc.]',
                                                    value: 'Adeguamento passive [tipo A]',
                                                },
                                                {
                                                    label: 'Adeguamento passive [B: es swap cdz, stazioni energia]',
                                                    value: 'Adeguamento passive [tipo B]',
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },

                            {
                                fieldGroupClassName: 'row',
                                fieldGroup: [
                                    {
                                        className: 'col-sm-12',
                                        type: 'select',
                                        key: 'string2',
                                        templateOptions: {
                                            required: false,
                                            label: 'Parere INWIT',
                                            options: okKo,
                                        },
                                        expressionProperties: {
                                            'templateOptions.required': 'model.date2',
                                        },
                                    },
                                ],
                            },

                            {
                                fieldGroupClassName: 'row',
                                fieldGroup: [
                                    {
                                        className: 'col-sm-6',
                                        type: 'datepicker',
                                        key: 'date1',
                                        templateOptions: {
                                            required: true,
                                            label: 'Data Invio Richiesta a INWIT',
                                        },
                                    },
                                    {
                                        className: 'col-sm-6',
                                        type: 'datepicker',
                                        key: 'date2',
                                        templateOptions: {
                                            required: false,
                                            label: 'Data Risposta INWIT',
                                        },
                                        expressionProperties: {
                                            'templateOptions.required': 'model.string2',
                                        },
                                    },
                                ],
                            },

                            {
                                fieldGroupClassName: 'row',
                                fieldGroup: [
                                    {
                                        className: 'col-12',
                                        type: 'textarea',
                                        key: 'string3',
                                        templateOptions: {
                                            required: false,
                                            label: 'Note',
                                            rows: 3,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
        ],
    },
];

export const extraRinegoziazioneInwit: FormlyFieldConfig[] = [
    {
        fieldGroup: [
            {
                fieldGroupClassName: 'row',
                validators: {
                    validation: [
                        {
                            name: 'fieldDateMatch',
                            options: {
                                fieldTocheck: [
                                    {
                                        fromDate: 'date1',
                                        toDate: 'date2',
                                        errorText: 'Data risposta Inwit > Data richiesta Rinegoziazione'
                                    },
                                    {
                                        fromDate: 'date2',
                                        toDate: 'date3',
                                        errorText: 'Data Chiusura rinegoziazione > Data risposta Inwit'
                                    },
                                ]
                            }
                        }
                    ],
                },
                fieldGroup: [
                    {
                        className: 'col-sm-6',
                        type: 'select',
                        key: 'string1',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'Tipologia Upgrade Inwit',
                            options: [
                                { label: '', value: '' },
                                { label: 'A', value: 'A' },
                                { label: 'B', value: 'B' },
                                { label: 'C', value: 'C' },
                            ],
                        },
                    },
                ],
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-4',
                        type: 'datepicker',
                        key: 'date1',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'Data richiesta Rinegoziazione',
                            //description: 'Data condivisione PdM con INWIT per rinegoziazione',
                        },
                        hideExpression: 'model.string1 != "B" && model.string1 != "C"',
                        expressionProperties: {
                            //'templateOptions.disabled': 'model.string1 != "B" && model.string1 != "C"',
                            'templateOptions.required': 'model.string1 === "B" || model.string1 === "C"',
                        },
                    },
                    {
                        className: 'col-sm-4',
                        type: 'select',
                        key: 'string2',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'Esito rinegoziazione Inwit',
                            options: [
                                { label: 'In Attesa', value: 'In Attesa' },
                                { label: 'Non necessaria o chiusa', value: 'Non necessaria o chiusa' },
                                {
                                    label: 'Necessaria ma non ai fini di PL',
                                    value: 'Necessaria ma non ai fini di PL',
                                },
                                { label: 'Necessaria per PL', value: 'Necessaria per PL' },
                            ],
                        },
                        hideExpression: 'model.string1 != "B" && model.string1 != "C"',
                        expressionProperties: {
                            //'templateOptions.disabled': 'model.string1 != "B" && model.string1 != "C"',
                            'templateOptions.required': 'model.string1 === "B" || model.string1 === "C"',
                        },
                    },
                    {
                        className: 'col-sm-4',
                        type: 'datepicker',
                        key: 'date2',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'Data risposta Inwit',
                        },
                        hideExpression: 'model.string1 != "B" && model.string1 != "C"',
                        expressionProperties: {
                            //'templateOptions.disabled': 'model.string1 != "B" && model.string1 != "C"',
                            'templateOptions.required': 'model.string1 === "B" || model.string1 === "C"',
                        },
                    },
                ],
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-12',
                        type: 'datepicker',
                        key: 'date3',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'Data Chiusura rinegoziazione',
                        },
                        hideExpression: '(model.string1 != "B" && model.string1 != "C") || (model.string2 != "Necessaria ma non ai fini di PL" && model.string2 != "Necessaria per PL")',
                        expressionProperties: {
                            //'templateOptions.disabled': ' model.string1 != "B" && model.string1 != "C"',
                            'templateOptions.required': ' (model.string1 === "B" || model.string1 === "C")',
                        },
                    },
                ],
            },
        ],
    },
];

export const extraCaricamentoDocInwit: FormlyFieldConfig[] = [
    {
        fieldGroup: [
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-12',
                        type: 'checkbox',
                        key: 'flag1',
                        templateOptions: {
                            //disableIfCommited: true,
                            //required: false,
                            label: 'Caricamento Documentazione su Inwi',
                        },
                    },
                ],
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-6',
                        type: 'checkbox',
                        key: 'flag2',
                        templateOptions: {
                            //disableIfCommited: true,
                            //required: true,
                            label: 'Caratterizzazione in BIM disponibile',
                        },
                    },
                    {
                        className: 'col-sm-6',
                        type: 'datepicker',
                        key: 'date1',
                        templateOptions: {
                            //disableIfCommited: true,
                            //required: false,
                            label: 'Data Caricamento BIM su MTI',
                        },
                    },
                ],
            },
        ],
    },
];

export const extraAccessiSito: FormlyFieldConfig[] = [
    {
        fieldGroupClassName: 'row',
        fieldGroup: [
            {
                className: 'col-sm-12',
                type: 'communication',
                key: 'communication',
                templateOptions: {
                    custom: true,
                    required: false,
                    label: 'Richiesta accesso al Sito',
                    communicationConfig: {
                        fields: [
                            {
                                fieldGroupClassName: 'row',
                                fieldGroup: [
                                    {
                                        className: 'col-sm-12',
                                        type: 'select',
                                        key: 'string1',
                                        defaultValue: 'Accesso sito garantito',
                                        templateOptions: {
                                            required: true,
                                            label: 'Tipologia Comunicazione',
                                            options: [
                                                { label: 'Accesso sito garantito', value: 'Accesso sito garantito' },
                                                {
                                                    label: 'Accesso in presenza di cantiere (Richiesta FOB)',
                                                    value: 'Accesso in presenza di cantiere (Richiesta FOB)',
                                                },
                                                {
                                                    label: 'Accesso in presenza di cantiere (Richiesta OB)',
                                                    value: 'Accesso in presenza di cantiere (Richiesta OB)',
                                                },
                                                {
                                                    label: 'Accesso con accompagnamento',
                                                    value: 'Accesso con accompagnamento',
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                            {
                                fieldGroupClassName: 'row',
                                validators: {
                                    validation: [
                                        {
                                            name: 'fieldDateMatch',
                                            options: {
                                                fieldTocheck: [
                                                    {
                                                        fromDate: 'date1',
                                                        toDate: 'date2',
                                                        errorText: 'Data Risposta > Data Richiesta',
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                                fieldGroup: [
                                    {
                                        className: 'col-sm-6',
                                        type: 'datepicker',
                                        key: 'date1',
                                        templateOptions: {
                                            required: true,
                                            label: 'Data Richiesta Accesso a INWIT',
                                        },
                                    },
                                    {
                                        className: 'col-sm-6',
                                        type: 'datepicker',
                                        key: 'date2',
                                        templateOptions: {
                                            // required: true,
                                            label: 'Data Accesso Effettivo',
                                        },
                                        expressionProperties: {
                                            'templateOptions.required': 'model.string2',
                                        },
                                    },
                                ]
                            },
                            {
                                fieldGroupClassName: 'row',
                                fieldGroup: [
                                    {
                                        className: 'col-12',
                                        type: 'textarea',
                                        key: 'string3',
                                        templateOptions: {
                                            required: false,
                                            label: 'Note',
                                            rows: 3,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
        ],
    },
];

export const extraImpiantiRisalita: FormlyFieldConfig[] = [
    {
        fieldGroupClassName: 'row',
        fieldGroup: [

            {
                className: 'col-sm-12',
                type: 'communication',
                key: 'communication',
                templateOptions: {
                    custom: true,
                    required: false,
                    label: 'Certificazione impianto di risalita',
                    communicationConfig: {
                        fields: [
                            {
                                fieldGroupClassName: 'row',
                                validators: {
                                    validation: [
                                        {
                                            name: 'fieldDateMatch',
                                            options: {
                                                fieldTocheck: [
                                                    {
                                                        fromDate: 'date1',
                                                        toDate: 'date2',
                                                        errorText: 'Data Risposta > Data Richiesta',
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                                fieldGroup: [
                                    {
                                        className: 'col-sm-6',
                                        type: 'datepicker',
                                        key: 'date1',
                                        templateOptions: {
                                            required: true,
                                            label: 'Data Richiesta a INWIT',
                                        },
                                    },
                                    {
                                        className: 'col-sm-6',
                                        type: 'datepicker',
                                        key: 'date2',
                                        templateOptions: {
                                            // required: true,
                                            label: 'Data Risposta da INWIT',
                                        },
                                        expressionProperties: {
                                            'templateOptions.required': 'model.string2',
                                        },
                                    },
                                    {
                                        className: 'col-12',
                                        type: 'textarea',
                                        key: 'string3',
                                        templateOptions: {
                                            required: false,
                                            label: 'Note',
                                            rows: 3,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
        ],
    },
];

export const extraMotivazioneRecessoInwit: FormlyFieldConfig[] = [
    {
        fieldGroupClassName: 'row',
        fieldGroup: [
            {
                className: 'col-sm-12',
                type: 'select',
                key: 'string1',
                templateOptions: {
                    disableIfCommited: true,
                    required: true,
                    label: 'Motivazione Recesso',
                    options: [
                        { label: 'Piano dismissione (Siti XL-ZL)', value: 'Piano dismissione (Siti XL-ZL)' },
                        { label: 'Impossibilitá tecnica', value: 'Impossibilitá tecnica' },
                        { label: 'Ragioni urbanistiche', value: 'Ragioni urbanistiche' },
                        { label: 'Mancanza di spazio', value: 'Mancanza di spazio' },
                        { label: 'Divieto di contratto principale non risolto', value: 'Divieto di contratto principale non risolto' },
                        { label: 'Libero e ad nutum (CAP 290 siti - 8 anni)', value: 'Libero e ad nutum (CAP 290 siti - 8 anni)' },
                        { label: 'Disservizio continuativo', value: 'Disservizio continuativo' },
                        { label: 'Altro', value: 'Altro' },
                    ],
                },
            },
        ],
    },
    {
        fieldGroupClassName: 'row',
        fieldGroup: [
            {
                className: 'col-12',
                type: 'textarea',
                key: 'string2',
                templateOptions: {
                    required: false,
                    label: 'Note',
                    rows: 3,
                },
            },
        ],
    },
];

export const extraMotivazioneRecesso: FormlyFieldConfig[] = [
    {
        fieldGroupClassName: 'row',
        fieldGroup: [
            {
                className: 'col-sm-12',
                type: 'select',
                key: 'string1',
                templateOptions: {
                    disableIfCommited: true,
                    required: true,
                    label: 'Motivazione Recesso',
                    options: [
                        { label: 'Impossibilitá tecnica', value: 'Impossibilitá tecnica' },
                        { label: 'Ragioni urbanistiche', value: 'Ragioni urbanistiche' },
                        { label: 'Mancanza di spazio', value: 'Mancanza di spazio' },
                        { label: 'Disservizio continuativo', value: 'Disservizio continuativo' },
                        { label: 'Altro', value: 'Altro' },
                    ],
                },
            },
        ],
    },
    {
        fieldGroupClassName: 'row',
        fieldGroup: [
            {
                className: 'col-12',
                type: 'textarea',
                key: 'string2',
                templateOptions: {
                    required: false,
                    label: 'Note',
                    rows: 3,
                },
            },
        ],
    },
];
