import { Action, createReducer, on } from '@ngrx/store';
import { RolesActions, RolesState } from '@store/roles-store/index';

const rolesReducer = createReducer( RolesState.initState,
    on( RolesActions.setRoles, ( state, { roles } ) => ( { ...state, roles: roles.reduce( ( s, r ) => ( { ...s, [ r.name ]: r } ), {} ) } ) ),
    on( RolesActions.setRolesResetSelected, ( state, { roles } ) => ( { ...state, roles: roles.reduce( ( s, r ) => ( { ...s, [ r.name ]: r } ), {} ), selectedRole: null } ) ),
    on( RolesActions.setPolicies, ( state, { policies } ) => ( { ...state, policies } ) ),
    on( RolesActions.setRole, ( state, { role } ) => ( { ...state, roles: { ...state.roles, [ role.name ]: role } } ) ),
    on( RolesActions.selectRole, ( state, { selectedRole } ) => ( { ...state, selectedRole } ) ),
);

export const reducers = ( state: RolesState.State | undefined, action: Action ): RolesState.State => rolesReducer( state, action );
