import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ContrattiState } from './index';
import { reducers } from '@store/contratti-store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { ContrattiEffects } from '@store/contratti-store/effects';


@NgModule( {
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature( ContrattiState.featureKey, reducers ),
        EffectsModule.forFeature( [ ContrattiEffects ] ),
    ],
} )
export class ContrattiStoreModule {
}
