import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridColumnsActions, GridColumnsSelectors } from '@store/grid-columns-store';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component( {
    selector: 'wnd-grid-columns-chooser',
    templateUrl: './grid-columns-chooser.component.html',
    styleUrls: [ './grid-columns-chooser.component.scss' ],
} )
export class GridColumnsChooserComponent implements OnInit {
    @Input() gridName: string;
    selectedSet$?: Observable<string[]>;
    columnsCheckSets?: { name: string; columns: string[]; checked: boolean }[];

    constructor( private store: Store ) {
    }

    ngOnInit(): void {

        this.store.dispatch( GridColumnsActions.loadGridColumns( { name: this.gridName } ) );
        this.selectedSet$ = this.store.select( GridColumnsSelectors.selectSelectedSets( this.gridName ) ).pipe(
            switchMap( ( resSelected: string[] ) => this.store.select( GridColumnsSelectors.selectGridColumns( this.gridName ) ).pipe(
                tap( ( cols ) => {
                    if( cols ) {
                        this.columnsCheckSets = cols.map( ( col ) => ( { ...col, checked: !!resSelected.find( ( el ) => el === col.name ) } ) );
                    }
                } ),
                map( () => resSelected ),
            ) ),
        );
    }

    setChanged() {
        const colsSelected = this.columnsCheckSets.filter( ( el ) => el.checked );
        this.store.dispatch( GridColumnsActions.selectColumnsSet( { gridName: this.gridName, setNames: colsSelected.map( ( m ) => m.name ) } ) );
    }
}
