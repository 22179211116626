import { Config, MapDrawConfigService } from './../map-draw-config-service.service';
import { OsmDrawMapComponent } from '@shared/components/osm-draw-map/osm-draw-map.component';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ToastrService } from 'ngx-toastr';

@Component( {
    selector: 'wnd-osm-draw-wrapper',
    templateUrl: './osm-draw-wrapper.component.html',
    styleUrls: [ './osm-draw-wrapper.component.scss' ],
} )
export class OsmDrawWrapperComponent extends FieldType implements OnInit {
    show = false;
    mapTouched = false;
    panelOpenState = false;
    @ViewChild( 'map' ) mapComp: OsmDrawMapComponent;
    @Input() mapHeight = '69vh';
    @Input() maxWidth = '100%';
    drawConfig: Config;

    constructor(
        private mapDrawConfigService: MapDrawConfigService,
        private toastr: ToastrService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.drawConfig = this.mapDrawConfigService.getConfig(
            this.field.templateOptions.map.typeOfService,
        );
        this.show = true;
        this.formControl.valueChanges.subscribe( () => this._checkConsisteny() );
    }

    menuMapOpened() {
        this.panelOpenState = !this.panelOpenState;
        this.mapComp.fixSize();
        this.mapTouched = true;
    }

    check() {
        console.log( this.formControl.value );
    }

    drawItemGroupChange( event: any ) {
        this.formControl.setValue( event.features );
    }

    mapClosed() {
    }

    mapOpened() {
    }

    private _checkConsisteny() {
        if( this.formControl.value && this.formControl.value.features ) {
            if(
                this.field.templateOptions.map.numberOfItems.min &&
                this.field.templateOptions.map.numberOfItems.min > this.formControl.value.features.length
            ) {
                this.formControl.setErrors( { incorrect: true } );
            } else {
                this.formControl.setErrors( null );
            }
            if(
                this.field.templateOptions.map.numberOfItems.max &&
                this.field.templateOptions.map.numberOfItems.max < this.formControl.value.features.length
            ) {
                const temp = this.formControl.value as { type: 'string', features: any[] };
                temp.features.pop();
                this.formControl.setValue( temp );
                this.toastr.warning( this.field.templateOptions.map.errorMessage );
            }
        } else {
            this.formControl.setErrors( { incorrect: true } );
        }
    }

}
