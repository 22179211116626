import { Struttura } from '../../../../../interfaces/struttura';

export class CandidateMetaData {
    constructor( s: Struttura ) {
        this.owner = s.gestore;
        this.gestore_cod = s.gestore_cod;
        this.accepted = false;
        this.idActivity = '';
        this.group = s.group;
        this.idStruttura = s.id.toString();
        this.codiciAltroOperatore = s.operatori.map( o => o.operatore_TLC + ' - ' + o.office ).join( '/' );
        this.tipoGestore = s.tipo_Gestore;
        this.tipo = s.tipo;
        this.comune = s.comune;
        this.regione = s.regione;
        this.siglaProvincia = s.siglaProvincia;
        this.provincia = s.provincia;
    }

    owner: string;
    // tslint:disable-next-line:variable-name
    gestore_cod: string;
    state?: string;
    accepted: boolean;
    priority?: number;
    description?: string;
    idActivity: string;
    group?: any;
    id?: string;
    idStruttura?: string;
    codiciAltroOperatore?: string;
    tipoGestore?: string;
    dataRicezioneSAR?: Date;
    tipo?: string;
    indirizzo?: string;
    regione?: string;
    siglaProvincia?: string;
    comune?: string;
    provincia?: string;
    foglio?: string;
    mappale?: string;
    tempoRealizzazione?: number;
    offertaEconomica?: number;
    dataValutazioneRAN?: Date;
    dataValutazioneMSA?: Date;
    notes?: string;
}

