/* eslint-disable @typescript-eslint/unbound-method */

import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component( {
    selector: 'wnd-add-reason-dialog',
    templateUrl: './add-reason-dialog.component.html',
    styleUrls: [ './add-reason-dialog.component.scss' ]
} )
export class AddReasonDialogComponent {
    control = new FormControl( null );
    title = 'Richiesta Incompleta';
    label = 'Motivo del rifiuto';

    constructor(
        public dialogRef: MatDialogRef<AddReasonDialogComponent>,
        @Inject( MAT_DIALOG_DATA ) public data: { title?: string; label?: string; required: boolean }
    ) {
        if ( data ) {
            this.title = data.title || this.title;
            this.label = data.label || this.label;
            if ( data.required ) {
                this.control.setValidators( [ Validators.required ] );
            }
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }

    submitReason(): void {
        this.dialogRef.close( this.control.value );
    }

}
