import { ContrattoStatus } from '../../routes/contratti/models/contratto-status';
import { IContrattoElement } from '../../routes/contratti/models/contratto-element';

export interface State {
    viewingOffice?: string;
    contratti: { [ key: number ]: IContrattoElement };
    loading: boolean;
    listFilter: ContrattoStatus;
}

export const initialState: State = {
    contratti: {},
    loading: false,
    listFilter: 'ATTIVO',
};

export const featureKey = 'contratti';
