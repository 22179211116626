import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserManagerService } from 'app/routes/user-manager/services/user-manager.service';
import { ToastrService } from 'ngx-toastr';

@Component( {
    selector: 'app-register',
    templateUrl: './register.component.html',
} )
export class RegisterComponent implements OnInit {
    registerForm: UntypedFormGroup;
    public ruoli: string[];
    public passwordText = 'La password deve contenere almeno 8 caratteri, Uno Maiuscolo, Uno Minuscolo, Uno Speciale (!@?#)';

    constructor(
        private fb: UntypedFormBuilder,
        private userManagerService: UserManagerService,
        private toastrService: ToastrService,
        private router: Router ) {
        this.registerForm = this.fb.group( {
            username: [ '', [ Validators.required ] ],
            email: [ '', [ Validators.required, this.mailValidator ] ],
            password: [ '', [ Validators.required, this.passwordValidator ] ],
            confirmPassword: [ '', [ this.confirmValidator ] ],
            role: [ [], [] ]
        } );
        userManagerService.getAllAppRoles( 'no-ldap' ).subscribe( roles => this.ruoli = roles.filter( role => role !== 'Administrator' ) )
    }

    ngOnInit() {
    }

    confirmValidator = ( control: UntypedFormControl ): { [ k: string ]: boolean } => {
        if( !control.value ) {
            return { error: true, required: true };
        } else if( control.value !== this.registerForm.controls.password.value ) {
            return { error: true, confirm: true };
        }
        return {};
    };
    passwordValidator = ( control: UntypedFormControl ): { [ k: string ]: boolean } => {
        const regeex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
        if( !control.value ) {
            return { error: true, required: true };
        } else if( !regeex.test( control.value ) ) {
            return { error: true, check: true };
        }
        return {};
    }

    mailValidator = ( control: UntypedFormControl ): { [ k: string ]: boolean } => {
        const regeex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if( !control.value ) {
            return { error: true, required: true };
        } else if( !regeex.test( control.value ) ) {
            return { error: true, check: true };
        }
        return {};
    }

    register() {
        const { username, password, role, email } = this.registerForm.controls;
        this.userManagerService.register( username.value, password.value, role.value, email.value )
            .subscribe( result => {
                if( result === null ) {
                    this.toastrService.success( 'Registrazione avvenuta con successo aspetta la validazione di Admin' );
                    this.router.navigateByUrl( '/auth/login' );
                } else {
                    this.toastrService.error( result );
                }
            }, ( res ) => {
                this.toastrService.error( res.error );
            } );
    }
}
