import { createAction, props } from '@ngrx/store';
import { IRole } from '../../routes/user-manager/interfaces/role';
import { IPolicy } from '../../routes/user-manager/interfaces/policy';

export const setRoles = createAction( '[roles] set roles', props<{ roles: IRole[] }>() );
export const setRolesResetSelected = createAction( '[roles] set roles reset selected', props<{ roles: IRole[] }>() );
export const setPolicies = createAction( '[roles] set policies', props<{ policies: IPolicy[] }>() );
export const setRole = createAction( '[roles] set role', props<{ role: IRole }>() );

export const addRole = createAction( '[roles] add role', props<{ role: IRole }>() );
export const editRole = createAction( '[roles] edit role', props<{ role: IRole }>() );
export const changeRolePolicies = createAction( '[roles] change role policies', props<{ role: IRole; policies: IPolicy[] }>() );
export const loadRoles = createAction( '[roles] load roles' );
export const loadPolicies = createAction( '[roles] load policies' );
export const loadAll = createAction( '[roles] load all' );
export const selectRole = createAction( '[roles] select role', props<{ selectedRole: string | null }>() );
export const deleteSelectedRole = createAction( '[roles] delete selected role' );
