import { forkJoin, Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { StrutturaLight } from '../../../interfaces/struttura_light';
import { Router } from '@angular/router';
import { ApiPlansService } from '@piani/services/api-plans.service';
import { IOfficePlan } from '@piani/models/office-plan';
import { ApiOfficesService } from '@shared/services/api-offices.service';
import { IOffice } from '@shared/interfaces/office';
import { ApiDustService } from '@shared/services/api-dust.service';
import { filter, map } from 'rxjs/operators';

@Component( {
    selector: 'app-place-details',
    templateUrl: './place-details.component.html',
    styleUrls: [ './place-details.component.scss' ],
} )
export class PlaceDetailsComponent implements OnInit, OnDestroy {
    @Input() place: StrutturaLight;
    office: IOffice;
    plans: IOfficePlan[];
    subscriptions = new Subscription();

    constructor( private cdr: ChangeDetectorRef,
                 private router: Router,
                 private zone: NgZone,
                 private apiPlans: ApiPlansService,
                 private apiOffices: ApiOfficesService,
                 private apiDust: ApiDustService,
    ) {
    }

    ngOnInit() {
        //console.log( 'Viewing office', this.place );
        if( this.place.officeType === 'STRUTTURE' ) {
            this.apiDust.getStrutturaById( Number.parseInt( this.place.id, 10 ) ).pipe(
                filter( s => s?.length > 0 ),
                map( ( [ s ] )  => ( {
                    name: s.nome_vodafone,
                    office: s.office_vodafone,
                    address: null, // s.sarf_name,
                    city: s.comune_vf,
                    pr: s.pr_vf,
                    province: s.prov,
                    frequencies: [],
                    region: s.regione,
                    zone: s.zona,
                    owner: s.proprietario_struttura,
                    structureType: s.tipo_struttura,
                    housingType: s.tipo_housing,
                    officeType: 'STRUTTURE',
                    ko: null,
                    topANPO: s.top_anpo,
                    connettivita: null,
                    fibra: null
                } ) )
            ).subscribe( o => {
                this.office = o;
                this.cdr.detectChanges();
            } );
        } else if( this.place.officeType !== 'TRASFERIMENTI' ) {
            if( this.place?.id && typeof this.place?.id == 'string' ) {
                this.subscriptions.add( forkJoin( [
                    this.apiPlans.getOfficePlans( 2023, this.place.id ),
                    this.apiOffices.getOffice( this.place.id ),
                ] ).subscribe( ( [ plans, office ] ) => {
                    //console.log( 'Office data:', office, plans );
                    this.office = office;
                    this.plans = plans.data;
                    this.cdr.detectChanges();
                } ) );
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    openDialog() {
        this.zone.run( () => {
            void this.router.navigate( [ 'nd', 'office-list', 'struttura-details' ] ).then();
        } );
    }
}
