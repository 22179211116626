import { IInputTypeaheadDataSource } from '@shared/components/input-typeahead/interfaces/input-typeahead-data-source';
import { BehaviorSubject, Observable } from 'rxjs';

const DUMMY_LIST = [
    'lorem', 'ipsum', 'dolor', 'sit', 'amet', 'consectetur', 'adipiscing', 'elit'
];

export class DummyDataSource implements IInputTypeaheadDataSource {
    list = new BehaviorSubject( DUMMY_LIST );
    list$ = this.list.asObservable();

    connect(): Observable<string[]> {
        return this.list$;
    }

    disconnect(): void {
    }

    setFilter( filter: string ): void {
        this.list.next( filter ? DUMMY_LIST.filter( s => s.includes( filter.toLowerCase() ) ) : DUMMY_LIST );
    }
}
