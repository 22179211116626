import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from './models';
import { NOTIFICATIONS_URL } from '@shared/models/urls-constants';

@Injectable( {
    providedIn: 'root',
} )
export class NotificationsService {

    constructor( private http: HttpClient, @Inject( NOTIFICATIONS_URL ) private baseUrl: string ) {
    }

    retrieveNotifications(): Observable<Notification[]> {
        const headers = new HttpHeaders( { ignoreProgressBar: '' } );
        return this.http.get<Notification[]>( `${ this.baseUrl }notification`, { headers } );
    }

    retrieveUnreadNotifications(): Observable<Notification[]> {
        const headers = new HttpHeaders( { ignoreProgressBar: '' } );
        return this.http.get<Notification[]>( `${ this.baseUrl }notification/unread`, { headers } );
    }

    createNotification( message: string, users: string[] ) {
        const headers = new HttpHeaders( { ignoreProgressBar: '' } );
        return this.http.post( `${ this.baseUrl }notification`, { notificationText: message, recipientUsernames: users }, { headers } );
    }

    readNotification( id: number ) {
        const headers = new HttpHeaders( { ignoreProgressBar: '' } );
        return this.http.patch( `${ this.baseUrl }notification/confirm`, { notificationIDs: [ id ] }, { headers } );
    }

    // TODO: Add implementation
    readAllNotifications() {
        return this.http.patch( '', { notificationIDs: [ -1 ] } );
    }
}
