<mat-form-field class="wonder-autocomplete-field-wrapper"> <!-- [ngClass]="{ 'full-width': fullWidth }" -->
    <input matInput [matAutocomplete]="auto" [placeholder]="label" [formControl]="control" (blur)="markAsTouched(); onValueChange()"/>
    <mat-autocomplete (optionSelected)="elementSelected( $event )" #auto="matAutocomplete">
        <cdk-virtual-scroll-viewport [itemSize]="24" [ngStyle]="{ height: panelListHeight }" >
            <mat-option *cdkVirtualFor="let value of autoCompleteList$ | async" [value]="value">
                {{ value }}
            </mat-option>
        </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
    <button mat-button *ngIf="control.value && !control.disabled" matSuffix mat-icon-button aria-label="Clear" (click)="control.setValue('')">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
