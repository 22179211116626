/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument */
// noinspection SpellCheckingInspection

import { transformJsonToLayer, transformLayerToJson } from '@shared/components/osm-draw-map/custom-leaflet-function';
// noinspection JSDeprecatedSymbols
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, EventEmitter, Injector, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import * as L from 'leaflet';
import { DivIcon, DrawEvents, featureGroup, FeatureGroup, latLng, LeafletEvent, LeafletMouseEvent, Map, marker, Marker, MarkerClusterGroup, MarkerClusterGroupOptions, tileLayer } from 'leaflet';
import { SearchService } from 'app/routes/nd/services/search.service';

import { Subscription } from 'rxjs';
import { CustomMapIcon } from './icons-svg';
import { Config } from './map-draw-config-service.service';
import { fixMarker } from './custom-leaflet-function';
import { PlaceDetailsComponent } from 'app/routes/nd/struttura-list/office-list/place-details/place-details.component';
import { IOfficeSearchView } from '../../../routes/nd/interfaces/office-search-view';
import { OfficesService } from '@shared/services/offices.service';
import { StrutturaLight } from '../../../routes/nd/interfaces/struttura_light';
import 'leaflet.polylinemeasure';

interface MarkerMetaData {
    id: string;
    markerInstance: Marker;
}

@Component( {
    selector: 'osm-draw-map',
    templateUrl: './osm-draw-map.component.html',
    styleUrls: [ './osm-draw-map.component.scss' ],
} )
export class OsmDrawMapComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() map$ = new EventEmitter<Map>();
    @Output() zoom$ = new EventEmitter<number>();
    @Output() mapClick$ = new EventEmitter<LeafletMouseEvent>();
    @Output() clickOnOffice = new EventEmitter<StrutturaLight | IOfficeSearchView>();
    @Output() mapDblClick$ = new EventEmitter<LeafletMouseEvent>();
    @Output() markerClusterGroup$ = new EventEmitter<MarkerClusterGroup>();
    @Output() featureCollectionChange = new EventEmitter<any>();
    @Output() changingModeChange = new EventEmitter<any>();
    @Output() featureGroupChange = new EventEmitter<{ type: string; group: FeatureGroup }>();
    @Input() mapHeight = '69vh';
    @Input() maxWidth = '100%';
    @Input() center: { coords: L.LatLng; zoom: number };
    @Input() showGeoSearch = true;
    @Input() drawConfig: Config;
    @Input() isMainMap = false;
    @Input() heightPadding = 16;
    @Input() showTBD = false;

    public showCoordInput = false;
    public options: L.MapOptions;
    public map: Map;
    public zoom: number;
    markerClusterGroup: MarkerClusterGroup;
    markerClusterData: Marker[] = [];
    markerClusterOptions: MarkerClusterGroupOptions;
    markerClusterMetaData: { instance: Marker; id: string }[] = [];
    lastRemoveClusterMarker: { instance: Marker; id: string };
    drawItemGroup = featureGroup();
    layersControl: any;
    isMeasureControlEnabled = false;
    public drawControl: L.Control.Draw;
    // noinspection JSDeprecatedSymbols
    private readonly factory: ComponentFactory<any>;
    private mapUpdateSub: Subscription;
    private markersMetaData: MarkerMetaData[];

    // noinspection JSDeprecatedSymbols
    constructor(
        private resolver: ComponentFactoryResolver,
        public searchService: SearchService,
        //private _sidebarStateService: SidebarStateService,
        private injector: Injector,
        private cdr: ChangeDetectorRef,
        private officeService: OfficesService,
        private zone: NgZone,
    ) {
        this.factory = this.resolver.resolveComponentFactory( PlaceDetailsComponent );
    }

    @Input()
    set featureCollection( val: any ) {
        if( val ) {
            if( val instanceof FeatureGroup ) {
                fixMarker( val )
                    .getLayers()
                    .forEach( l => {
                        this.drawItemGroup.addLayer( l );
                    } );
                const lys = val.getLayers();
                this.drawItemGroup.getLayers().forEach( m => {
                    if( !lys.includes( m ) ) {
                        this.drawItemGroup.removeLayer( m );
                    }
                } );
            } else {
                this.drawItemGroup.clearLayers();
                fixMarker( transformJsonToLayer( val ) )
                    .getLayers()
                    .forEach( l => {
                        this.drawItemGroup.addLayer( l );
                    } );
            }
        } else {
            this.drawItemGroup.clearLayers();
        }
    }

    ngOnInit() {
        this.searchService.wantTBD( this.showTBD );
        this.layersControl = {
            baseLayers: {
                base: tileLayer(
                    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    {
                        opacity: 1,
                        maxZoom: 19,
                        detectRetina: false,
                        attribution:
                            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    },
                ),
                light: tileLayer(
                    'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
                    {
                        opacity: 1,
                        maxZoom: 19,
                        detectRetina: false,
                        attribution:
                            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    },
                ),
                dark: tileLayer(
                    'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
                    {
                        opacity: 1,
                        maxZoom: 19,
                        detectRetina: false,
                        attribution:
                            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    },
                ),
                toner: tileLayer(
                    'http://tile.stamen.com/toner/{z}/{x}/{y}.png',
                    {
                        opacity: 1,
                        maxZoom: 19,
                        detectRetina: false,
                        attribution:
                            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    },
                ),
                topo: tileLayer(
                    'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
                    {
                        opacity: 1,
                        maxZoom: 17,
                        detectRetina: false,
                        attribution:
                            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    },
                ),
                orto: tileLayer(
                    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    {
                        opacity: 1,
                        maxZoom: 19,
                        detectRetina: false,
                        attribution:
                            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    },
                ),
            },
        };


        this.options = {
            layers: [
                this.layersControl.baseLayers.base,
            ],
            zoom: this.center.zoom,
            center: this.center.coords,
            // measureControl: true,
        };
        if( this.drawConfig.showControls ) {
            this.drawConfig.drawOptions.edit.featureGroup = this.drawItemGroup;
        }
        this.markerClusterData = [];
        this.markersMetaData = [];
    }

    ngAfterViewInit() {
        this.mapUpdateSub = this.searchService.structureInventory$.subscribe( offices => {
            this.setClusterData( offices, this.factory );
            this.cdr.detectChanges();
        } );
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        this.map.clearAllEventListeners();
        //this.map.remove();
        this.drawItemGroup.clearLayers();
        this.mapUpdateSub.unsubscribe();
    }

    onMapReady( map: Map ): void {
        this.map = map;
        this.map$.emit( map );
        this.zoom = map.getZoom();
        this.zoom$.emit( this.zoom );
        L.control.polylineMeasure().addTo( map );

        map.on( 'polylinemeasure:toggle', () => {
            this.isMeasureControlEnabled = !this.isMeasureControlEnabled;
            if( this.isMeasureControlEnabled ) {
                const overlayPane = document.querySelector( '.leaflet-overlay-pane' );
                ( overlayPane as HTMLElement ).style.zIndex = '1000';
            } else {
                const overlayPane = document.querySelector( '.leaflet-overlay-pane' );
                ( overlayPane as HTMLElement ).style.zIndex = '400';
            }
        } );

        setTimeout( () => {
            this.map.invalidateSize();
        }, 150 );
    }

    onMapZoomEnd( e: LeafletEvent ) {
        this.zoom = e.target.getZoom();
        this.zoom$.emit( this.zoom );
        this.map$.emit( this.map );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onMapMoveEnd( _e: LeafletEvent ) {
        this.map$.emit( this.map );
    }

    markerClusterReady( group: MarkerClusterGroup ) {
        this.markerClusterGroup = group;
        this.markerClusterGroup$.emit( group );
    }

    fixSize() {
        setTimeout( () => {
            if( typeof this.map !== 'undefined' ) {
                this.map.invalidateSize();
            }
        }, 200 );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onResize( _event: LeafletEvent ) {
        this.fixSize();
    }

    setView( coords: L.LatLng, radius: number = 500 ) {
        const bounds = coords.toBounds( radius ); // 500 = metres
        this.map.panTo( coords ).fitBounds( bounds );
    }

    removeMarkers( ids: string[] ) {
        const markerIds = this.markersMetaData.filter( m => ids.includes( m.id ) );
        markerIds.forEach( m => {
            this.markersMetaData.splice( this.markersMetaData.indexOf( m ), 1 );
            m.markerInstance.removeFrom( this.map );
        } );
    }

    // noinspection JSUnusedGlobalSymbols
    getMarkerById( id: string ): L.LatLng {
        if( this.markersMetaData.length === 0 ) {
            return null;
        }
        return this.markersMetaData.filter( m => m.id === id )[ 0 ].markerInstance.getLatLng();
    }

    /**
     * This function is called to set a marker of the viewing office. When the user is looking at the popup with office details.
     *
     * @param coords
     * @param icon
     * @param factory
     */
    // noinspection JSDeprecatedSymbols
    addMarkers( coords: IOfficeSearchView[], icon = CustomMapIcon.newPlaceIcon, factory?: ComponentFactory<any> ) {
        //[id,lat,lon]
        coords.forEach( c => {
            const m = marker( [ c.lat, c.lon ], {
                icon,
            } );
            if( factory ) {
                m.on( 'click', () => this.isMeasureControlEnabled ? null : this.clickOnMarker( m, factory, c ) );
            }
            m.addTo( this.map );
            const actualMarker: MarkerMetaData = {
                id: c.office,
                markerInstance: m,
            };
            this.markersMetaData.push( actualMarker );
        } );
    }

    markMarkerAsSearched( item: IOfficeSearchView, radius: number = 350 ) {
        if( false ) {
            if( this.lastRemoveClusterMarker ) {
                this.markerClusterGroup.addLayer( this.lastRemoveClusterMarker.instance );
                this.markerClusterMetaData.push( this.lastRemoveClusterMarker );
                this.removeMarkers( [ this.lastRemoveClusterMarker.id ] );
            }
            const indexItem = this.markerClusterMetaData.indexOf(
                this.markerClusterMetaData.filter( x => x.id === item.office )[ 0 ],
            );
            // console.log( 'markMarkerAsSearched index', indexItem, item, this.markerClusterMetaData );
            this.markerClusterGroup.removeLayer( this.markerClusterMetaData[ indexItem ].instance );
            this.addMarkers( [ item ], CustomMapIcon.searchIcon, this.factory );

            this.lastRemoveClusterMarker = this.markerClusterMetaData.splice( indexItem, 1 )[ 0 ];
        }
        this.setView( latLng( item.lat, item.lon ), radius );
    }

    // noinspection JSDeprecatedSymbols
    setClusterData( coords: StrutturaLight[], factory: ComponentFactory<any>, reset = true ) {
        if( reset ) {
            this.markerClusterData = [];
            this.markerClusterMetaData = [];
        }
        coords.forEach( c => {
            let icon = CustomMapIcon.templateIcon;
            switch( c.officeType ) {
            case 'PONTE RADIO':
                icon = CustomMapIcon.ponteRadioIcon;
                break;
            case 'MACRO':
                icon = CustomMapIcon.macroIcon;
                break;
            case 'ALARM':
                icon = CustomMapIcon.alarmIcon;
                break;
            case 'SMALL':
                icon = CustomMapIcon.smallIcon;
                break;
            case 'PONTE RADIO + ALARM':
                icon = CustomMapIcon.ponteRadioAlarmIcon;
                break;
            case 'STRUTTURE':
                icon = CustomMapIcon.struttureIcon;
                break;
            case 'TRASFERIMENTI':
                icon = CustomMapIcon.trasferimentiIcon;
                break;
            default:
                break;
            }

            this.setOneClusterMarker( icon, factory, c );
        } );
    }

    public onDrawCreated( e: any ) {
        this.drawItemGroup.addLayer( ( e as DrawEvents.Created ).layer );
        this.featureCollectionChange.emit( {
            type: 'add',
            features: transformLayerToJson( this.drawItemGroup ),
        } );
        this.featureGroupChange.emit( {
            type: 'add',
            group: this.drawItemGroup,
        } );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onDrawDeleted( _e: any ) {
        this.featureCollectionChange.emit( {
            type: 'delete',
            features: transformLayerToJson( this.drawItemGroup ),
        } );
        this.featureGroupChange.emit( {
            type: 'delete',
            group: this.drawItemGroup,
        } );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onDrawEdited( _e: any ) {
        this.featureCollectionChange.emit( {
            type: 'edit',
            features: transformLayerToJson( this.drawItemGroup ),
        } );
        this.featureGroupChange.emit( {
            type: 'edit',
            group: this.drawItemGroup,
        } );
    }

    public onChangingMode( e: any ) {
        if( e.type === 'draw:drawstart' ) {
            this.showCoordInput = true;
        }

        if( !this.showCoordInput ) {
            this.changingModeChange.emit( e );
        }
    }

    onDrawReady( drawControl: L.Control.Draw ) {
        this.drawControl = drawControl;
    }

    markerInputEnd() {
        this.showCoordInput = false;
    }

    /**
     * This is called when setting up the marker for the main map.
     *
     * @param icon
     * @param factory
     * @param c
     * @private
     */
    // noinspection JSDeprecatedSymbols
    private setOneClusterMarker( icon: DivIcon, factory: ComponentFactory<any>, c: StrutturaLight ) {
        const m = marker( [ c.lat, c.lon ], {
            icon,
        } ).on( 'click', () => this.clickOnMarker( m, factory, c ) );
        this.markerClusterData.push( m );
        this.markerClusterMetaData.push( { instance: m, id: c.id } );
    }

    // noinspection JSDeprecatedSymbols
    private clickOnMarker = ( m: L.Marker, factory: ComponentFactory<any>, c: StrutturaLight | IOfficeSearchView ) => {
        // console.log( 'Clicked on marker', m, c );
        this.clickOnOffice.emit( c );
        if( this.isMainMap ) {
            this.zone.run( () => this.officeService.showOfficesDetails( c ) );
        } else {
            const componentInstance = factory.create( this.injector );
            componentInstance.instance.place = c;
            componentInstance.changeDetectorRef.detectChanges();
            const popupContent = componentInstance.location.nativeElement;
            setTimeout( () => this.map.setView( m.getLatLng() ) );

            m.bindPopup( popupContent );
            m.openPopup();
            m.getPopup().on( 'remove', () => {
                m.unbindPopup();
                componentInstance.destroy();
            } );
        }
    };
}
