<mat-card style="height: 100%;">

    <mat-card-title fxLayout="row" fxLayoutAlign="center center">Benvenuto</mat-card-title>
    <mat-card-content class="m-t-32">
        <div fxLayout="row" fxLayoutAlign="center center">
            <img mat-card-image src="/assets/images/wonder_logo.svg"
                 alt="Wonder Logo" class="img-card" height="75%"/>
        </div>
        <div
                fxLayout="row wrap"
                fxLayoutAlign="space-between center"
                class="m-t-16">
            <div
                    fxLayout="column"
                    fxFlex.gt-lg="25%"
                    fxFlex="50%"
                    fxLayoutAlign="center center">
                <mat-icon aria-hidden="false" aria-label="Monitoring icon"
                          class="icon-size m-16">settings_input_antenna
                </mat-icon>
                <p>Networking</p>
            </div>
            <div
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    fxFlex.gt-lg="25%"
                    fxFlex="50%">
                <mat-icon aria-hidden="false" aria-label="Monitoring icon"
                          class="icon-size m-16">dvr
                </mat-icon>
                <p>Monitoring</p>
            </div>
            <div
                    fxLayout="column"
                    fxFlex.gt-lg="25%"
                    fxFlex="50%"
                    fxLayoutAlign="center center">
                <mat-icon aria-hidden="false" aria-label="Monitoring icon"
                          class="icon-size m-16">map
                </mat-icon>
                <p>Localization</p>
            </div>
            <div
                    fxLayout="column"
                    fxFlex.gt-lg="25%"
                    fxFlex="50%"
                    fxLayoutAlign="center center">
                <mat-icon aria-hidden="false" aria-label="Monitoring icon"
                          class="icon-size m-16">account_tree
                </mat-icon>
                <p>Workflow</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="lp-text">
            <div fxLayout="column">
                <p>
                    <abbr title="Workflow Network Deployment Review"> WoNDer</abbr> è un applicativo moderno e veloce, realizzato dal gruppo <strong>Vodafone
                </strong>di<strong> Network
                    Deployment</strong>, per la gestione, il monitoraggio, la geo localizzazione e la
                    definizione
                    dei flussi e dei processi che coinvolgono la realizzazione dei siti radio-base.
                </p>
                <p *ngIf="!requested && !rejected">
                    La tua procedura di registrazione al sito è quasi completa: richiedi un ruolo per effettuare l'accesso a woNDer,
                    l'amministratore del sistema provvederà ad abilitarti.
                </p>
                <p *ngIf="requested">
                    La tua richiesta di un ruolo è in fase di abilitazione. Torna più tardi per vedere se è stata accettata.
                </p>
                <p *ngIf="rejected" class="rejected">
                    La tua richiesta è stata rifiutata. Contatta l'amministratore del sistema per conoscere le ragioni ed, eventualmente, modificare la richiesta.
                </p>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <button *ngIf="!requested && !rejected"
                mat-raised-button
                id="settings-btn"
                color="primary"
                (click)="onRoleRequest()">
            Richiedi un ruolo
        </button>
    </mat-card-actions>
</mat-card>
