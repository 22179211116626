import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component( {
    selector: 'wnd-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: [ './confirm-dialog.component.scss' ]
} )
export class ConfirmDialogComponent {
    title: string;
    message: string;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject( MAT_DIALOG_DATA ) private data: { title: string; message: string } ) {
        this.title = this.data.title;
        this.message = this.data.message;
    }

    close( result: boolean ) {
        this.dialogRef.close( result );
    }
}
