import { Component, OnInit } from '@angular/core';
import { GeoSyncCoordinates } from '@shared/geo-sync/interfaces/geo-sync-coordinates';
import { FieldType } from '@ngx-formly/core';
import { GeoSyncAll } from '@shared/geo-sync/interfaces/geo-sync-all';
import { GeoSyncEvent } from '@shared/components/geo-sync/geo-sync-event';

@Component( {
    selector: 'app-geo-sync-field',
    templateUrl: './geo-sync-field.component.html',
    styleUrls: [ './geo-sync-field.component.scss' ],
} )
export class GeoSyncFieldComponent extends FieldType implements OnInit {
    coordinates: GeoSyncCoordinates;
    initData: GeoSyncAll;
    private updating = false;

    constructor() {
        super();
    }

    ngOnInit(): void {
        console.log( 'GeoSyncField', this.field, this.field.formControl.value, this.formControl.value );
        this.formControl.valueChanges.subscribe( v => {
            if( !this.updating ) {
                this.initData = v;
                if( v?.coordinates ) {
                    this.coordinates = v.coordinates;
                }
            }
            console.log( 'GeoSync value changed', v );
        } );
    }

    changes( value: GeoSyncEvent ) {
        console.log( 'GeoSync changes', value );
        this.updating = true;
        if( value.eventType === 'comune' ) {
            this.formControl.setValue( value.object );
        }
        setTimeout( () => this.updating = false );
    }

    onStatusChanges( status: string ) {
        if( status === 'VALID' ) {
            this.formControl.setErrors( null );
        } else if( status === 'INVALID' ) {
            this.formControl.setErrors( { locationInvalid: true } );
        }
    }
}
