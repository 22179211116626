import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import { Menu, MenuService } from '@core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TopmenuState } from './topmenu.component';

@Component( {
    selector: 'app-topmenu-panel',
    templateUrl: './topmenu-panel.component.html',
} )
export class TopmenuPanelComponent implements OnInit, OnDestroy {
    @ViewChild( 'menu', { static: true } ) menu: MatMenu;

    @Input() items: Menu[] = [];
    @Input() parentRoute = [];
    @Input() level = 1;
    @Output() routeChange = new EventEmitter<any>();

    menuStates: TopmenuState[] = [];

    private routerSubscription: Subscription;

    constructor( public menuSrv: MenuService, private router: Router ) {
    }

    buildRoute = ( route: string[] ) => this.menuSrv.buildRoute( route );

    ngOnInit() {
        this.items.forEach( item => {
            this.menuStates.push( { active: this.checkRoute( item ), route: item.route } );
        } );
    }

    ngOnDestroy() {
        this.routerSubscription?.unsubscribe();
    }

    checkRoute( item: Menu ) {
        if( !item.route ) {
            return this.checkChildRoute( item.children );
        } else {
            return this.router.url.split( '/' ).includes( item.route );
        }
    }

    checkChildRoute( menuItems: Menu[] ) {
        return menuItems.some( child => {
            if( this.router.url.split( '/' ).includes( child.route ) ) {
                return true;
            }
            if( !child.route && child.children ) {
                this.checkChildRoute( child.children );
            }
        } );
    }

    onRouterLinkClick( rla: RouterLinkActive ) {
        this.routeChange.emit( rla );
    }

    onRouteChange( rla: RouterLinkActive, index: number ) {
        this.routeChange.emit( rla );

        this.routerSubscription?.unsubscribe();
        this.routerSubscription = this.router.events
            .pipe( filter( event => event instanceof NavigationEnd ) )
            .subscribe( () => {
                this.menuStates.forEach( item => ( item.active = false ) );
                setTimeout( () => ( this.menuStates[ index ].active = rla.isActive ) );
            } );
    }
}
