import { filter, map } from 'rxjs/operators';

import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { TusSingleFileUploader, UploadService } from '@shared/services/upload.service';
import { Subscription } from 'rxjs';

export interface UploaderConfig {
    allowedTypes?: string[];
    maxSize?: number;
    metadata?: object | ( () => object );
    lazyStart?: boolean;
}

@Directive( {
    selector: '[tus-uploader]',
} )
export class TusUploaderDirective implements OnInit, OnDestroy {
    inputEl: HTMLInputElement | HTMLDivElement;
    @Input( 'tus-uploader' ) config: UploaderConfig;
    @Input() numberOfSelections = 1;
    private subscription: Subscription;
    private uploadService: UploadService;
    private clusterUid: number;
    @Output() selectionError = new EventEmitter<string>();
    @Output() uploadEvents = new EventEmitter<{
        source: TusSingleFileUploader;
        uploaders: TusSingleFileUploader[];
    }>();
    @Output() uploaded = new EventEmitter<{
        source: TusSingleFileUploader;
        uploaders: TusSingleFileUploader[];
    }>();

    constructor( el: ElementRef, uploadService: UploadService ) {
        this.inputEl = el.nativeElement;
        this.uploadService = uploadService;
    }

    ngOnInit(): void {
        this.clusterUid = this.uploadService.getNewClusterUid();
        if( this.inputEl instanceof HTMLInputElement ) {
            this.inputEl.type = 'file';
            this.inputEl.multiple = this.numberOfSelections > 1;
            this.inputEl.accept = !!this.config?.allowedTypes ? this.config.allowedTypes.join( ',' ) : '/*';
            this.inputEl.addEventListener( 'change', event => this.addUploader( event ) );
        }
        if( this.inputEl instanceof HTMLDivElement ) {
            this.inputEl.ondragover = evt => {
                evt.preventDefault();
                evt.stopPropagation();
            };
            this.inputEl.ondragenter = evt => {
                evt.preventDefault();
                evt.stopPropagation();
            };
            this.inputEl.addEventListener( 'drop', event => this.addUploaderDiv( event ) );
        }

        this.subscription = this.uploadEvent().subscribe( e => {
            this.uploadEvents.emit( e );
        } );
    }

    ngOnDestroy(): void {
        if( this.inputEl instanceof HTMLElement ) {
            this.inputEl.removeEventListener( 'change', event => this.addUploader( event ) );
        }
        if( this.inputEl instanceof HTMLDivElement ) {
            this.inputEl.removeEventListener( 'drop', event => this.addUploaderDiv( event ) );
        }

        if( this.subscription ) {
            this.subscription.unsubscribe();
        }
    }

    private addUploader( event ) {
        const files = event.target.files as FileList;
        this.addUploaderBase( files );
    }

    private addUploaderDiv( event: DragEvent ) {
        event.preventDefault();
        event.stopPropagation();
        const files = event.dataTransfer.files as FileList;
        this.addUploaderBase( files );
    }

    private async addUploaderBase( files: FileList ) {
        if( files.length > this.numberOfSelections ) {
            this.selectionError.next( 'Attenzione. Numero massimo di file selezionabili ' );
        } else {
            let errore = false;
            // tslint:disable-next-line:prefer-for-of
            for( let i = 0; i < files.length; i++ ) {
                if( this.isAllowedSize( files[ i ] ) && this.isAllowedType( files[ i ] ) ) {
                    const uploadingFile = await this.uploadService.addFileUploader( files[ i ], files[ i ].name, this.clusterUid );
                    let metadata;
                    if( typeof this.config.metadata === 'function' ) {
                        metadata = this.config.metadata();
                    } else {
                        metadata = this.config.metadata;
                    }


                    uploadingFile.addMetadata( metadata );
                } else {
                    errore = true;
                }
            }
            if( errore ) {
                this.selectionError.next(
                    `Attenzione. Alcuni file selezionati superano la dimensione massima
           o hanno un tipo vietato.
           inserire file di tipo: ${ this.config?.allowedTypes }`,
                );
            }
            if( !this.config.lazyStart ) {
                this.uploadService.startAllInCluster( this.clusterUid );
            }
        }
    }

    private isAllowedType( file: File ) {
        if( !this.config?.allowedTypes ) {
            return true;
        }
        if( !file.name.includes( '.' ) ) {
            return false;
        }
        const ext = ( file.name.split( '.' ).slice( -1 )[ 0 ] ).toLowerCase();
        return this.config.allowedTypes.map( a => a.toLowerCase() ).includes( '.' + ext );
    }

    private isAllowedSize( file: File ) {
        if( !this.config?.maxSize ) {
            return true;
        }
        return file.size <= this.config.maxSize;
    }

    private uploadEvent() {
        return this.uploadService.uploadersChanges.pipe(
            filter( fs => fs.source.clusterUid === this.clusterUid ),
            map( fs => {
                return {
                    source: fs.source,
                    uploaders: fs.uploaders.filter( f => f.clusterUid === this.clusterUid ),
                };
            } ),
        );
    }
}
