import { WndGridColumnQueryEvent, WndGridColumnSearchType } from './../helpers/wnd-grid-column-search';
import { HttpClient } from '@angular/common/http';
import { WndGridColumnOptions } from './../helpers/wnd-grid-column-options';
import { WndGridColumnSearch } from '../helpers/wnd-grid-column-search';
import { Injectable } from '@angular/core';
import { WndGridColumn } from '../interfaces/wnd-grid-column';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { WndGridService } from '@shared/components/wonder-grid/services/wnd-grid.service';
import { Store } from '@ngrx/store';
import { GridFiltersActions, GridFiltersSelectors } from '@store/grid-filters-store';
import { IGridFiltersCollection } from '@store/grid-filters-store/state';
import { cloneDeep } from 'lodash';


@Injectable()
export class WndGridColumnService {

    columnSearch: WndGridColumnSearch;
    columnOptions: WndGridColumnOptions;

    private _column: WndGridColumn;

    private filterSet = false;
    private gridName?: string;

    constructor( private http: HttpClient, private gridService: WndGridService, private store: Store ) {
    }

    init( column: WndGridColumn, gridName?: string ) {
        this.gridName = gridName;
        this._column = column;
        this.columnSearch = new WndGridColumnSearch( this.store, this.gridName, column );
    }

    get column() {
        return this._column;
    }


    initColumnOptionsService( rangeSource: string ) {
        this.columnOptions = new WndGridColumnOptions( 20, this.column.fieldName, rangeSource, this.http, this.column.cacheOnStart, this.gridService );
    }

    resetFilters() {
        this.columnSearch.resetFilters();
    }

    get type() {
        return this.columnSearch.column.type;
    }

    get fieldName() {
        return this.columnSearch.column.fieldName;
    }

    get displayName() {
        return this.columnSearch.column.displayName;
    }

    get filterEvents(): Observable<WndGridColumnFilterEvent> {
        const initial = this.getFilterFromStore().pipe(
            map( f => f.filter )
        );
        return merge( initial, this.columnSearch.filters, this.columnOptions.inListFilter ).pipe(
            map( ( e ) => ( {
                event: e,
                sourceField: this.fieldName,
            } as WndGridColumnFilterEvent ) ),
            distinctUntilChanged( ( x, y ) => x.event.arguments.length === 0 && y.event.arguments.length === 0 ),
            tap( e => this.store.dispatch( GridFiltersActions.setFilter( { grid: this.gridName, column: e.sourceField, filter: cloneDeep( e.event ) } ) ) ),
            tap( e => {
                this.filterSet = e.event.arguments.length !== 0;
            } ),
        );
    }

    get anyFilters() {
        return this.filterSet;
    }

    inputType() {
        if( !this.column?.type || this.column?.type === 'text' ) {
            return 'text';
        }
        if( this.column.type === 'number' ) {
            return 'number';
        }
        if( this.column.type === 'boolean' ) {
            return 'text';
        }
        return 'text';
    }

    private getFilterFromStore(): Observable<IGridFiltersCollection> {
        return this.store.select( GridFiltersSelectors.selectColumnFilter( this.gridName, this.column.fieldName ) ).pipe(
            take( 1 ),
            map( f => f ?? { gridColumnName: `${ this.gridName }:${ this.column.fieldName }`, filter: { type: WndGridColumnSearchType.EMPTY, arguments: [] } } ),
        );
    }
}


export interface WndGridColumnFilterEvent {
    event: WndGridColumnQueryEvent;
    sourceField: string;
}
