import { Component, Input, OnInit } from '@angular/core';
import { ApiDustService } from '@shared/services/api-dust.service';
import { Observable } from 'rxjs';
import { IStruttura } from '@shared/models/struttura';

@Component( {
    selector: 'wnd-struttura-details',
    templateUrl: './struttura-details.component.html',
    styleUrls: [ './struttura-details.component.scss' ]
} )
export class StrutturaDetailsComponent implements OnInit {
    @Input() strutturaId: string;
    @Input() officeType: string;

    struttura$: Observable<IStruttura>;

    constructor( private api: ApiDustService ) {
    }

    ngOnInit(): void {
        if( this.strutturaId ) {
            this.struttura$ = this.officeType === 'STRUTTURE' ? this.api.getStruttura( this.strutturaId ) : this.api.getTrasferimento( this.strutturaId );
        }
    }
}
