<div class="wnd-grid-option-list-wrapper" fxLayout="column" fxLayoutAlign="start">
    <mat-form-field class="wnd-grid-option-list-textbox">
        <input (focus)="focus()" (blur)="blur()" [formControl]="searchControl" matInput placeholder="Search..."/>
        <mat-checkbox
                [checked]="this.columnService.columnOptions.isFilterActive"
                matSuffix
                [matTooltip]="checkBoxTooltip"
                (change)="toggleFilter($event)"
        ></mat-checkbox>
    </mat-form-field>

    <cdk-virtual-scroll-viewport [itemSize]="columnService.columnOptions.unitSize">
        <mat-list class="wnd-grid-option-list" multiple>
            <ng-container *cdkVirtualFor="let item of this.columnService.columnOptions.items">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-checkbox
                            [checked]="isSelected(item)"
                            (change)="onChange($event, item)"
                    ></mat-checkbox>
                    <mat-list-item> {{ item }} </mat-list-item>
                </div>
            </ng-container>
            <div class="wnd-grid-option-list-spinner" *ngIf="!columnService.columnOptions.semaphore && columnService.columnOptions.isFilterActive">
                <mat-progress-spinner [mode]="'indeterminate'" [diameter]="fixedUnitSize">
                </mat-progress-spinner>
            </div>
        </mat-list>
    </cdk-virtual-scroll-viewport>
</div>

<!--
<button (click)="dub()">Dub</button>

<button (click)="log()">Log</button> -->
