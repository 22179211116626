import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { IGridFiltersCollection } from './state';
import { createReducer, on } from '@ngrx/store';
import { GridFiltersActions } from '@store/grid-filters-store/index';
import { WndGridColumnSearchType } from '@shared/components/wonder-grid/helpers/wnd-grid-column-search';
import { cloneDeep } from 'lodash';

const selectId = ( f: IGridFiltersCollection ) => f.gridColumnName;

export const adapter: EntityAdapter<IGridFiltersCollection> = createEntityAdapter<IGridFiltersCollection>( { selectId } );

export const initialState = adapter.getInitialState( {} );

export const reducers = createReducer( initialState,
    on( GridFiltersActions.setFilter, ( state, { grid, column, filter } ) => {
        if( filter.type !== WndGridColumnSearchType.EMPTY ) {
            if( filter.arguments.length && filter.arguments[ 0 ] ) {
                return adapter.setOne( { gridColumnName: `${ grid }:${ column }`, filter: cloneDeep( filter ) }, state );
            } else {
                return adapter.removeOne( `${ grid }:${ column }`, state );
            }
        }
        return state;
    } ),
    on( GridFiltersActions.clearFilter, ( state, { grid, column } ) => adapter.removeOne( `${ grid }:${ column }`, state ) ),
    on( GridFiltersActions.clearAllTableFilters, ( state, { grid, columns } ) => adapter.removeMany( columns.map( column => `${ grid }:${ column }` ), state ) )
);
