import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, EventEmitter, Injectable, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { IBaselineYear } from '@piani/models/baseline-year';

export class Node {
    constructor( public item: string, public children?: Node[], public id?: number, public version?: string, public status?: string, _published?: Date ) {
    }
}

export class FlatNode {
    item: string;
    level: number;
    expandable: boolean;
    id: number;
}

@Injectable( { providedIn: 'root' } )
export class ChecklistDatabase {
    treeData: any[];

    constructor() {
    }

    initialize( listToTree: IBaselineYear[] ): Node[] {
        const tempTree: Node[] = [];
        // Trasformo input Data in Tree Data
        listToTree.forEach( ( listItem ) => {
            // In baselines c'è un array di periodi
            const periods = new Array<Node>();
            listItem.baselines.forEach( ( b ) => {
                const versions = new Array<Node>();
                b.children.forEach( ( c ) => {
                    // Ogni children costituisce un children del periodo
                    versions.push( new Node( c.version, null, c.id, c.version, c.status, new Date( c.published ) ) );
                } );
                // Ogni periodo costituisce un children dell'anno
                periods.push( new Node( b.name, versions, listItem.year ) );
            } );
            // Ogni anno è un nodo di root
            tempTree.push( new Node( listItem.year.toString(), periods, listItem.year ) );
        } );
        this.treeData = tempTree;
        return tempTree;
    }
}

@Component( {
    selector: 'wnd-combo-tree',
    templateUrl: './combo-tree.component.html',
    styleUrls: [ './combo-tree.component.scss' ],
} )
export class ComboTreeComponent implements OnInit, OnChanges {
    @ViewChild( MatAutocompleteTrigger ) autocomplete: MatAutocompleteTrigger;
    @Input() treeListObs$: Observable<IBaselineYear[]>;
    @Input() selectedNodeId: number;
    @Output() selectionChanged = new EventEmitter<number>();

    flatNodeMap = new Map<FlatNode, Node>();
    nestedNodeMap = new Map<Node, FlatNode>();
    selectedParent: FlatNode | null = null;
    newItemName = '';

    treeControl: FlatTreeControl<FlatNode>;
    treeFlattener: MatTreeFlattener<Node, FlatNode>;
    dataSource: MatTreeFlatDataSource<Node, FlatNode>;

    checklistSelection = new SelectionModel<FlatNode>( true /* multiple */, undefined, undefined, ( o1, o2 ) => ( o1.id === o2.id ) );

    myControl = new FormControl();
    options: string[] = [ 'One', 'Two', 'Three' ];
    filteredOptions: Observable<string[]>;

    private obsSub = new Subscription();

    constructor( private _database: ChecklistDatabase, private ref: ChangeDetectorRef ) {
    }

    ngOnInit() {
    }

    ngOnChanges( changes: SimpleChanges ): void {
        if( changes.treeListObs$?.currentValue ) {
            // Se c'è già una sub attiva, la sostituisco
            if( this.obsSub ) {
                this.obsSub.unsubscribe();
            }
            const list$ = changes.treeListObs$.currentValue as Observable<IBaselineYear[]>;
            this.obsSub = list$.subscribe( ( treeList ) => {
                console.log( 'New tree', treeList, this.selectedNodeId );
                const tree = this._database.initialize( treeList );
                this.treeFlattener = new MatTreeFlattener(
                    this.transformer,
                    this.getLevel,
                    this.isExpandable,
                    this.getChildren,
                );
                this.treeControl = new FlatTreeControl<FlatNode>(
                    this.getLevel,
                    this.isExpandable,
                );
                this.dataSource = new MatTreeFlatDataSource(
                    this.treeControl,
                    this.treeFlattener,
                );
                this.dataSource.data = tree;
                this.selectElement( this.selectedNodeId );
            } );
        }
        if( changes.selectedNodeId?.currentValue ) {
            if( this.dataSource ) {
                console.log( 'node id changed', this.selectedNodeId, this.dataSource.data );
                this.selectElement( changes.selectedNodeId.currentValue as number );
                this.ref.detectChanges();
            }
        }
    }

    getLevel = ( node: FlatNode ) => node.level;
    isExpandable = ( node: FlatNode ) => node.expandable;
    getChildren = ( node: Node ): Node[] => node.children;
    hasChild = ( _: number, _nodeData: FlatNode ) => _nodeData.expandable;
    hasNoContent = ( _: number, _nodeData: FlatNode ) => _nodeData.item === '';

    transformer = ( node: Node, level: number ) => {
        const existingNode = this.nestedNodeMap.get( node );
        const flatNode =
            existingNode && existingNode.item === node.item
                ? existingNode
                : new FlatNode();
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.id = node.id;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set( flatNode, node );
        this.nestedNodeMap.set( node, flatNode );
        return flatNode;
    };

    todoItemSelectionToggle( node: FlatNode ): void {
        this.checklistSelection.toggle( node );
    }

    todoLeafItemSelectionToggle( node: FlatNode ): void {
        console.log( 'Clicked', node );
        this.checklistSelection.clear();
        this.checklistSelection.toggle( node );
        // Chiusura automatica e output
        this.autocomplete.closePanel();
        this.selectionChanged.emit( node.id );
    }

    checkAllParentsSelection( node: FlatNode ): void {
        let parent: FlatNode | null = this.getParentNode( node );
        while( parent ) {
            this.checkRootNodeSelection( parent );
            parent = this.getParentNode( parent );
        }
    }

    checkRootNodeSelection( node: FlatNode ): void {
        this.checklistSelection.select( node );
    }

    getParentNode( node: FlatNode ): FlatNode | null {
        const currentLevel = this.getLevel( node );
        if( currentLevel < 1 ) {
            return null;
        }
        const startIndex = this.treeControl.dataNodes.findIndex( n => n.id === node.id && n.level === node.level && n.item === node.item ) - 1;
        for( let i = startIndex; i >= 0; i-- ) {
            const currentNode = this.treeControl.dataNodes[ i ];
            if( this.getLevel( currentNode ) < currentLevel ) {
                return currentNode;
            }
        }
        return null;
    }

    getSelectedItems(): string {
        // console.log( 'Combo tree getSelectedItems', this.checklistSelection.selected, this.treeControl.dataNodes );
        if( !this.selectedNodeIsValid() ) {
            return 'Seleziona la baseline';
        }
        const period = this.getParentNode( this.checklistSelection.selected[ 0 ] );
        const year = this.getParentNode( period );
        return `${ year.item } > ${ period.item } > ${ this.checklistSelection.selected[ 0 ].item }`;
    }

    private selectedNodeIsValid(): boolean {
        if( !this.checklistSelection.selected.length ) return false;
        const node = this.checklistSelection.selected[ 0 ];
        return this.treeControl.dataNodes.findIndex( n => n.id === node.id && n.level === node.level && n.item === node.item ) !== -1;
    }


    private selectElement( id: number ) {
        this.checklistSelection.clear();
        // Find del nodo per id
        for( const flatNode of this.treeControl.dataNodes ) {
            if( flatNode.level === 2 && flatNode.id === id ) {
                if( !this.checklistSelection.isSelected( flatNode ) ) {
                    this.todoItemSelectionToggle( flatNode );
                }
                // Espando il range
                const firstParent = this.getParentNode( flatNode );
                this.treeControl.expand( firstParent );
                // Espando l'anno
                const secondParent = this.getParentNode( firstParent );
                this.treeControl.expand( secondParent );
            }
        }
    }
}
