import { OsmDrawMapComponent } from '@shared/components/osm-draw-map/osm-draw-map.component';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StepsCollectionService } from '../../../steps-collection.service';
import { ChangeDetectorRef, Inject, Injectable, Injector } from '@angular/core';
import { Struttura } from 'app/routes/nd/interfaces/struttura';
import { CandidateMetaData } from './models/candidate-meta-data';
import { CandidateActivity } from './candidate-activity';
import { BASE_URL } from '@shared/models/urls-constants';


@Injectable()
export class CandidateService {
    private usedStructApi = this.baseUrl + 'wonder-src-workflow-api/Candidates/IsUsedStructure/';
    public activity;
    public addStruct = new Subject<Struttura>();
    candidateActivity: CandidateActivity;

    constructor(
        private stepsCollectionService: StepsCollectionService,
        private http: HttpClient,
        @Inject( BASE_URL ) private baseUrl: string,
        private injector: Injector,
    ) {
    }

    public init( templateOptions: any, fixCluster: Subject<OsmDrawMapComponent> ) {
        this.stepsCollectionService.activityChange.subscribe( act => {
            if( act !== null ) {
                this.activity = act;
                this.candidateActivity = new CandidateActivity(
                    this.http,
                    this.baseUrl,
                    act,
                    this.injector,
                    templateOptions,
                    fixCluster,
                    this.addStruct,
                );
                this.injector.get( ChangeDetectorRef ).detectChanges();
            }
        } );
    }

    public isUsedStructure( idStruttura ) {
        return this.http.get<CandidateMetaData>( this.usedStructApi + idStruttura ).pipe(
            map( struct => {
                if( struct && struct.idActivity === this.activity.id ) {
                    return undefined;
                } else {
                    return this.candidateActivity.disableInput ? 'disabled' : struct;
                }
            } ),
        );
    }
}
