import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { CommonsActions, CommonsSelectors } from '@store/commons-store';
import { Subject, takeUntil, tap } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component( {
    selector: 'wnd-year-selector',
    templateUrl: './year-selector.component.html',
    styleUrls: [ './year-selector.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
} )
export class YearSelectorComponent implements OnInit, OnDestroy {
    selectedYear: number;
    years = [ 2023, 2024, 2025 ];

    private destroyed$ = new Subject();

    constructor( private store: Store, private cdr: ChangeDetectorRef ) { }

    ngOnInit(): void {
        this.store.pipe(
            select( CommonsSelectors.selectFiscalYear ),
            distinctUntilChanged(),
            tap( y => console.log( 'Year changed', y ) ),
            tap( year => this.selectedYear = year ),
            tap( () => this.cdr.detectChanges() ),
            takeUntil( this.destroyed$ )
        ).subscribe();
    }

    onYearChanged() {
        this.store.dispatch( CommonsActions.setFiscalYear( { fiscalYear: this.selectedYear } ) );
    }

    ngOnDestroy(): void {
        this.destroyed$.next( true );
        this.destroyed$.complete();
    }
}
