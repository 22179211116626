<div class='wnd-grid-wrapper' fxLayout='row' fxLayoutAlign='stretch'
     [fluidHeight]='!fullHeight ? null : 0' [padding]="heightPadding"
     [ngClass]="{ 'full-height': fullHeight }">
    <mat-drawer-container
            [ngClass]="{
            'wnd-grid-drawer-container': true,
            'side-nav': sideNavConfigs?.items?.length > 0,
            'no-side-nav': !( sideNavConfigs?.items?.length > 0 )
        }"
            [ngStyle]="{ 'min-height': minimumTableHeight }">
        <mat-drawer
            #drawer
            class='wnd-grid-sidenav mat-elevation-z4'
            mode='side'
            [position]="'end'"
            *ngIf='sideNavConfigs?.items?.length > 0'>
            <div class='h-full' fxLayout='row' fxLayoutAlign='stretch'>
                <div
                        (click)='closeSideNav()'
                        [ngClass]="{ 'wnd-grid-side-trigger-closer': true }"
                        fxLayout='column'
                        fxLayoutAlign='center center'>
                    <mat-icon>arrow_right</mat-icon>
                </div>
                <div
                        *ngFor='let menu of portals; let i = index'
                        class='wnd-grid-sidenav-content'
                        [hidden]='!menu.show'>
                    <ng-template [cdkPortalOutlet]='menu.portal'></ng-template>
                </div>
            </div>
        </mat-drawer>
        <div class="elastic-search" fxLayout="row" fxLayoutAlign="start center" *ngIf="showElasticSearch">
            <mat-icon style="margin-right: 10px;" matPrefix>search</mat-icon>
            <mat-form-field style="width: 30%;">
                <input
                        type='text'
                        placeholder='Elastic Search for Offices'
                        aria-label='Elastic search' matInput [formControl]="elasticSearchControl"/>
                <mat-icon matSuffix (click)="elasticSearchControl.reset()" *ngIf="elasticSearchControl.getRawValue()"
                          style="cursor: pointer;">close
                </mat-icon>
            </mat-form-field>
        </div>
        <mtx-grid
            #table
            [ngClass]="{ 'wnd-grid': true, 'wnd-grid-row-selectable': !rowPanelOpen && rowSelectable, 'has-elastic-search': showElasticSearch }"
            [data]='data'
            [columns]='materoColumns'
            [length]='totalItems'
            [loading]='false'
            [pageOnFront]='false'
            [paginationTemplate]="paginationTemplate"
            (page)='getNextPage($event)'
            [headerTemplate]='headerTpl'
            [showToolbar]='false'
            [showColumnMenuButton]='false'
            [cellSelectable]='false'
            [multiSelectable]="multiSelectable"
            (rowClick)='onRowSelected($event)'
            [expandable]='expandable'
            [expansionTemplate]='expansionTemplate'
            (expansionChange)="onExpansionChange($event)"
            [cellTemplate]="cellTemplate"
            [rowStriped]="true"
            [rowSelectable]="selectable"
            [columnResizable]="false"
            (rowSelectionChange)="onRowSelectionChanged( $event )"
            [rowSelected]="selection"
            [hideRowSelectionCheckbox]="true"
            [sortOnFront]="false"
            [sortActive]="defaultColumnSorted"
            [sortDirection]="defaultColumnsSortDirection?.toLowerCase()">
        </mtx-grid>
    </mat-drawer-container>
    <div
            *ngIf="sideNavConfigs?.items?.length > 0"
            class='wnd-grid-side-trigger-wrapper mat-elevation-z4'
            fxLayout='column'
            fxLayoutAlign='start stretch'>
        <div
                *ngFor='let menu of sideNavConfigs.items'
                (click)='sidenavSelection(menu)'
                [ngClass]="{ 'wnd-grid-side-trigger': true, 'active': isMenuSelected(menu) }"
                fxLayout='column'
                fxLayoutAlign='center center'>
            <mat-icon>{{ menu.icon }}</mat-icon>
            <p>{{ menu.name }}</p>
        </div>
    </div>
</div>

<ng-template #headerTpl let-col>
    <div *ngIf="getWndColumn(col) as wndCol">
        <wnd-column-header [gridName]="gridName" [headerBgColor]="wndCol.headerBgColor" [headerFontColor]="wndCol.headerFontColor" [setColor]="wndCol.setBgColor"
                           *ngIf="col.type !== 'button'; else normalHeader"
                           [col]='wndCol'></wnd-column-header>
        <ng-template #normalHeader>
            <div [ngStyle]="{ 'background-color' : wndCol.headerBgColor, 'color' : wndCol.headerFontColor }"
                 fxLayout='row'
                 fxLayoutAlign='center center'>{{ wndCol.displayName }}
            </div>
        </ng-template>
    </div>
</ng-template>

<ng-template #rowSelectionTpl let-data>
    <wnd-grid-row-panel
            [row]='data.row'
            [columns]='data.columns'
            [closeRequestedSource]='data.closeRequested'></wnd-grid-row-panel>
</ng-template>

<ng-template #cellTemplate let-data let-row let-index="index" let-col="colDef">
    <div *ngIf="getWndColumn(col) as wndCol">
        <span [ngSwitch]="wndCol.type">

            <span *ngSwitchCase="'icon'" class="cell-container">
                <mat-icon aria-hidden="false"
                          [ngClass]="cellConverter.getClasses( wndCol.fieldName, data[ col.field ] )">
                    {{ cellConverter.convertData(wndCol.fieldName, data[col.field]) }}
                </mat-icon>
            </span>

            <span *ngSwitchCase="'date'" class="cell-container">
                {{
                    cellConverter.convertData(wndCol.fieldName, data[col.field]) | date :
                        'dd/MM/yyyy' : locale
                }}
            </span>

            <span *ngSwitchCase="'datetime'" class="cell-container">
                {{
                    cellConverter.convertData(wndCol.fieldName, data[col.field]) | date :
                                    'dd/MM/yyyy HH:mm:ss' : locale
                }}
            </span>

            <span *ngSwitchDefault class="cell-container"
                  [ngClass]="cellConverter.getClasses( wndCol.fieldName, data[ col.field ] )">
                {{ cellConverter.convertData(wndCol.fieldName, data[col.field]) | cell }}
            </span>
        </span>
    </div>
</ng-template>

<ng-template #exportTemplate>
    <wnd-excel-export
            [filename]='defaultExportFilename'
            [gridService]='gridService'
            [customExport]="customExport"
            [customExportTitle]="customExportTitle"
            (customExportClick)="customExportClick.emit()"
            [customWorking]="customWorking"
    >
    </wnd-excel-export>
</ng-template>

<ng-template #gridColumnsSetTemplate>
    <wnd-grid-columns-chooser [gridName]='gridColumnsName'></wnd-grid-columns-chooser>
</ng-template>

<ng-template #dynamicColumnsTemplate>
    <wnd-dynamic-columns-drawer [gridName]="gridColumnsName"></wnd-dynamic-columns-drawer>
</ng-template>

<ng-template #paginationTemplate>
    <div class="paginator-wrapper">
        <mat-icon matTooltip="Reset filters" (click)="resetFilters()">edit_off</mat-icon>
        <span class="import-date" *ngIf="updatedAt$ | async as updatedAt">Ultima importazione: {{
                updatedAt | date: 'dd/MM/yyyy HH:mm'
            }}</span>
        <mat-paginator
                [length]="totalItems"
                [pageIndex]='gridService.pageChange.index | async'
                [pageSize]='gridService.pageChange.size | async'
                [pageSizeOptions]='pageSizeOptions.list'
                (page)='getNextPage($event)'>
        </mat-paginator>
    </div>
</ng-template>

<!-- <ng-template #actionsTpl let-row let-index="index" let-col="colDef">
    <div *ngFor="let action of actions">
        <button *ngIf="action.type === 'button'" mat-icon-button [matTooltip]="action.tooltip" (click)="action.onClick(row)">
            <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
            {{ action.label }}
        </button>
    </div>
</ng-template> -->

<!-- <ng-template #expansionTpl let-row>
  TEST
</ng-template> -->
