import { Component, Input } from '@angular/core';
import { ActionMenuItem } from './types';

@Component( {
    selector: 'wnd-action-menu',
    templateUrl: './action-menu.component.html',
    styleUrls: [ './action-menu.component.scss' ]
} )
export class ActionMenuComponent {
    @Input() actions: ActionMenuItem[] = [];

    constructor() { }
}
