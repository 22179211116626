<nav aria-label="breadcrumb">
    <ol class="matero-breadcrumb">
        <li class="matero-breadcrumb-item"
            *ngFor="let navLink of navs; trackBy: trackByNavLink; first as isFirst;">
            <ng-container *ngIf="!isFirst">
                <mat-icon class="chevron">chevron_right</mat-icon>
            </ng-container>

            <a [routerLink]="navLink.address" class="link" *ngIf="navLink.address">{{ navLink.name | translate }}</a>
            <span class="link" *ngIf="!navLink.address">{{ navLink.name | translate }}</span>
        </li>
    </ol>
</nav>
