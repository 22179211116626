import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPostRequestRoleResponse } from '../models/post-request-role-response';
import { AUTHZ_URL } from '@shared/models/urls-constants';
import { IRoleRequest } from '../models/role-request';
import { IGetRoleRequestsResponse } from '../models/get-role-requests-response';
import { map } from 'rxjs/operators';
import { IGetRoleRequestResponse } from '../models/get-role-request-response';
import { IRole } from '../interfaces/role';
import { IUser } from '../interfaces/user';
import { IGetAllUsersResponse } from '../models/get-all-users-response';
import { IPolicy } from '../interfaces/policy';

@Injectable( {
    providedIn: 'root',
} )
export class ApiUserManagementService {

    constructor( private http: HttpClient, @Inject( AUTHZ_URL ) private baseUrl: string ) {
    }

    requestRole(): Observable<IPostRequestRoleResponse> {
        const url = `${ this.baseUrl }roleRequests/roleRequest`;
        return this.http.post<IPostRequestRoleResponse>( url, {} );
    }

    getRoleRequests(): Observable<IRoleRequest[]> {
        const url = `${ this.baseUrl }roleRequests`;
        return this.http.get<IGetRoleRequestsResponse>( url ).pipe(
            map( res => res.data ),
        );
    }

    getMyRoleRequest(): Observable<IRoleRequest> {
        const url = `${ this.baseUrl }roleRequests/myRequest`;
        return this.http.get<IGetRoleRequestResponse>( url ).pipe(
            map( res => res.data ),
        );
    }

    rejectRequest( userName: string ): Observable<IRoleRequest[]> {
        return this.rejectOrRemoveRequest( userName, false );
    }

    approveRequest( username: string, roles: string[], aree: string[] ): Observable<IRoleRequest[]> {
        const url = `${ this.baseUrl }roleRequests/${ username }`;
        return this.http.put<IGetRoleRequestsResponse>( url, { username, roles, aree } ).pipe(
            map( res => res.data ),
        );
    }

    removeRequest( username: string ): Observable<IRoleRequest[]> {
        return this.rejectOrRemoveRequest( username, true );
    }

    getRolesNames(): Observable<string[]> {
        return this.getAllRoles().pipe(
            map( res => res.map( r => r.name ) )
        );
    }

    getUserData( username: string ) {
        const url = `${ this.baseUrl }users/${ username }`;
        return this.http.get( url );
    }

    getAllUser(): Observable<IUser[]> {
        const url = `${ this.baseUrl }users/r`;
        return this.http.get<IGetAllUsersResponse>( url );
    }

    saveUser( user: IUser ): Observable<IUser> {
        const url = `${ this.baseUrl }users/w/${ user.username }`;
        return this.http.put<IUser>( url, user );
    }

    getUsersLogsFile(): Observable<any> {
        const url = `${ this.baseUrl }users/logins`;
        return this.http.get( url, {
            responseType: 'text'
        } );
    }

    getAllRoles(): Observable<IRole[]> {
        const url = `${ this.baseUrl }roles/roles`;
        return this.http.get<IRole[]>( url );
    }

    /**
     * Add a new role to the roles list. The api takes a role and returns a new list with the add role.
     * But the UI handles one role at time.
     *
     * @param role
     */
    addRole( role: IRole ): Observable<IRole[]> {
        const url = `${ this.baseUrl }roles/roles`;
        return this.http.post<IRole[]>( url, role );
    }

    /**
     * Update a role. It updates only label and/or description.
     *
     * @param role  The role name/id to change.
     */
    editRole( role: IRole ): Observable<IRole[]> {
        const url = `${ this.baseUrl }roles/roles/${ role.name }`;
        return this.http.put<IRole[]>( url, role );
    }

    /**
     * Associate one or more policy to a role
     *
     * @param role          The role name/id
     * @param policies      The list of policies to associate.
     */
    associatePoliciesToRole( role: IRole, policies: string[] ): Observable<IRole> {
        const url = `${ this.baseUrl }roles/roles/${ role.name }`;
        return this.http.put<IRole>( url, { data: policies, remove: false } );
    }

    /**
     * Disassociate one or more policy from a role.
     *
     * @param role      The role name/id
     * @param policies  The list of policies to disassociate.
     */
    disassociatePoliciesFromRole( role: IRole, policies: string[] ): Observable<IRole> {
        const url = `${ this.baseUrl }roles/roles/${ role.name }`;
        return this.http.put<IRole>( url, { data: policies, remove: true } );
    }

    /**
     * Delete a role
     *
     * @param role      The role to delete.
     */
    deleteRole( role: IRole ): Observable<IRole[]> {
        const url = `${ this.baseUrl }roles/roles/${ role.name }`;
        return this.http.delete<IRole[]>( url );
    }

    /**
     * returns all the policies in the system
     */
    getAllPolicies(): Observable<IPolicy[]> {
        const url = `${ this.baseUrl }roles/policies`;
        return this.http.get<IPolicy[]>( url );
    }

    private rejectOrRemoveRequest( username: string, remove: boolean ): Observable<IRoleRequest[]> {
        const params = new HttpParams( { fromObject: { remove } } );
        const url = `${ this.baseUrl }roleRequests/${ username }`;
        return this.http.delete<IGetRoleRequestsResponse>( url, { params } ).pipe(
            map( res => res.data ),
        );
    }

}
