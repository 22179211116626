import { initializerEnvironment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject, timer, zip } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { LocalStorageService } from '@shared/services/storage.service';
import { CodeGrantRequest, CodeGrantResponse, IdentityData, LastLoginData, Token, TokenRefreshRequest, TokenRefreshResponse, UserGrantResponse, UserInfoRequest } from './interfaces/models';

const TOKEN_KEY = 'wonder-jwt';
const USER_KEY = 'wonder-user';

@Injectable( {
    providedIn: 'root',
} )
export class TokenService {
    private token$ = new BehaviorSubject<Token>( null );

    private userData: IdentityData = {
        authData: undefined,
        authzData: undefined,
    };
    private logoutRequest$ = new Subject<void>();
    private _currentUsername = this.store.get( USER_KEY ) as string;
    private currentToken: Token;
    private userDataChange$ = new BehaviorSubject<IdentityData>( null );
    private encodedSecret = btoa( initializerEnvironment.idpSettings.clientId + ':' + initializerEnvironment.idpSettings.clientSecret );

    private loginTime = new BehaviorSubject<LastLoginData>( null );
    public loginTime$ = this.loginTime.asObservable();


    private set currentUsername( value: string ) {
        this._currentUsername = value;
        this.store.set( USER_KEY, value );
    }

    get currentUsername() {
        return this._currentUsername;
    }

    get currentTokenModel() {
        return this.token$.asObservable().pipe( filter( t => t !== null ) );
    }

    constructor( private store: LocalStorageService, private http: HttpClient ) {
    }


    currentIdentityData() {
        return this.currentUserData.pipe(
            map( data => {
                if( data.authData ) {
                    this.userData.authData = data.authData;
                }
                this.userData.authzData = data.authzData;
                return this.userData;
            } ),
        );
    }


    tokenServiceLogoutRequest() {
        return this.logoutRequest$.asObservable();
    }

    init() {
        this.currentToken = this._getToken();
        if( !!this.currentToken ) {
            this.updateToken( this.currentToken, this.currentUsername );
            console.log( 'valore first attempt delay', this.getFirstAttemptDelay() );
            if( this.getFirstAttemptDelay() > 0 ) {
                this.getUserInfo();
            }
            this.bookRefresh().subscribe();

        } else {
            let letRedirect = true;
            const search = location.search.substring( 1 );
            if( search ) {
                const split = search.split( '=' );
                if( split.length === 2 ) {
                    const request: CodeGrantRequest = {
                        EncodedAuthentication: this.encodedSecret,
                        Code: split[ 1 ],
                    };
                    letRedirect = false;
                    this.http.post<CodeGrantResponse>( initializerEnvironment.idpSettings.codeGrantUrl, request )
                        .subscribe( {
                            next: res => {
                                this.updateToken( res.token, res.identityData.authData.username );
                                this.userDataChange$.next( res.identityData );
                                this.getUserInfo();
                                this.bookRefresh().subscribe();
                            },
                            error: ( err: { status: number } ) => {
                                console.log( err );
                                if( err.status === 401 ) {
                                    this.requireLogout();
                                }
                            },
                        } );
                }
            }
            if( letRedirect ) {
                window.location.href = initializerEnvironment.idpSettings.redirectUrl;
            }
        }
    }

    isUserAuthenticated() {
        const token = this._getToken();
        if( !token || !( token.accessToken || !( token.expirationTime ) ) ) {
            return false;
        }
        return ( new Date( token.expirationTime ) ) > new Date();
    }

    public clearStorage() {
        this.store.remove( TOKEN_KEY );
        this.store.remove( USER_KEY );
    }


    private _getToken(): Token {
        return this.store.get( TOKEN_KEY ) as Token;
    }

    private _storeToken( value: Token ) {
        this.store.set( TOKEN_KEY, value );
    }

    private requireLogout() {
        this.clearStorage();
        this.logoutRequest$.next();

    }


    private get currentUserData() {
        return this.userDataChange$.asObservable().pipe( filter( val => val !== null ) );
    }


    private getFirstAttemptDelay() {
        const now = new Date();
        const delta = ( new Date( this.currentToken.expirationTime ).valueOf() - now.valueOf() ) / 2; // - 7 * 1000 * 60;
        console.log( 'delta', delta, new Date( this.currentToken.expirationTime ).valueOf() - now.valueOf() );
        return delta > 0 ? delta : 0;
    }

    private bookRefresh(): Observable<number> {
        let cnt = 1;
        const stopAttempts$: Subject<boolean> = new Subject<boolean>();
        return zip(
            from( [ 1, 2, 3, 4, 5, 6, 7 ] ),
            timer( this.getFirstAttemptDelay(), 1000 * 60 ),
        ).pipe(
            map( ( [ val, _i ] ) => val ),
            filter( val => [ 1, 2, 5, 7 ].includes( val ) ),
            takeUntil( stopAttempts$ ),
            tap( val => {
                if( val !== 7 ) {
                    const refreshRequest: TokenRefreshRequest = {
                        RefreshToken: this.currentToken.refreshToken,
                        ClientSecret: initializerEnvironment.idpSettings.clientSecret,
                        Username: this.currentUsername,
                    };
                    console.log( `Token Refresh tentative ${ cnt }` );
                    cnt = cnt + 1;
                    this.http.post<TokenRefreshResponse>( initializerEnvironment.idpSettings.refreshUrl, refreshRequest )
                        .subscribe( {
                            next: res => {
                                this.currentToken.accessToken = res.accessToken;
                                this.currentToken.idToken = res.idToken;

                                //this.currentToken.expirationTime = new Date(new Date().valueOf() + 10 * 60 * 1000).toUTCString();
                                this.currentToken.expirationTime = res.expirationTime;
                                this.updateToken( this.currentToken, res.authzResponse.username );
                                const userData: IdentityData = {
                                    authData: undefined,
                                    authzData: res.authzResponse,
                                };
                                if( this.userDataChange$.value === null ) {
                                    this.getUserInfo();
                                } else {
                                    this.userDataChange$.next( userData );
                                }

                                stopAttempts$.next( true );
                                stopAttempts$.unsubscribe();
                                this.bookRefresh().subscribe();


                            },
                            error: ( err: { error: string } ) => {
                                console.log( err );
                                if( err.error === 'Please login' ) {
                                    stopAttempts$.next( true );
                                    stopAttempts$.unsubscribe();
                                    this.requireLogout();
                                }
                            },
                        } );
                } else {
                    console.log( 'Token Refresh all attempts failed' );
                    stopAttempts$.next( true );
                    stopAttempts$.unsubscribe();
                    this.requireLogout();
                }
            } ),
        );
    }


    private updateToken( token: Token, username: string ) {
        this.currentUsername = username;
        this.currentToken = token;
        this._storeToken( token );
        this.token$.next( token );
    }


    private getUserInfo() {
        const userRequest: UserInfoRequest = {
            accessToken: this.currentToken.accessToken,
        };
        this.http.post<UserGrantResponse>( initializerEnvironment.idpSettings.userUrl, userRequest )
            .subscribe( {
                next: res => {
                    this.currentUsername = res.identityData.authData.username;
                    this.userDataChange$.next( res.identityData );
                    this.loginTime.next( res.lastLogin );

                },
                error: ( err: { status: number } ) => {
                    console.log( err );
                    if( err.status === 401 ) {
                        this.requireLogout();
                    }
                },
            } );
    }
}
