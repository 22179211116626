<div class="notification-dialog">
    <div mat-dialog-content>
        <p>{{ data.content.content }}</p>
    </div>
    <p class="url-to-jump" (click)="jumpToPage()">{{ urlToJump }}</p>
    <ng-template *ngIf="urlToJump">
        Apri
    </ng-template>
    <div mat-dialog-actions class="actions">
        <button mat-button (click)="close()">Close</button>
    </div>
</div>
