export enum UploadingFileStatuses {
    PAUSED = 'paused',
    RUNNING = 'running',
    CANCELED = 'canceled',
    SERVER_ERROR = 'serverError',
    COMPLETED = 'completed',
    WAITING = 'waiting',
}


export enum TusPendingRequest {
    UPLOAD = 'upload',
    PAUSE = 'pause',
    DELETE = 'delete',
    RESUME = 'resume',
    NOTHING = 'nothing'
}
