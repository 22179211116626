<div class='title-wrapper mt' *ngIf='frequencies?.length > 0'>
    <div class='title'>
        <p>Frequenze attive</p>
    </div>
</div>
<div class='frequency' fxLayout='row wrap' fxLayoutGap="10px" fxFlexAlign='space-evenly'>
    <div *ngFor='let frequency of frequencies'>
        {{ frequency.technology }} {{ frequency.frequency }}
    </div>
</div>
