import { Injectable } from '@angular/core';
import { CustomMapIcon } from './icons-svg';

export interface Config {
    key: string;
    drawOptions?: any;
    drawLocal?: any;
    showControls: boolean;
    maxNumOfFeatures?: number;
    minNumOfFeatures?: number;
}

const drawOptionsDict: Config[] = [
    {
        minNumOfFeatures: 1,
        maxNumOfFeatures: 1,
        showControls: true,
        key: 'search_area',
        drawOptions: {
            position: 'topright',
            draw: {
                polygon: true,
                polyline: false,
                rectangle: { showArea: false }, // disable showArea
                circle: false,
                marker: { icon: CustomMapIcon.defaultMarkerIcon },
                circlemarker: false,
            },
            edit: {
                featureGroup: null,
            },
        },
        drawLocal: {
            draw: {
                handlers: {
                    /*
                        circle: {
                            tooltip: {
                                start: 'Insert a search area.',
                            },
                        },
                    */
                    marker: {
                        tooltip: {
                            start: 'Add a new structure.',
                        },
                    },
                    polygon: {
                        tooltip: {
                            start: 'Insert a search area.',
                        },
                    },
                    rectangle: {
                        tooltip: {
                            start: 'Insert a search area.',
                        },
                    },
                },
            },
        },
    },
    {
        minNumOfFeatures: 1,
        maxNumOfFeatures: 1,
        showControls: true,
        key: 'new_site',
        drawOptions: {
            position: 'topright',
            draw: {
                polygon: false,
                polyline: false,
                rectangle: false,
                circle: false,
                marker: {
                    icon: CustomMapIcon.newPlaceIcon,
                },
                circlemarker: false,
            },
            edit: {
                featureGroup: null,
            },
        },
        drawLocal: {
            draw: {
                handlers: {
                    // circle: {
                    //     tooltip: {
                    //         start: 'Insert a search area.',
                    //     },
                    // },
                    marker: {
                        tooltip: {
                            start: 'Add a new structure.',
                        },
                    },
                    polygon: {
                        tooltip: {
                            start: 'Insert a search area.',
                        },
                    },
                    rectangle: {
                        tooltip: {
                            start: 'Insert a search area.',
                        },
                    },
                },
            },
        },
    },
    {
        key: 'no_edit',
        showControls: false,
    }
];


@Injectable( {
    providedIn: 'root',
} )
export class MapDrawConfigService {
    constructor() {
    }

    public getConfig( typeOfService: string ): Config {
        return drawOptionsDict.filter( o => o.key === typeOfService )[ 0 ];
    }
}
