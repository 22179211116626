import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
    name: 'zone',
} )
export class ZonePipe implements PipeTransform {

    transform( value: number ): string {
        switch( value ) {
        case 1:
            return 'NORD OVEST';
        case 2:
            return 'NORD EST';
        case 3:
            return 'CENTRO';
        case 4:
            return 'SUD';
        default:
            return 'ITALIA';
        }
    }

}
