import { SettingsService, TokenService } from '@core';
import { environment, initializerEnvironment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { LoggerService } from '@shared/services/logger.service';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
    private _currentAccessToken = '';

    constructor( private toastr: ToastrService, private token: TokenService, private setting: SettingsService, private logger: LoggerService ) {
        this.token.currentTokenModel.subscribe( t => ( this._currentAccessToken = t.accessToken ) );
    }

    intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
        // Add server host
        const url = req.url;

        if ( !( ( url.startsWith( `http://${initializerEnvironment.apiHost}` ) || url.startsWith( `https://${initializerEnvironment.apiHost}` ) ) && !url.includes( initializerEnvironment.idpSettings.apiPrefix ) ) ) {
            return next.handle( req );
        }

        // All APIs need JWT authorization
        const headers = {
            Accept: url.includes( 'users/logins' ) ? 'text/plain' : 'application/json',
            Authorization: `Bearer ${this._currentAccessToken}`,
        };

        const newReq = req.clone( { url: url.toLowerCase(), setHeaders: headers, withCredentials: false } );
        const noShowErrors = req.headers.get( 'no-show-error' ) === 'true' || req.url.includes( 'wonder-src-tusserver-api' );

        if ( url.includes( 'users/logins' ) ) {
            return next.handle( newReq );
        } else {
            return next.handle( newReq ).pipe(
                // mergeMap( ( event: HttpEvent<any> ) => this.handleOkReq( event ) ),
                catchError( ( error: HttpErrorResponse ) => this.handleErrorReq( error, noShowErrors ) )
            );
        }
    }

    /*
        private goto( url: string ) {
            setTimeout( () => void this.router.navigateByUrl( url ) );
        }

        private handleOkReq( event: HttpEvent<any> ): Observable<HttpEvent<any>> {
            if( event instanceof HttpResponse ) {
                const body: any = event.body;
                // failure: { code: **, msg: 'failure' }
                // success: { code: 0,  msg: 'success', data: {} }
                if( body && 'code' in body && body.code !== 0 ) {
                    if( body.msg && body.msg !== '' ) {
                        //this.toastr.error(body.msg);
                        console.error( 'ERROR', body.msg );
                    }
                    return throwError( [] );
                }
            }
            // Pass down event if everything is OK
            return of( event );
        }
    */

    private handleErrorReq( error: HttpErrorResponse, noShowErrors: boolean ): Observable<never> {
        if ( error.error instanceof Error ) {
            console.error( 'Frontend/network error ', error.error.message, error );
            if ( !noShowErrors ) {
                this.showError( environment.production ? 'Operazione non andata a buon fine' : error.error.message, 'Application error' );
            }
        } else {
            switch ( error.status ) {
            case 302:
                // nothing
                break;
            case 401:
                this.setting.logout();
                break;
            case 403:
                // nothing
                break;
            default:
                console.error( `Backend error ${error.status}: ${error.error as string ?? error.message}`, error );
                if ( !noShowErrors ) {
                    this.showError( environment.production ? 'Operazione non andata a buon fine' : error.error as string ?? error.message );
                }
                break;
            }
        }

        this.logger.saveLog( {
            timestamp: new Date(),
            username: this.setting.user ? this.setting.user.username : '',
            errorMessage: error.error instanceof Error ? error.error.message : error.message,
            errorCode: error.status?.toString(),
            userAgent: navigator.userAgent,
            errorContext: error.url,
        } );

        return throwError( () => error );
    }

    private showError( message: string, title: string = '' ) {
        this.toastr.error( `<div>${message}</div><div>${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}</div>`, title, {
            timeOut: 1000 * 60,
            enableHtml: true,
        } );
    }
}
