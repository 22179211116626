import { UploaderManagerComponent } from './uploader-manager/uploader-manager.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';

import { SidebarComponent } from './sidebar/sidebar.component';
import { UserPanelComponent } from './sidebar/user-panel.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { AccordionDirective } from './sidemenu/accordion.directive';
import { AccordionItemDirective } from './sidemenu/accordionItem.directive';
import { AccordionAnchorDirective } from './sidemenu/accordionanchor.directive';
import { SidebarNoticeComponent } from './sidebar-notice/sidebar-notice.component';

import { TopmenuComponent } from './topmenu/topmenu.component';
import { TopmenuPanelComponent } from './topmenu/topmenu-panel.component';

import { HeaderComponent } from './header/header.component';
import { BrandingComponent } from './header/widgets/branding.component';
import { NotificationComponent } from './header/widgets/notification.component';
import { TranslateComponent } from './header/widgets/translate.component';
import { UserComponent } from './header/widgets/user.component';
import { CustomizerComponent } from './customizer/customizer.component';
import { UploadingElementComponent } from './uploading-element/uploading-element.component';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MaterialModule } from '../material.module';
import { NgProgressModule } from 'ngx-progressbar';
import { CommonModule } from '@angular/common';
import { NotificationDialogComponent } from '@theme/notification-dialog/notification-dialog.component';


@NgModule( {
    declarations: [
        AdminLayoutComponent,
        AuthLayoutComponent,
        SidebarComponent,
        UserPanelComponent,
        SidemenuComponent,
        AccordionDirective,
        AccordionItemDirective,
        AccordionAnchorDirective,
        SidebarNoticeComponent,
        TopmenuComponent,
        TopmenuPanelComponent,
        HeaderComponent,
        BrandingComponent,
        NotificationComponent,
        TranslateComponent,
        UserComponent,
        UploaderManagerComponent,
        CustomizerComponent,
        UploadingElementComponent,
    ],
    imports: [ CommonModule, SharedModule, MatRippleModule, TranslateModule, FlexModule, MatIconModule, MatMenuModule, MaterialModule, ExtendedModule, NgProgressModule ],
    exports: [
        HeaderComponent,
    ],
} )
export class ThemeModule {
}
