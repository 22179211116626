import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadingFileStatuses } from '@shared/models/uploading-file-statuses';
import { TusSingleFileUploader } from '@shared/services/upload.service';

@Component( {
    selector: 'app-uploading-element',
    templateUrl: './uploading-element.component.html',
    styleUrls: [ './uploading-element.component.scss' ]
} )
export class UploadingElementComponent implements OnInit {
    @Input() file: TusSingleFileUploader;
    @Output() togglePause: EventEmitter<TusSingleFileUploader> = new EventEmitter<TusSingleFileUploader>();
    @Output() abort: EventEmitter<TusSingleFileUploader> = new EventEmitter<TusSingleFileUploader>();
    @Output() complete: EventEmitter<TusSingleFileUploader> = new EventEmitter<TusSingleFileUploader>();
    @Output() running: EventEmitter<TusSingleFileUploader> = new EventEmitter<TusSingleFileUploader>();

    get filename(): string {
        return this.file.fileStatus.filename;
    }

    get progress(): number {
        return this.file.fileStatus.progress;
    }

    get isRunning(): boolean {
        return this.file.fileStatus.state === UploadingFileStatuses.RUNNING;
    }

    get isPaused(): boolean {
        return this.file.fileStatus.state === UploadingFileStatuses.PAUSED;
    }

    get isWaiting(): boolean {
        return this.file.fileStatus.state === UploadingFileStatuses.WAITING;
    }

    get isCompleted(): boolean {
        return this.file.fileStatus.state === UploadingFileStatuses.COMPLETED;
    }

    get isInError(): boolean {
        return this.file.fileStatus.state === UploadingFileStatuses.SERVER_ERROR;
    }

    get tooltipText(): string {
        switch( this.file.fileStatus.state ) {
        case UploadingFileStatuses.RUNNING:
            this.running.emit( this.file );
            return `Upoading ${ this.file.fileStatus.progress }%...`;
        case UploadingFileStatuses.PAUSED:
            return `Upload paused. Click play to resume...`;
        case UploadingFileStatuses.COMPLETED:
            this.complete.emit( this.file );
            return 'Upload completed.';
        case UploadingFileStatuses.CANCELED:
            return 'Upload canceled. Closing...';
        case UploadingFileStatuses.SERVER_ERROR:
            this.complete.emit( this.file );
            return 'Error in uploading file. Try again later.';
        case UploadingFileStatuses.WAITING:
            return 'Executing request...';
        }
    }

    constructor() {
    }

    ngOnInit(): void {
    }

    onAbort(): void {
        this.abort.emit( this.file );
    }

    onTogglePause(): void {
        this.togglePause.emit( this.file );
    }

}
