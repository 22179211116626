<div fxLayout="column" fxLayoutAlign="start center">
    <div class='title'>
        <p>Colonne aggiuntive</p>
    </div>
    <button mat-button matTooltip="Vai alla generazione del template per le colonne aggiuntive" (click)="onTemplateClick()">Template</button>
    <button mat-button matTooltip="Inserisci un template compilato con le colonne aggiuntive" (click)="inputFile.click()">Importa</button>
    <input #inputFile id="inputFile" hidden [tus-uploader]="tusConfig" (uploadEvents)="onUploadEvent($event)" (selectionError)="onSelectionError($event)"/>

    <mat-progress-bar mode='indeterminate' *ngIf='working'></mat-progress-bar>
</div>
