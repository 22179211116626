import { ExtraFlowConfig } from '../extraFlow_config';
import { koActivity } from './arrayXSelect';

export const defaultClosingForms: { finalizeForm: ExtraFlowConfig, abortForm: ExtraFlowConfig } = {
    finalizeForm: {
        displayName: 'Finalize',
        name: 'finalizeForm',
        fields: [
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-12',
                        type: 'datepicker',
                        key: 'date1',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'Data chiusura',
                        },
                    },
                ],
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-12',
                        type: 'textarea',
                        key: 'string2',
                        templateOptions: {
                            disableIfCommited: true,
                            required: false,
                            label: 'Note',
                            rows: 3,
                        },
                    },
                ],
            }
        ]
    },
    abortForm: {
        displayName: 'Abort',
        name: 'abortForm',
        fields: [
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-12',
                        type: 'datepicker',
                        key: 'date1',
                        templateOptions: {
                            disableIfCommited: true,
                            required: true,
                            label: 'Data dichiarazione KO',
                        },
                    },
                ],
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-12',
                        type: 'select',
                        key: 'string1',
                        templateOptions: {
                            //multiple: true,
                            options: koActivity,
                            disableIfCommited: true,
                            required: true,
                            label: 'Motivazione KO',
                        },
                    },
                ],
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-12',
                        type: 'textarea',
                        key: 'string2',
                        templateOptions: {
                            disableIfCommited: true,
                            required: false,
                            label: 'Note',
                            rows: 3,
                        },
                    },
                ],
            }
        ]
    }
}
