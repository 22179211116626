import { ExtraFlowConfig } from './extraFlow_config';
import { ToastrService } from 'ngx-toastr';
import { ActivityConfig } from './process-template/activity_config';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StepConfig } from './step_config';
import { Inject, Injectable } from '@angular/core';
import { ProcessSelector } from './process-template/process-selector';
import { Step } from './steps-stepper/activity-services/Step';
import { ExtraFlow } from './steps-stepper/activity-services/ExtraFlow';
import { defaultClosingForms } from './process-template/defaultClosingForm';
import { BASE_URL } from '@shared/models/urls-constants';

export interface StepEvent {
    source: Step;
    type: string;
}

@Injectable()
export class StepsCollectionService {
    constructor(
        private http: HttpClient,
        @Inject( BASE_URL ) private baseUrl: string,
        private toastr: ToastrService,
    ) {
    }

    stepEventChange = new Subject<StepEvent>();
    stepsReady = new BehaviorSubject<Step[]>( null );
    private _currentSteps: Step[] = [];
    extraFlowReady = new BehaviorSubject<ExtraFlow[]>( null );
    closingFormReady = new BehaviorSubject<{ finalizeForm: ExtraFlow, abortForm: ExtraFlow }>( null );
    private _currentExtraFlows: ExtraFlow[] = [];
    private _currentClosingForm: { finalizeForm: ExtraFlow, abortForm: ExtraFlow };
    cnt = 0;

    private _activityId: string;
    public typeOfActivity: string;
    activityChange = new BehaviorSubject<any>( null );
    private _activityConfig: ActivityConfig;
    public activityConfigChange = new BehaviorSubject<ActivityConfig>( null );
    public activityIdChange = new BehaviorSubject<string>( null );
    public stepConfig$ = new Subject<StepConfig[]>();
    possibleConfig: StepConfig[];
    extraFlowsConfig: ExtraFlowConfig[];
    closingFormConfig: { finalizeForm: ExtraFlowConfig, abortForm: ExtraFlowConfig };

    get activityId(): string {
        return this._activityId;
    }

    set activityId( val: string ) {
        this._activityConfig = ProcessSelector.getTemplate( this.typeOfActivity );
        this.extraFlowsConfig = this._activityConfig.extraFlowConfig;
        this.possibleConfig = this._activityConfig.stepsConfig;
        this._setFormConfig();
        this.activityConfigChange.next( this._activityConfig );
        this._activityId = val;
        this.activityIdChange.next( this._activityId );
        this.http.get<any>( this.baseUrl + 'wonder-src-workflow-api/Activities/' + val ).subscribe( res => {
            this._activity = res;
            this.activityChange.next( res );
        } );
        this._init();
        this._getNewStepSubscribe();
    }

    get steps() {
        return this._currentSteps;
    }

    private _activity: any;
    get activity() {
        return this._activity;
    }

    get currentClosingForm() {
        return this._currentClosingForm;
    }

    getExtraFormSelected( name: string ) {
        return this._currentExtraFlows.find( c => c.nome === name );
    }


    private _setFormConfig() {
        if( !!this._activityConfig.closingFormConfig ) {
            this.closingFormConfig = this._activityConfig.closingFormConfig;
        } else {
            this.closingFormConfig = defaultClosingForms;
        }

    }

    private _getNewStepSubscribe() {
        this.stepEventChange.subscribe( event => {
            if( event !== null && event.source.config.nextStep && event.type === 'commit' ) {
                const nextStep = this.possibleConfig.filter(
                    s => s.name === event.source.config.nextStep( event.source.data ),
                )[ 0 ];
                Step.createStep(
                    this.activityId,
                    this.stepEventChange,
                    this.cnt++,
                    undefined,
                    nextStep,
                    this.http,
                    this.baseUrl,
                    this.toastr,
                ).subscribe( step => {
                    this._currentSteps.push( step );
                    this.stepsReady.next( this._currentSteps );
                } );
            }
        } );
    }

    private _createClosingForm( index: number, data: any[], config: ExtraFlowConfig ) {
        const dati = data.find(
            dd => dd.nome === config.name,
        );

        return ExtraFlow.createExtraFlow(
            this.activityId,
            index,
            dati,
            config,
            this.http,
            this.baseUrl,
            this.toastr,
        );
    }

    private _init() {
        return this.http
            .get<any[]>( this.baseUrl + 'wonder-src-workflow-api/Steps/GetStepByActivity/' + this._activityId )
            .subscribe( data => {
                forkJoin(
                    [ this._createClosingForm( 1000, data, this.closingFormConfig.finalizeForm ),
                        this._createClosingForm( 1001, data, this.closingFormConfig.abortForm ) ] )
                    .subscribe( ( flows: ExtraFlow[] ) => {
                        this._currentClosingForm = {
                            finalizeForm: flows[ 0 ],
                            abortForm: flows[ 1 ],
                        };
                        this.closingFormReady.next( this._currentClosingForm );
                    } );


                const dataSteps = data.filter(
                    dd => this.possibleConfig.filter( ee => ee.name === dd.nome ).length > 0,
                );

                if( this.extraFlowsConfig.length > 0 ) {
                    const dataExtraFlow = data.filter(
                        dd => this.extraFlowsConfig.filter( ee => ee.name === dd.nome ).length > 0,
                    );
                    let flowObs: Observable<ExtraFlow>[];
                    flowObs = this.extraFlowsConfig.map( e => {
                        const d = dataExtraFlow.find( item => item.nome === e.name );
                        return ExtraFlow.createExtraFlow(
                            this.activityId,
                            this.cnt++,
                            d,
                            e,
                            this.http,
                            this.baseUrl,
                            this.toastr,
                        );
                    } );
                    forkJoin( flowObs ).subscribe( ( flows: ExtraFlow[] ) => {
                        this._currentExtraFlows = flows;
                        this.extraFlowReady.next( this._currentExtraFlows );
                    } );
                }

                let stpesObs: Observable<Step>[];
                if( dataSteps.length > 0 ) {
                    stpesObs = dataSteps.map( d =>
                        Step.createStep(
                            this.activityId,
                            this.stepEventChange,
                            this.cnt++,
                            d,
                            this.possibleConfig.filter( p => p.name === d.nome )[ 0 ],
                            this.http,
                            this.baseUrl,
                            this.toastr,
                        ),
                    );
                } else {
                    const entryStep = this.possibleConfig.filter( s => s.isEntryStep )[ 0 ];
                    stpesObs = [
                        Step.createStep(
                            this.activityId,
                            this.stepEventChange,
                            this.cnt++,
                            undefined,
                            entryStep,
                            this.http,
                            this.baseUrl,
                            this.toastr,
                        ),
                    ];
                }
                forkJoin( stpesObs ).subscribe( ( steps: Step[] ) => {
                    this._currentSteps = steps;
                    this.stepsReady.next( this._currentSteps );
                } );
            } );
    }

    finalize() {
        return this.http
            .post( this.baseUrl + 'wonder-src-workflow-api/Activities/Finalize/' + this._activityId, {} );
    }

    abort() {
        return this.http
            .post( this.baseUrl + 'wonder-src-workflow-api/Activities/Abort/' + this._activityId, {} );
    }
}
