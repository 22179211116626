<div *ngIf="data?.candidate?.codiciAltroOperatore">
    <p>
        Questa struttura ospita gli impianti
    </p>
    <ul>
        <li *ngFor="let impianto of listaImpiantiOspitati">{{impianto}}</li>
    </ul>
</div>
<form [formGroup]="form">
    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form">
    </formly-form>
    <button class="m-r-8" mat-raised-button color="primary" (click)="submit()" [disabled]="!form.valid">
        Save
    </button>
    <button mat-raised-button type="button" (click)="cancel()">Cancel</button>
</form>
