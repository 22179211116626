<div class="w-full h-full" fxLayout="row wrap">
    <mat-card class="mat-elevation-z4 m-auto" style="max-width: 380px;">
        <mat-card-title class="m-b-12" style="text-align: center;">
            {{'register.welcome' | translate}}, <br/>
            {{'register.title' | translate}}
        </mat-card-title>

        <form class="form-field-full" [formGroup]="registerForm">
            <mat-form-field appearance="outline">
                <mat-label>{{'login.username' | translate}}</mat-label>
                <input matInput formControlName="username" required>
                <mat-error *ngIf="registerForm.get('username').invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" required>
                <mat-error *ngIf="registerForm.get('email').invalid">
                    {{'validations.required' | translate}}
                </mat-error>
                <mat-error *ngIf="registerForm.get('email').hasError('check')">
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'login.password' | translate}}</mat-label>
                <input matInput type="password" formControlName="password" required>
                <mat-error *ngIf="registerForm.get('password').hasError('required')">
                    {{passwordText}}
                </mat-error>
                <mat-error *ngIf="registerForm.get('password').hasError('check')">
                    {{passwordText}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" style="padding-top: 5px;">
                <mat-label>{{'register.confirm_password' | translate}}</mat-label>
                <input matInput type="password" formControlName="confirmPassword" required>
                <mat-error *ngIf="registerForm.get('confirmPassword').hasError('required')">
                    {{'validations.required' | translate}}
                </mat-error>
                <mat-error *ngIf="registerForm.get('confirmPassword').hasError('confirm')"
                           translate [translateParams]="{value: 'login.password' | translate}">
                    {{'validations.inconsistent'}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Seleziona Ruoli</mat-label>
                <mat-select formControlName="role" multiple>
                    <mat-option *ngFor="let role of ruoli" [value]="role">
                        {{role}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <button class="w-full" mat-raised-button color="primary" (click)="register()" [disabled]="registerForm.invalid">
                {{'register.register' | translate}}
            </button>

            <div class="m-t-16">{{'register.have_an_account' | translate}}?
                <a routerLink="/auth/login">{{'login.login' | translate}}</a>
            </div>
        </form>

    </mat-card>
</div>
