import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PreloaderService } from '@core';
import { StreamCraftMessagesStore } from '@core/services/stream-craft-client/stream-craft-messages.store';
import { TestMessage } from '@core/services/stream-craft-client/models/TestMessage';
import { StreamCraftMessageTypes } from '@core/services/stream-craft-client/models/stream-craft-message-types';

@Component( {
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
} )
export class AppComponent implements OnInit, AfterViewInit {
    constructor( private preloader: PreloaderService, private socketStore: StreamCraftMessagesStore ) {
    }

    ngOnInit() {
        const stream$ = this.socketStore.stream( StreamCraftMessageTypes.testStream );
        if( stream$ ) {
            stream$.subscribe( ( m: TestMessage ) => console.log( 'Test message arrived', m ) );
        } else {
            console.error( 'Fails to get test stream' );
        }

    }

    ngAfterViewInit() {
        this.preloader.hide();
    }
}
