import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { DashboardPianiState } from './index';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { DashboardPianiEffects } from './effects';

@NgModule( {
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature( DashboardPianiState.featureKey, reducers ),
        EffectsModule.forFeature( [ DashboardPianiEffects ] ),
    ],
} )
export class DashboardPianiStoreModule {
}
