<div class="coords-control" id="custom">
    <mat-form-field appearance="legacy">
        <mat-label>Digitare Coordinate</mat-label>
        <input
                matInput
                [formControl]="coordsControl"
                placeholder="Placeholder"
                #coordsInput="matInput"
                (keydown)="userKeyDown($event)"
        />
        <button
                *ngIf="coordsState === 'valid'"
                mat-button
                class="m-4"
                (click)="submitCoords()"
                matSuffix
                mat-icon-button
                color="primary"
        >
            <mat-icon class="icon-18">check_circle</mat-icon>
        </button>
        <button
                *ngIf="coordsState === 'not valid'"
                mat-button
                class="m-4"
                (click)="coordsControl.setValue('')"
                matSuffix
                mat-icon-button
                color="warn"
        >
            <mat-icon class="icon-18">cancel</mat-icon>
        </button>
    </mat-form-field>
    <button mat-button *ngIf="userTyping" (click)="enableDrawer()" mat-icon-button>
        <mat-icon class="icon-18">clear</mat-icon>
    </button>
</div>
