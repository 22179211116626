<!-- <button type="button" (click)="check()">log</button> -->
<mat-form-field class="example-full-width map-form-input" [ngClass]="{'mat-form-field-invalid': (!formControl.valid) && mapTouched}">
    <mat-label>Choose on Map</mat-label>
    <input type="mapInput" readonly class="map-form-input" matInput>
    <button matSuffix (click)="menuMapOpened()" mat-icon-button>
        <mat-icon class="icon-24">map</mat-icon>
    </button>
</mat-form-field>
<mat-expansion-panel class="map-input" (opened)="mapOpened()" (closed)="mapClosed()" [expanded]="panelOpenState" hideToggle>
    <osm-draw-map *ngIf="show"
                  [maxWidth]="maxWidth"
                  [mapHeight]="mapHeight"
                  #map
                  (click)="$event.stopPropagation()"
                  [featureCollection]="formControl.value"
                  (featureCollectionChange)="drawItemGroupChange($event)"
                  [drawConfig]="drawConfig"
    ></osm-draw-map>
</mat-expansion-panel>

<!-- <code>
  <pre>{{mapTouched |json}}</pre>
</code> -->
