import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';

import { AdminLayoutComponent } from '@theme/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '@theme/auth-layout/auth-layout.component';
import { DashboardComponent } from './nd/dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { LandingComponent } from 'app/routes/landing/landing.component';
import { AuthGuard } from '@core';
import { UserSettingsComponent } from '@shared/components/user-settings/user-settings.component';

const routes: Routes = [
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [ AuthGuard ],
        canActivateChild: [ AuthGuard ],
        children: [
            { path: '', redirectTo: 'nd/kpi', pathMatch: 'full' },
            {
                path: 'landing',
                component: LandingComponent,
                data: { title: 'Landing', titleI18n: 'Landing' },
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                data: { title: 'Dashboard', titleI18n: 'Dashboard' },
            },
            {
                path: 'targhe',
                loadChildren: () => import( './targhe/targhe.module' ).then( m => m.TargheModule ),
                data: { title: 'Liste', titleI18n: 'Liste' },
            },
            {
                path: 'sessions',
                loadChildren: () => import( './sessions/sessions.module' ).then( m => m.SessionsModule ),
                data: { title: 'Sessions', titleI18n: 'Sessions' },
            },
            { path: 'nd', loadChildren: () => import( './nd/nd.module' ).then( m => m.NdModule ) },
            {
                path: 'management',
                loadChildren: () => import( './user-manager/user-manager.module' ).then( m => m.UserManagerModule ),
            },
            {
                path: 'user-settings', component: UserSettingsComponent,
            },
            {
                path: 'piani',
                loadChildren: () => import( './piani/piani.module' ).then( m => m.PianiModule ),
                data: { title: 'Piani', titleI18n: 'Piani' },
            },
            {
                path: 'offices-matrix',
                loadChildren: () => import( './offices-matrix/offices-matrix.module' ).then( m => m.OfficesMatrixModule ),
                data: { title: 'Offices Matrix', titleI18n: 'Offices Matrix' },
            },
            {
                path: 'avanzamento-piani',
                loadChildren: () => import( './avanzamento-piani/avanzamento-piani.module' ).then( m => m.AvanzamentoPianiModule ),
                data: { title: 'Avanzamento Piani', titleI18n: 'Avanzamento Piani' },
            },
            {
                path: 'planning',
                loadChildren: () => import( './planning/planning.module' ).then( m => m.PlanningModule ),
                data: { title: 'Planning', titleI18n: 'Planning' },
            },
            {
                path: 'on-air',
                loadChildren: () => import( './on-air/on-air.module' ).then( m => m.OnAirModule ),
                data: { title: 'On air', titleI18n: 'On air' },
            },
            {
                path: 'reporting',
                loadChildren: () => import( './reporting/reporting.module' ).then( m => m.ReportingModule )
            },
            {
                path: 'tracking',
                loadChildren: () => import( './tracking/tracking.module' ).then( m => m.TrackingModule )
            },
            { path: 'ko', loadChildren: () => import( './ko/ko.module' ).then( m => m.KoModule ) },
            {
                path: 'new-site', loadChildren: () => import( './new-site/new-site.module' ).then( m => m.NewSiteModule ),
                data: { title: 'Richieste nuovo office', titleI18n: 'New office requests' }
            },
            {
                path: 'dynamic-columns',
                loadChildren: () => import( './dynamic-columns/dynamic-columns.module' ).then( m => m.DynamicColumnsModule )
            },
            {
                path: 'columns-update',
                loadChildren: () => import( './columns-update/columns-update.module' ).then( m => m.ColumnsUpdateModule )
            },
            { path: 'tool-tx', loadChildren: () => import( './tool-tx/tool-tx.module' ).then( m => m.ToolTxModule ) },
        ],
    },
    {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
                data: { title: 'Login', titleI18n: 'Login' },
            },
            {
                path: 'register',
                component: RegisterComponent,
                data: { title: 'Register', titleI18n: 'Register' },
            },
        ],
    },
    { path: '**', redirectTo: 'dashboard' },
];

@NgModule( {
    imports: [
        RouterModule.forRoot( routes, {
            useHash: environment.useHash,
            enableTracing: environment.enableRouteTracing,
        } ),
    ],
    exports: [ RouterModule ],
} )
export class RoutesRoutingModule {
}
