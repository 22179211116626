export interface IElementsFilterDTO {
    idPlan: number;
    technology: string;
    driver: string;
    type?: string;
    frequency?: number;
    zone?: string;
    subZone?: string;
    high: boolean;
    low: boolean;
    subDriver?: string;
}


export class ElementsFilterDTO implements IElementsFilterDTO {
    idPlan: number;
    technology: string;
    driver: string;
    type?: string;
    frequency?: number;
    zone?: string;
    subZone?: string;
    high: boolean;
    low: boolean;
    subDriver?: string;

    constructor( init: Partial<ElementsFilterDTO> ) {
        Object.assign( this, init );
    }
}
