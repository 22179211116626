<div style="text-align: center; margin-top: 1rem" *ngIf="notifications?.length === 0; else notificationsList">
    No new notifications
</div>

<ng-template #notificationsList>
    <mat-toolbar>Notifications</mat-toolbar>
    <div class="toggle-wrapper">
        <mat-slide-toggle [(ngModel)]="allNotifications">Show all notifications</mat-slide-toggle>
    </div>
    <div class="notifications-wrapper">
        <!-- <p class="btn-wrapper">
            <button mat-button primary (click)="readAllNotifications.emit()">Read all</button>
        </p> -->
        <div *ngFor="let notification of list; trackBy: trackByNotificationId" class="notification" [ngClass]="{ 'read': notification.receptionConfirmed }">
            <div class="notification-wrapper" (click)="openNotification.emit(notification)">
                <p class="notification-content">{{ notification.content.content }}</p>
                <p class="notification-date">{{ notification.content.triggerTime | date:'dd/MM/yyyy HH:mm' }}</p>
            </div>
            <button mat-icon-button (click)="readNotification.emit(notification.id); notification.receptionConfirmed = true" *ngIf="!notification.receptionConfirmed" matTooltip="Read notification">
                <mat-icon>check</mat-icon>
            </button>
        </div>
    </div>
</ng-template>