/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/restrict-template-expressions,@typescript-eslint/no-unsafe-return */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, pluck } from 'rxjs/operators';
import { initializerEnvironment } from '@env/environment';
import { RolesRequest, UserRequest } from '../interfaces/request';

@Injectable( {
    providedIn: 'root',
} )
export class UserManagerService {
    pageIndex = 0;
    pageSize = 10;

    constructor( private http: HttpClient ) {
    }

    //USARE ENV
    register( username: string, password: string, roles: string[], email: string ) {
        const userRequest: UserRequest = {
            email,
            password,
            issuerUser: username,
        };
        const toSend = {
            userRequest,
            userRoleRequests: {
                userName: username,
                rolesRequests: this._buildRoleRequest( roles, username ),
            },
        };
        console.log( toSend );
        return this.http.put<any>( `${ initializerEnvironment.identitySettings.baseUrl }/api/request/AddUserRequest`, toSend );
    }

    getRequests() {
        return this.http.get<any>( `${ initializerEnvironment.identitySettings.baseUrl }/api/request` );
    }

    // noinspection JSUnusedGlobalSymbols
    getUserRoles( username: string ) {
        return this.http.get<any>( `${ initializerEnvironment.identitySettings.baseUrl }/api/v1/roles/roles/${ username }` );
        // .pipe(
        //   map(roles => {
        //     return roles.map( role => {
        //       return role.name
        //     })
        //   })
        // )
    }

    manageUserCreation( row, accepted, action ) {
        row.rolesRequests.forEach( element => {
            element.accepted = accepted;
            element.action = action;
        } );
        return this.http.put<any>( `${ initializerEnvironment.identitySettings.baseUrl }/api/request/MenageUserCreationRequest/${ row.userName }`, row );
    }

    manageRoleRequest( row, accepted, action ) {
        row.rolesRequests.forEach( element => {
            element.accepted = accepted;
            element.action = action;
        } );
        return this.http.put<any>( `${ initializerEnvironment.identitySettings.baseUrl }/api/request/MenageRolesRequest`, row );
    }

    addRoleRequest( body ) {
        return this.http.put<any>( `${ initializerEnvironment.identitySettings.baseUrl }/api/request/AddRoleRequest`, body );
    }

    getAllPendingRoleRequest() {
        // noinspection JSDeprecatedSymbols
        return this.http.get<any>( `${ initializerEnvironment.identitySettings.baseUrl }/api/v1/users`, { params: this.params } ).pipe(
            pluck( 'data' ),
            map( user => user.map( u => ( {
                ...u,
                roles: u.roles.filter( role => role !== 'NoAction' ),
            } ) ) ),
        );
    }

    sendRoleAcceptance( id: string, user: any ) {
        return this.http.put( `${ initializerEnvironment.identitySettings.baseUrl }/api/v1/users/${ id }`, {
            ...user,
            enabled: true,
        } );
    }

    sendRoleRejected( id: string, user: any ) {
        return this.http.put( `${ initializerEnvironment.identitySettings.baseUrl }/api/v1/users/${ id }`, {
            ...user,
            enabled: false,
        } );
    }

    getAllAppRoles( type: string ) {
        return this.http.get<any>( `${ initializerEnvironment.identitySettings.baseUrl }/api/v1/roles/${ type }` )
            .pipe(
                map( roles => roles.map( role => role.name ) ),
            );
    }

    private get params(): HttpParams {
        let temp = new HttpParams()
            .set( 'pageIndex', this.pageIndex )
            .set( 'pageSize', this.pageSize );
        temp = temp
            .set( 'filterColumns', '' )
            .set( 'filterQueries', '' );
        return temp;
    }

    setIndex( index: number ) {
        this.pageIndex = index;
    }

    setSize( size: number ) {
        this.pageSize = size;
    }

    private _buildRoleRequest( roles: string[], issuerUser: string ) {
        if( roles.length === 0 ) {
            return [ {
                role: 'NoAction',
                action: 'create',
                issuerUser,
            } as RolesRequest ];
        }
        return roles.map( ( role, _index ) => ( {
            role,
            action: 'create',
            issuerUser,
        } as RolesRequest ) );
    }
}
