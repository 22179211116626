import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { WndGridColumn } from '@shared/components/wonder-grid/interfaces/wnd-grid-column';
import { IGetWndGridColumnsResponse } from '@shared/components/wonder-grid/interfaces/get-wnd-grid-columns-response';
import { map, take, tap } from 'rxjs/operators';
import { GRID_COLUMNS_URL } from '@shared/models/urls-constants';
import { Store } from '@ngrx/store';
import { GridColumnsActions, GridColumnsSelectors } from '@store/grid-columns-store';

@Injectable( {
    providedIn: 'root'
} )
export class WndGridColumnsApiService {
    columnsEndpoint = `${ this.baseUrl }gridColumns/wndGridColumns`;
    saveColumnsEndpoint = `${ this.baseUrl }gridColumns/columns`;
    columnsCache: { [ key: string ]: WndGridColumn[] } = {};

    constructor( @Inject( GRID_COLUMNS_URL ) private baseUrl: string, private http: HttpClient, private store: Store ) {
    }

    getColumns( year: number, gridName: string ): Observable<WndGridColumn[]> {
        // const gridNameYear = `${gridName}-${year}`;
        // const cachedColumns = this.columnsCache[ gridNameYear ];
        // if( cachedColumns && cachedColumns.length ) {
        //     return of( cachedColumns );
        // } else {
        return this.http.get<IGetWndGridColumnsResponse>( this.columnsEndpoint, { params: { year, gridName } } ).pipe(
            map( res => res.data ),
            //tap( cols => this.columnsCache[ gridNameYear ] = cols ),
            tap( cols => this.store.dispatch( GridColumnsActions.setAllGridColumns( { name: gridName, columns: cols } ) ) )
        );
        return this.store.select( GridColumnsSelectors.selectAllGridColumns( gridName ) ).pipe(
            take( 1 ),
            switchMap( columns =>
                // if( columns ) {
                //     return of( columns );
                // } else {
                this.http.get<IGetWndGridColumnsResponse>( this.columnsEndpoint, { params: { year, gridName } } ).pipe(
                    map( res => res.data ),
                    //tap( cols => this.columnsCache[ gridNameYear ] = cols ),
                    tap( cols => this.store.dispatch( GridColumnsActions.setAllGridColumns( { name: gridName, columns: cols } ) ) )
                )
                // }
            )
        );
        // }
    }

    saveColumns( gridName: string, year: number, columns: WndGridColumn[] ): Observable<IGetWndGridColumnsResponse> {
        return this.http.put<IGetWndGridColumnsResponse>( this.saveColumnsEndpoint, { gridName, year, columns } ).pipe(
            tap( () => this.store.dispatch( GridColumnsActions.setAllGridColumns( { name: gridName, columns } ) ) )
        );
    }
}
