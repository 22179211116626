<!-- <div class="matero-page-header-inner">
  <h1 class="matero-page-title">{{title | translate}} <small>{{subtitle}}</small>  </h1>
  <breadcrumb *ngIf="!hideBreadcrumb" [nav]="nav"></breadcrumb>
</div> -->


<div>
    <mat-toolbar>
        <mat-toolbar-row style="height: 100%;">
            <button *ngIf='showBackButton' mat-icon-button aria-label='Back to previous page'>
                <mat-icon (click)='back()'>arrow_back_ios</mat-icon>
            </button>
            <div>
                <h1>{{title | translate}} <small>{{subtitle}}</small></h1>
                <breadcrumb *ngIf='!hideBreadcrumb' [navs]='navs'></breadcrumb>
            </div>
            <div style="width: 16px"></div>
            <span *ngIf="!hasContent" class='spacer'></span>
            <div class = 'spacer' *ngIf="hasContent"><ng-content></ng-content></div>

            <wnd-year-selector *ngIf="showYearSelector"></wnd-year-selector>

            <button mat-icon-button aria-label='help...' (click)='open_dialog()'>
                <mat-icon>help</mat-icon>
            </button>
        </mat-toolbar-row>

<!--        <mat-toolbar-row *ngIf="hasContent">-->
<!--            <ng-content></ng-content>-->
<!--        </mat-toolbar-row>-->

    </mat-toolbar>
</div>


<ng-template #dialogContent>

    <h1 mat-dialog-title>
        <mat-icon>help</mat-icon>
        {{helpBoxTitle}}
    </h1>

    <hr>

    <mat-dialog-content class='mat-typography'>
        <p [innerHTML]="description"></p>
        <br>
    </mat-dialog-content>

    <mat-dialog-actions align='center'>
        <small>per ulteriori informazioni contatta il gruppo</small>
        <!-- <button mat-button mat-dialog-close>Cancel</button> -->
    </mat-dialog-actions>
</ng-template>
