import { createAction, props } from '@ngrx/store';
import { WndGridColumn } from '@shared/components/wonder-grid/interfaces/wnd-grid-column';

const tag = '[grid-columns]';

export const loadGridsColumns = createAction( `${ tag } load grids columns` );
//export const setGridsColumns = createAction( `${ tag } set grids columns`, props<{ columns: { [key: string]: string[] } }>() );
export const loadGridColumns = createAction( `${ tag } load grid columns`, props<{ name: string }>() );
export const setGridColumns = createAction( `${ tag } set grid columns`, props<{ name: string; set: { name: string; columns: string[] }[] }>() );
export const setGridUpdateAt = createAction( `${ tag } set grid update at`, props<{ name: string; updatedAt: string }>() );
export const selectColumnsSet = createAction( `${ tag } select columns set`, props<{ gridName: string; setNames: string[] }>() );
export const setAllGridColumns = createAction( `${ tag } set all grid columns`, props<{ name: string; columns: WndGridColumn[] }>() );
