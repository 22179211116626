import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { StepConfig } from 'app/routes/nd/lavorazioni/step_config';
import { FormlyFormOptions } from '@ngx-formly/core';
import { UntypedFormGroup } from '@angular/forms';
import { ServerCommunicationService } from 'app/routes/nd/services/server-communication.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component( {
    selector: 'wnd-comunicazioni-edit',
    templateUrl: './comunicazioni-edit.component.html',
    styleUrls: [ './comunicazioni-edit.component.scss' ],
} )
export class ComunicazioniEditComponent implements OnInit, AfterViewInit {
    form2 = new UntypedFormGroup( {} );
    model2: any = {};
    options: FormlyFormOptions = {};
    edit = false;
    stepId: number;
    comId: number;
    stepConfig: StepConfig;

    constructor(
        private serverCommunicationService: ServerCommunicationService,
        public dialogRef: MatDialogRef<ComunicazioniEditComponent>,
        protected cdr: ChangeDetectorRef,
        @Inject( MAT_DIALOG_DATA ) public data: any,
    ) {
    }

    ngOnInit(): void {
        this.stepId = this.data.record.idStep;
        this.stepConfig = this.data.stepConfig;
        if( this.data.record.id ) {
            this.edit = true;
            this.comId = this.data.record.id;
            this._scriptFields( this.data.record );
        } else {
            this.edit = false;
        }
    }

    ngAfterViewInit() {
        if( !this.edit ) {
            this.options.resetModel();
            this.cdr.detectChanges();
        }
    }

    submit() {
        this.model2.IdStep = this.stepId;
        if( this.comId !== undefined ) {
            this.model2.id = this.comId;
        }
        this.serverCommunicationService.saveCommunication( this.edit, this.model2, true ).subscribe( x => {
            if( x ) {
                this.comId = x;
                this.edit = true;
            }
            this.dialogRef.close( { refreshComTab: true } );
        } );
    }

    cancel(): void {
        this.dialogRef.close( { refreshComTab: false } );
    }

    clear() {
        this.options.resetModel();
    }

    private _scriptFields( received: any ) {
        this.stepConfig.fields = this.stepConfig.fields.map( f => {
            if( f.key ) {
                f.defaultValue = received[ f.key as string ];
            } else {
                if( f.fieldGroup ) {
                    f.fieldGroup = f.fieldGroup.map( fi => {
                        if( fi.key ) {
                            fi.defaultValue = received[ fi.key as string ];
                        }
                        return fi;
                    } );
                }
            }
            return f;
        } );
    }
}
