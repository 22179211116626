import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

export const PANEL_CLOSE = 'close';
export const PANEL_OPEN = 'open';
export const PANEL_TOGGLE = 'toggle';


export type EventType = typeof PANEL_CLOSE | typeof PANEL_OPEN | typeof PANEL_TOGGLE;

@Injectable( {
    providedIn: 'root'
} )
export class SidebarStateService {


    private notificationPanel = new Subject<EventType>();
    public notificationPanel$ = this.notificationPanel.asObservable();


    constructor() {
    }

    closeNotificationPanel() {
        this.notificationPanel.next( PANEL_CLOSE );
    }

    openNotificationPanel() {
        this.notificationPanel.next( PANEL_OPEN );
    }

    toggleNotificationPanel() {
        this.notificationPanel.next( PANEL_TOGGLE );
    }
}
