export enum NotificationEventType {
    FORECAST_REQUEST_CREATION = 'FORECAST_REQUEST_CREATION',
    FORECAST_REQUEST_APPROVAL = 'FORECAST_REQUEST_APPROVAL',
    BASELINE_PREVIEW = 'BASELINE_PREVIEW',
    BASELINE_PUBLICATION = 'BASELINE_PUBLICATION',
    FORECAST_REQUEST_REJECTION = 'FORECAST_REQUEST_REJECTION',
    GRID_IMPORT = 'GRID_IMPORT',
    NEW_OFFICE_SENT = 'NEW_OFFICE_SENT',
    NEW_OFFICE_IN_EVALUATION = 'NEW_OFFICE_IN_EVALUATION',
    NEW_OFFICE_IMPORT_ERROR = 'NEW_OFFICE_IMPORT_ERROR',
    NEW_OFFICE_NEW_CLASSIFICATION = 'NEW_OFFICE_NEW_CLASSIFICATION',
    NEW_OFFICE_IMPORT_SUCCESFULLY = 'NEW_OFFICE_IMPORT_SUCCESFULLY',
    NEW_OFFICE_EVALUATION_REFUSED = 'NEW_OFFICE_EVALUATION_REFUSED',
    NEW_OFFICE_CLASSIFICATION_REFUSED = 'NEW_OFFICE_CLASSIFICATION_REFUSED',
    NEW_OFFICE_CLASSIFIED = 'NEW_OFFICE_CLASSIFIED',
    NEW_OFFICE_IN_CLASSIFICATION = 'NEW_OFFICE_IN_CLASSIFICATION',
    NEW_OFFICE_EVALUATED = 'NEW_OFFICE_EVALUATED',
    NEW_OFFICE_BULK_IMPORT = 'NEW_OFFICE_BULK_IMPORT',
    TOOLTX_ALTERNATIVE_PROPOSAL = 'TOOLTX_ALTERNATIVE_PROPOSAL',
    TOOLTX_IN_APPROVED = 'TOOLTX_IN_APPROVED',
    TOOLTX_APPROVED_PROJECT = 'TOOLTX_APPROVED_PROJECT',
    TOOLTX_IN_EVALUATION = 'TOOLTX_IN_EVALUATION',
    TOOLTX_MODIFIED_PROPOSAL = 'TOOLTX_MODIFIED_PROPOSAL',
    TOOLTX_PROJECT_IN_EVALUATION = 'TOOLTX_PROJECT_IN_EVALUATION',
    TOOLTX_REFUSED_BASELINE = 'TOOLTX_REFUSED_BASELINE',
    TOOLTX_REFUSED = 'TOOLTX_REFUSED',
    TOOLTX_REFUSED_PROPOSAL = 'TOOLTX_REFUSED_PROPOSAL',
}

export interface NotificationContent {
    id: number;
    content: string;
    triggerTime: string;
    triggerEvent: string;
    eventType: NotificationEventType;
    eventData: { [ key: string ]: string };
}

export interface Notification {
    id: number;
    idContent: number;
    content: NotificationContent;
    recipientUsername: string;
    receptionConfirmed: boolean;
}
