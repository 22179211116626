import { Action, createReducer, on } from '@ngrx/store';
import { DashboardPianiActions, DashboardPianiState } from './index';
import produce from 'immer';
import { IPanel } from '@piani/models/dashboard/panel.interface';
import { IDriver } from '@piani/models/dashboard/driver.interface';
import { IMonth } from '@piani/models/dashboard/month.interface';
import { IPeriod } from '@piani/models/dashboard/period.interface';
import { panelFromType } from '@piani/services/dashboard-utils';

const getForecastElements = ( elements: IDriver[] ): IDriver[] => elements.map( ( val ) => ( {
    ...val,
    panels: val.panels.map( p => ( {
        ...p,
        months: p.months.map( m => ( {
            ...m,
            periods: m.periods.map( ( pd, i ) => ( { ...pd, periodId: m.periodId * 10 + i + 1 } ) )
        } ) )
    } ) )
} ) );

const dashboardPianiReducers = createReducer( DashboardPianiState.initialState,
    on( DashboardPianiActions.dashboardLoaded, ( state, { elements } ) => {
        const arrayRes = elements.map( ( e: IDriver ) => produce( e.panels, newPanels => {
            // Putting level and italic inside panel obj
            // Setting visible true for all panels
            newPanels.forEach( ( p ) => {
                p.idDriver = e.id;
                p.driverLevel = e.level;
                p.driverItalic = e.italic;
                p.visible = true;
            } );
        } ) );
        const panels = new Array<IPanel>();
        const distinctPanels = new Array<IPanel>();
        arrayRes.forEach( ( pList: IPanel[] ) => {
            pList.forEach( ( p: IPanel ) => {
                panels.push( { ...p } );
                if( !distinctPanels.find( ( e ) => e.name === p.name ) ) {
                    distinctPanels.push( p );
                }
            } );
        } );

        return ( {
            ...state, dashboardElements: elements,
            dashboardDrivers: elements.map( e => ( { ...e, panels: null } ) ),
            distinctPanels,
            panels
        } );
    } ),
    on( DashboardPianiActions.updateValue, ( state, value ) => produce( state, draft => {
        const panelIndex = draft.panels.findIndex( p => p.id === panelFromType( value.idTipoDato ) && p.idDriver === value.idDriver );
        if( panelIndex >= 0 ) {
            const flattenMonths = draft.panels[ panelIndex ].months.reduce( ( acc: ( IMonth | IPeriod )[], m ) => ( m.periods.length ? [ ...acc, ...m.periods ] : [ ...acc, m ] ), [] );
            const newMonth = flattenMonths.find( m => m.id === value.idDataRif && m.periodId === value.idTipoDato );
            newMonth.value = value.valore;
            console.log( 'Updating', panelIndex, newMonth );
        }
        console.log( 'Updating', value );
    } ) ),
    on( DashboardPianiActions.dashboardReset, state => ( { ...state, dashboardElements: undefined, dashboardDrivers: [], panels: [], distinctPanels: [] } ) ),
    on( DashboardPianiActions.changePanelVisibility, ( state, visibility ) => produce( state,
        draft => {
            draft.panelVisibility[ visibility.id ] = visibility.visible;
            console.log( 'Visibility changed', visibility );
        } ) ),
    on( DashboardPianiActions.changeSelectedCategory, ( state, { idCategory } ) => ( { ...state, selectedCategory: idCategory } ) ),
    on( DashboardPianiActions.changeSelectedZone, ( state, { idZone } ) => ( { ...state, selectedZone: idZone } ) ),
    // on( DashboardPianiActions.changeSelectedYear, ( state, { fy } ) => ( { ...state, selectedYear: fy } ) ),
    on( DashboardPianiActions.forecastLoaded, ( state, { elements } ) =>
        ( {
            ...state,
            forecastElements: getForecastElements( elements ),
            forecastValuesToBeSaved: [],
            forecastSaving: false,
        } ) ),
    on( DashboardPianiActions.changeSelectedForecastZone, ( state, { idZone } ) =>
        ( { ...state, selectedForecastZone: idZone, forecastLoading: true, } ) ),
    on( DashboardPianiActions.changeSelectedForecastMonth, ( state, { idMonth } ) =>
        idMonth ? ( { ...state, selectedForecastMonth: idMonth, forecastLoading: true, } ) : ( {
            ...state,
            selectedForecastMonth: idMonth,
            forecastElements: [],
            forecastRequest: undefined
        } )
    ),
    on( DashboardPianiActions.changeSelectedForecastFilter, ( state, { idZone, idMonth } ) => (
        ( idMonth && idZone ) ? ( {
            ...state,
            selectedForecastZone: idZone,
            selectedForecastMonth: idMonth,
            forecastLoading: true
        } ) : ( { ...state, selectedForecastZone: idZone, selectedForecastMonth: idMonth, forecastElements: [], forecastRequest: undefined } ) ) ),
    on( DashboardPianiActions.loadForecastRequest, ( state ) => {
        console.log( 'Loading forecast' );
        return { ...state, forecastLoading: true };
    } ),
    on( DashboardPianiActions.forecastRequestLoaded, ( state, { forecastRequest } ) => ( { ...state, forecastLoading: false, forecastRequest } ) ),
    on( DashboardPianiActions.forecastRequestLoadFailure, ( state ) => ( { ...state, forecastLoading: false, forecastRequest: undefined } ) ),
    on( DashboardPianiActions.approvalRequestsLoaded, ( state, { approvalRequests } ) => ( { ...state, approvalRequests } ) ),
    on( DashboardPianiActions.refusedRequestsLoaded, ( state, { refusedRequests } ) => ( { ...state, refusedRequests } ) ),

    // Update or insert a value to be saved.
    on( DashboardPianiActions.updateForecastValue, ( state, { value } ) => produce( state, draft => {
        const valueToChange = draft.forecastValuesToBeSaved.find( v => v.idDriver === value.idDriver && v.idMonth === value.idMonth &&
            v.idZone === value.idZone && v.subZone === value.subZone );
        if( valueToChange ) {
            valueToChange.value = value.value;
        } else {
            draft.forecastValuesToBeSaved.push( value );
        }
        // get forecast panel
        /*
        console.group( 'Update forecast value', value );
        const panel = draft.forecastElements[ value.idDriver ].panels.find( p => p.id === 5 );
        if( panel ) {
            console.debug( panel.name );
            const month = panel.months.find( m => m.id === value.idMonth );
            if( month ) {
                console.debug( month.name );
                if( month.name === value.subZone ) {
                    month.value = value.value;
                } else {
                    const period = month.periods.find( p => p.name === value.subZone );
                    if( period ) {
                        console.debug( period.name );
                        period.value = value.value;
                    }
                }
            }
        }
        console.groupEnd();
        */
    } ) ),
    on( DashboardPianiActions.saveForecastValues, ( state ) => ( { ...state, forecastSaving: true } ) ),
    on( DashboardPianiActions.reloadForecast, ( state ) => ( { ...state, forecastSaving: true } ) ),
    on( DashboardPianiActions.forecastSaved, ( state, { elements } ) => ( {
        ...state,
        forecastSaving: false,
        forecastElements: getForecastElements( elements ),
        // forecastElements: elements.map( e => ( {
        //     ...e,
        //     panels: e.panels.map( p => ( { ...p, months: p.months.map( m => ( { ...m, periods: m.periods.map( ( pd, i ) => ( { ...pd, periodId: m.periodId * 10 + i + 1 } ) ) } ) ) } ) )
        // } ) ),
        forecastValuesToBeSaved: []
    } ) ),
    on( DashboardPianiActions.forecastSaveFailure, ( state ) => ( { ...state, forecastSaving: false } ) ),
    on( DashboardPianiActions.setCanEditDashboard, ( state, { canEdit } ) => ( { ...state, canEdit } ) ),
    on( DashboardPianiActions.changeViewSelectedMonth, ( state, { viewSelectedMonth } ) => ( { ...state, viewSelectedMonth } ) ),
);

export const reducers = ( state: DashboardPianiState.State | undefined, action: Action ): DashboardPianiState.State => dashboardPianiReducers( state, action );

