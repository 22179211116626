import { Injectable } from '@angular/core';

@Injectable( {
    providedIn: 'root'
} )
export class DownloadService {

    constructor() {
    }

    /**
     * Takes a blob and a filename and simulate a link to download the blob.
     *
     * @param blob
     * @param fileName
     * @private
     */
    public triggerSaveAs( blob: Blob, fileName: string ): void {
        const link = document.createElement( 'a' );
        link.href = URL.createObjectURL( blob );
        link.setAttribute( 'visibility', 'hidden' );
        link.download = fileName;
        document.body.appendChild( link );
        link.click();
        document.body.removeChild( link );
    }
}
