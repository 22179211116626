<mat-toolbar class="matero-toolbar">
    <button mat-icon-button class="matero-toolbar-button" *ngIf="showToggle"
            matTooltip="Nascondi il menu laterale"
            (click)="toggleSidenav.emit()">
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button class="matero-toolbar-button"
            matTooltip="Espandi la finestra dell'applicazione a schermo intero"
            (click)="toggleFullscreen()">
        <mat-icon>fullscreen</mat-icon>
    </button>

    <app-branding *ngIf="showBranding"></app-branding>
    <span fxFlex></span>

    <!-- <button mat-icon-button class="matero-toolbar-button">
      <mat-icon>search</mat-icon>
    </button> -->

    <!-- <app-notification fxHide.lt-sm></app-notification>

    <app-translate></app-translate> -->

    <button *ngIf="environment.isLocalDev" mat-button (click)="onClearCache()">Clear cache</button>
    <app-user></app-user>

    <button mat-icon-button class="matero-toolbar-button" fxHide.lt-sm
            (click)="toggleSidenavNotice.emit()">
        <mat-icon [matBadge]="notificationsCount" [matBadgeHidden]="notificationsCount === 0">notifications</mat-icon>
    </button>
</mat-toolbar>
