import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { Destroyable } from '@shared/classes/destroyable';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { IInputTypeaheadDataSource } from '@shared/components/input-typeahead/interfaces/input-typeahead-data-source';
import { DummyDataSource } from '@shared/components/input-typeahead/classes/dummy-data-source';

@Component( {
    selector: 'wnd-input-typeahead',
    templateUrl: './input-typeahead.component.html',
    styleUrls: [ './input-typeahead.component.scss' ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: InputTypeaheadComponent,
        },
    ],

} )
export class InputTypeaheadComponent extends Destroyable implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() label = '';
    @Input() dataSource: IInputTypeaheadDataSource = new DummyDataSource();

    @Output() valueChange = new EventEmitter<string>();

    autoCompleteList$: Observable<string[]>;

    touched = false;

    control = new FormControl<string>( '' );

    panelListHeight = '0px';

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.autoCompleteList$ = this.dataSource.connect().pipe( tap( res => this.panelListHeight = res.length < 5 ? ( res.length * 48 ).toString() + 'px' : '240px' ) );
        this.control.valueChanges.pipe( takeUntil( this.$destroy ), distinctUntilChanged() ).subscribe( value => this.changeValue( value ) );
    }

    ngOnDestroy() {
        this.dataSource.disconnect();
        super.ngOnDestroy();
    }

    markAsTouched() {
        if( !this.touched ) {
            this.onTouched();
            this.touched = true;
        }
    }

    //region ControlValueAccessor
    onChange = ( _value: string ) => {
    };

    onTouched = () => {
    };


    registerOnChange( fn: any ): void {
        this.onChange = fn as ( value: string ) => void;
    }

    registerOnTouched( fn: any ): void {
        this.onTouched = fn as () => void;
    }

    writeValue( value: string ): void {
        this.control.setValue( value );
    }

    setDisabledState( isDisabled: boolean ): void {
        if( isDisabled ) this.control.disable(); else this.control.enable();
    }

    //endregion

    elementSelected( _$event: MatAutocompleteSelectedEvent ) {
        this.onChange( this.control.value );
        this.markAsTouched();
    }

    onValueChange(  ) {
        this.valueChange.emit( this.control.value );
    }

    private changeValue( value: string ) {
        this.onChange( value );
        this.dataSource.setFilter( value );
    }
}
