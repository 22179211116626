import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContrattiState } from '@store/contratti-store/index';

export const selectContrattiState = createFeatureSelector<ContrattiState.State>( ContrattiState.featureKey );
export const selectContratti = createSelector( selectContrattiState, ( state ) => state.contratti );
export const selectViewingOffice = createSelector( selectContrattiState, ( state ) => state.viewingOffice );
export const selectLoading = createSelector( selectContrattiState, ( state ) => state.loading );
export const selectListFilter = createSelector( selectContrattiState, ( state ) => state.listFilter );
export const selectContratto = ( idContratto: number ) => createSelector( selectContrattiState, ( state ) => state.contratti[ idContratto ] );

