import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IOfficeView } from '@shared/models/office-view';

@Component( {
    selector: 'wnd-office-details-dialog',
    templateUrl: './office-details-dialog.component.html',
    styleUrls: [ './office-details-dialog.component.scss' ]
} )
export class OfficeDetailsDialogComponent implements OnInit {
    officeView: IOfficeView;

    constructor(
        private dialogRef: MatDialogRef<OfficeDetailsDialogComponent>,
        @Inject( MAT_DIALOG_DATA ) private data: { officeView: IOfficeView },
    ) {
    }

    ngOnInit(): void {
        this.officeView = this.data.officeView;
    }

    closeMe() {
        this.dialogRef.close();
    }
}
