import { ActivityConfig } from './activity_config';
import { extraFlowNPlus, extraMotivazioneRecesso } from './extraFlow';

// workFlow
export const dismissione: ActivityConfig = {
    extraFlowConfig: [
        {
            name: 'Ext_Motivazione Recesso',
            displayName: 'Motivazione Recesso',
            fields: extraMotivazioneRecesso,
        },
        {
            name: 'Ext_Legame NPlus',
            displayName: 'Legame NPlus',
            fields: extraFlowNPlus,
        },
    ],
    name: 'dismissione',
    displayName: 'dismissione',
    stepsConfig: [
        {
            displayName: 'Richiesta e Ottenimento Permessi',
            name: '200_Richiesta e Ottenimento Permessi',
            isEntryStep: true,
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data di Riferimento',
                            },
                        },
                        // {
                        //   className: 'col-sm-6',
                        //   type: 'singleFile',
                        //   key: 'DocDataRiferimento',
                        //   templateOptions: {
                        //     //disableIfCommited: true,
                        //     custom: true,
                        //     label: 'Upload file Data di Riferimento',
                        //     //required: true,
                        //     fileProperties: {
                        //       id: '1',
                        //       allowedExstension: ['.pdf', '.p7m', '.doc', '.docx', '.xlsx']
                        //     },
                        //   },
                        //},
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data di Permesso Ottenuto',
                            },
                        },
                        // {
                        //   className: 'col-sm-6',
                        //   type: 'singleFile',
                        //   key: 'DocPermesso ttenuto',
                        //   templateOptions: {
                        //     //disableIfCommited: true,
                        //     custom: true,
                        //     label: 'Upload file Permesso Ottenuto',
                        //     //required: true,
                        //     fileProperties: {
                        //       id: '1',
                        //       allowedExstension: ['.pdf', '.p7m', '.doc', '.docx', '.xlsx']
                        //     },
                        //   },
                        // },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Data di Spegnimento',
                            },
                        },

                    ],
                },
            ],
            nextStep: m => {
                return '400_Dismissione e Cantiere';
            },
        },
        {
            displayName: 'Dismissione e Cantiere',
            name: '400_Dismissione e Cantiere',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Data di Dismissione (effettivo rilascio)',
                            },
                        },
                        // {
                        //   className: 'col-sm-6',
                        //   type: 'singleFile',
                        //   key: 'DocSiteDisinstallationReport',
                        //   templateOptions: {
                        //     //disableIfCommited: true,
                        //     custom: true,
                        //     label: 'Site Disinstallation Report',
                        //     //required: true,
                        //     fileProperties: {
                        //       id: '1',
                        //       allowedExstension: ['.pdf', '.p7m', '.doc', '.docx', '.xlsx']
                        //     },
                        //   },
                        // },
                    ],
                },


                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data di Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InizioLavori',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'InizioLavori',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data di Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FineLavori',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'FineLavori',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
        {
            displayName: 'Chiusura attività',
            name: '999_Chiusura attività',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Data chiusura attività',
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
