import { WndGridColumnQueryEvent } from '@shared/components/wonder-grid/helpers/wnd-grid-column-search';
import { EntityState } from '@ngrx/entity';

/**
 * Each object represent a filter. The gridColumnName is a unique string that identify the column inside the grid.
 * The name format must be something <gridName>:<columnName>
 * So that there is a big flat filters' table
 */
export interface IGridFiltersCollection {
    gridColumnName: string;
    filter: WndGridColumnQueryEvent;
}

export interface State extends EntityState<IGridFiltersCollection> {

}

export const featureKey = 'grid-filters';
