import { createAction, props } from '@ngrx/store';
import { DashboardValueDTO } from '@piani/models/dashboard/dashboard-value-dto';
import { PanelVisibility } from '@piani/models/dashboard/panel-visibility';
import { IDriver } from '@piani/models/dashboard/driver.interface';
import { IForecastValueDTO } from '@piani/models/dashboard/forecast-value-dto';
import { IForecastRequest } from '@piani/models/dashboard/forecast-request';

const dashboardTag = '[dashboard-piani]';
const forecastTag = '[forecast-piani]';

export const loadDashboard = createAction( `${ dashboardTag } load dashboard` );
export const dashboardLoaded = createAction( `${ dashboardTag } dashboard loaded`, props<{ elements: IDriver[] }>() );
export const dashboardLoadFailure = createAction( `${ dashboardTag } dashboard load failure` );

export const dashboardReset = createAction( `${ dashboardTag } dashboard reset` );

export const updateValue = createAction( `${ dashboardTag } update value`, props<DashboardValueDTO>() );
export const valueUpdated = createAction( `${ dashboardTag } value updated` );
export const valueUpdateFailure = createAction( `${ dashboardTag } value update failure` );

export const changePanelVisibility = createAction( `${ dashboardTag } change panel visibility`, props<PanelVisibility>() );

export const changeSelectedCategory = createAction( `${ dashboardTag } change selected category`, props<{ idCategory: number }>() );
export const changeSelectedZone = createAction( `${ dashboardTag } change selected zone`, props<{ idZone: number }>() );
export const changeSelectedYear = createAction( `${ dashboardTag } change selected year`, props<{ fy: number }>() );

export const changeSelectedForecastZone = createAction( `${ forecastTag } change selected zone`, props<{ idZone: number }>() );
export const changeSelectedForecastMonth = createAction( `${ forecastTag } change selected month`, props<{ idMonth: number }>() );
export const changeSelectedForecastFilter = createAction( `${ forecastTag } change selected forecast filter`, props<{ idZone: number; idMonth: number }>() );

export const loadForecast = createAction( `${ forecastTag } load forecast` );
export const reloadForecast = createAction( `${ forecastTag } reload forecast` );
export const forecastLoaded = createAction( `${ forecastTag } forecast loaded`, props<{ elements: IDriver[] }>() );
export const forecastLoadFailure = createAction( `${ forecastTag } forecast load failure` );

export const updateForecastValue = createAction( `${ forecastTag } update value`, props<{ value: IForecastValueDTO }>() );
export const saveForecastValues = createAction( `${ forecastTag } save forecast values` );
export const forecastSaved = createAction( `${ forecastTag } saved`, props<{ elements: IDriver[] }>() );
export const forecastSaveFailure = createAction( `${ forecastTag } save failure` );

export const forecastValueUpdated = createAction( `${ forecastTag } value updated` );
export const forecastValueUpdateFailure = createAction( `${ forecastTag } value update failure` );

export const loadForecastRequest = createAction( `${ forecastTag } load forecast request` );
export const forecastRequestLoaded = createAction( `${ forecastTag } forecast request loaded`, props<{ forecastRequest: IForecastRequest }>() );
export const forecastRequestLoadFailure = createAction( `${ forecastTag } forecast request load failure` );
export const forecastApprovalRequest = createAction( `${ forecastTag } approval forecast request` );
export const forecastRequestApprovalFailure = createAction( `${ forecastTag } approval forecast request failure` );

export const loadApprovalRequests = createAction( `${ forecastTag } load approval requests` );
export const approvalRequestsLoaded = createAction( `${ forecastTag } approval requests loaded`, props<{ approvalRequests: IForecastRequest[] }>() );
export const approvalRequestsLoadFailure = createAction( `${ forecastTag } approval requests load failure` );

export const forecastApproveRequest = createAction( `${ forecastTag } approve forecast request` );
export const forecastRequestApproveFailure = createAction( `${ forecastTag } approve forecast request failure` );

export const loadRefusedRequests = createAction( `${ forecastTag } load refused requests` );
export const refusedRequestsLoaded = createAction( `${ forecastTag } refused requests loaded`, props<{ refusedRequests: IForecastRequest[] }>() );
export const refusedRequestsLoadFailure = createAction( `${ forecastTag } refused requests load failure` );

export const setCanEditDashboard = createAction( `${ forecastTag } set can edit dashboard`, props<{ canEdit: boolean }>() );
export const changeViewSelectedMonth = createAction( `${ forecastTag } change view selected month`, props<{ viewSelectedMonth: boolean }>() );

