import { Component, OnInit } from '@angular/core';
import { ApiUserManagementService } from '../user-manager/services/api-user-management.service';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';

@Component( {
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: [ './landing.component.scss' ],
} )
export class LandingComponent implements OnInit {
    requested = true;
    rejected = false;

    constructor( private api: ApiUserManagementService, private toast: ToastrService ) {
    }

    ngOnInit(): void {
        this.api.getMyRoleRequest().pipe(
            tap( req => this.requested = !!req && !req.approved && !req.rejected ),
            tap( req => this.rejected = !!req && !req.approved && req.rejected )
        ).subscribe();
    }

    onRoleRequest(): void {
        this.api.requestRole().subscribe( {
            next: () => {
                this.toast.success( 'La richiesta è stata inviata' );
                this.requested = true;
            },
            error: ( err ) => {
                console.error( 'Errore nel richiedere un ruolo', err );
                this.toast.error( 'Errore nel richiedere un ruolo' );
            },
        } );
    }
}
