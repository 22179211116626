import { Pipe, PipeTransform } from '@angular/core';
import { TokenService } from '@core';
import { map } from 'rxjs';

@Pipe( { name: 'requiredPolicy' } )
export class RequiredPolicyPipe implements PipeTransform {
    constructor( private tokenService: TokenService ) {}

    transform( policy: string ) {
        return this.tokenService.currentIdentityData().pipe(
            map( user => {
                const policies = user.authzData.policies;
                return policies.includes( policy );
            } ),
        );
    }
}
