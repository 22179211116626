<div fxLayout="row" fxLayout.lt-md="column">
    <mat-card fxLayout="column" fxLayoutAlign="space-around center" class="m-x-8">
        <mat-card-title>{{ user.authData.name }} {{ user.authData.surname }}</mat-card-title>
        <!--        <mat-card-title>{{ user.username }}</mat-card-title>-->

        <div class="container">
            <img alt='User avatar' #profileimg [src]="settings.getAvatar$() | async" class="image matero-avatar"/>
            <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    [ngClass]="{ overlay: true, uploading: isUploading }"
            >
                <a
                        *ngIf="!isUploading"
                        matTooltip="Modifica immagine del profilo"
                        (click)="inputFileClick()"
                        mat-icon-button
                >
                    <mat-icon>add_a_photo</mat-icon>
                </a>
                <a
                        *ngIf="(settings.hasAvatar$() | async) && !isUploading"
                        matTooltip="Rimuovi immagine del profilo"
                        (click)="delete()"
                        mat-icon-button
                >
                    <mat-icon>delete</mat-icon>
                </a>
                <mat-spinner *ngIf="isUploading"></mat-spinner>
            </div>
            <input
                #inputFile
                id="inputFile"
                type="file"
                hidden
                [tus-uploader]="tusConfig"
                (uploadEvents)="onUploadEvent($event)"
                (selectionError)="onSelectionError($event)"
            />
        </div>
    </mat-card>
    <mat-card class="m-x-8">
        <mat-card-title class="title">Anagrafica</mat-card-title>
        <div fxLayout="row" class="m-t-16">
            <mat-label>Utente: {{ user.username }}</mat-label>
        </div>
        <hr/>
        <div fxLayout="row" class="m-t-16">
            <mat-label>Email: {{ user.authData.email }}</mat-label>
        </div>
        <hr/>
        <div fxLayout="row" fxLayoutAlign="start center" class="m-t-24">
            <mat-label style="width: 80px">Ruoli Attivi:</mat-label>
            <mat-chip-list>
                <mat-chip class="no-hover" disabled *ngFor="let role of getRoles()" style="margin-left: 5px">
                    {{ role }}
                </mat-chip>
            </mat-chip-list>
        </div>
        <!--
                <hr/>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="m-t-24" *ngIf="!isAdmin()">
                    <mat-label style="width: 180px">Richiedi Ruoli:</mat-label>
                    <mat-select multiple (selectionChange)="onChange($event)">
                        <mat-option *ngFor="let role of selectableRoles" [value]="role">
                            {{ role }}
                        </mat-option>
                    </mat-select>
                </div>
                <hr *ngIf="!isAdmin()"/>
                <button
                        mat-raised-button
                        id="register-btn"
                        color="primary"
                        class="m-t-24 pushed"
                        (click)="onClick()"
                        *ngIf="!isAdmin()"
                >
                    Save
                </button>
                <button
                        mat-raised-button
                        id="admin-btn"
                        color="primary"
                        class="pushedB"
                        (click)="goToManagement()"
                        *ngIf="isAdmin()"
                >
                    Gestione utenze
                </button>
        -->
    </mat-card>
</div>
