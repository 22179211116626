import { Struttura } from '../interfaces/struttura';
import { Observable } from 'rxjs/internal/Observable';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, expand, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StrutturaLight } from '../interfaces/struttura_light';
import { IInventoryByTermResponse } from '../interfaces/office-dto';
import { IOfficeSearchView } from '../interfaces/office-search-view';
import { BASE_URL, OFFICES_URL } from '@shared/models/urls-constants';
import { STRUCTURES_MOCK } from './search.mock';

// noinspection JSMismatchedCollectionQueryUpdate
@Injectable()
export class SearchService {
    private mocked = false;
    private inventoryUrl = 'api/v1/inventory';
    private _loading$ = new BehaviorSubject<boolean>( false );
    private _search$ = new Subject<string>();
    public _trigger$ = new Subject<void>();
    private _total$ = new BehaviorSubject<number>( 0 );
    private _page = 1;
    private _pageSize = 1;
    private _searchTerm = '';
    private _bufferData: IOfficeSearchView[] = [];
    private _alreadySearched = '';
    private _refreshPlace = new BehaviorSubject<string[]>( [ 'MACRO', 'MICRO' ] );

    private lastPieceInventory: StrutturaLight[] = [];
    private structureInventory: StrutturaLight[] = [];
    private _structureInventoryPagCnt = 0;
    private _behaviorSubStructInv = new BehaviorSubject<StrutturaLight[]>( [] );

    private showTBD = false;

    constructor( private http: HttpClient, @Inject( BASE_URL ) private baseUrl: string, @Inject( OFFICES_URL ) private officesUrl: string ) {
        this._myQuery().subscribe( d => {
            this._bufferData = d;
            this._trigger$.next();
        } );
        this._structures$().subscribe();
        // this._pieceArrived.asObservable().pipe( tap( res => console.debug( 'Offices data arrived', this.structureInventory ) ), map( () => this.structureInventory ) ).subscribe( this._behaviorSubStructInv );
    }

    get myData(): Observable<IOfficeSearchView[]> {
        return this._trigger$.pipe(
            map( () => {
                const tot = this._bufferData.length;
                const start = this.pageSize * ( this.page - 1 );
                let end = start + this.pageSize;
                if( end > tot ) {
                    end = tot;
                }
                return this._bufferData.slice( start, end );
            } ),
        );
    }

    get page() {
        return this._page;
    }

    set page( page: number ) {
        this._page = page;
        this._trigger$.next();
    }

    get pageSize() {
        return this._pageSize;
    }

    set pageSize( pageSize: number ) {
        this._pageSize = pageSize;
        this._trigger$.next();
    }

    // noinspection JSUnusedGlobalSymbols
    get total$() {
        return this._total$.asObservable();
    }

    // noinspection JSUnusedGlobalSymbols
    get loading$() {
        return this._loading$.asObservable();
    }

    set searchTerm( term: string ) {
        term = term.toLowerCase().trim();
        if( this._alreadySearched === '' || this._alreadySearched !== term ) {
            this._alreadySearched = term;
            this._page = 1;
            this._searchTerm = term;
            this._search$.next( term );
        }
    }

    public getOfficeTypesFilter(): string[] {
        return this._refreshPlace.value;
    }

    public wantTBD( w: boolean ) {
        this.showTBD = w;
    }

    // noinspection JSUnusedGlobalSymbols
    public getItemsInBoundBox( box$: Observable<{ north: number; east: number; south: number; west: number }> ): Observable<any[]> {
        const apiBoxUrl = 'general_api/cities/itemByCoords/';
        return box$.pipe(
            debounceTime( 400 ),
            distinctUntilChanged(),
            switchMap( box => {
                if( box ) {
                    return this.http.get<any[]>( `${ this.baseUrl }${ apiBoxUrl }${ box.north }/${ box.east }/${ box.south }/${ box.west }` );
                } else {
                    return of( [] );
                }
            } ),
            catchError( err => {
                console.log( err );
                return of( [] );
            } ),
        );
    }

    public refreshStructureInventory( filter: string[] ) {
        this.structureInventory = [];
        this._refreshPlace.next( filter );
    }

    public strutturaById( id: number | string ): Observable<Struttura> {
        return this.http.get<Struttura>( `${ this.baseUrl }api/Struttura/${ id }` );
    }

    get structureInventory$(): BehaviorSubject<StrutturaLight[]> {
        return this._behaviorSubStructInv;
    }

    private _myQuery(): Observable<IOfficeSearchView[]> {
        return this._search$.pipe(
            tap( () => this._loading$.next( true ) ),
            debounceTime( 400 ),
            distinctUntilChanged(),
            switchMap( term => this.searchEntries( term, this._refreshPlace.value ).pipe(
                tap( x => {
                    this._loading$.next( false );
                    this._total$.next( x.length );
                    this._page = 1;
                } ),
            ) ),
            catchError( err => {
                console.log( err );
                this._loading$.next( false );
                this._total$.next( 0 );
                this._page = 1;
                return of( [] );
            } ),
        );
    }

    private searchEntries( term: string, officeTypes: string[] ): Observable<IOfficeSearchView[]> {
        if( term === '' ) {
            return of( [] as IOfficeSearchView[] );
        } else {
            let params = new HttpParams();
            officeTypes.forEach( t => params = params.append( 'officeTypes', t ) );
            return this.http.get<IInventoryByTermResponse>( `${ this.officesUrl }${ this.inventoryUrl }/byTerm/${ term }`, { params } ).pipe(
                map( res => res.data ),
            );
        }
    }

    private getStructuresUrl = (): string => `${ this.officesUrl }${ this.inventoryUrl }/${ this._structureInventoryPagCnt }`;

    private getOffices( types: string[], showTBD: boolean ): Observable<{ finished: boolean; data: StrutturaLight[] }> {
        let params = new HttpParams();
        types.forEach( t => params = params.append( 'officeTypes', t ) );
        if( showTBD ) {
            params = params.append( 'withTbd', true );
        }
        return this.http.get<{ finished: boolean; data: StrutturaLight[] }>( this.getStructuresUrl(), { params } ).pipe(
            catchError( () => EMPTY ),
        );
    }

    private _structures$(): Observable<StrutturaLight[]> {
        this._structureInventoryPagCnt = 0;
        return this._refreshPlace.pipe(
            tap( () => this._structureInventoryPagCnt = 0 ),
            switchMap( filter => ( this.mocked ? of( STRUCTURES_MOCK ) : this.getOffices( filter, this.showTBD ) ).pipe(
                expand( res => {
                    this.structureInventory = this.structureInventory.concat( res.data );
                    this.lastPieceInventory = res.data;
                    this._behaviorSubStructInv.next( this.structureInventory );
                    this._structureInventoryPagCnt++;
                    // this._pieceArrived.next();
                    if( !res.finished ) {
                        return this.getOffices( filter, this.showTBD );
                    } else {
                        this._structureInventoryPagCnt = 0;
                        return EMPTY;
                    }
                } ),
                map( res => res.data )
            ) )
        );
    }
}
