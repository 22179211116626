import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

const colors: string[] = [
    '#A76868',
    '#A78E68',
    '#8BA768',
    '#68A3A7',
    '#686FA7',
    '#A668A7',
    '#68A776',
    '#5C6AEF',
    '#2EB594',
    '#C19081',
];

@Component( {
    selector: 'app-key-value-details',
    templateUrl: './key-value-details.component.html',
    styleUrls: [ './key-value-details.component.scss' ],
} )
export class KeyValueDetailsComponent implements OnInit {
    @Input() value: string;
    @Input() key: string;

    barColor: string;

    constructor() {
    }

    ngOnInit(): void {
        const dateTest = /\d+-\d+-\d+T\d+:\d+:\d+Z/g;
        if( dateTest.test( this.value ) ) {
            moment.locale( 'it' );
            const data = moment( this.value, moment.ISO_8601 );
            if( data.isValid() ) {
                this.value = data.local().format( 'L' );
            }
        }
        this.barColor = colors[ Math.floor( Math.random() * colors.length ) ];
    }

}
