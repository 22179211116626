/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-argument */
import { StepService } from '../../step.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { MtxDialog } from '@ng-matero/extensions/dialog';
import { ServerCommunicationService } from 'app/routes/nd/services/server-communication.service';
import { FileUploaderComponent } from '../../file-uploader/file-uploader.component';
import { ComunicazioniEditComponent } from './comunicazioni-edit/comunicazioni-edit.component';
import { CustomFieldTypeComponent } from '../custom-field-type/custom-field-type.component';
import { of } from 'rxjs';
import { MtxGridColumn } from '@ng-matero/extensions/grid';

@Component( {
    selector: 'wnd-communications',
    templateUrl: './communications.component.html',
    styleUrls: [ './communications.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class CommunicationsComponent extends CustomFieldTypeComponent implements OnInit {
    dataColumns: MtxGridColumn[];
    tableButtons: MtxGridColumn = {
        header: 'Operation',
        field: 'operation',
        width: '160px',
        pinned: 'right',
        right: '0px',
        type: 'button',
        buttons: [
            {
                type: 'icon',
                text: 'delete',
                icon: 'attach_file',
                click: record => this.openFileUploader( record ),
            },
            {
                type: 'icon',
                text: 'edit',
                icon: 'edit',
                click: record => this.edit( record ),
            },
            {
                type: 'icon',
                text: 'delete',
                icon: 'delete',
                color: 'warn',
                pop: {
                    title: 'Confirm delete?',
                },
                click: record => this.delete( record ),
            },
        ],
    };
    stepId: number;
    list: any[] = [];
    touched = false;
    panelOpenState = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private serverCommunicationService: ServerCommunicationService,
        public dialog: MtxDialog,
        stepService: StepService,
    ) {
        super( stepService );
    }

    get controlDisabled(): boolean {
        return this.field?.templateOptions?.disabled;
    }

    ngOnInit() {
        super.ngOnInit();
        this.stepId = +this.stepService.step.id;
        this._refreshCommTable();

        this.dataColumns = this.field.templateOptions.communicationConfig.fields
            .map( f => {
                if( f.key ) {
                    if(
                        f.key
                            .toString()
                            .toLowerCase()
                            .indexOf( 'date' ) < 0
                    ) {
                        return [
                            {
                                header: f.templateOptions.label,
                                field: f.key,
                            } as MtxGridColumn,
                        ];
                    } else {
                        return [
                            this.formatDate( f ),
                        ];
                    }
                } else {
                    if( f.fieldGroup ) {
                        return f.fieldGroup.map( fl => {
                            if( fl.key ) {
                                if(
                                    fl.key
                                        .toString()
                                        .toLowerCase()
                                        .indexOf( 'date' ) < 0
                                ) {
                                    if( fl.templateOptions.label === 'Note' ) {
                                        return this.formatNote( fl );
                                    }
                                    return { header: fl.templateOptions.label, field: fl.key } as MtxGridColumn;
                                } else {
                                    return this.formatDate( fl );
                                }
                            }
                        } );
                    }
                }
            } )
            .flat();
        this.dataColumns.push( this.tableButtons );

        console.log( 'Field disabled', this.field.templateOptions.disabled );
    }

    edit( value: any ) {
        if( !this.controlDisabled ) {
            const dialogRef = this.dialog.originalOpen( ComunicazioniEditComponent, {
                width: '600px',
                data: { record: value, stepConfig: this.field.templateOptions.communicationConfig },
            } );

            dialogRef.afterClosed().subscribe( result => {
                if( result.refreshComTab ) {
                    this._refreshCommTable();
                }
            } );
        }
    }

    menuOpened() {
        this.panelOpenState = !this.panelOpenState;
        this.touched = true;
    }

    closed() {
    }

    opened() {
    }

    delete( value: any ) {
        this.dialog.confirm( 'Confirm delete?', null, () => {
            this.serverCommunicationService.deleteCommunication( value.id ).subscribe( res => {
                if( res ) {
                    this._refreshCommTable();
                }
            } );
        } );
    }

    openFileUploader( value: any ) {
        value.idStep = this.stepId;
        this.dialog.originalOpen( FileUploaderComponent, {
            width: '700px',
            panelClass: 'dialog-no-padding',
            data: {
                comId: value.id,
                fileProperties: this.field.templateOptions.communicationConfig.fileProperties,
                disabled: this.field.templateOptions.disabled,
            },
        } );
    }

    addCommunication() {
        const value: any = {};
        value.idStep = this.stepId;
        const dialogRef = this.dialog.originalOpen( ComunicazioniEditComponent, {
            width: '600px',
            data: { record: value, stepConfig: this.field.templateOptions.communicationConfig },
        } );
        dialogRef.afterClosed().subscribe( result => {
            if( result.refreshComTab ) {
                this._refreshCommTable();
            }
        } );
    }

    onCommit() {
        return of( undefined );
    }

    onDraftSaving() {
        return of( undefined );
    }

    defaultLabel() {
        return 'Comunicazioni';
    }

    private _refreshCommTable() {
        this.serverCommunicationService.getCommunicationsByStep( this.stepId ).subscribe( x => {
            this.list = x;
            this.formControl.setValue( this.list );
            console.log( this.formControl.value );
            this.cdr.detectChanges();
        } );
    }

    private formatNote( field ): MtxGridColumn {
        return {
            header: field.templateOptions.label,
            field: field.key,
            formatter: ( note: any ) => {
                if( !note[ field.key as string ] ) return '';
                if( note[ field.key as string ].length > 15 ) {
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    return note[ field.key as string ].substring( 0, 10 ) + '[...]';
                } else {
                    return note[ field.key as string ];
                }
            },
        } as MtxGridColumn;
    }

    private formatDate( field ): MtxGridColumn {
        return {
            header: field.templateOptions.label,
            field: field.key,
            formatter: ( data: any ) => {
                if( !data[ field.key as string ] ) return null;
                return new Date( data[ field.key as string ] ).toLocaleDateString( 'it-IT', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                } );
            },
        } as MtxGridColumn;
    }
}
