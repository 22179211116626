import { Observable, Subscription } from 'rxjs';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

@Component( {
    selector: 'wonder-autoselect',
    templateUrl: './autoselect.component.html',
    styleUrls: [ './autoselect.component.scss' ]
} )
export class AutoselectComponent implements OnInit, OnDestroy {
    @Input() name;
    @Input() allItems: Observable<string[]>;
    @Output() choiceChange = new EventEmitter<string>();

    private allItemsList: string[];
    private subscription: Subscription[] = [];
    @Input() control: AbstractControl = new UntypedFormControl();
    list: Observable<string[]>;

    constructor() {
    }

    ngOnDestroy(): void {
        this.subscription.forEach( s => s.unsubscribe() );
    }

    ngOnInit(): void {
        this.subscription.push( this.allItems.subscribe( a => this.allItemsList = a ) );
        this.list = this.control.valueChanges.pipe(
            debounceTime( 400 ),
            startWith( '' ),
            distinctUntilChanged(),
            map( term => {
                if( term === '' ) {
                    return this.allItemsList;
                }
                return this.allItemsList.filter( i => i.includes( term ) );
            } )
        );
    }


    emit( event: MatAutocompleteSelectedEvent ) {
        this.choiceChange.emit( event.option.value );
    }


    clear( event: MatAutocompleteSelectedEvent ) {
        this.control.setValue( '' );
        this.choiceChange.emit( '' );
    }
}
