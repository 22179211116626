<div class="matero-sidebar-header" *ngIf="showHeader">
    <app-branding></app-branding>
    <span fxFlex></span>
    <mat-slide-toggle (change)="toggleCollapsed.emit()" [checked]="toggleChecked" *ngIf="showToggle"
                      [matTooltip]="toggleChecked ? 'Mantieni il menu laterale aperto' : 'Riduci il menu laterale'">
    </mat-slide-toggle>
</div>

<div class="matero-sidebar-main scrollbar-none" fxLayout="column">
    <app-user-panel *ngIf="showUser"></app-user-panel>
    <app-sidemenu [ripple]="showToggle"></app-sidemenu>
    <div class="spacer"></div>
    <div class="matero-user-panel-name self-center">Version {{version}}</div>
</div>
