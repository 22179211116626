import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component( {
    template: '',
    animations: [
        trigger( 'updateField', [
            state( 'true', style( { background: '#353535' } ) ),
            transition( '* => true', [ animate( '2s', keyframes( [
                style( { opacity: 0.1, offset: 0.1, background: '#353535' } ),
                style( { opacity: 0.6, offset: 0.2, background: '#353535' } ),
                style( { opacity: 1,   offset: 0.3, background: '#353535' } ),
                style( { opacity: 0.2, offset: 0.4, background: '#353535' } ),

                style( { opacity: 0.1, offset: 0.5, background: '#353535' } ),
                style( { opacity: 0.6, offset: 0.6, background: '#353535' } ),
                style( { opacity: 1,   offset: 0.7, background: '#353535' } ),
                style( { opacity: 0.2, offset: 0.8, background: '#353535' } )
            ] ) ) ] )
        ] )
    ]
} )
export class AnimatedFormComponent {
    @Input() animations: { name: string; animation: boolean }[] = [];

    isAnimated( name: string ): boolean {
        return this.animations.find( a => a.name === name )?.animation;
    }
}
