import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, skip, tap } from 'rxjs/operators';
import { WndGridOptionsResponse } from '../interfaces/wnd-grid-options-response';
import { WndGridColumnQueryEvent, WndGridColumnSearchType } from './wnd-grid-column-search';
import { WndGridService } from '@shared/components/wonder-grid/services/wnd-grid.service';

export class WndGridColumnOptions {
    private queryString: string;
    offset = 0;
    total = 0;
    soglia = 0.6;
    items: string[] = [];
    isFilterActive = false;
    unitSize = 0;
    semaphore = false;
    private options = new Subject<string[]>();
    private isFirstCall = true;
    private cachedData: string[] = [];
    filterElement = new BehaviorSubject<string[]>( [] );

    constructor( public pageSize: number, private fieldName: string, private dataSource: string, private http: HttpClient, private cacheOnStart = false, private gridService: WndGridService ) {
        if( cacheOnStart ) {
            this.pageSize = 1e3;
        }
    }

    get availableOptions() {
        return this.options.asObservable();
    }


    get inListFilter() {
        return this.filterElement.asObservable().pipe(
            skip( 1 ),
            map( ( val ) => {
                console.log( 'New item added', val );

                const res: WndGridColumnQueryEvent = {
                    type: WndGridColumnSearchType.INLIST,
                    arguments: val,
                };
                return res;
            } ) );
    }

    get currentQuery() {
        return this.queryString;
    }

    setEndpoint( endpoint: string ): void {
        this.dataSource = endpoint;
    }

    setQueryString( val: string ) {
        if( val ) {
            this.queryString = val.trim().toLowerCase();
        } else {
            this.queryString = undefined;
        }

    }


    get isComplete() {
        return this.total === this.offset;
    }

    activeFilter() {
        this.getNextBatch();
    }

    getNextBatch() {
        //this.gridService.requestSource.pipe( take( 1 ) ).subscribe( res => console.log( 'Filter', res ) );
        console.log( 'Last filter', this.gridService.lastAllFilter );
        if( !this.cacheOnStart || this.isFirstCall ) {
            let params = new HttpParams()
                .set( 'offset', this.offset.toString() )
                .set( 'pageSize', this.pageSize.toString() )
                .set( 'fieldName', this.fieldName );

            if( this.gridService.lastAllFilter ) {
                params = params.set( 'filters', this.gridService.lastAllFilter.filters );
            }

            if( this.queryString ) {
                params = params.set( 'queryString', this.queryString );
            }

            this.http.get<WndGridOptionsResponse>( this.dataSource, { params } ).pipe(
                tap( res => {
                    this.total = res.total;
                    this.offset += res.pageSize;
                } ),
            ).subscribe( data => {
                if( this.cacheOnStart ) {
                    this.cachedData = data.data;
                }
                this.isFirstCall = false;
                this.options.next( data.data );
            } );
        } else {
            this.isFirstCall = false;
            this.options.next( !!this.queryString ? this.cachedData.filter( d => d.includes( this.queryString ) ) : this.cachedData );
        }
    }
}
