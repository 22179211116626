export enum StreamCraftMessageTypes {
    testStream = 'test',
    requestStream = 'request',
    classificationStream = 'classification',
    requestStateStream = 'requestState',
    classificationStateStream = 'classificationState',
    toolTxStream = 'toolTx',
    toolTxStateStream = 'toolTxState',
    toolTxImportStream = 'toolTxImport',
    trackingStream = 'tracking',
}
