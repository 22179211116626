import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { PlansState } from './index';
import { reducers } from './reducers';


@NgModule( {
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature( PlansState.featureKey, reducers ),
    ],
} )
export class PlansStoreModule {
}
