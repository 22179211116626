import { WndGridColumnSearchType } from './../../helpers/wnd-grid-column-search';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap, throttleTime } from 'rxjs/operators';
import { WndGridColumnService } from '../../services/wnd-grid-column-service';

// https://cdk-virtual-scroll-simple-autosize-jjlcfv.stackblitz.io
@Component( {
    selector: 'wnd-options-list',
    templateUrl: './options-list.component.html',
    styleUrls: [ './options-list.component.scss' ],
} )
export class OptionsListComponent implements OnInit, OnDestroy, AfterViewInit {

    @Output() preventClose = new EventEmitter<boolean>();
    @ViewChild( CdkVirtualScrollViewport )
    public virtualScrollViewport: CdkVirtualScrollViewport;

    searchControl: FormControl<any>;

    private subscriptions: Subscription[] = [];

    constructor( public columnService: WndGridColumnService, private zone: NgZone, private cdr: ChangeDetectorRef ) {
    }

    ngOnInit(): void {
        console.log( 'Option list', this.columnService, this.columnService.columnSearch, this.columnService.columnOptions, this.columnService.columnSearch.filterSource.value, this.columnService.columnSearch.firstArgSource.value,
            this.columnService.columnSearch.secondArgSource.value, );
        this.searchControl = new UntypedFormControl();
        if( this.columnService.columnSearch.filterSource.value.type !== WndGridColumnSearchType.INLIST ) {
            this.disableOnStart();
        }
        if( !this.columnService.columnOptions.isFilterActive ) {
            this.searchControl.disable( { emitEvent: false } );
        }
        this.searchControl.setValue( this.columnService.columnOptions.currentQuery, { emitEvent: false } );
        this.registerEmission();
        this.registerSearchChanges();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach( s => s.unsubscribe() );
    }

    ngAfterViewInit() {
        this.subscriptions.push( this.virtualScrollViewport.elementScrolled().pipe(
            map( () => this.virtualScrollViewport.measureScrollOffset( 'top' ) ),
            filter( scrollOffset => scrollOffset >
                ( this.columnService.columnOptions.offset / this.columnService.columnOptions.pageSize + this.columnService.columnOptions.soglia - 1 ) *
                this.columnService.columnOptions.pageSize *
                this.columnService.columnOptions.unitSize &&
                this.columnService.columnOptions.semaphore &&
                this.columnService.columnOptions.isFilterActive &&
                this.columnService.columnOptions.offset < this.columnService.columnOptions.total ),
            throttleTime( 200 ),
        ).subscribe( () => {
            this.zone.run( () => {
                this.columnService.columnOptions.semaphore = false;
                this.emitValues();
            } );
        } ), );
    }

    registerSearchChanges() {
        this.subscriptions.push(
            this.searchControl.valueChanges.pipe(
                debounceTime( 400 ),
                distinctUntilChanged(),
                tap( val => {
                    this.columnService.columnOptions.offset = 0;
                    this.columnService.columnOptions.items = [];
                    this.columnService.columnOptions.setQueryString( val as string );
                    this.emitValues();
                } ),
            ).subscribe(),
        );
    }

    registerEmission() {
        this.subscriptions.push(
            this.columnService.columnOptions.availableOptions.subscribe( data => {
                console.log( 'Columns filter', data );
                if( this.columnService.columnOptions.unitSize === 0 ) {
                    this.computeUnitSize();
                }
                if( this.columnService.columnOptions.offset === this.columnService.columnOptions.pageSize ) {
                    setTimeout( () => {
                        this.virtualScrollViewport.elementRef.nativeElement.scrollTop = 0;
                    } );
                }
                this.columnService.columnOptions.items = [ ...this.columnService.columnOptions.items, ...data ];

                this.columnService.columnOptions.semaphore = true;
                this.cdr.detectChanges();
            } ),
        );
    }

    emitValues() {
        this.columnService.columnOptions.getNextBatch();
    }

    toggleFilter( event: MatCheckboxChange ) {
        if( this.columnService.columnOptions.isFilterActive !== event.checked ) {
            this.columnService.columnOptions.isFilterActive = event.checked;
            this.columnService.columnOptions.offset = 0;
            this.columnService.columnOptions.items = [];
            if( this.columnService.columnOptions.isFilterActive ) {
                console.log( 'CF toggle filter' );

                this.columnService.columnSearch.filterSource.next( { displayName: 'Nella lista', type: WndGridColumnSearchType.INLIST } );
                this.searchControl.enable( { emitEvent: false } );
                this.columnService.columnOptions.semaphore = false;
                this.emitValues();
            } else {
                console.log( 'CF toggle filter 2' );
                this.columnService.columnSearch.filterSource.next( this.columnService.columnSearch.supportedCalculation[ 0 ] );
                this.searchControl.disable( { emitEvent: false } );
                this.searchControl.setValue( '', { emitEvent: false } );
                this.columnService.columnOptions.setQueryString( undefined );
                this.columnService.columnOptions.filterElement.next( [] );
            }
        }

    }

    isSelected( element: string ) {
        return this.columnService.columnOptions.filterElement.value.includes( element );

    }

    get checkBoxTooltip() {
        return this.columnService.columnOptions.isFilterActive ? 'Remove Filter' : 'Active Filter';
    }

    get fixedUnitSize() {
        return !!this.columnService.columnOptions.unitSize ? this.columnService.columnOptions.unitSize * 0.7 : 35;
    }


    onChange( change: MatCheckboxChange, item: string ) {

        if( change.checked && !this.columnService.columnOptions.filterElement.value.includes( item ) ) {
            const temp = this.columnService.columnOptions.filterElement.value;
            temp.push( item );
            this.columnService.columnOptions.filterElement.next( temp );
        }
        if( !change.checked ) {
            this.columnService.columnOptions.filterElement.next(
                this.columnService.columnOptions.filterElement.value.filter( f => f !== item ),
            );
        }
    }

    // noinspection JSUnusedGlobalSymbols
    dub() {
        this.columnService.columnOptions.items = [ ...this.columnService.columnOptions.items, ...Array( 50 )
            .fill( 0 )
            .map( ( _, i ) => `Item # ${ i }` ) ];
    }

    log() {
    }

    focus() {
        setTimeout( () => {
            if( this.columnService.columnOptions.isFilterActive ) {
                // console.log('focus', this.columnService.columnOptions.isFilterActive);
                this.preventClose.next( true );
            }
        }, 20 );

    }

    blur() {
        setTimeout( () => {
            if( this.columnService.columnOptions.isFilterActive ) {
                // console.log('blur', this.columnService.columnOptions.isFilterActive);
                this.preventClose.next( false );
            }
        }, 20 );

    }

    private computeUnitSize() {
        setTimeout( () => {
            this.columnService.columnOptions.unitSize =
                this.virtualScrollViewport.elementRef.nativeElement.scrollHeight /
                this.columnService.columnOptions.pageSize;
        } );
    }

    private disableOnStart() {
        this.columnService.columnOptions.isFilterActive = false;
        this.columnService.columnOptions.offset = 0;
        this.columnService.columnOptions.items = [];
        this.searchControl.disable( { emitEvent: false } );
        this.searchControl.setValue( '', { emitEvent: false } );
        this.columnService.columnOptions.setQueryString( undefined );
        this.columnService.columnOptions.filterElement.next( [] );
    }

}
