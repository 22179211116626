import { GeoSyncService } from '@shared/geo-sync/services/geo-sync.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { GeoSyncCoordinates } from '@shared/geo-sync/interfaces/geo-sync-coordinates';
import { Subscription } from 'rxjs';
import { GeoSyncEvent, GeoSyncEventObject, GeoSyncEventType } from './geo-sync-event';
import { AnimatedFormComponent } from '../animated-form/animated-form.component';


@Component( {
    selector: 'geo-sync',
    templateUrl: './geo-sync.component.html',
    styleUrls: [ './geo-sync.component.scss' ],
    providers: [ GeoSyncService ],
} )
export class GeoSyncComponent extends AnimatedFormComponent implements OnInit, OnDestroy {
    @Input() set disabled( value: boolean ) {
        this._disabled = value;
        if( this.showZone ) {
            if( value ) {
                this.form.controls.Zona.disable();
            } else {
                this.form.controls.Zona.enable();
            }
        }
    }
    get disabled(): boolean {
        return this._disabled;
    }


    @Input() flexDirection: 'row' | 'column' = 'row';
    @Input() fullWidth = false;
    @Input() showZone = true;
    @Input() showGeoJSON = false;

    @Input() set coordinates( coords: GeoSyncCoordinates ) {
        this.setupCoordinates( coords );
    }

    @Input() set initData( data: { comune: string; provincia: string; regione: string } ) {
        if( data ) {
            this.geoSyncService.selectInitial( data.comune, data.provincia, data.regione ).subscribe( () => {
                this.emitChanges( 'comune' );
            } );
        } else {
            console.warn( 'data is null in GeoSyncComponent' );
        }
    }

    @Input() zonaRequired = false;
    @Input() regioneRequired = false;
    @Input() provinciaRequired = false;
    @Input() comuneRequired = false;
    @Input() form = new UntypedFormGroup( {} );
    @Input() addAutocompleteControl = true;
    @Input() emitAutocompleteEvent = true;

    @Output() valueChanges: EventEmitter<GeoSyncEvent> = new EventEmitter<GeoSyncEvent>();
    @Output() invalidCoords: EventEmitter<void> = new EventEmitter<void>();
    @Output() statusChanges: EventEmitter<string> = new EventEmitter<string>();
    @Output() valueCleared: EventEmitter<GeoSyncEventType> = new EventEmitter<GeoSyncEventType>();

    private subscriptions: Subscription[] = [];
    private _disabled = false;

    placeholderRegione = 'regione';
    placeholderProvincia = 'provincia';
    placeholderComune = 'comune';
    placeholderZona = 'zona';

    public zonaOptions = [ '', '1', '2', '3', '4' ];

    get layoutAlign() {
        return this.flexDirection === 'row' ? 'space-between center' : 'center start';
    }

    get controlRegione(): AbstractControl {
        return this.form.controls[ this.placeholderRegione ];
    }

    get controlProvincia(): AbstractControl {
        return this.form.controls[ this.placeholderProvincia ];
    }

    get controlComune(): AbstractControl {
        return this.form.controls[ this.placeholderComune ];
    }

    constructor( private geoSyncService: GeoSyncService ) {
        super();
    }

    ngOnInit(): void {
        this.initControls();
        this.setupCoordinates( this.coordinates );
        this.subscriptions.push( this.geoSyncService.selectedComune$.pipe( distinctUntilChanged() ).subscribe( comune => {
            this.setValidValue( this.controlComune, 'comune', comune );
        } ) );
        this.subscriptions.push( this.geoSyncService.selectedProvincia$.pipe( distinctUntilChanged() ).subscribe( provincia => {
            this.setValidValue( this.controlProvincia, 'provincia', provincia );
        } ) );
        this.subscriptions.push( this.geoSyncService.selectedRegione$.pipe( distinctUntilChanged() ).subscribe( regione => {
            this.setValidValue( this.controlRegione, 'regione', regione );
        } ) );
        this.geoSyncService.considerZona = this.showZone;
        this.geoSyncService.showGeoJSON = this.showGeoJSON;
        if( this.showZone ) {
            this.subscriptions.push( this.geoSyncService.selectedZona$.pipe( distinctUntilChanged() ).subscribe( zona => {
                this.setValidValue( this.controlZona, 'zona', zona );
            } ) );
        }
        this.form.statusChanges.subscribe( status => this.statusChanges.emit( status ) );
    }

    filterRegione = ( term: string ) => this.geoSyncService.regioneFilter( term ).pipe( map( d => d.map( f => f.regione ) ) );
    filterProvincia = ( term: string ) => this.geoSyncService.provinciaFilter( term ).pipe( map( d => d.map( f => f.provincia ) ) );
    filterComune = ( term: string ) => this.geoSyncService.comuneFilter( term ).pipe( map( d => d.map( f => f.comune + ' (' + f.siglaProv + ')' ) ) );

    regioneChoice( value: string ) {
        this.geoSyncService.selectRegione( value ).subscribe( () => {
            if( value ) {
                this.emitChanges( 'regione' );
            }
        } );
    }

    provinciaChoice( value: string ) {
        this.geoSyncService.selectProvincia( value ).subscribe( () => {
            if( value ) {
                this.emitChanges( 'provincia' );
            }
        } );
    }

    comuneChoice( value: string ) {
        this.geoSyncService.selectComune( value ).subscribe( () => {
            if( value ) {
                this.emitChanges( 'comune' );
            }
        } );
    }

    ngOnDestroy(): void {
        while( this.subscriptions.length ) {
            this.subscriptions.pop().unsubscribe();
        }
    }

    clearFilter() {
        this.controlRegione.setValue( null );
    }

    public get controlZona(): AbstractControl<string> {
        return this.form.controls[ this.placeholderZona ];
    }

    private initControls(): void {
        if( this.showZone ) {
            this.form.addControl( 'zona', new UntypedFormControl( '' ) );
            this.controlZona.valueChanges.pipe(
                distinctUntilChanged(),
            ).subscribe( s => {
                this.geoSyncService.selectZona( s );
                this.emitChanges( 'zona' );
            } );
        }
    }


    private setValidValue( formControl: AbstractControl, eventType: GeoSyncEventType, value: any ) {
        // if( !formControl ) {
        //     console.warn( 'Form control is null. Value', value, this.form );
        // }
        formControl?.setValue( value, { emitEvent: true } );
        if( value ) {
            formControl?.updateValueAndValidity();
            //formControl?.setErrors(null, {emitEvent: false});
        } else {
            this.valueCleared.emit( eventType );
        }
    }

    // noinspection JSUnusedLocalSymbols
    private setNotValidValue( formControl: AbstractControl ) {
        formControl?.setValue( null, { emitEvent: false } );
    }

    private emitChanges( type: GeoSyncEventType ) {
        let obj: GeoSyncEventObject;
        switch( type ) {
        case 'comune':
            obj = this.geoSyncService.selectedComuneObj;
            break;
        case 'provincia':
            obj = this.geoSyncService.selectedProvinciaObj;
            break;
        case 'regione':
            obj = this.geoSyncService.selectedRegioneObj;
            break;
        case 'zona':
            obj = this.geoSyncService.selectedZona;
            break;
        }
        this.valueChanges.emit( { eventType: type, object: obj } );
    }

    private setupCoordinates( coords: GeoSyncCoordinates ) {
        if( coords ) {
            this.geoSyncService.getGeoFromCoordinates( coords ).subscribe( () => {
                this.emitChanges( 'comune' );
            } );
        } else {
            this.invalidCoords.emit();
        }
    }
}
