import { FormlyFieldConfig } from '@ngx-formly/core';
import { ActivityConfig } from './activity_config';
import { okKo, strutturaDiInwit } from './arrayXSelect';

// Formly form configuration
const extraFlowDataConfig: FormlyFieldConfig[] = [
    {
        validators: {
            validation: [
                {
                    name: 'fieldDateMatch',
                    options: {
                        fieldTocheck: [
                            {
                                fromDate: 'date1',
                                toDate: 'date2',
                                errorText: 'Data di Permesso Ottenuto > Permesso Presentato',
                            },
                            {
                                fromDate: 'date2',
                                toDate: 'date3',
                                errorText: 'Data di Pronto Locale > Permesso Ottenuto',
                            },
                            {
                                fromDate: 'date3',
                                toDate: 'date4',
                                errorText: 'Data di On Air > Pronto Locale',
                            },
                        ],
                    },
                },
            ],
        },
        fieldGroup: [
            {
                type: 'datepicker',
                key: 'date1',
                templateOptions: {
                    required: false,
                    label: 'Permesso presentato',
                },
                validators: {
                    validation: [],
                },
            },
            {
                type: 'datepicker',
                key: 'date2',
                templateOptions: {
                    required: false,
                    label: 'Permesso ottenuto',
                },
            },
            {
                type: 'datepicker',
                key: 'date3',
                templateOptions: {
                    required: false,
                    label: 'Pronto locale',
                },
            },
            {
                type: 'datepicker',
                key: 'date4',
                templateOptions: {
                    required: false,
                    label: 'On Air',
                },
            },
        ],
    },
];

export const extraFlowTestConfig: FormlyFieldConfig[] = [
    {
        validators: {
            validation: [
                {
                    name: 'fieldDateMatch',
                    options: {
                        fieldTocheck: [
                            {
                                fromDate: 'date1',
                                toDate: 'date2',
                                errorText: 'Test date Before > Test date Before',
                            },
                        ],
                    },
                },
            ],
        },
        fieldGroup: [
            {
                type: 'datepicker',
                key: 'date1',
                templateOptions: {
                    required: false,
                    label: 'Test date Before',
                },
            },
            {
                type: 'datepicker',
                key: 'date2',
                templateOptions: {
                    required: false,
                    label: 'Test date After',
                },
            },
            {
                key: 'extr-area',
                type: 'searchArea',
                templateOptions: {
                    disableIfCommited: true,
                    custom: true,
                    map: {
                        errorMessage: 'You can\'t submit more than one area.',
                    },
                },
            },
        ],
    },
];

export const debug: ActivityConfig = {
    extraFlowConfig: [
        {
            name: 'New Forecast',
            displayName: 'New Forecast Label',
            fields: extraFlowDataConfig,
        },
        {
            name: 'Test Extra',
            displayName: 'Test Extra Label',
            fields: extraFlowTestConfig,
        },
    ],
    name: 'debug',
    displayName: 'Process Debug',
    stepsConfig: [
        {
            displayName: 'New Stand Alone',
            name: 'New Stand Alone',
            isEntryStep: true,
            fields: [
                {
                    type: 'singleFile',
                    key: 'fle',
                    templateOptions: {
                        label: 'Inserisci un file',
                        disableIfCommited: true,
                        custom: true,
                        required: false,
                        fileProperties: {
                            allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                        },
                    },
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        label: 'Comunicazione INWIT',
                        custom: true,
                        communicationConfig: {
                            fileProperties: {
                                maxNumberOfFile: 3,
                                allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                            },
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: false,
                                                label: 'Data Invio INWIT',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Data Invio nuovo',
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    key: 'searchArea',
                    type: 'searchArea',
                    templateOptions: {
                        required: false,
                        disableIfCommited: true,
                        custom: true,
                        map: {
                            errorMessage: 'You can\'t submit more than one area.',
                        },
                    },
                },
                {
                    key: 'candidate',
                    type: 'candidate',
                    templateOptions: {
                        isValutazioneMSA: true,
                        required: true,
                        disableIfCommited: true,
                        custom: true,
//            structureOwners: proprietariStrutture,
                        structureOwners: strutturaDiInwit,
                        scope: [ 'population', 'selection' ],
                        map: {
                            numberOfItems: {
                                min: 1,
                                max: 3,
                            },
                            errorMessage: 'You can\'t sumbit more than boh candidate.',
                        },
                    },
                },
                /*
                        {
                          fieldGroupClassName: 'row',
                          fieldGroup: [
                            {
                              className: 'col-sm-12',
                              type: 'geoSync',
                              key: 'locazione',
                              templateOptions: {
                                disableIfCommited: true,
                                required: false,
                                label: 'Inserisce locazione',
                                custom: true,
                              },
                            },
                          ]
                        },
                */
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                disableIfCommited: true,
                                required: false,
                                label: 'Data Invio SARF 3^ Parte',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'input',
                            key: 'string2',
                            templateOptions: {
                                disableIfCommited: true,
                                required: false,
                                label: 'Nuovo Office',
                                minLength: 8,
                                maxLength: 8,
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                disableIfCommited: true,
                                required: false,
                                label: 'Data Nuova',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'select',
                            key: 'string3',
                            templateOptions: {
                                disableIfCommited: true,
                                required: false,
                                label: 'Next Step',
                                options: okKo,
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'input',
                            key: 'valore',
                            wrappers: [ 'form-field', 'suffix' ],
                            templateOptions: {
                                disableIfCommited: true,
                                required: false,
                                label: 'Valore materiale',
                                suffixIcon: 'euro_symbol',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                if( m.string3 === 'KO' ) {
                    return 'KO Step';
                } else {
                    return 'OK Step';
                }
            },
        },
        {
            displayName: 'KO Step',
            name: 'KO Step',
            isKOStep: true,
            commitMessage: 'vuoi definitivamente chiudere l\'attività',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: false,
                                label: 'Test',
                                options: okKo,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                disableIfCommited: true,
                                required: false,
                                label: 'Data test nuovo step',
                            },
                        },
                    ],
                },
            ],
        },
        {
            displayName: 'OK Step',
            name: 'OK Step',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: false,
                                label: 'Test',
                                options: okKo,
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                disableIfCommited: true,
                                required: false,
                                label: 'Data test nuovo step',
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
