<div class='m-b-0'>
    <mtx-grid class='grid' [pageSize]='10000' [data]='allFiles' [columns]='savedFilesColumns' [showPaginator]='false'
              [cellTemplate]="{operation: operationTpl}"></mtx-grid>

    <ng-template #operationTpl let-row let-index="index" let-col="colDef">
        <button mat-icon-button (click)="download(row)" aria-label="Download attached file" color='primary'>
            <mat-icon>cloud_download</mat-icon>
        </button>
        <button mat-icon-button (click)="delete(row)" [disabled]="controlDisabled" aria-label="Delete the file" color='warn'>
            <mat-icon>delete</mat-icon>
        </button>
    </ng-template>

</div>

<div class='drop-container' *ngIf='maxFilesNumber > 0'
     ngFileDrop
     [tus-uploader]='tusConfig'
     [numberOfSelections]='maxFilesNumber'
     (selectionError)='handleError($event)'
     (uploadEvents)='onUploadEvent($event)'>
    <p>
        Drag files here or
        <label class='upload-button'>
            <input
                    id='inputFile'
                    hidden
                    type='file'
                    [tus-uploader]='tusConfig'
                    [numberOfSelections]='maxFilesNumber'
                    (uploadEvents)='onUploadEvent($event)'
                    (selectionError)='onSelectionError( $event )'
            /> <strong>browse</strong>
        </label>
        to upload.
    </p>
    <mat-spinner *ngIf='isUploading' class='m-b-4' [diameter]='24'></mat-spinner>
</div>

