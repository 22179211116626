import { Component, Input } from '@angular/core';
import { IOfficeView } from '@shared/models/office-view';

@Component( {
    selector: 'wnd-operatore-modal',
    templateUrl: './operatore-modal.component.html',
    styleUrls: [ './operatore-modal.component.scss' ],
} )
export class OperatoreModalComponent {
    @Input() office: IOfficeView;
    @Input() large = false;

    columns = [
        {
            fieldName: 'zona',
            displayName: 'Zona',
        },
        {
            fieldName: 'topAnpo',
            displayName: 'Top ANPO',
        },
        {
            fieldName: 'proprietarioStruttura',
            displayName: 'Proprietà struttura',
        },
        {
            fieldName: 'tipoStruttura',
            displayName: 'Tipo struttura',
        },
        {
            fieldName: 'tipoHousing',
            displayName: 'Tipo apparati',
        },
        {
            fieldName: 'microMacro',
            displayName: 'Tipo sito micro/macro',
        },
        {
            fieldName: 'ltePresente',
            displayName: 'Tipo LTE presente',
        },
        {
            fieldName: 'fiveGPresente',
            displayName: 'Tipo 5G presente',
        },
        {
            fieldName: 'ltePiano',
            displayName: 'Tipo LTE a piano',
        },
        {
            fieldName: 'fiveGPiano',
            displayName: 'Tipo 5G a piano',
        },
        {
            fieldName: 'ko',
            displayName: 'KO',
        },
        {
            fieldName: 'not-existing',
            displayName: '',
        },
        {
            fieldName: 'fibra',
            displayName: 'Stato fibra',
        },
        {
            fieldName: 'connettivita',
            displayName: 'Connettività',
        },
    ];
}
