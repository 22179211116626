import { NdDocument } from '../../../../interfaces/nd_document';
import { ToastrService } from 'ngx-toastr';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ServerCommunicationService } from 'app/routes/nd/services/server-communication.service';
import * as FileSaver from 'file-saver';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput, UploadStatus } from 'ngx-uploader';
import { tap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ActivitiesImportResponse } from './activities-import-response';
import { MatMenuTrigger } from '@angular/material/menu';
import { SettingsService, TokenService } from '@core';
import { BASE_URL } from '@shared/models/urls-constants';

@Component( {
    selector: 'wnd-single-file-uploader',
    templateUrl: './single-file-uploader.component.html',
    styleUrls: [ './single-file-uploader.component.scss' ],
} )

export class SingleFileUploaderComponent extends FieldType implements OnInit, OnDestroy {
    get controlDisabled(): boolean {
        return this.field?.templateOptions?.disabled;
    }

    //url = this.baseUrl + 'wonder-src-workflow-api/Activities/Upload';
    url = this.baseUrl + 'wonder-src-workflow-api/Activities/Import';
    document: NdDocument;
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    dragOver: boolean;
    uploadOptions: UploaderOptions;
    private refreshIdSubject = new Subject<void>();
    isUploading = false;
    resultElements: string[] = [];
    totalImported: number | undefined;
    @ViewChild( MatMenuTrigger ) trigger: MatMenuTrigger;

    private accessToken: string;
    private subscriptions = new Subscription();

    constructor(
        private serverCommunicationService: ServerCommunicationService,
        private toastr: ToastrService,
        private tokenService: TokenService,
        private settingsService: SettingsService,
        @Inject( BASE_URL ) private baseUrl: string,
    ) {
        super();
        this.uploadOptions = { concurrency: 1, maxUploads: 1, maxFileSize: 1000000 };
        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.subscriptions.add( this.tokenService.currentTokenModel.subscribe( t => this.accessToken = t.accessToken ) );
    }

    onUploadOutput( output: UploadOutput ): void {
        console.log( 'Uploading file', output.type, output );
        if( output.type === 'allAddedToQueue' ) {
            this._startUpload();
        } else if( output.type === 'addedToQueue' && typeof output.file !== 'undefined' ) {
            this.files.push( output.file );
        } else if( output.type === 'uploading' && typeof output.file !== 'undefined' ) {
            this.totalImported = null;
            this.resultElements = [];
            this.isUploading = true;
            const index = this.files.findIndex(
                file => typeof output.file !== 'undefined' && file.id === output.file.id,
            );
            this.files[ index ] = output.file;
        } else if( output.type === 'cancelled' || output.type === 'removed' ) {
            this.files = this.files.filter( ( file: UploadFile ) => file !== output.file );
        } else if( output.type === 'dragOver' ) {
            this.dragOver = true;
        } else if( output.type === 'dragOut' ) {
            this.dragOver = false;
        } else if( output.type === 'drop' ) {
            this.dragOver = false;
        } else if( output.type === 'rejected' && typeof output.file !== 'undefined' ) {
            this.toastr.error( output.file.name + ' can\'t be uploaded' );
        } else if( output.type === 'done' ) {
            console.log( 'Upload terminated', output );
            this.isUploading = false;
            if( output.file.responseStatus >= 200 && output.file.responseStatus <= 299 ) {
                this.toastr.success( output.file.name + ' uploading OK' );
                this.showActivitiesErrors( output.file?.response );
                this.refreshIdSubject.next();
            } else {
                this.toastr.error( output.file.name + ' uploading failed' );
            }
        }
        this.files = this.files.filter( file => file.progress.status !== UploadStatus.Done );
    }

    inputFileClick() {
        const inputFile = document.getElementById( 'inputFile' ) as HTMLElement;
        inputFile.click();
    }

    onMenuToggle() {
        //console.log("onMenuToggle...");
        this.trigger.toggleMenu();
    }

    void( e: MouseEvent ) {
        //console.log("void...",e);
        e.stopPropagation();
    }

    private _startUpload(): void {
        if(
            !this.field.templateOptions.fileProperties.allowedExstension ||
            ( this.field.templateOptions.fileProperties.allowedExstension as string[] ).includes(
                this._getExtension( this.files[ 0 ].name ),
            )
        ) {
            this.serverCommunicationService
                .dummyDocReq()
                .pipe(
                    tap( () => {
                        if( this.accessToken ) {
                            const event: UploadInput = {
                                type: 'uploadAll',
                                url: this.url,
                                method: 'POST',
                                data: {
                                    idInCategory:
                                        'activity_' +
                                        this.field.templateOptions.fileProperties.id,
                                    category: 'singleFiles',
                                },
                                headers: { Authorization: `Bearer ${ this.accessToken }` },
                            };
                            this.uploadInput.emit( event );
                        } else {
                            this.settingsService.logout();
                        }
                    } ),
                )
                .subscribe( () => {
                } );
        } else {
            this.toastr.error( 'Not supported file type, upload a xls' );
        }
    }

    // Template download
    downloadTemplate() {
        FileSaver.saveAs( 'assets/files/template_massive_attivita_v01.xlsx', 'template_massive_attivita_v01.xlsx' );
        // this.serverCommunicationService.downloadActivitiesDownloadTemplate(+this.document.id).subscribe(x => {
        //   FileSaver.saveAs(x, this.document.name);
        // });
    }

    private _getExtension( name: string ): string {
        const temp = name.split( '.' );
        return temp
            .slice( -1 )[ 0 ]
            .trim()
            .toLowerCase();
    }

    // noinspection JSUnusedLocalSymbols
    private _updateFileStatus( doc?: NdDocument ) {
        if( doc ) {
            this.document = doc;
            this.formControl.setValue( doc.name );
            this.formControl.setErrors( null );
        } else {
            this.document = undefined;
            this.formControl.setValue( '' );
            if( this.field.templateOptions.required ) {
                this.formControl.setErrors( { empty: true } );
            }
        }
    }

    private showActivitiesErrors( response: ActivitiesImportResponse ) {
        this.resultElements = response?.detail;
        this.totalImported = response?.rowProcessed;
    }
}
