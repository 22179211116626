<div
    #container
    class="wnd-grid-column-header-container"
    fxLayout="column"
    fxLayoutAlign="space-between"
>
    <ng-container *ngIf="columnService.column?.type !== 'button'">
        <div class="wnd-grid-column-diplayname-ext" fxLayout="row" fxLayoutAlign="space-between center"
             [ngStyle]="{ 'background-color' : headerBgColor, 'color' : headerFontColor }">
            <div
                    (click)="nextSortState()"
                    class="wnd-grid-column-diplayname"
                    fxLayout="row"
                    fxLayoutAlign="start center"
            >
                <p>{{ columnService.column?.displayName }}</p>
                <mat-icon *ngIf="sortState === 1">arrow_upward</mat-icon>
                <mat-icon *ngIf="sortState === 2">arrow_downward</mat-icon>
            </div>
            <div (click)="resetFilters()" class="wnd-grid-column-clear" fxLayout="column" fxLayoutAlign="center center">
                <mat-icon *ngIf="columnService.anyFilters">edit_off</mat-icon>
            </div>
            <div *ngIf="setColor" [ngStyle]="{ 'background-color': setColor }" class="set-color"></div>
        </div>
        <div class="divider"></div>
        <div class="wnd-grid-column-header-filter-ext" fxLayoutAlign="start center">
            <div
                    class="wnd-grid-column-header-filter"
                    #field
                    fxLayout="row"
                    fxLayoutGap="5px"
                    fxLayoutAlign="space-between center"
            >
                <wnd-filter-main-field
                        class="w-full"
                        [firstArg]="firstArg"
                        [placeholder]="placeholder"
                        [inputType]="columnService.inputType()"
                        [inRangeActive]="false"
                        (focus)="onFocus()"
                ></wnd-filter-main-field>
                <div [hidden]="columnService.columnSearch?.supportedCalculation.length <= 1">
                    <mat-icon
                            id="menutrigger"
                            #menutrigger
                            (click)="opened = true"
                            class="wnd-grid-filter-icon light"
                            cdkOverlayOrigin
                            #trigger="cdkOverlayOrigin"
                    >filter_list
                    </mat-icon
                    >
                </div>
            </div>
        </div>
        <!-- <button (click)="columnService.resetFilters()">Help</button> -->
        <ng-template
            #overlay
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="opened"
            (overlayOutsideClick)="onOutClick($event)"
            (backdropClick)="onBackClick()"
            [cdkConnectedOverlayOffsetX]="openPosition.x"
            [cdkConnectedOverlayOffsetY]="openPosition.y"
        >
            <wnd-filter-host (closeEvent)="closeEventHandler($event)"></wnd-filter-host>
        </ng-template>
    </ng-container>

</div>
