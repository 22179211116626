<mat-form-field class="wonder-autocomplete-field-wrapper" [ngClass]="{ 'full-width': fullWidth }" [@updateField]="formName && isAnimated(formName)">
    <input matInput [matAutocomplete]="auto" [formControl]="formControl" [placeholder]="label"/>
    <mat-autocomplete (optionSelected)="emit($event)" #auto="matAutocomplete">
        <mat-option *ngFor="let value of autoCompleteList" [value]="value">
            {{ value }}
        </mat-option>
    </mat-autocomplete>
    <button
            mat-button
            *ngIf="formControl.value && !formControl.disabled"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="formControl.setValue('')"
    >
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
