import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ExtraFlow } from '../steps-stepper/activity-services/ExtraFlow';
import { Step } from '../steps-stepper/activity-services/Step';

@Injectable()
export class StepService {
    public step: Step | ExtraFlow;

    constructor() {
    }

    get stepSavedEvent() {
        if( this.step && this.step instanceof Step ) {
            return this.step.collectionSaveEvent.pipe(
                filter( event => event.source.nome === this.step.nome ),
                map( m => {
                } )
            );
        }
        return this.step.saveRequest.pipe(
            map( m => {
            } )
        );
    }
}
