import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers } from '@store/commons-store/reducers';
import { CommonsState } from '@store/commons-store/index';

@NgModule( {
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature( CommonsState.featureKey, reducers ),
    ],
} )
export class CommonsStoreModule {
}
