<div [matTooltip]="tooltipText">
    <div>{{ filename }}</div>
    <div class="element-content" *ngIf="!isCompleted && !isInError">
        <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
        <button [disabled]="isWaiting" mat-icon-button (click)="onTogglePause()">
            <mat-icon *ngIf="isPaused">play_arrow</mat-icon>
            <mat-icon *ngIf="isRunning">pause</mat-icon>
        </button>
        <button [disabled]="isWaiting" mat-icon-button (click)="onAbort()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <div class='element-content completed' *ngIf='isCompleted'>UPLOAD COMPLETED</div>
    <div class='element-content in-error' *ngIf='isInError'>UPLOAD FAILED</div>
</div>
