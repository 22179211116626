<mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        [expanded]="true"
>
    <mat-expansion-panel-header
            [ngClass]="panelOpenState ? 'mat-expansion-panel-big-top-margin-indicator' : 'p-l-16'"
    >
        <mat-panel-title *ngIf="!panelOpenState">
            {{ title }}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
</mat-expansion-panel>
