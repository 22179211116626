<mat-form-field>
    <input matInput [matAutocomplete]="auto" [formControl]="control" [placeholder]="name"/>
    <button
            mat-button
            *ngIf="control.value && control.value !== ''"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clear()"
    >
        <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete
            class="small-autocomplete"
            (optionSelected)="emit($event)"
            #auto="matAutocomplete"
    >
        <mat-option *ngFor="let value of list | async" [value]="value">
            {{ value }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
