import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { TusSingleFileUploader } from '@shared/services/upload.service';
import { FilesContainerService, RemoteFile } from '@shared/services/files-container.service';

import { UntypedFormControl } from '@angular/forms';
import { StepService } from '../../step.service';
import { ToastrService } from 'ngx-toastr';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { CustomFieldTypeComponent } from '../custom-field-type/custom-field-type.component';
import { UploaderConfig } from '@shared/directives/tus-uploader.directive';
import { UploadingFileStatuses } from '@shared/models/uploading-file-statuses';
import { finalize } from 'rxjs/operators';
import { WorkflowHelper } from '../../../workflow-helper';
import { MatMenuTrigger } from '@angular/material/menu';
import { BASE_URL } from '@shared/models/urls-constants';

export interface IInputFileElement {
    value: string;

    click(): void;
}

@Component( {
    selector: 'wnd-single-file',
    templateUrl: './single-file.component.html',
    styleUrls: [ './single-file.component.scss' ],
    providers: [ FilesContainerService ],
} )
export class SingleFileComponent extends CustomFieldTypeComponent implements OnInit, OnDestroy {
    @ViewChild( 'inputFile' ) inputFileView: ElementRef<IInputFileElement>;
    @ViewChild( MatMenuTrigger ) trigger: MatMenuTrigger;

    get controlDisabled(): boolean {
        return this.field?.templateOptions?.disabled;
    }

    private metadata: any;
    tusConfig: UploaderConfig;
    canUpload = false;
    isUploading = false;
    private tusUploader: TusSingleFileUploader;
    private subscriptions: Subscription[] = [];
    remoteFile: RemoteFile;

    private refreshSubject = new BehaviorSubject<void>( null );
    fileNameControl = new UntypedFormControl();

    constructor(
        public filesContainerService: FilesContainerService,
        private toastr: ToastrService,
        stepService: StepService,
        @Inject( BASE_URL ) private baseUrl: string,
    ) {
        super( stepService );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.metadata = {
            entity: 'step',
            stepId: this.stepService.step.id,
            fieldKey: this.field.key,
        };
        this.tusConfig = {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            metadata: this.metadata,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            allowedTypes: this.buildAllowedTypes(),
            lazyStart: false,
        };
        this.filesContainerService.setFilter( this.metadata );
        this.subscriptions.push(
            this.refreshSubject.subscribe( () =>
                this.filesContainerService.refreshCollection().subscribe( res => {
                    if( res.length > 0 ) {
                        this.remoteFile = res[ 0 ];
                        this.canUpload = false;
                    }
                    this._updateFileStatus();
                } ),
            ),
        );
    }

    onMenuToggle() {
        //console.log("onMenuToggle...");
        this.trigger.toggleMenu();
    }

    void( e: MouseEvent ) {
        //console.log("void...",e);
        e.stopPropagation();
    }

    buildAllowedTypes() {
        const me = WorkflowHelper.getFlattedFieldsConfig( this.stepService.step.fields ).find( f => f.type === 'singleFile' && f.key === this.field.key );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        console.log( 'Allowed types for ', me?.key, me?.templateOptions?.fileProperties?.allowedExstension );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
        return me?.templateOptions?.fileProperties?.allowedExstension;
    }

    onUploadEvent( event: { source: TusSingleFileUploader; uploaders: TusSingleFileUploader[] } ) {
        this.tusUploader = event.source;
        switch( event.source.fileStatus.state ) {
        case UploadingFileStatuses.PAUSED: {
            this.isUploading = false;
            break;
        }
        case UploadingFileStatuses.SERVER_ERROR: {
            this.toastr.warning( 'Upload fallito, riprovare in seguito.' );
            this.isUploading = false;
            this.fileNameControl.setValue( '' );
            this.canUpload = true;
            this.inputFileView.nativeElement.value = '';
            break;
        }
        case UploadingFileStatuses.RUNNING: {
            console.log( 'File is running', event.source.fileStatus.filename );
            this.fileNameControl.setValue( event.source.fileStatus.filename );
            this.isUploading = true;
            this.canUpload = false;
            break;
        }
        case UploadingFileStatuses.CANCELED: {
            this.isUploading = false;
            this.fileNameControl.setValue( '' );
            this.canUpload = true;
            this.inputFileView.nativeElement.value = '';
            break;
        }
        case UploadingFileStatuses.COMPLETED: {
            this.isUploading = false;
            this.canUpload = false;
            this.refreshSubject.next();
            break;
        }
        default: {
            break;
        }
        }
    }

    inputFileClick() {
        const inputFile = document.getElementById( 'inputFile' );
        inputFile.click();
    }

    // File download
    download() {
        this.remoteFile.download();
    }

    delete() {
        this.isUploading = true;
        this.remoteFile.delete().pipe(
            finalize( () => {
                this.isUploading = false;
            } ),
        ).subscribe( {
            next: () => {
                this.remoteFile = undefined;
                this._updateFileStatus();
                this.isUploading = false;
                this.canUpload = true;
                this.inputFileView.nativeElement.value = '';
            }, error: error => {
                console.error( 'Error in cancelling the file', error );
                this.toastr.error( 'Error in deleting uploading file' );
            }
        } );
    }

    onCommit() {
        return of( undefined );
    }

    onDraftSaving() {
        return of( undefined );
    }

    defaultLabel() {
        return 'Inserire file';
    }

    ngOnDestroy(): void {
        while( this.subscriptions.length > 0 ) {
            this.subscriptions.pop().unsubscribe();
        }
    }

    onSelectionError( message: string ) {
        this.toastr.error( message );
    }

    private _updateFileStatus() {
        if( this.remoteFile ) {
            this.formControl.setValue( this.remoteFile.gridFsFile );
            this.fileNameControl.setValue( this.remoteFile.filename );
            this.formControl.setErrors( null );
            this.fileNameControl.setErrors( null );
        } else {
            this.formControl.setValue( undefined );
            this.fileNameControl.setValue( '' );
            if( this.field.templateOptions.required ) {
                this.formControl.setErrors( { empty: true } );
                this.fileNameControl.setErrors( { empty: true } );
            }
        }
    }

    get allowUpdate() {
        return !( this.field.templateOptions.disableIfCommited && this.stepService.step.isCommited );
    }

}
