<ng-container *ngIf="struttura$ | async as struttura">
    <div class='title-wrapper'>
        <div class='title'>
            <p>{{ struttura.nome_vodafone }}</p>
        </div>
        <div class='office'>
            <p><b>{{ struttura.office_vodafone }}</b></p>
        </div>
        <div class='sub-title'>
            <p>{{ struttura.regione + ' - ' + struttura.comune + ' (' + struttura.prov + ')' }}</p>
        </div>
    </div>
</ng-container>
