import { Injectable } from '@angular/core';
//import * as XLSX from 'xlsx';
import { WndGridColumn } from '@shared/components/wonder-grid/interfaces/wnd-grid-column';

const SHEET_NAME = 'Sheet1';

@Injectable( {
    providedIn: 'root',
} )
export class TableExportService {

    constructor() {
    }

    /**
     * Check a filename and, if not already, add .xlsx
     *
     * @param filename  The file name to check
     * @param formato   The file extension to be written
     * @return return a new filename with xls extension or null if it is invalid.
     */
    normalizeFilename( filename: string, formato: string ): string {
        if( filename ) {
            // eslint-disable-next-line no-bitwise
            const ext = filename.slice( ( filename.lastIndexOf( '.' ) - 1 >>> 0 ) + 2 );
            if( ext === formato ) {
                return filename;
            } else if( !ext ) {
                return filename + `.${ formato }`;
            }
        }
        return '';
    }

    /**
     * Export the data in Excel format.
     *
     * @param filename  The filename to which save data
     * @param data      An array of array with the data to export
     * @param sizes     An array with max sizes (in chars) of each column
     */
    exportAsExcel( filename: string, data: any[][], sizes: number[] ): boolean {
        /*
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet( data, { cellDates: true } );
        ws[ '!cols' ] = sizes.map( s => ( { wch: s } ) );

        console.log( 'Worksheet columns', ws[ '!cols' ] );

        wb.SheetNames.push( SHEET_NAME );
        wb.Sheets[ SHEET_NAME ] = ws;

        XLSX.writeFile( wb, filename );

        return true;
        */
        return false;
    }

    /**
     * Transform the data arrived from server in an array of arrays suitable to be exported in Excel.
     *
     * @param data  The input data is an array of objects.
     * @param columns   The columns to include in the export.
     */
    transformData( data: { [ name: string ]: string | number }[], columns: WndGridColumn[] ): any[][] {
        const allowedColumns = columns.map( c => c.fieldName );
        console.log( 'Transform data to export', data, allowedColumns );

        const header = columns.map( c => c.displayName );
        const rows = data.map( r => columns.map( c => r[ c.fieldName ] ) );

        return [ header, ...rows ];
    }

    calculateColumnsSize( data: string | number[][], columns: WndGridColumn[] ): number[] {
        const sizes = columns.map( () => 10 );
        for( const row of data ) {
            for( let i = 0; i < row.length; i++ ) {
                const cell = row[ i ];
                if( typeof cell === 'string' ) {
                    sizes[ i ] = Math.max( sizes[ i ], cell.length );
                }
            }
        }
        console.log( 'Max columns length', sizes );
        return sizes;
    }
}
