import {Pipe, PipeTransform} from '@angular/core';

@Pipe( {
    name: 'dateAgo',
} )
export class DateAgoPipe implements PipeTransform {
    transform( value: Date, args?: any ): any {
        if ( value ) {
            const seconds = Math.floor( ( +new Date() - +new Date( value ) ) / 1000 );
            if ( seconds < 29 ) { return 'Un momento fa'; }
            const intervals: { [key: string]: {value: number; singular: string; plural: string} } = {
                ora: {
                    value: 3600,
                    singular: 'ora',
                    plural: 'ore'
                },
                minuto: {
                    value: 60,
                    singular: 'minuto',
                    plural: 'minuti'
                },
                secondo: {
                    value: 1,
                    singular: 'secondo',
                    plural: 'secondi'
                }
            };
            let counter: number;
            for ( const i in intervals ) {
                if ( intervals.hasOwnProperty( i ) ) {
                    counter = Math.floor( seconds / intervals[ i ].value );
                    if ( counter > 0 ) {
                        return `${counter} ${counter === 1 ? intervals[ i ].singular : intervals[ i ].plural} fa`;
                    }
                }
            }
        }
        return value;
    }
}
