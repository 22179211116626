import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export class WorkflowHelper {
    public static scriptFields( fields: FormlyFieldConfig[], form: UntypedFormGroup, received: any ) {
        fields = fields.map( f => {
            if( f.key && !f.templateOptions.custom ) {
                const field = form.get( f.key as string );
                if( field ) {
                    field.setValue( received[ f.key as string ] );
                } else {
                    console.warn( `Field ${ f.key } not found in WorkflowHelper` );
                }
            } else {
                if( f.fieldGroup ) {
                    f.fieldGroup = f.fieldGroup.map( fi => {
                        if( fi.key && !fi.templateOptions.custom ) {
                            const field = form.get( fi.key as string );
                            if( field ) {
                                field.setValue( received[ fi.key as string ] );
                            } else {
                                console.warn( `Field ${ f.key } not found in WorkflowHelper` );
                            }
                        }
                        return fi;
                    } );
                }
            }
            return f;
        } );
    }

    // public static disableFieldsIfCommitted(form: FormGroup, fieldsConfig: FormlyFieldConfig[], performDisable:boolean) {
    //   const copy = clone(fieldsConfig);
    //   copy.forEach(fc => {
    //     if(!fc.templateOptions.custom){
    //       if (fc.templateOptions?.disableIfCommited && performDisable) {
    //         form.get(fc.key as string).disable();
    //         if (fc.type && fc.type === 'datepicker') {
    //           fc.templateOptions.datepickerOptions.disabled = true;
    //         }
    //       }
    //       // else{
    //       //   form.get(fc.key as string).enable();
    //       //   if (fc.type && fc.type === 'datepicker') {
    //       //     fc.templateOptions.datepickerOptions.disabled = false;
    //       //   }
    //       // }
    //     }
    //   });
    //   fieldsConfig = copy;
    // }


    public static disableFields( form: UntypedFormGroup, fieldsConfig: FormlyFieldConfig[], disableAll: boolean = true, performDisable: boolean = false ) {
        console.log( 'Disabling fields', fieldsConfig, disableAll, performDisable );
        this.getFlattedFieldsConfig( fieldsConfig ).forEach( fc => {
            if( disableAll || ( performDisable && !fc.templateOptions.custom && fc.templateOptions?.disableIfCommited ) ) {
                form.get( fc.key as string )?.disable();
                if( fc.type && fc.type === 'datepicker' && fc.templateOptions ) {
                    fc.templateOptions.datepickerOptions.disabled = true;
                }
            }
        } );
    }

    /**
     * Restituisce un vettore 'appiattito' dei componenti in una form formly.
     * L'appiattimento funziona attraversando il vettore originale e se un elemento è un fieldGroup, lo elimina ed
     * aggiunge in coda gli elementi del gruppo.
     *
     * @param fields  Il vettore originale dei campi
     */
    static getFlattedFieldsConfig( fields: FormlyFieldConfig[] ): FormlyFieldConfig[] {
        const flatted = fields.slice();
        let i = 0;

        while( i < flatted.length ) {
            if( flatted[ i ].fieldGroup ) {
                flatted.splice( i, 1, ...flatted[ i ].fieldGroup );
            } else {
                i++;
            }
        }

        return flatted;
    }

    static setFlattedFieldsConfig( fields: FormlyFieldConfig[], val: FormlyFieldConfig[] ) {
        val.forEach( f => {
            this.getFlattedFieldsConfig( fields ).filter( s => s.key === f.key )[ 0 ] = f;
        } );
    }
}
