<mat-form-field class="example-full-width cursor-pointer" (click)="onMenuToggle()">
    <mat-label>Upload activities xls</mat-label>
    <input
            matInput
            readonly
            class="file-label cursor-pointer"
            [formControl]="formControl"
            [formlyAttributes]="field"
    />
    <button matSuffix mat-icon-button [matMenuTriggerFor]="uploadMenu" (click)="void($event)">
        <mat-icon>insert_drive_file</mat-icon>
    </button>
</mat-form-field>


<mat-menu #uploadMenu="matMenu">
    <button *ngIf="!document" mat-menu-item (click)="inputFileClick()" [disabled]='controlDisabled'>
        Upload
    </button>
    <input
            id="inputFile"
            hidden
            type="file"
            ngFileSelect
            [options]="options"
            (uploadOutput)="onUploadOutput($event)"
            [uploadInput]="uploadInput"
    />
    <button mat-menu-item (click)="downloadTemplate()">Download template</button>
</mat-menu>

<mat-spinner *ngIf='isUploading' class='m-b-4' [diameter]='24'></mat-spinner>
<p *ngIf='totalImported'>Attività importate: {{totalImported}}</p>
<br>
<h3 *ngIf='resultElements?.length > 0'>Errori:</h3>
<p *ngFor='let element of resultElements'>{{element}}</p>



