<nav mat-tab-nav-bar>
    <ng-container *ngFor="let menuItem of menu$ | async; let index=index">
        <button *ngIf="menuItem.type === 'link'" mat-button
                [routerLink]="buildRoute([menuItem.route])" routerLinkActive="active">
            <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem}">
            </ng-container>
        </button>

        <a *ngIf="menuItem.type === 'extLink'" mat-button [href]="menuItem.route">
            <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem}">
            </ng-container>
        </a>

        <a *ngIf="menuItem.type === 'extTabLink'" mat-button [href]="menuItem.route" target="_blank">
            <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem}">
            </ng-container>
        </a>

        <button *ngIf="menuItem.type === 'sub'" mat-button
                [matMenuTriggerFor]="submenu.menu" routerLinkActive="active"
                [class.active]="menuStates[index].active">
            <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem}">
            </ng-container>

            <app-topmenu-panel #submenu
                               [items]="menuItem.children"
                               [parentRoute]="[menuItem.route]"
                               [level]="1"
                               (routeChange)="onRouteChange($event, index)">
            </app-topmenu-panel>
        </button>
    </ng-container>
</nav>

<ng-template #linkTypeTpl let-item="item" let-level="level">
    <mat-icon class="menu-icon">{{item.icon}}</mat-icon>
    <span class="menu-name">{{item.name | translate}}</span>
    <span class="badge menu-label bg-{{item.label.color}}" *ngIf="item.label">
        {{item.label.value}}
    </span>
    <span class="badge menu-badge bg-{{item.badge.color}}" *ngIf="item.badge">
        {{item.badge.value}}
    </span>
    <mat-icon class="menu-caret" *ngIf="item.type!=='link'">
        {{item.type === 'sub' ? 'arrow_drop_down' : 'launch'}}
    </mat-icon>
</ng-template>
