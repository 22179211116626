import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseMessage } from '@core/services/stream-craft-client/models/BaseMessage';
import { TestMessage } from '@core/services/stream-craft-client/models/TestMessage';
import { SocketMessage } from '@core/services/stream-craft-client/models/SocketMessage';
import { StreamCraftClientService } from '@core/services/stream-craft-client/stream-craft-client.service';
import { FieldUpdatedMessage } from '@core/services/stream-craft-client/models/FieldUpdatedMessage';
import { StreamCraftMessageTypes } from '@core/services/stream-craft-client/models/stream-craft-message-types';
import { NewSiteStateChangedMessage } from '@core/services/stream-craft-client/models/new-site-state-changed-message';
import { PlanStateChangedMessage } from './models/plan-state-changed-message';
import { DetailsFieldUpdatedMessage } from './models/DetailsFieldUpdatedMessage';
import { ToolTxImportListChangeMessage } from '@core/services/stream-craft-client/models/tool-tx-import-list-change-message';

class SubjectObservableMessage {
    sub: Subject<BaseMessage>;
    obs: Observable<BaseMessage>;

    constructor( sub: Subject<BaseMessage> ) {
        this.sub = sub;
        this.obs = sub.asObservable();
    }
}

@Injectable( {
    providedIn: 'root'
} )
export class StreamCraftMessagesStore {
    private store: { [ key: string ]: SubjectObservableMessage } = {};

    constructor( private ws: StreamCraftClientService ) {
        console.log( 'Stream Craft Messages store started' );
        this.store.test = new SubjectObservableMessage( new Subject<TestMessage>() );
        this.store.request = new SubjectObservableMessage( new Subject<FieldUpdatedMessage>() );
        this.store.classification = new SubjectObservableMessage( new Subject<FieldUpdatedMessage>() );
        this.store.requestState = new SubjectObservableMessage( new Subject<NewSiteStateChangedMessage>() );
        this.store.classificationState = new SubjectObservableMessage( new Subject<NewSiteStateChangedMessage>() );
        this.store.toolTx = new SubjectObservableMessage( new Subject<FieldUpdatedMessage>() );
        this.store.toolTxState = new SubjectObservableMessage( new Subject<PlanStateChangedMessage>() );
        this.store.tracking = new SubjectObservableMessage( new Subject<DetailsFieldUpdatedMessage>() );
        this.store.toolTxImport = new SubjectObservableMessage( new Subject<ToolTxImportListChangeMessage>() );

        this.ws.socket().subscribe( { next: ( message: SocketMessage ) => {
            console.log( 'Dispatching message', message );
            switch( message?.type ) {
            case 'test':
                this.store.test.sub.next( new TestMessage( message ) );
                break;
            case 'RequestFieldUpdated':
                this.store.request.sub.next( new FieldUpdatedMessage( message ) );
                break;
            case 'ClassificationFieldUpdated':
                this.store.classification.sub.next( new FieldUpdatedMessage( message ) );
                break;
            case 'RequestNewState':
                this.store.requestState.sub.next( new NewSiteStateChangedMessage( message ) );
                break;
            case 'ClassificationNewState':
                this.store.classificationState.sub.next( new NewSiteStateChangedMessage( message ) );
                break;
            case 'PlanFieldUpdated':
                this.store.toolTx.sub.next( new FieldUpdatedMessage( message ) );
                break;
            case 'PlanNewState':
                this.store.toolTxState.sub.next( new PlanStateChangedMessage( message ) );
                break;
            case 'DetailsFieldUpdated':
                this.store.tracking.sub.next( new DetailsFieldUpdatedMessage( message ) );
                break;
            case 'importListChanged':
                if( message.source === 'ToolTxImporter' )
                    this.store.toolTxImport.sub.next( new ToolTxImportListChangeMessage( message ) );
                else
                    console.warn( "importListChanged streamcraft message from unknown source", message );
                break;
            default:
                console.warn( "Unknown streamcraft message type", message );
            }
        }, error: ( err: Error ) => {
            console.error( 'Error from websocket', err );
        } } );
    }

    public stream( key: StreamCraftMessageTypes ) {
        return this.store[ key ]?.obs;
    }
}
