/* eslint-disable @typescript-eslint/no-loss-of-precision */
import { StrutturaLight } from '../interfaces/struttura_light';

export const STRUCTURES_MOCK: { finished: boolean; data: StrutturaLight[] } = {
    finished: true,
    data: [
        {
            id: '1OF00002',
            office: '1OF00002',
            name: 'P.ta Genova',
            officeType: 'MACRO',
            lat: 45.453333333333333333333333333,
            lon: 9.166388888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00003',
            office: '1OF00003',
            name: 'Via Mazzini',
            officeType: 'MACRO',
            lat: 45.465438888888888888888888889,
            lon: 9.185211111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00004',
            office: '1OF00004',
            name: 'Piazza Sempione',
            officeType: 'MACRO',
            lat: 45.476847222222222222222222223,
            lon: 9.171963888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00005',
            office: '1OF00005',
            name: 'Via Moscova',
            officeType: 'MACRO',
            lat: 45.475538888888888888888888889,
            lon: 9.192219444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00007',
            office: '1OF00007',
            name: 'Piazza Castello',
            officeType: 'MACRO',
            lat: 45.469166666666666666666666667,
            lon: 9.176666666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00008',
            office: '1OF00008',
            name: 'Pzza Insubria',
            officeType: 'MACRO',
            lat: 45.453188888888888888888888889,
            lon: 9.220888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00012',
            office: '1OF00012',
            name: 'P.zza Carrara',
            officeType: 'MACRO',
            lat: 45.434444444444444444444444444,
            lon: 9.180277777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00013',
            office: '1OF00013',
            name: 'Ospedale Maggiore Niguarda',
            officeType: 'MACRO',
            lat: 45.504838888888888888888888889,
            lon: 9.185200000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00014',
            office: '1OF00014',
            name: 'Viale Omero',
            officeType: 'MACRO',
            lat: 45.433630555555555555555555555,
            lon: 9.221088888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00015',
            office: '1OF00015',
            name: 'Porta Romana',
            officeType: 'MACRO',
            lat: 45.444530555555555555555555555,
            lon: 9.199538888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00016',
            office: '1OF00016',
            name: 'Via Nuova Strada Prov.',
            officeType: 'MACRO',
            lat: 45.432388888888888888888888889,
            lon: 9.257588888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00018',
            office: '1OF00018',
            name: 'Via de Predis',
            officeType: 'MACRO',
            lat: 45.503561111111111111111111111,
            lon: 9.152788888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00020',
            office: '1OF00020',
            name: 'Via Marelli',
            officeType: 'MACRO',
            lat: 45.530727777777777777777777778,
            lon: 9.230011111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00021',
            office: '1OF00021',
            name: 'Stazione Porta Garibaldi',
            officeType: 'MACRO',
            lat: 45.490019444444444444444444444,
            lon: 9.184288888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00022',
            office: '1OF00022',
            name: 'Piazzale Tripoli',
            officeType: 'MACRO',
            lat: 45.455861111111111111111111111,
            lon: 9.148161111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00024',
            office: '1OF00024',
            name: 'Policlinico',
            officeType: 'MACRO',
            lat: 45.463438888888888888888888889,
            lon: 9.207238888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00025',
            office: '1OF00025',
            name: 'V. Tosi',
            officeType: 'MACRO',
            lat: 45.443150000000000000000000000,
            lon: 9.157280555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00028',
            office: '1OF00028',
            name: 'Fiera Campionaria',
            officeType: 'MACRO',
            lat: 45.477880555555555555555555556,
            lon: 9.152180555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00029',
            office: '1OF00029',
            name: 'Via Francesco Cilea',
            officeType: 'MACRO',
            lat: 45.495772222222222222222222222,
            lon: 9.109972222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00030',
            office: '1OF00030',
            name: 'Via Quinto Romano',
            officeType: 'MACRO',
            lat: 45.460938888888888888888888889,
            lon: 9.084311111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00031',
            office: '1OF00031',
            name: 'Cascina Gobba',
            officeType: 'MACRO',
            lat: 45.511530555555555555555555556,
            lon: 9.260719444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00032',
            office: '1OF00032',
            name: 'Viale Forlanini',
            officeType: 'MACRO',
            lat: 45.455830555555555555555555556,
            lon: 9.2543,
            zoneId: 1,

        },
        {
            id: '1OF00033',
            office: '1OF00033',
            name: 'Via Rubattino',
            officeType: 'MACRO',
            lat: 45.483800000000000000000000000,
            lon: 9.242611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00034',
            office: '1OF00034',
            name: 'Via Manduria',
            officeType: 'MACRO',
            lat: 45.408333333333333333333333333,
            lon: 9.174722222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00038',
            office: '1OF00038',
            name: 'Olivetti Towers',
            officeType: 'MACRO',
            lat: 45.445166666666666666666666666,
            lon: 9.117055555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00042',
            office: '1OF00042',
            name: 'Via Villa',
            officeType: 'MACRO',
            lat: 45.542222222222222222222222222,
            lon: 9.198611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00043',
            office: '1OF00043',
            name: 'Via G. Pucci',
            officeType: 'MACRO',
            lat: 45.539986111111111111111111111,
            lon: 9.246700000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00045',
            office: '1OF00045',
            name: 'Via Perugino',
            officeType: 'MACRO',
            lat: 45.536944444444444444444444444,
            lon: 9.295555555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00046',
            office: '1OF00046',
            name: 'Cascina Offelle',
            officeType: 'MACRO',
            lat: 45.561794444444444444444444444,
            lon: 9.303916666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00047',
            office: '1OF00047',
            name: 'Dordina',
            officeType: 'MACRO',
            lat: 45.55005,
            lon: 9.341591666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00048',
            office: '1OF00048',
            name: 'Cascina Nuova',
            officeType: 'MACRO',
            lat: 45.398555555555555555555555555,
            lon: 9.149305555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00049',
            office: '1OF00049',
            name: 'Milano 3',
            officeType: 'MACRO',
            lat: 45.360027777777777777777777778,
            lon: 9.158458333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00051',
            office: '1OF00051',
            name: 'Aeroporto di Linate',
            officeType: 'MACRO',
            lat: 45.462052777777777777777777778,
            lon: 9.280811111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00054',
            office: '1OF00054',
            name: 'Piazza VIII Novembre',
            officeType: 'MACRO',
            lat: 45.477480555555555555555555556,
            lon: 9.211288888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00055',
            office: '1OF00055',
            name: 'Viale Monza',
            officeType: 'MACRO',
            lat: 45.510330555555555555555555556,
            lon: 9.226038888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00056',
            office: '1OF00056',
            name: 'Largo Mazzini',
            officeType: 'MACRO',
            lat: 45.583888888888888888888888889,
            lon: 9.274572222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00057',
            office: '1OF00057',
            name: 'Via Luciano Manara',
            officeType: 'MACRO',
            lat: 45.590916666666666666666666666,
            lon: 9.262194444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00058',
            office: '1OF00058',
            name: 'Via Lucania',
            officeType: 'MACRO',
            lat: 45.568888888888888888888888889,
            lon: 9.232500000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00059',
            office: '1OF00059',
            name: 'Via Tiepolo',
            officeType: 'MACRO',
            lat: 45.579094444444444444444444445,
            lon: 9.305222222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00061',
            office: '1OF00061',
            name: 'Binasco',
            officeType: 'MACRO',
            lat: 45.330625000000000000000000000,
            lon: 9.097450000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00064',
            office: '1OF00064',
            name: 'Via Speroni',
            officeType: 'MACRO',
            lat: 45.818169444444444444444444445,
            lon: 8.826750000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00065',
            office: '1OF00065',
            name: 'Viale Borri',
            officeType: 'MACRO',
            lat: 45.805,
            lon: 8.843333333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00066',
            office: '1OF00066',
            name: 'Stadio Franco Ossola',
            officeType: 'MACRO',
            lat: 45.836388888888888888888888889,
            lon: 8.797222222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00067',
            office: '1OF00067',
            name: 'Piazza Missori',
            officeType: 'MACRO',
            lat: 45.461669444444444444444444444,
            lon: 9.190000000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00068',
            office: '1OF00068',
            name: 'Cerro Maggiore',
            officeType: 'MACRO',
            lat: 45.593055555555555555555555555,
            lon: 8.971388888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00070',
            office: '1OF00070',
            name: 'Piazza de Angeli',
            officeType: 'MACRO',
            lat: 45.468080555555555555555555556,
            lon: 9.154680555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00071',
            office: '1OF00071',
            name: 'S. Ambrogio',
            officeType: 'MACRO',
            lat: 45.461583333333333333333333333,
            lon: 9.182472222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00072',
            office: '1OF00072',
            name: 'P.le Medaglie d`Oro',
            officeType: 'MACRO',
            lat: 45.452661111111111111111111111,
            lon: 9.206838888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00074',
            office: '1OF00074',
            name: 'Viale Corsica',
            officeType: 'MACRO',
            lat: 45.463227777777777777777777778,
            lon: 9.227019444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00075',
            office: '1OF00075',
            name: 'Via Padova',
            officeType: 'MACRO',
            lat: 45.498961111111111111111111111,
            lon: 9.235980555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00077',
            office: '1OF00077',
            name: 'P.zza Nigra',
            officeType: 'MACRO',
            lat: 45.497944444444444444444444444,
            lon: 9.176333333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00078',
            office: '1OF00078',
            name: 'Piazza Cavour',
            officeType: 'MACRO',
            lat: 45.471069444444444444444444445,
            lon: 9.191361111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00081',
            office: '1OF00081',
            name: 'Piazza Kennedy',
            officeType: 'MACRO',
            lat: 45.497988888888888888888888889,
            lon: 9.132169444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00082',
            office: '1OF00082',
            name: 'San Donato Milanese',
            officeType: 'MACRO',
            lat: 45.419405555555555555555555556,
            lon: 9.261722222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00083',
            office: '1OF00083',
            name: 'Rozzano Nord',
            officeType: 'MACRO',
            lat: 45.391055555555555555555555555,
            lon: 9.173016666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00084',
            office: '1OF00084',
            name: 'Vigentino',
            officeType: 'MACRO',
            lat: 45.431661111111111111111111111,
            lon: 9.201469444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00086',
            office: '1OF00086',
            name: 'Viale Piave',
            officeType: 'MACRO',
            lat: 45.470330555555555555555555556,
            lon: 9.207130555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00087',
            office: '1OF00087',
            name: 'Paullo',
            officeType: 'MACRO',
            lat: 45.419777777777777777777777778,
            lon: 9.422691666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00089',
            office: '1OF00089',
            name: 'Parco di Monza',
            officeType: 'MACRO',
            lat: 45.619966666666666666666666667,
            lon: 9.281572222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00090',
            office: '1OF00090',
            name: 'Corso Indipendenza',
            officeType: 'MACRO',
            lat: 45.469419444444444444444444445,
            lon: 9.214511111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00092',
            office: '1OF00092',
            name: 'San Colombano',
            officeType: 'MACRO',
            lat: 45.177500000000000000000000000,
            lon: 9.464083333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00093',
            office: '1OF00093',
            name: 'Bernareggio',
            officeType: 'MACRO',
            lat: 45.664041666666666666666666667,
            lon: 9.417363888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00097',
            office: '1OF00097',
            name: 'P.za Velasquez',
            officeType: 'MACRO',
            lat: 45.467319444444444444444444445,
            lon: 9.139380555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00098',
            office: '1OF00098',
            name: 'Turro',
            officeType: 'MACRO',
            lat: 45.500694444444444444444444444,
            lon: 9.218638888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00099',
            office: '1OF00099',
            name: 'Stadio Meazza',
            officeType: 'MACRO',
            lat: 45.480083333333333333333333334,
            lon: 9.124694444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00101',
            office: '1OF00101',
            name: 'Sedriano Sud-Ovest',
            officeType: 'MACRO',
            lat: 45.486461111111111111111111111,
            lon: 8.964230555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00103',
            office: '1OF00103',
            name: 'Passirana',
            officeType: 'MACRO',
            lat: 45.548419444444444444444444444,
            lon: 9.058136111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00104',
            office: '1OF00104',
            name: 'Nerviano',
            officeType: 'MACRO',
            lat: 45.565555555555555555555555556,
            lon: 8.991666666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00106',
            office: '1OF00106',
            name: 'P.zza Gorini',
            officeType: 'MACRO',
            lat: 45.473330555555555555555555556,
            lon: 9.230561111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00107',
            office: '1OF00107',
            name: 'Crocetta',
            officeType: 'MACRO',
            lat: 45.458116666666666666666666667,
            lon: 9.194655555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00108',
            office: '1OF00108',
            name: 'Piazza S. Luigi',
            officeType: 'MACRO',
            lat: 45.442338888888888888888888889,
            lon: 9.2147,
            zoneId: 1,

        },
        {
            id: '1OF00109',
            office: '1OF00109',
            name: 'Largo Giambellino',
            officeType: 'MACRO',
            lat: 45.452680555555555555555555556,
            lon: 9.142419444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00110',
            office: '1OF00110',
            name: 'Cesano Boscone',
            officeType: 'MACRO',
            lat: 45.440291666666666666666666666,
            lon: 9.099605555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00111',
            office: '1OF00111',
            name: 'Vimodrone',
            officeType: 'MACRO',
            lat: 45.517069444444444444444444445,
            lon: 9.293519444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00112',
            office: '1OF00112',
            name: 'Abbiategrasso',
            officeType: 'MACRO',
            lat: 45.399588888888888888888888889,
            lon: 8.917941666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00113',
            office: '1OF00113',
            name: 'S. Martino Olearo',
            officeType: 'MACRO',
            lat: 45.428194444444444444444444445,
            lon: 9.366277777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00115',
            office: '1OF00115',
            name: 'Campomorto',
            officeType: 'MACRO',
            lat: 45.339233333333333333333333333,
            lon: 9.20085,
            zoneId: 1,

        },
        {
            id: '1OF00116',
            office: '1OF00116',
            name: 'Magenta',
            officeType: 'MACRO',
            lat: 45.464036111111111111111111111,
            lon: 8.891963888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00118',
            office: '1OF00118',
            name: 'Arcore',
            officeType: 'MACRO',
            lat: 45.622222222222222222222222223,
            lon: 9.323333333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00119',
            office: '1OF00119',
            name: 'Legnano',
            officeType: 'MACRO',
            lat: 45.596600000000000000000000000,
            lon: 8.914222222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00120',
            office: '1OF00120',
            name: 'Parabiago',
            officeType: 'MACRO',
            lat: 45.561944444444444444444444444,
            lon: 8.953055555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00121',
            office: '1OF00121',
            name: 'Garbagnate',
            officeType: 'MACRO',
            lat: 45.582113888888888888888888889,
            lon: 9.070950000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00122',
            office: '1OF00122',
            name: 'Bollate',
            officeType: 'MACRO',
            lat: 45.542988888888888888888888889,
            lon: 9.111486111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00123',
            office: '1OF00123',
            name: 'Lissone',
            officeType: 'MACRO',
            lat: 45.618780555555555555555555556,
            lon: 9.236888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00124',
            office: '1OF00124',
            name: 'Paderno Dugnano',
            officeType: 'MACRO',
            lat: 45.575111111111111111111111111,
            lon: 9.165788888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00125',
            office: '1OF00125',
            name: 'Varedo',
            officeType: 'MACRO',
            lat: 45.598250000000000000000000000,
            lon: 9.154419444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00126',
            office: '1OF00126',
            name: 'Cologno Monzese Sud',
            officeType: 'MACRO',
            lat: 45.533469444444444444444444444,
            lon: 9.273080555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00127',
            office: '1OF00127',
            name: 'Vimercate',
            officeType: 'MACRO',
            lat: 45.624611111111111111111111111,
            lon: 9.371333333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00128',
            office: '1OF00128',
            name: 'Gorgonzola',
            officeType: 'MACRO',
            lat: 45.529219444444444444444444445,
            lon: 9.406213888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00129',
            office: '1OF00129',
            name: 'Melzo',
            officeType: 'MACRO',
            lat: 45.482163888888888888888888889,
            lon: 9.414288888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00130',
            office: '1OF00130',
            name: 'Carate Brianza',
            officeType: 'MACRO',
            lat: 45.677938888888888888888888889,
            lon: 9.235250000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00131',
            office: '1OF00131',
            name: 'Cernusco sul Naviglio',
            officeType: 'MACRO',
            lat: 45.516719444444444444444444445,
            lon: 9.338330555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00132',
            office: '1OF00132',
            name: 'Limito',
            officeType: 'MACRO',
            lat: 45.483394444444444444444444444,
            lon: 9.324683333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00133',
            office: '1OF00133',
            name: 'Segrate',
            officeType: 'MACRO',
            lat: 45.495738888888888888888888889,
            lon: 9.286530555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00134',
            office: '1OF00134',
            name: 'Trezzano S.N.',
            officeType: 'MACRO',
            lat: 45.426688888888888888888888889,
            lon: 9.059244444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00135',
            office: '1OF00135',
            name: 'Rozzano',
            officeType: 'MACRO',
            lat: 45.384152777777777777777777777,
            lon: 9.165402777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00136',
            office: '1OF00136',
            name: 'Novate',
            officeType: 'MACRO',
            lat: 45.534561111111111111111111111,
            lon: 9.140350000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00137',
            office: '1OF00137',
            name: 'Cinisello Balsamo',
            officeType: 'MACRO',
            lat: 45.556486111111111111111111111,
            lon: 9.210744444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00139',
            office: '1OF00139',
            name: 'Cornaredo-Bareggio',
            officeType: 'MACRO',
            lat: 45.488919444444444444444444444,
            lon: 9.002780555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00140',
            office: '1OF00140',
            name: 'Corbetta',
            officeType: 'MACRO',
            lat: 45.470555555555555555555555556,
            lon: 8.918055555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00141',
            office: '1OF00141',
            name: 'Desio',
            officeType: 'MACRO',
            lat: 45.618169444444444444444444445,
            lon: 9.207941666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00144',
            office: '1OF00144',
            name: 'San Giuliano Milanese',
            officeType: 'MACRO',
            lat: 45.395833333333333333333333333,
            lon: 9.286666666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00145',
            office: '1OF00145',
            name: 'Solaro',
            officeType: 'MACRO',
            lat: 45.610077777777777777777777778,
            lon: 9.081650000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00146',
            office: '1OF00146',
            name: 'Meda',
            officeType: 'MACRO',
            lat: 45.656944444444444444444444444,
            lon: 9.148611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00147',
            office: '1OF00147',
            name: 'Colnago',
            officeType: 'MACRO',
            lat: 45.640016666666666666666666666,
            lon: 9.481466666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00148',
            office: '1OF00148',
            name: 'Osnago',
            officeType: 'MACRO',
            lat: 45.669608333333333333333333334,
            lon: 9.382458333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00149',
            office: '1OF00149',
            name: 'Castano Primo',
            officeType: 'MACRO',
            lat: 45.553391666666666666666666667,
            lon: 8.765986111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00150',
            office: '1OF00150',
            name: 'Seveso',
            officeType: 'MACRO',
            lat: 45.641669444444444444444444444,
            lon: 9.124169444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00151',
            office: '1OF00151',
            name: 'Busto Garolfo',
            officeType: 'MACRO',
            lat: 45.547577777777777777777777777,
            lon: 8.876430555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00156',
            office: '1OF00156',
            name: 'Giussano',
            officeType: 'MACRO',
            lat: 45.706441666666666666666666667,
            lon: 9.207661111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00158',
            office: '1OF00158',
            name: 'Pogliano',
            officeType: 'MACRO',
            lat: 45.540761111111111111111111111,
            lon: 9.0102,
            zoneId: 1,

        },
        {
            id: '1OF00161',
            office: '1OF00161',
            name: 'Usmate - Velate FS',
            officeType: 'MACRO',
            lat: 45.65425,
            lon: 9.375419444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00163',
            office: '1OF00163',
            name: 'Cusano Milanino',
            officeType: 'MACRO',
            lat: 45.560763888888888888888888889,
            lon: 9.188616666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00164',
            office: '1OF00164',
            name: 'V.le Bligny',
            officeType: 'MACRO',
            lat: 45.449194444444444444444444444,
            lon: 9.188138888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00165',
            office: '1OF00165',
            name: 'P.zza S. Agostino',
            officeType: 'MACRO',
            lat: 45.45995,
            lon: 9.168761111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00166',
            office: '1OF00166',
            name: 'Viale Abruzzi',
            officeType: 'MACRO',
            lat: 45.476388888888888888888888889,
            lon: 9.218561111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00167',
            office: '1OF00167',
            name: 'Via Teodosio',
            officeType: 'MACRO',
            lat: 45.483869444444444444444444444,
            lon: 9.230638888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00168',
            office: '1OF00168',
            name: 'P.le Istria',
            officeType: 'MACRO',
            lat: 45.506305555555555555555555556,
            lon: 9.203222222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00169',
            office: '1OF00169',
            name: 'Piazza Stuparich',
            officeType: 'MACRO',
            lat: 45.482161111111111111111111111,
            lon: 9.145250000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00170',
            office: '1OF00170',
            name: 'Via Paolo Sarpi',
            officeType: 'MACRO',
            lat: 45.481130555555555555555555556,
            lon: 9.173580555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00171',
            office: '1OF00171',
            name: 'P.le Baracca',
            officeType: 'MACRO',
            lat: 45.467722222222222222222222223,
            lon: 9.167661111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00173',
            office: '1OF00173',
            name: 'Via Pascarella',
            officeType: 'MACRO',
            lat: 45.523055555555555555555555556,
            lon: 9.137222222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00174',
            office: '1OF00174',
            name: 'Sesto San Giovanni',
            officeType: 'MACRO',
            lat: 45.548355555555555555555555555,
            lon: 9.237808333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00176',
            office: '1OF00176',
            name: 'Viale Tunisia',
            officeType: 'MACRO',
            lat: 45.479030555555555555555555556,
            lon: 9.20295,
            zoneId: 1,

        },
        {
            id: '1OF00177',
            office: '1OF00177',
            name: 'Via Brera',
            officeType: 'MACRO',
            lat: 45.473019444444444444444444445,
            lon: 9.188080555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00178',
            office: '1OF00178',
            name: 'Via Cermenate',
            officeType: 'MACRO',
            lat: 45.438969444444444444444444444,
            lon: 9.182080555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00179',
            office: '1OF00179',
            name: 'Peschiera Borromeo',
            officeType: 'MACRO',
            lat: 45.437652777777777777777777777,
            lon: 9.285872222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00180',
            office: '1OF00180',
            name: 'Bellusco',
            officeType: 'MACRO',
            lat: 45.627236111111111111111111111,
            lon: 9.413158333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00181',
            office: '1OF00181',
            name: 'MELEGNANO EST',
            officeType: 'MACRO',
            lat: 45.362469444444444444444444444,
            lon: 9.315469444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00182',
            office: '1OF00182',
            name: 'Biassono',
            officeType: 'MACRO',
            lat: 45.627777777777777777777777778,
            lon: 9.264722222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00183',
            office: '1OF00183',
            name: 'Senago',
            officeType: 'MACRO',
            lat: 45.568055555555555555555555556,
            lon: 9.124722222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00184',
            office: '1OF00184',
            name: 'Bovisio Masciago',
            officeType: 'MACRO',
            lat: 45.615,
            lon: 9.160833333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00185',
            office: '1OF00185',
            name: 'CASORATE PRIMO',
            officeType: 'MACRO',
            lat: 45.319780555555555555555555556,
            lon: 9.018866666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00186',
            office: '1OF00186',
            name: 'ROSATE',
            officeType: 'MACRO',
            lat: 45.344961111111111111111111111,
            lon: 9.014930555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00187',
            office: '1OF00187',
            name: 'TURBIGO',
            officeType: 'MACRO',
            lat: 45.529519444444444444444444445,
            lon: 8.739061111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00188',
            office: '1OF00188',
            name: 'Cassina de\' Pecchi',
            officeType: 'MACRO',
            lat: 45.520055555555555555555555556,
            lon: 9.358111111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00189',
            office: '1OF00189',
            name: 'CERNUSCO SUL NAVIGLIO NORD',
            officeType: 'MACRO',
            lat: 45.529827777777777777777777778,
            lon: 9.313375,
            zoneId: 1,

        },
        {
            id: '1OF00190',
            office: '1OF00190',
            name: 'Monza Sud',
            officeType: 'MACRO',
            lat: 45.573080555555555555555555556,
            lon: 9.256669444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00192',
            office: '1OF00192',
            name: 'CUSAGO',
            officeType: 'MACRO',
            lat: 45.455716666666666666666666667,
            lon: 9.035513888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00193',
            office: '1OF00193',
            name: 'C.so Magenta',
            officeType: 'MACRO',
            lat: 45.462088888888888888888888889,
            lon: 9.172430555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00195',
            office: '1OF00195',
            name: 'Piazzale Negrelli',
            officeType: 'MACRO',
            lat: 45.446369444444444444444444444,
            lon: 9.139288888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00196',
            office: '1OF00196',
            name: 'Lentate S./S.',
            officeType: 'MACRO',
            lat: 45.674986111111111111111111111,
            lon: 9.1058,
            zoneId: 1,

        },
        {
            id: '1OF00199',
            office: '1OF00199',
            name: 'Arese',
            officeType: 'MACRO',
            lat: 45.550738888888888888888888889,
            lon: 9.080038888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00200',
            office: '1OF00200',
            name: 'Cavour',
            officeType: 'MACRO',
            lat: 44.787500000000000000000000000,
            lon: 7.3766666666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00201',
            office: '1OF00201',
            name: 'RAI Via Verdi',
            officeType: 'MACRO',
            lat: 45.069561111111111111111111111,
            lon: 7.6925611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00203',
            office: '1OF00203',
            name: 'P.za Bernini',
            officeType: 'MACRO',
            lat: 45.075980555555555555555555556,
            lon: 7.6576194444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00204',
            office: '1OF00204',
            name: 'V. Sansovino',
            officeType: 'MACRO',
            lat: 45.109261111111111111111111111,
            lon: 7.6523694444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00206',
            office: '1OF00206',
            name: 'Nole',
            officeType: 'MACRO',
            lat: 45.246833333333333333333333333,
            lon: 7.5809972222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00208',
            office: '1OF00208',
            name: 'Gassino',
            officeType: 'MACRO',
            lat: 45.127700000000000000000000000,
            lon: 7.8224750000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00210',
            office: '1OF00210',
            name: 'Tagliaferro Tetti Piatti',
            officeType: 'MACRO',
            lat: 44.980997222222222222222222223,
            lon: 7.6727000000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00211',
            office: '1OF00211',
            name: 'Casello Settimo Torinese',
            officeType: 'MACRO',
            lat: 45.173166666666666666666666667,
            lon: 7.7562527777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00213',
            office: '1OF00213',
            name: 'Torre Acqua Rosta / Bruere',
            officeType: 'MACRO',
            lat: 45.079369444444444444444444445,
            lon: 7.5664472222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00214',
            office: '1OF00214',
            name: 'Borgo S. Pietro',
            officeType: 'MACRO',
            lat: 45.01425,
            lon: 7.6606111111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00215',
            office: '1OF00215',
            name: 'Sangano',
            officeType: 'MACRO',
            lat: 45.029833333333333333333333334,
            lon: 7.4636111111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00216',
            office: '1OF00216',
            name: 'Montanaro',
            officeType: 'MACRO',
            lat: 45.239097222222222222222222222,
            lon: 7.8576833333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00219',
            office: '1OF00219',
            name: 'Vigone',
            officeType: 'MACRO',
            lat: 44.849513888888888888888888889,
            lon: 7.4987555555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00221',
            office: '1OF00221',
            name: 'Marocchina',
            officeType: 'MACRO',
            lat: 45.110061111111111111111111111,
            lon: 7.5959583333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00222',
            office: '1OF00222',
            name: 'Cso Giulio Cesare',
            officeType: 'MACRO',
            lat: 45.106758333333333333333333333,
            lon: 7.7031555555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00223',
            office: '1OF00223',
            name: 'Torre acqua-Telesio',
            officeType: 'MACRO',
            lat: 45.083613888888888888888888889,
            lon: 7.6307472222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00226',
            office: '1OF00226',
            name: 'Aeroporto Caselle',
            officeType: 'MACRO',
            lat: 45.192194444444444444444444444,
            lon: 7.6439555555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00228',
            office: '1OF00228',
            name: 'Torre Acqua Sabaudia / C.so Francia',
            officeType: 'MACRO',
            lat: 45.071641666666666666666666667,
            lon: 7.5849583333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00229',
            office: '1OF00229',
            name: 'Rio Cocchi',
            officeType: 'MACRO',
            lat: 44.880138888888888888888888889,
            lon: 7.7400277777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00231',
            office: '1OF00231',
            name: 'Lungo Dora Voghera',
            officeType: 'MACRO',
            lat: 45.074311111111111111111111111,
            lon: 7.7252888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00232',
            office: '1OF00232',
            name: 'Santena',
            officeType: 'MACRO',
            lat: 44.945550000000000000000000000,
            lon: 7.7659972222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00233',
            office: '1OF00233',
            name: 'Lingotto',
            officeType: 'MACRO',
            lat: 45.035369444444444444444444444,
            lon: 7.6676000000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00234',
            office: '1OF00234',
            name: 'P.za Robillant',
            officeType: 'MACRO',
            lat: 45.061138888888888888888888889,
            lon: 7.6462388888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00235',
            office: '1OF00235',
            name: 'Piazza Pitagora',
            officeType: 'MACRO',
            lat: 45.042975000000000000000000000,
            lon: 7.6355250000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00236',
            office: '1OF00236',
            name: 'Torre Pellice-Luserna',
            officeType: 'MACRO',
            lat: 44.814241666666666666666666667,
            lon: 7.2874638888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00237',
            office: '1OF00237',
            name: 'Lovere Centro',
            officeType: 'MACRO',
            lat: 45.821441666666666666666666667,
            lon: 10.074008333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00239',
            office: '1OF00239',
            name: 'Stazione Dora',
            officeType: 'MACRO',
            lat: 45.093188888888888888888888889,
            lon: 7.6787944444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00240',
            office: '1OF00240',
            name: 'Rondissone',
            officeType: 'MACRO',
            lat: 45.228716666666666666666666667,
            lon: 7.941363888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00241',
            office: '1OF00241',
            name: 'Brandizzo',
            officeType: 'MACRO',
            lat: 45.184302777777777777777777777,
            lon: 7.8307000000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00243',
            office: '1OF00243',
            name: 'Montalenghe/Foglizzo',
            officeType: 'MACRO',
            lat: 45.338658333333333333333333333,
            lon: 7.8299138888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00245',
            office: '1OF00245',
            name: 'Quassolo',
            officeType: 'MACRO',
            lat: 45.514336111111111111111111111,
            lon: 7.8490916666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00246',
            office: '1OF00246',
            name: 'Salasco',
            officeType: 'MACRO',
            lat: 45.328611111111111111111111111,
            lon: 8.267216666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00247',
            office: '1OF00247',
            name: 'Vercelli',
            officeType: 'MACRO',
            lat: 45.327861111111111111111111111,
            lon: 8.419561111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00249',
            office: '1OF00249',
            name: 'Avigliana',
            officeType: 'MACRO',
            lat: 45.133000000000000000000000000,
            lon: 7.3925277777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00250',
            office: '1OF00250',
            name: 'Cuorgne`',
            officeType: 'MACRO',
            lat: 45.381000000000000000000000000,
            lon: 7.6487500000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00251',
            office: '1OF00251',
            name: 'Borgone di Susa',
            officeType: 'MACRO',
            lat: 45.119527777777777777777777778,
            lon: 7.2614555555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00253',
            office: '1OF00253',
            name: 'Leini`',
            officeType: 'MACRO',
            lat: 45.188633333333333333333333333,
            lon: 7.7287527777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00257',
            office: '1OF00257',
            name: 'Vinovo-La Loggia',
            officeType: 'MACRO',
            lat: 44.947222222222222222222222222,
            lon: 7.6576944444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00258',
            office: '1OF00258',
            name: 'Chiomonte',
            officeType: 'MACRO',
            lat: 45.121111111111111111111111111,
            lon: 6.9941666666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00260',
            office: '1OF00260',
            name: 'Piazza San Giovanni',
            officeType: 'MACRO',
            lat: 45.074769444444444444444444445,
            lon: 7.6851805555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00261',
            office: '1OF00261',
            name: 'Jolly Hotel Ambasciatori',
            officeType: 'MACRO',
            lat: 45.068950000000000000000000000,
            lon: 7.6663861111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00262',
            office: '1OF00262',
            name: 'Jolly Hotel Principi di Piemonte',
            officeType: 'MACRO',
            lat: 45.065969444444444444444444444,
            lon: 7.6827805555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00263',
            office: '1OF00263',
            name: 'Ponte Umberto',
            officeType: 'MACRO',
            lat: 45.062630555555555555555555556,
            lon: 7.6974222222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00265',
            office: '1OF00265',
            name: 'Via Pacini',
            officeType: 'MACRO',
            lat: 45.088161111111111111111111111,
            lon: 7.7005,
            zoneId: 1,

        },
        {
            id: '1OF00266',
            office: '1OF00266',
            name: 'Pino Torinese',
            officeType: 'MACRO',
            lat: 45.040319444444444444444444444,
            lon: 7.7979750000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00267',
            office: '1OF00267',
            name: 'Oulx',
            officeType: 'MACRO',
            lat: 45.028472222222222222222222223,
            lon: 6.8176111111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00269',
            office: '1OF00269',
            name: 'Bardonecchia',
            officeType: 'MACRO',
            lat: 45.082630555555555555555555556,
            lon: 6.7077777777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00271',
            office: '1OF00271',
            name: 'Ivrea2 - Castellazzo',
            officeType: 'MACRO',
            lat: 45.466700000000000000000000000,
            lon: 7.8729611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00272',
            office: '1OF00272',
            name: 'ICO',
            officeType: 'MACRO',
            lat: 45.45945,
            lon: 7.8739888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00274',
            office: '1OF00274',
            name: 'Orbassano',
            officeType: 'MACRO',
            lat: 45.007872222222222222222222222,
            lon: 7.5474472222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00275',
            office: '1OF00275',
            name: 'Rivoli',
            officeType: 'MACRO',
            lat: 45.071941666666666666666666667,
            lon: 7.5148916666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00276',
            office: '1OF00276',
            name: 'Settimo T.se',
            officeType: 'MACRO',
            lat: 45.141269444444444444444444444,
            lon: 7.7691694444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00277',
            office: '1OF00277',
            name: 'Pampalu',
            officeType: 'MACRO',
            lat: 45.167000000000000000000000000,
            lon: 7.0347777777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00278',
            office: '1OF00278',
            name: 'Rivarolo',
            officeType: 'MACRO',
            lat: 45.339611111111111111111111111,
            lon: 7.7234722222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00279',
            office: '1OF00279',
            name: 'Chieri',
            officeType: 'MACRO',
            lat: 45.020100000000000000000000000,
            lon: 7.8213222222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00283',
            office: '1OF00283',
            name: 'Pragelato',
            officeType: 'MACRO',
            lat: 44.992719444444444444444444444,
            lon: 6.9494111111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00284',
            office: '1OF00284',
            name: 'Villar Perosa',
            officeType: 'MACRO',
            lat: 44.897791666666666666666666666,
            lon: 7.2534444444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00288',
            office: '1OF00288',
            name: 'Sestriere',
            officeType: 'MACRO',
            lat: 44.953888888888888888888888889,
            lon: 6.8866666666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00289',
            office: '1OF00289',
            name: 'Lanzo Torinese',
            officeType: 'MACRO',
            lat: 45.292019444444444444444444444,
            lon: 7.4769333333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00290',
            office: '1OF00290',
            name: 'Chivasso',
            officeType: 'MACRO',
            lat: 45.191711111111111111111111111,
            lon: 7.8892972222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00291',
            office: '1OF00291',
            name: 'Corso Marconi',
            officeType: 'MACRO',
            lat: 45.05655,
            lon: 7.6837500000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00292',
            office: '1OF00292',
            name: 'Corso Einaudi',
            officeType: 'MACRO',
            lat: 45.058472222222222222222222222,
            lon: 7.6633888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00296',
            office: '1OF00296',
            name: 'Alpignano',
            officeType: 'MACRO',
            lat: 45.104041666666666666666666667,
            lon: 7.5423027777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00297',
            office: '1OF00297',
            name: 'Carmagnola',
            officeType: 'MACRO',
            lat: 44.842911111111111111111111111,
            lon: 7.7186166666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00298',
            office: '1OF00298',
            name: 'Cirie`',
            officeType: 'MACRO',
            lat: 45.236138888888888888888888889,
            lon: 7.6065638888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00301',
            office: '1OF00301',
            name: 'San Bernardino',
            officeType: 'MACRO',
            lat: 44.416527777777777777777777778,
            lon: 8.944583333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00302',
            office: '1OF00302',
            name: 'Piazza Paolo Da Novi',
            officeType: 'MACRO',
            lat: 44.405488888888888888888888889,
            lon: 8.949841666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00303',
            office: '1OF00303',
            name: 'Albaro',
            officeType: 'MACRO',
            lat: 44.400661111111111111111111111,
            lon: 8.969058333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00304',
            office: '1OF00304',
            name: 'Fiera Internazionale',
            officeType: 'MACRO',
            lat: 44.405916666666666666666666667,
            lon: 8.936611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00305',
            office: '1OF00305',
            name: 'Marassi',
            officeType: 'MACRO',
            lat: 44.417161111111111111111111111,
            lon: 8.968280555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00306',
            office: '1OF00306',
            name: 'Via di Francia-Sampierdarena',
            officeType: 'MACRO',
            lat: 44.409911111111111111111111111,
            lon: 8.902761111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00307',
            office: '1OF00307',
            name: 'Aereoporto',
            officeType: 'MACRO',
            lat: 44.41625,
            lon: 8.851583333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00308',
            office: '1OF00308',
            name: 'S. Martino',
            officeType: 'MACRO',
            lat: 44.406802777777777777777777778,
            lon: 8.985583333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00309',
            office: '1OF00309',
            name: 'Corso Europa-Castagna',
            officeType: 'MACRO',
            lat: 44.398050000000000000000000000,
            lon: 9.00195,
            zoneId: 1,

        },
        {
            id: '1OF00311',
            office: '1OF00311',
            name: 'Erzelli',
            officeType: 'MACRO',
            lat: 44.421130555555555555555555556,
            lon: 8.864238888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00312',
            office: '1OF00312',
            name: 'Palmaro',
            officeType: 'MACRO',
            lat: 44.432388888888888888888888889,
            lon: 8.782944444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00313',
            office: '1OF00313',
            name: 'Multedo',
            officeType: 'MACRO',
            lat: 44.429811111111111111111111111,
            lon: 8.805061111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00314',
            office: '1OF00314',
            name: 'Oregina',
            officeType: 'MACRO',
            lat: 44.429027777777777777777777778,
            lon: 8.928333333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00316',
            office: '1OF00316',
            name: 'Voltri',
            officeType: 'MACRO',
            lat: 44.429444444444444444444444445,
            lon: 8.753888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00317',
            office: '1OF00317',
            name: 'Coronata',
            officeType: 'MACRO',
            lat: 44.432788888888888888888888889,
            lon: 8.881850000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00318',
            office: '1OF00318',
            name: 'Staglieno',
            officeType: 'MACRO',
            lat: 44.437647222222222222222222222,
            lon: 8.953008333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00319',
            office: '1OF00319',
            name: 'Torbella',
            officeType: 'MACRO',
            lat: 44.446861111111111111111111111,
            lon: 8.90625,
            zoneId: 1,

        },
        {
            id: '1OF00320',
            office: '1OF00320',
            name: 'Capenardo',
            officeType: 'MACRO',
            lat: 44.454166666666666666666666667,
            lon: 9.065833333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00322',
            office: '1OF00322',
            name: 'Madonna del Monte',
            officeType: 'MACRO',
            lat: 44.413605555555555555555555556,
            lon: 8.965147222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00324',
            office: '1OF00324',
            name: 'Monte Maggio',
            officeType: 'MACRO',
            lat: 44.556694444444444444444444444,
            lon: 9.003638888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00325',
            office: '1OF00325',
            name: 'Sestri Levante',
            officeType: 'MACRO',
            lat: 44.270555555555555555555555556,
            lon: 9.397777777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00326',
            office: '1OF00326',
            name: 'Monte Verzi',
            officeType: 'MACRO',
            lat: 44.429580555555555555555555556,
            lon: 9.242711111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00327',
            office: '1OF00327',
            name: 'Carasco',
            officeType: 'MACRO',
            lat: 44.3662,
            lon: 9.348805555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00328',
            office: '1OF00328',
            name: 'Bocchetta',
            officeType: 'MACRO',
            lat: 44.552222222222222222222222222,
            lon: 8.892222222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00329',
            office: '1OF00329',
            name: 'Sturla',
            officeType: 'MACRO',
            lat: 44.391858333333333333333333333,
            lon: 8.978366666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00330',
            office: '1OF00330',
            name: 'Arenzano',
            officeType: 'MACRO',
            lat: 44.398341666666666666666666666,
            lon: 8.678852777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00331',
            office: '1OF00331',
            name: 'Cogoleto',
            officeType: 'MACRO',
            lat: 44.389166666666666666666666666,
            lon: 8.630555555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00332',
            office: '1OF00332',
            name: 'S. Desiderio',
            officeType: 'MACRO',
            lat: 44.410938888888888888888888889,
            lon: 9.004919444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00333',
            office: '1OF00333',
            name: 'Crocefieschi',
            officeType: 'MACRO',
            lat: 44.597002777777777777777777777,
            lon: 9.051519444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00335',
            office: '1OF00335',
            name: 'Fregarolo',
            officeType: 'MACRO',
            lat: 44.517294444444444444444444445,
            lon: 9.316266666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00336',
            office: '1OF00336',
            name: 'Portofino',
            officeType: 'MACRO',
            lat: 44.303055555555555555555555556,
            lon: 9.215,
            zoneId: 1,

        },
        {
            id: '1OF00337',
            office: '1OF00337',
            name: 'Nazaro',
            officeType: 'MACRO',
            lat: 44.399405555555555555555555555,
            lon: 8.95605,
            zoneId: 1,

        },
        {
            id: '1OF00338',
            office: '1OF00338',
            name: 'Municipio',
            officeType: 'MACRO',
            lat: 44.411055555555555555555555556,
            lon: 8.935850000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00339',
            office: '1OF00339',
            name: 'Santa Margherita',
            officeType: 'MACRO',
            lat: 44.340055555555555555555555555,
            lon: 9.21475,
            zoneId: 1,

        },
        {
            id: '1OF00341',
            office: '1OF00341',
            name: 'Principe',
            officeType: 'MACRO',
            lat: 44.417780555555555555555555556,
            lon: 8.924719444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00342',
            office: '1OF00342',
            name: 'S. Teodoro',
            officeType: 'MACRO',
            lat: 44.422469444444444444444444445,
            lon: 8.909994444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00344',
            office: '1OF00344',
            name: 'Samp',
            officeType: 'MACRO',
            lat: 44.412391666666666666666666667,
            lon: 8.894763888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00345',
            office: '1OF00345',
            name: 'CIRCONVALLAZIONE',
            officeType: 'MACRO',
            lat: 44.414697222222222222222222222,
            lon: 8.930202777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00346',
            office: '1OF00346',
            name: 'CENTRO STORICO',
            officeType: 'MACRO',
            lat: 44.408638888888888888888888889,
            lon: 8.933419444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00347',
            office: '1OF00347',
            name: 'Maggiorasca',
            officeType: 'MACRO',
            lat: 44.55095,
            lon: 9.489333333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00348',
            office: '1OF00348',
            name: 'Carate Brianza Sud',
            officeType: 'MACRO',
            lat: 45.664372222222222222222222222,
            lon: 9.233444444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00349',
            office: '1OF00349',
            name: 'Portoria',
            officeType: 'MACRO',
            lat: 44.411658333333333333333333333,
            lon: 8.939350000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00350',
            office: '1OF00350',
            name: 'Camnago',
            officeType: 'MACRO',
            lat: 45.665502777777777777777777778,
            lon: 9.128983333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00351',
            office: '1OF00351',
            name: 'Valleregia',
            officeType: 'MACRO',
            lat: 44.517500000000000000000000000,
            lon: 8.946666666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00352',
            office: '1OF00352',
            name: 'Montoggio',
            officeType: 'MACRO',
            lat: 44.518305555555555555555555556,
            lon: 9.071277777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00357',
            office: '1OF00357',
            name: 'LAGACCIO',
            officeType: 'MACRO',
            lat: 44.427930555555555555555555556,
            lon: 8.917711111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00359',
            office: '1OF00359',
            name: 'Chiavari',
            officeType: 'MACRO',
            lat: 44.311944444444444444444444444,
            lon: 9.337416666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00360',
            office: '1OF00360',
            name: 'Bogliasco',
            officeType: 'MACRO',
            lat: 44.390811111111111111111111111,
            lon: 9.075700000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00362',
            office: '1OF00362',
            name: 'Recco',
            officeType: 'MACRO',
            lat: 44.372250000000000000000000000,
            lon: 9.156638888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00363',
            office: '1OF00363',
            name: 'Rapallo',
            officeType: 'MACRO',
            lat: 44.360555555555555555555555556,
            lon: 9.240000000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00364',
            office: '1OF00364',
            name: 'Zoagli',
            officeType: 'MACRO',
            lat: 44.340083333333333333333333333,
            lon: 9.259555555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00365',
            office: '1OF00365',
            name: 'Campodonico',
            officeType: 'MACRO',
            lat: 44.335083333333333333333333333,
            lon: 9.336611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00366',
            office: '1OF00366',
            name: 'Entella',
            officeType: 'MACRO',
            lat: 44.317936111111111111111111111,
            lon: 9.321775000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00367',
            office: '1OF00367',
            name: 'Cavi',
            officeType: 'MACRO',
            lat: 44.307277777777777777777777778,
            lon: 9.377972222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00368',
            office: '1OF00368',
            name: 'Pila',
            officeType: 'MACRO',
            lat: 44.262680555555555555555555556,
            lon: 9.415480555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00369',
            office: '1OF00369',
            name: 'Croce dei Tozzi',
            officeType: 'MACRO',
            lat: 44.277500000000000000000000000,
            lon: 9.479722222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00371',
            office: '1OF00371',
            name: 'Schiena di Sciona',
            officeType: 'MACRO',
            lat: 44.254611111111111111111111111,
            lon: 9.572750000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00372',
            office: '1OF00372',
            name: 'Pontex',
            officeType: 'MACRO',
            lat: 44.50265,
            lon: 8.896688888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00373',
            office: '1OF00373',
            name: 'SESTRI PONENTE',
            officeType: 'MACRO',
            lat: 44.427111111111111111111111111,
            lon: 8.846888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00374',
            office: '1OF00374',
            name: 'S. Fruttuoso',
            officeType: 'MACRO',
            lat: 44.407302777777777777777777778,
            lon: 8.961333333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00375',
            office: '1OF00375',
            name: 'Expo',
            officeType: 'MACRO',
            lat: 44.412941666666666666666666667,
            lon: 8.930822222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00376',
            office: '1OF00376',
            name: 'Cornigliano',
            officeType: 'MACRO',
            lat: 44.417200000000000000000000000,
            lon: 8.873980555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00377',
            office: '1OF00377',
            name: 'POLCEVERA',
            officeType: 'MACRO',
            lat: 44.448800000000000000000000000,
            lon: 8.889580555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00378',
            office: '1OF00378',
            name: 'Pegli',
            officeType: 'MACRO',
            lat: 44.426938888888888888888888889,
            lon: 8.830569444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00380',
            office: '1OF00380',
            name: 'Casa della Volpe',
            officeType: 'MACRO',
            lat: 44.447508333333333333333333333,
            lon: 8.737272222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00381',
            office: '1OF00381',
            name: 'Masone',
            officeType: 'MACRO',
            lat: 44.498888888888888888888888889,
            lon: 8.714027777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00382',
            office: '1OF00382',
            name: 'Bric Mondo',
            officeType: 'MACRO',
            lat: 44.552777777777777777777777778,
            lon: 8.681666666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00383',
            office: '1OF00383',
            name: 'Ruentes',
            officeType: 'MACRO',
            lat: 44.351972222222222222222222222,
            lon: 9.219111111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00384',
            office: '1OF00384',
            name: 'SAN GOTTARDO',
            officeType: 'MACRO',
            lat: 44.454666666666666666666666667,
            lon: 8.972750000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00385',
            office: '1OF00385',
            name: 'Prato',
            officeType: 'MACRO',
            lat: 44.448211111111111111111111111,
            lon: 9.008411111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00386',
            office: '1OF00386',
            name: 'Foce',
            officeType: 'MACRO',
            lat: 44.399355555555555555555555555,
            lon: 8.946319444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00388',
            office: '1OF00388',
            name: 'VAL GRAVEGLIA',
            officeType: 'MACRO',
            lat: 44.340833333333333333333333333,
            lon: 9.389166666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00389',
            office: '1OF00389',
            name: 'S. Stefano d`Aveto',
            officeType: 'MACRO',
            lat: 44.522500000000000000000000000,
            lon: 9.388055555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00390',
            office: '1OF00390',
            name: 'QUINTO',
            officeType: 'MACRO',
            lat: 44.402219444444444444444444444,
            lon: 8.984361111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00391',
            office: '1OF00391',
            name: 'Merate Brugarolo',
            officeType: 'MACRO',
            lat: 45.688952777777777777777777777,
            lon: 9.415652777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00392',
            office: '1OF00392',
            name: 'Piccapietra',
            officeType: 'MACRO',
            lat: 44.409027777777777777777777778,
            lon: 8.935972222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00393',
            office: '1OF00393',
            name: 'Galliera',
            officeType: 'MACRO',
            lat: 44.402466666666666666666666667,
            lon: 8.940777777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00394',
            office: '1OF00394',
            name: 'SAN VINCENZO',
            officeType: 'MACRO',
            lat: 44.406872222222222222222222222,
            lon: 8.942647222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00395',
            office: '1OF00395',
            name: 'Tommaseo',
            officeType: 'MACRO',
            lat: 44.402022222222222222222222222,
            lon: 8.951716666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00397',
            office: '1OF00397',
            name: 'Isonzo',
            officeType: 'MACRO',
            lat: 44.394191666666666666666666666,
            lon: 8.984180555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00398',
            office: '1OF00398',
            name: 'Ferraris',
            officeType: 'MACRO',
            lat: 44.418138888888888888888888889,
            lon: 8.953488888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00399',
            office: '1OF00399',
            name: 'GROPALLO',
            officeType: 'MACRO',
            lat: 44.388838888888888888888888889,
            lon: 9.042219444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00401',
            office: '1OF00401',
            name: 'Novara',
            officeType: 'MACRO',
            lat: 45.453805555555555555555555556,
            lon: 8.647416666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00402',
            office: '1OF00402',
            name: 'Mosezzo',
            officeType: 'MACRO',
            lat: 45.467500000000000000000000000,
            lon: 8.524305555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00403',
            office: '1OF00403',
            name: 'Greggio',
            officeType: 'MACRO',
            lat: 45.461,
            lon: 8.388213888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00404',
            office: '1OF00404',
            name: 'Fornace',
            officeType: 'MACRO',
            lat: 45.448569444444444444444444444,
            lon: 8.235808333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00405',
            office: '1OF00405',
            name: 'Santhia',
            officeType: 'MACRO',
            lat: 45.371625000000000000000000000,
            lon: 8.157427777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00406',
            office: '1OF00406',
            name: 'Cigliano',
            officeType: 'MACRO',
            lat: 45.290833333333333333333333333,
            lon: 8.05305,
            zoneId: 1,

        },
        {
            id: '1OF00407',
            office: '1OF00407',
            name: 'Borgo San Giovanni',
            officeType: 'MACRO',
            lat: 45.281361111111111111111111111,
            lon: 9.436527777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00408',
            office: '1OF00408',
            name: 'Brembio',
            officeType: 'MACRO',
            lat: 45.216388888888888888888888889,
            lon: 9.565830555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00409',
            office: '1OF00409',
            name: 'Seregno',
            officeType: 'MACRO',
            lat: 45.648272222222222222222222222,
            lon: 9.209933333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00410',
            office: '1OF00410',
            name: 'Malpensa',
            officeType: 'MACRO',
            lat: 45.649150000000000000000000000,
            lon: 8.725033333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00411',
            office: '1OF00411',
            name: 'Monte della Croce',
            officeType: 'MACRO',
            lat: 45.831277777777777777777777778,
            lon: 9.046333333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00412',
            office: '1OF00412',
            name: 'Turate',
            officeType: 'MACRO',
            lat: 45.654527777777777777777777778,
            lon: 9.014972222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00413',
            office: '1OF00413',
            name: 'Fino Mornasco',
            officeType: 'MACRO',
            lat: 45.759722222222222222222222222,
            lon: 9.046944444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00414',
            office: '1OF00414',
            name: 'Lazzago',
            officeType: 'MACRO',
            lat: 45.798611111111111111111111111,
            lon: 9.062222222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00415',
            office: '1OF00415',
            name: 'Viverone',
            officeType: 'MACRO',
            lat: 45.403166666666666666666666667,
            lon: 8.018694444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00416',
            office: '1OF00416',
            name: 'Piazza Vittoria',
            officeType: 'MACRO',
            lat: 45.806083333333333333333333333,
            lon: 9.090027777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00417',
            office: '1OF00417',
            name: 'Piazza Camerlata',
            officeType: 'MACRO',
            lat: 45.782055555555555555555555556,
            lon: 9.082777777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00418',
            office: '1OF00418',
            name: 'Cernobbio',
            officeType: 'MACRO',
            lat: 45.841750000000000000000000000,
            lon: 9.075472222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00420',
            office: '1OF00420',
            name: 'Guanzate',
            officeType: 'MACRO',
            lat: 45.730111111111111111111111111,
            lon: 9.004333333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00422',
            office: '1OF00422',
            name: 'Palanzo',
            officeType: 'MACRO',
            lat: 45.869166666666666666666666667,
            lon: 9.162527777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00424',
            office: '1OF00424',
            name: 'Porlezza',
            officeType: 'MACRO',
            lat: 46.004777777777777777777777778,
            lon: 9.069000000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00425',
            office: '1OF00425',
            name: 'Lomazzo',
            officeType: 'MACRO',
            lat: 45.707305555555555555555555556,
            lon: 9.025138888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00426',
            office: '1OF00426',
            name: 'Lenno',
            officeType: 'MACRO',
            lat: 45.968888888888888888888888889,
            lon: 9.195030555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00427',
            office: '1OF00427',
            name: 'Lipomo',
            officeType: 'MACRO',
            lat: 45.796388888888888888888888889,
            lon: 9.128055555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00428',
            office: '1OF00428',
            name: 'Merone',
            officeType: 'MACRO',
            lat: 45.782500000000000000000000000,
            lon: 9.240555555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00429',
            office: '1OF00429',
            name: 'Alzate Brianza',
            officeType: 'MACRO',
            lat: 45.772861111111111111111111111,
            lon: 9.167805555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00430',
            office: '1OF00430',
            name: 'Olate',
            officeType: 'MACRO',
            lat: 45.864241666666666666666666667,
            lon: 9.404708333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00431',
            office: '1OF00431',
            name: 'Casatenovo',
            officeType: 'MACRO',
            lat: 45.698888888888888888888888889,
            lon: 9.3125,
            zoneId: 1,

        },
        {
            id: '1OF00432',
            office: '1OF00432',
            name: 'Calusco d`Adda',
            officeType: 'MACRO',
            lat: 45.694166666666666666666666666,
            lon: 9.481944444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00433',
            office: '1OF00433',
            name: 'Calco',
            officeType: 'MACRO',
            lat: 45.725508333333333333333333334,
            lon: 9.417438888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00434',
            office: '1OF00434',
            name: 'Brugherio est',
            officeType: 'MACRO',
            lat: 45.551722222222222222222222222,
            lon: 9.305741666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00435',
            office: '1OF00435',
            name: 'Bulciago',
            officeType: 'MACRO',
            lat: 45.752222222222222222222222222,
            lon: 9.265833333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00436',
            office: '1OF00436',
            name: 'Garlate',
            officeType: 'MACRO',
            lat: 45.804513888888888888888888889,
            lon: 9.450158333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00437',
            office: '1OF00437',
            name: 'Promessi Sposi',
            officeType: 'MACRO',
            lat: 45.850875,
            lon: 9.391805555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00438',
            office: '1OF00438',
            name: 'L`Innominato',
            officeType: 'MACRO',
            lat: 45.841244444444444444444444444,
            lon: 9.409952777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00441',
            office: '1OF00441',
            name: 'Civate',
            officeType: 'MACRO',
            lat: 45.831416666666666666666666667,
            lon: 9.340888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00442',
            office: '1OF00442',
            name: 'Domaso',
            officeType: 'MACRO',
            lat: 46.164944444444444444444444444,
            lon: 9.336138888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00443',
            office: '1OF00443',
            name: 'I Bravi',
            officeType: 'MACRO',
            lat: 45.899166666666666666666666666,
            lon: 9.308888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00444',
            office: '1OF00444',
            name: 'Lierna (ex Grumo)',
            officeType: 'MACRO',
            lat: 45.959888888888888888888888889,
            lon: 9.306677777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00445',
            office: '1OF00445',
            name: 'Gittana',
            officeType: 'MACRO',
            lat: 46.025555555555555555555555556,
            lon: 9.290833333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00446',
            office: '1OF00446',
            name: 'Dervio',
            officeType: 'MACRO',
            lat: 46.070833333333333333333333334,
            lon: 9.274166666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00447',
            office: '1OF00447',
            name: 'Valfurva',
            officeType: 'MACRO',
            lat: 46.417136111111111111111111111,
            lon: 10.490388888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00448',
            office: '1OF00448',
            name: 'Lago Palu`',
            officeType: 'MACRO',
            lat: 46.288805555555555555555555555,
            lon: 9.885705555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00449',
            office: '1OF00449',
            name: 'Villa di Chiavenna',
            officeType: 'MACRO',
            lat: 46.331811111111111111111111111,
            lon: 9.486238888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00450',
            office: '1OF00450',
            name: 'Sondrio',
            officeType: 'MACRO',
            lat: 46.173041666666666666666666667,
            lon: 9.837227777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00451',
            office: '1OF00451',
            name: 'Livigno',
            officeType: 'MACRO',
            lat: 46.541138888888888888888888889,
            lon: 10.165530555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00452',
            office: '1OF00452',
            name: 'Bormio',
            officeType: 'MACRO',
            lat: 46.472730555555555555555555556,
            lon: 10.353430555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00454',
            office: '1OF00454',
            name: 'PONTE IN VALTELLINA',
            officeType: 'MACRO',
            lat: 46.155283333333333333333333333,
            lon: 9.975852777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00456',
            office: '1OF00456',
            name: 'Morbegno',
            officeType: 'MACRO',
            lat: 46.159911111111111111111111111,
            lon: 9.568716666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00457',
            office: '1OF00457',
            name: 'Berbenno di Valtellina',
            officeType: 'MACRO',
            lat: 46.149550000000000000000000000,
            lon: 9.726250000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00459',
            office: '1OF00459',
            name: 'Cantu SUD',
            officeType: 'MACRO',
            lat: 45.730319444444444444444444445,
            lon: 9.167338888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00460',
            office: '1OF00460',
            name: 'Teglio',
            officeType: 'MACRO',
            lat: 46.157230555555555555555555556,
            lon: 10.113738888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00462',
            office: '1OF00462',
            name: 'Novate Mezzola',
            officeType: 'MACRO',
            lat: 46.213888888888888888888888889,
            lon: 9.430000000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00465',
            office: '1OF00465',
            name: 'Chiesa Valmalenco',
            officeType: 'MACRO',
            lat: 46.270133333333333333333333334,
            lon: 9.844813888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00466',
            office: '1OF00466',
            name: 'Madesimo',
            officeType: 'MACRO',
            lat: 46.437361111111111111111111111,
            lon: 9.35185,
            zoneId: 1,

        },
        {
            id: '1OF00468',
            office: '1OF00468',
            name: 'Grosotto',
            officeType: 'MACRO',
            lat: 46.272141666666666666666666667,
            lon: 10.251366666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00470',
            office: '1OF00470',
            name: 'Rovellasca',
            officeType: 'MACRO',
            lat: 45.663938888888888888888888889,
            lon: 9.046438888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00472',
            office: '1OF00472',
            name: 'VENIANO',
            officeType: 'MACRO',
            lat: 45.711111111111111111111111111,
            lon: 8.972811111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00473',
            office: '1OF00473',
            name: 'Cislago',
            officeType: 'MACRO',
            lat: 45.666666666666666666666666667,
            lon: 8.964722222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00475',
            office: '1OF00475',
            name: 'Valbrona',
            officeType: 'MACRO',
            lat: 45.871277777777777777777777778,
            lon: 9.303611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00476',
            office: '1OF00476',
            name: 'V.LE MONTEGRAPPA',
            officeType: 'MACRO',
            lat: 45.184177777777777777777777777,
            lon: 9.178380555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00477',
            office: '1OF00477',
            name: 'P.zza San Pietro',
            officeType: 'MACRO',
            lat: 45.196944444444444444444444444,
            lon: 9.155,
            zoneId: 1,

        },
        {
            id: '1OF00479',
            office: '1OF00479',
            name: 'Cava Manara',
            officeType: 'MACRO',
            lat: 45.144719444444444444444444444,
            lon: 9.101669444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00480',
            office: '1OF00480',
            name: 'Meda_2',
            officeType: 'MACRO',
            lat: 45.674452777777777777777777778,
            lon: 9.150702777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00481',
            office: '1OF00481',
            name: 'ASSO-CANZO',
            officeType: 'MACRO',
            lat: 45.858666666666666666666666667,
            lon: 9.254166666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00482',
            office: '1OF00482',
            name: 'Lanzo d`Intelvi',
            officeType: 'MACRO',
            lat: 45.992383333333333333333333333,
            lon: 9.049391666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00483',
            office: '1OF00483',
            name: 'Colico',
            officeType: 'MACRO',
            lat: 46.120911111111111111111111111,
            lon: 9.320250000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00485',
            office: '1OF00485',
            name: 'Stadio',
            officeType: 'MACRO',
            lat: 45.809666666666666666666666667,
            lon: 9.074027777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00487',
            office: '1OF00487',
            name: 'Figino Serenza',
            officeType: 'MACRO',
            lat: 45.705194444444444444444444444,
            lon: 9.141944444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00488',
            office: '1OF00488',
            name: 'Capiago Intimiano',
            officeType: 'MACRO',
            lat: 45.770694444444444444444444445,
            lon: 9.139777777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00489',
            office: '1OF00489',
            name: 'CAMPODOLCINO',
            officeType: 'MACRO',
            lat: 46.420661111111111111111111111,
            lon: 9.334280555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00490',
            office: '1OF00490',
            name: 'Borghetto Lodigiano',
            officeType: 'MACRO',
            lat: 45.210166666666666666666666667,
            lon: 9.505361111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00491',
            office: '1OF00491',
            name: 'Magreglio',
            officeType: 'MACRO',
            lat: 45.926111111111111111111111111,
            lon: 9.266666666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00492',
            office: '1OF00492',
            name: 'Uboldo',
            officeType: 'MACRO',
            lat: 45.609138888888888888888888889,
            lon: 9.011361111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00494',
            office: '1OF00494',
            name: 'Albavilla',
            officeType: 'MACRO',
            lat: 45.791350000000000000000000000,
            lon: 9.179680555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00498',
            office: '1OF00498',
            name: 'Cucciago',
            officeType: 'MACRO',
            lat: 45.743888888888888888888888889,
            lon: 9.094638888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00502',
            office: '1OF00502',
            name: 'Vallicella',
            officeType: 'MACRO',
            lat: 44.801663888888888888888888889,
            lon: 8.793086111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00503',
            office: '1OF00503',
            name: 'Cascina Rocchino',
            officeType: 'MACRO',
            lat: 44.934694444444444444444444444,
            lon: 8.852611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00505',
            office: '1OF00505',
            name: 'Cascina Accattasete',
            officeType: 'MACRO',
            lat: 45.144694444444444444444444444,
            lon: 8.987138888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00506',
            office: '1OF00506',
            name: 'Trivolzio',
            officeType: 'MACRO',
            lat: 45.248511111111111111111111111,
            lon: 9.040027777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00507',
            office: '1OF00507',
            name: 'Vigevano',
            officeType: 'MACRO',
            lat: 45.313888888888888888888888889,
            lon: 8.853611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00510',
            office: '1OF00510',
            name: 'Salice Terme',
            officeType: 'MACRO',
            lat: 44.928641666666666666666666667,
            lon: 9.008530555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00511',
            office: '1OF00511',
            name: 'Casteggio',
            officeType: 'MACRO',
            lat: 45.017833333333333333333333334,
            lon: 9.116086111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00512',
            office: '1OF00512',
            name: 'Certosa di Pavia',
            officeType: 'MACRO',
            lat: 45.272241666666666666666666667,
            lon: 9.135325000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00513',
            office: '1OF00513',
            name: 'Vigevano 2',
            officeType: 'MACRO',
            lat: 45.327550000000000000000000000,
            lon: 8.859741666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00515',
            office: '1OF00515',
            name: 'Gambolo`',
            officeType: 'MACRO',
            lat: 45.259166666666666666666666667,
            lon: 8.866388888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00516',
            office: '1OF00516',
            name: 'Busto Arsizio',
            officeType: 'MACRO',
            lat: 45.614333333333333333333333333,
            lon: 8.846450000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00517',
            office: '1OF00517',
            name: 'Gorla',
            officeType: 'MACRO',
            lat: 45.666638888888888888888888889,
            lon: 8.863388888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00518',
            office: '1OF00518',
            name: 'Gallarate-Centro',
            officeType: 'MACRO',
            lat: 45.660277777777777777777777778,
            lon: 8.795750000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00519',
            office: '1OF00519',
            name: 'Saronno',
            officeType: 'MACRO',
            lat: 45.625555555555555555555555556,
            lon: 9.040833333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00521',
            office: '1OF00521',
            name: 'St. Vincent',
            officeType: 'MACRO',
            lat: 45.7525,
            lon: 7.65,
            zoneId: 1,

        },
        {
            id: '1OF00522',
            office: '1OF00522',
            name: 'Andrate',
            officeType: 'MACRO',
            lat: 45.528825000000000000000000000,
            lon: 7.8848444444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00524',
            office: '1OF00524',
            name: 'Bard',
            officeType: 'MACRO',
            lat: 45.611947222222222222222222222,
            lon: 7.7479888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00526',
            office: '1OF00526',
            name: 'Brissogne',
            officeType: 'MACRO',
            lat: 45.728700000000000000000000000,
            lon: 7.4045777777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00528',
            office: '1OF00528',
            name: 'Champleval',
            officeType: 'MACRO',
            lat: 45.698333333333333333333333333,
            lon: 7.2138888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00529',
            office: '1OF00529',
            name: 'Morgex',
            officeType: 'MACRO',
            lat: 45.762125,
            lon: 7.0638361111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00530',
            office: '1OF00530',
            name: 'Courmayeur',
            officeType: 'MACRO',
            lat: 45.830280555555555555555555556,
            lon: 6.9519388888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00531',
            office: '1OF00531',
            name: 'La Thuile',
            officeType: 'MACRO',
            lat: 45.715277777777777777777777778,
            lon: 6.9394444444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00533',
            office: '1OF00533',
            name: 'Valtournanche',
            officeType: 'MACRO',
            lat: 45.876027777777777777777777778,
            lon: 7.6285555555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00534',
            office: '1OF00534',
            name: 'Cogne',
            officeType: 'MACRO',
            lat: 45.616666666666666666666666667,
            lon: 7.3572222222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00535',
            office: '1OF00535',
            name: 'Champoluc',
            officeType: 'MACRO',
            lat: 45.838561111111111111111111111,
            lon: 7.7413611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00536',
            office: '1OF00536',
            name: 'Brusson',
            officeType: 'MACRO',
            lat: 45.738888888888888888888888889,
            lon: 7.7530555555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00537',
            office: '1OF00537',
            name: 'SARONNO_2',
            officeType: 'MACRO',
            lat: 45.627222222222222222222222223,
            lon: 9.015555555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00538',
            office: '1OF00538',
            name: 'Fontainemore',
            officeType: 'MACRO',
            lat: 45.647525000000000000000000000,
            lon: 7.8657333333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00539',
            office: '1OF00539',
            name: 'CASORATE SEMPIONE',
            officeType: 'MACRO',
            lat: 45.675000000000000000000000000,
            lon: 8.744722222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00540',
            office: '1OF00540',
            name: 'Gallarate Sud',
            officeType: 'MACRO',
            lat: 45.651308333333333333333333333,
            lon: 8.780691666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00542',
            office: '1OF00542',
            name: 'Chaudonnaz',
            officeType: 'MACRO',
            lat: 45.561269444444444444444444444,
            lon: 7.11485,
            zoneId: 1,

        },
        {
            id: '1OF00543',
            office: '1OF00543',
            name: 'Creton',
            officeType: 'MACRO',
            lat: 45.583008333333333333333333334,
            lon: 7.2092611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00544',
            office: '1OF00544',
            name: 'Pont',
            officeType: 'MACRO',
            lat: 45.525555555555555555555555556,
            lon: 7.2016666666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00545',
            office: '1OF00545',
            name: 'Bonne',
            officeType: 'MACRO',
            lat: 45.623925000000000000000000000,
            lon: 7.0588916666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00546',
            office: '1OF00546',
            name: 'VARESE FFSS',
            officeType: 'MACRO',
            lat: 45.823055555555555555555555556,
            lon: 8.839250000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00547',
            office: '1OF00547',
            name: 'VARESE CARACCIOLO',
            officeType: 'MACRO',
            lat: 45.825000000000000000000000000,
            lon: 8.793055555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00548',
            office: '1OF00548',
            name: 'Aosta Nord',
            officeType: 'MACRO',
            lat: 45.740138888888888888888888889,
            lon: 7.3128055555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00549',
            office: '1OF00549',
            name: 'CADREZZATE',
            officeType: 'MACRO',
            lat: 45.798055555555555555555555555,
            lon: 8.635833333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00551',
            office: '1OF00551',
            name: 'Cervinia',
            officeType: 'MACRO',
            lat: 45.941944444444444444444444444,
            lon: 7.655,
            zoneId: 1,

        },
        {
            id: '1OF00553',
            office: '1OF00553',
            name: 'Punta Jolanda',
            officeType: 'MACRO',
            lat: 45.841388888888888888888888889,
            lon: 7.8327777777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00555',
            office: '1OF00555',
            name: 'Pila',
            officeType: 'MACRO',
            lat: 45.671666666666666666666666667,
            lon: 7.3041666666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00556',
            office: '1OF00556',
            name: 'Punta Helbronner',
            officeType: 'MACRO',
            lat: 45.845447222222222222222222222,
            lon: 6.9351000000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00557',
            office: '1OF00557',
            name: 'Aosta Sarre',
            officeType: 'MACRO',
            lat: 45.734555555555555555555555555,
            lon: 7.3019722222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00558',
            office: '1OF00558',
            name: 'Gressoney',
            officeType: 'MACRO',
            lat: 45.749277777777777777777777777,
            lon: 7.8265694444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00559',
            office: '1OF00559',
            name: 'VEDDASCA',
            officeType: 'MACRO',
            lat: 46.061361111111111111111111111,
            lon: 8.804388888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00560',
            office: '1OF00560',
            name: 'Aosta Centro',
            officeType: 'MACRO',
            lat: 45.738055555555555555555555555,
            lon: 7.3233333333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00562',
            office: '1OF00562',
            name: 'Champorcher',
            officeType: 'MACRO',
            lat: 45.604444444444444444444444444,
            lon: 7.5677777777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00563',
            office: '1OF00563',
            name: 'Solbiate Arno',
            officeType: 'MACRO',
            lat: 45.726166666666666666666666667,
            lon: 8.815638888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00565',
            office: '1OF00565',
            name: 'Malnate',
            officeType: 'MACRO',
            lat: 45.797222222222222222222222222,
            lon: 8.885555555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00567',
            office: '1OF00567',
            name: 'Luino',
            officeType: 'MACRO',
            lat: 45.998030555555555555555555555,
            lon: 8.736830555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00568',
            office: '1OF00568',
            name: 'Cittiglio',
            officeType: 'MACRO',
            lat: 45.887219444444444444444444444,
            lon: 8.645000000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00569',
            office: '1OF00569',
            name: 'Porto Ceresio',
            officeType: 'MACRO',
            lat: 45.888611111111111111111111111,
            lon: 8.915,
            zoneId: 1,

        },
        {
            id: '1OF00570',
            office: '1OF00570',
            name: 'Vergiate',
            officeType: 'MACRO',
            lat: 45.720833333333333333333333334,
            lon: 8.701666666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00571',
            office: '1OF00571',
            name: 'Besnate',
            officeType: 'MACRO',
            lat: 45.701194444444444444444444444,
            lon: 8.776388888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00572',
            office: '1OF00572',
            name: 'Castellanza',
            officeType: 'MACRO',
            lat: 45.616108333333333333333333333,
            lon: 8.902308333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00573',
            office: '1OF00573',
            name: 'Sesto Calende',
            officeType: 'MACRO',
            lat: 45.725111111111111111111111111,
            lon: 8.634722222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00574',
            office: '1OF00574',
            name: 'Ponte Tresa',
            officeType: 'MACRO',
            lat: 45.967222222222222222222222223,
            lon: 8.840000000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00575',
            office: '1OF00575',
            name: 'Lonate Pozzolo',
            officeType: 'MACRO',
            lat: 45.604794444444444444444444444,
            lon: 8.750966666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00576',
            office: '1OF00576',
            name: 'Somma Lombardo',
            officeType: 'MACRO',
            lat: 45.686666666666666666666666666,
            lon: 8.713333333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00577',
            office: '1OF00577',
            name: 'Samarate',
            officeType: 'MACRO',
            lat: 45.636805555555555555555555555,
            lon: 8.800555555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00578',
            office: '1OF00578',
            name: 'Arcisate',
            officeType: 'MACRO',
            lat: 45.858011111111111111111111111,
            lon: 8.861302777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00580',
            office: '1OF00580',
            name: 'Cassano Magnago',
            officeType: 'MACRO',
            lat: 45.683333333333333333333333333,
            lon: 8.817777777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00581',
            office: '1OF00581',
            name: 'Gavirate',
            officeType: 'MACRO',
            lat: 45.842777777777777777777777777,
            lon: 8.698333333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00582',
            office: '1OF00582',
            name: 'Ippodromo',
            officeType: 'MACRO',
            lat: 45.838611111111111111111111111,
            lon: 8.822638888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00583',
            office: '1OF00583',
            name: 'DAVERIO',
            officeType: 'MACRO',
            lat: 45.766944444444444444444444445,
            lon: 8.762777777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00584',
            office: '1OF00584',
            name: 'Courmayeur Centro',
            officeType: 'MACRO',
            lat: 45.789722222222222222222222222,
            lon: 6.9822222222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00586',
            office: '1OF00586',
            name: 'Aosta Est',
            officeType: 'MACRO',
            lat: 45.740611111111111111111111111,
            lon: 7.3485277777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00587',
            office: '1OF00587',
            name: 'Casbeno Questura sud',
            officeType: 'MACRO',
            lat: 45.816361111111111111111111111,
            lon: 8.809888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00588',
            office: '1OF00588',
            name: 'VARESE LAGO',
            officeType: 'MACRO',
            lat: 45.835888888888888888888888889,
            lon: 8.753188888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00589',
            office: '1OF00589',
            name: 'VENEGONO',
            officeType: 'MACRO',
            lat: 45.744666666666666666666666666,
            lon: 8.900686111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00590',
            office: '1OF00590',
            name: 'VIGGIU`',
            officeType: 'MACRO',
            lat: 45.870000000000000000000000000,
            lon: 8.910833333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00591',
            office: '1OF00591',
            name: 'Cabiaglio',
            officeType: 'MACRO',
            lat: 45.894722222222222222222222222,
            lon: 8.753611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00592',
            office: '1OF00592',
            name: 'VEDANO OLONA',
            officeType: 'MACRO',
            lat: 45.765277777777777777777777778,
            lon: 8.878611111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00593',
            office: '1OF00593',
            name: 'Magnago',
            officeType: 'MACRO',
            lat: 45.569069444444444444444444445,
            lon: 8.801819444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00595',
            office: '1OF00595',
            name: 'Legnano Ovest',
            officeType: 'MACRO',
            lat: 45.60235,
            lon: 8.890352777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00596',
            office: '1OF00596',
            name: 'Borsano',
            officeType: 'MACRO',
            lat: 45.587777777777777777777777777,
            lon: 8.850555555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00597',
            office: '1OF00597',
            name: 'IPER micro',
            officeType: 'MACRO',
            lat: 45.808055555555555555555555556,
            lon: 8.863333333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00598',
            office: '1OF00598',
            name: 'Le Corti',
            officeType: 'MICRO',
            lat: 45.816944444444444444444444445,
            lon: 8.828611111111111111111111111,
            zoneId: 1,
        },
        {
            id: '1OF00599',
            office: '1OF00599',
            name: 'Malpensa 2000',
            officeType: 'MACRO',
            lat: 45.628180555555555555555555556,
            lon: 8.713538888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00600',
            office: '1OF00600',
            name: 'Varazze',
            officeType: 'MACRO',
            lat: 44.362055555555555555555555556,
            lon: 8.570444444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00601',
            office: '1OF00601',
            name: 'Celle Ligure',
            officeType: 'MACRO',
            lat: 44.347575000000000000000000000,
            lon: 8.541397222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00602',
            office: '1OF00602',
            name: 'Albisola',
            officeType: 'MACRO',
            lat: 44.333694444444444444444444444,
            lon: 8.501055555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00603',
            office: '1OF00603',
            name: 'Savona City',
            officeType: 'MACRO',
            lat: 44.324888888888888888888888889,
            lon: 8.479277777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00604',
            office: '1OF00604',
            name: 'Zinola',
            officeType: 'MACRO',
            lat: 44.290750000000000000000000000,
            lon: 8.436577777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00605',
            office: '1OF00605',
            name: 'Villapiana',
            officeType: 'MACRO',
            lat: 44.31345,
            lon: 8.477786111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00607',
            office: '1OF00607',
            name: 'Feglino',
            officeType: 'MACRO',
            lat: 44.225355555555555555555555556,
            lon: 8.326936111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00608',
            office: '1OF00608',
            name: 'Calice',
            officeType: 'MACRO',
            lat: 44.174638888888888888888888889,
            lon: 8.31325,
            zoneId: 1,

        },
        {
            id: '1OF00610',
            office: '1OF00610',
            name: 'Noli',
            officeType: 'MACRO',
            lat: 44.199722222222222222222222222,
            lon: 8.421944444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00611',
            office: '1OF00611',
            name: 'Pietra',
            officeType: 'MACRO',
            lat: 44.158305555555555555555555556,
            lon: 8.272944444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00612',
            office: '1OF00612',
            name: 'Borghetto',
            officeType: 'MACRO',
            lat: 44.122638888888888888888888889,
            lon: 8.203388888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00613',
            office: '1OF00613',
            name: 'Albenga',
            officeType: 'MACRO',
            lat: 44.056666666666666666666666667,
            lon: 8.197777777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00615',
            office: '1OF00615',
            name: 'Alassio',
            officeType: 'MACRO',
            lat: 43.992786111111111111111111111,
            lon: 8.160830555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00616',
            office: '1OF00616',
            name: 'Andora',
            officeType: 'MACRO',
            lat: 43.948786111111111111111111111,
            lon: 8.133022222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00618',
            office: '1OF00618',
            name: 'Chiavella',
            officeType: 'MACRO',
            lat: 44.308944444444444444444444444,
            lon: 8.463194444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00619',
            office: '1OF00619',
            name: 'Vado Ligure',
            officeType: 'MACRO',
            lat: 44.265788888888888888888888889,
            lon: 8.434083333333333333333333333,
            zoneId: 1,

        },
        {
            id: '1OF00620',
            office: '1OF00620',
            name: 'Santa Libera',
            officeType: 'MACRO',
            lat: 44.225133333333333333333333334,
            lon: 8.367605555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00621',
            office: '1OF00621',
            name: 'Ceriale',
            officeType: 'MACRO',
            lat: 44.101388888888888888888888889,
            lon: 8.223888888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00624',
            office: '1OF00624',
            name: 'Altare',
            officeType: 'MACRO',
            lat: 44.320500000000000000000000000,
            lon: 8.339277777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00625',
            office: '1OF00625',
            name: 'Loano',
            officeType: 'MACRO',
            lat: 44.127611111111111111111111111,
            lon: 8.253486111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00627',
            office: '1OF00627',
            name: 'Stella',
            officeType: 'MACRO',
            lat: 44.383388888888888888888888889,
            lon: 8.520930555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00628',
            office: '1OF00628',
            name: 'Varigotti',
            officeType: 'MACRO',
            lat: 44.176250000000000000000000000,
            lon: 8.373125000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00630',
            office: '1OF00630',
            name: 'Pontinvrea',
            officeType: 'MACRO',
            lat: 44.451805555555555555555555556,
            lon: 8.417572222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00632',
            office: '1OF00632',
            name: 'SPOTORNO CENTRO',
            officeType: 'MACRO',
            lat: 44.220813888888888888888888889,
            lon: 8.413119444444444444444444444,
            zoneId: 1,

        },
        {
            id: '1OF00633',
            office: '1OF00633',
            name: 'Borgio Verezzi',
            officeType: 'MACRO',
            lat: 44.164275,
            lon: 8.315180555555555555555555556,
            zoneId: 1,

        },
        {
            id: '1OF00635',
            office: '1OF00635',
            name: 'Darsena',
            officeType: 'MACRO',
            lat: 44.314975,
            lon: 8.496630555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00637',
            office: '1OF00637',
            name: 'Piana',
            officeType: 'MACRO',
            lat: 44.055663888888888888888888889,
            lon: 8.225116666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00638',
            office: '1OF00638',
            name: 'ALASSIO CENTRO',
            officeType: 'MACRO',
            lat: 44.015691666666666666666666667,
            lon: 8.178386111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00640',
            office: '1OF00640',
            name: 'Marina di Andora',
            officeType: 'MACRO',
            lat: 43.950833333333333333333333333,
            lon: 8.145277777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00641',
            office: '1OF00641',
            name: 'Cadibona',
            officeType: 'MACRO',
            lat: 44.319472222222222222222222223,
            lon: 8.377777777777777777777777778,
            zoneId: 1,

        },
        {
            id: '1OF00642',
            office: '1OF00642',
            name: 'Zuccarello',
            officeType: 'MACRO',
            lat: 44.102841666666666666666666667,
            lon: 8.149630555555555555555555555,
            zoneId: 1,

        },
        {
            id: '1OF00643',
            office: '1OF00643',
            name: 'Borghetto Santo Spirito',
            officeType: 'MACRO',
            lat: 44.114327777777777777777777778,
            lon: 8.244447222222222222222222222,
            zoneId: 1,

        },
        {
            id: '1OF00645',
            office: '1OF00645',
            name: 'Fornaci',
            officeType: 'MACRO',
            lat: 44.291958333333333333333333333,
            lon: 8.453711111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00646',
            office: '1OF00646',
            name: 'Santa Rita',
            officeType: 'MACRO',
            lat: 44.307744444444444444444444444,
            lon: 8.475186111111111111111111111,
            zoneId: 1,

        },
        {
            id: '1OF00647',
            office: '1OF00647',
            name: 'Priamar',
            officeType: 'MACRO',
            lat: 44.306547222222222222222222222,
            lon: 8.487875000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00648',
            office: '1OF00648',
            name: 'Pecorile',
            officeType: 'MACRO',
            lat: 44.339777777777777777777777777,
            lon: 8.532972222222222222222222223,
            zoneId: 1,

        },
        {
            id: '1OF00650',
            office: '1OF00650',
            name: 'M. Chioso',
            officeType: 'MACRO',
            lat: 44.235527777777777777777777777,
            lon: 9.620694444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00651',
            office: '1OF00651',
            name: 'Varese Ligure',
            officeType: 'MACRO',
            lat: 44.391383333333333333333333333,
            lon: 9.616883333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00652',
            office: '1OF00652',
            name: 'Stadomelli',
            officeType: 'MACRO',
            lat: 44.212583333333333333333333333,
            lon: 9.774000000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00654',
            office: '1OF00654',
            name: 'Vezzano Ligure',
            officeType: 'MACRO',
            lat: 44.147194444444444444444444444,
            lon: 9.880750000000000000000000000,
            zoneId: 1,

        },
        {
            id: '1OF00655',
            office: '1OF00655',
            name: 'San Giorgio',
            officeType: 'MACRO',
            lat: 44.108111111111111111111111111,
            lon: 9.829333333333333333333333334,
            zoneId: 1,

        },
        {
            id: '1OF00656',
            office: '1OF00656',
            name: 'Porta Isolabella',
            officeType: 'MACRO',
            lat: 44.116858333333333333333333334,
            lon: 9.818066666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00657',
            office: '1OF00657',
            name: 'Castelnuovo Magra',
            officeType: 'MACRO',
            lat: 44.118155555555555555555555556,
            lon: 9.997902777777777777777777777,
            zoneId: 1,

        },
        {
            id: '1OF00659',
            office: '1OF00659',
            name: 'Sarzana',
            officeType: 'MACRO',
            lat: 44.115511111111111111111111111,
            lon: 9.968319444444444444444444445,
            zoneId: 1,

        },
        {
            id: '1OF00660',
            office: '1OF00660',
            name: 'Via Canaletto',
            officeType: 'MACRO',
            lat: 44.124363888888888888888888889,
            lon: 9.847916666666666666666666666,
            zoneId: 1,

        },
        {
            id: '1OF00661',
            office: '1OF00661',
            name: 'Lerici',
            officeType: 'MACRO',
            lat: 44.091111111111111111111111111,
            lon: 9.906666666666666666666666667,
            zoneId: 1,

        },
        {
            id: '1OF00663',
            office: '1OF00663',
            name: 'Monterosso',
            officeType: 'MACRO',
            lat: 44.145750000000000000000000000,
            lon: 9.641638888888888888888888889,
            zoneId: 1,

        },
        {
            id: '1OF00664',
            office: '1OF00664',
            name: 'Arsenale',
            officeType: 'MACRO',
            lat: 44.103616666666666666666666667,
            lon: 9.821583333333333333333333334,
            zoneId: 1,

        },
    ]
};
