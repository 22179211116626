import { BaseFilterComponent } from '../base-filter/base-filter.component';
import { Component, OnInit } from '@angular/core';
import { WndGridColumnService } from '@shared/components/wonder-grid/services/wnd-grid-column-service';

@Component( {
    selector: 'wnd-boolean-filter',
    templateUrl: './boolean-filter.component.html',
    styleUrls: [ './boolean-filter.component.scss' ]
} )
export class BooleanFilterComponent extends BaseFilterComponent implements OnInit {


    constructor( public columnService: WndGridColumnService ) {
        super( columnService );
    }


    ngOnInit(): void {
        super.ngOnInit();
    }

    preventCloseHandler( val: boolean ) {
        this.preventClose.next( val );
    }

}
