import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component( {
    selector: 'wnd-struttura-details-dialog',
    templateUrl: './struttura-details-dialog.component.html',
    styleUrls: [ './struttura-details-dialog.component.scss' ]
} )
export class StrutturaDetailsDialogComponent implements OnInit {
    strutturaId = this.data.id;
    officeType = this.data.type;

    constructor( private dialogRef: MatDialogRef<StrutturaDetailsDialogComponent>, @Inject( MAT_DIALOG_DATA ) private data: { id: string; type: string } ) {
    }

    ngOnInit(): void {
    }

    closeMe() {
        this.dialogRef.close();
    }

}
