import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GRID_COLUMNS_URL } from '@shared/models/urls-constants';
import { IGridsColumnsSet } from '@shared/interfaces/grids-columns-set';

interface IGetGridColumnsResponse {
    data: IGridsColumnsSet[];
}

@Injectable( {
    providedIn: 'root',
} )
export class ApiGridColumnsService {
    private gridColumnsUrl = `${ this.baseUrl }`;

    constructor( private http: HttpClient, @Inject( GRID_COLUMNS_URL ) private baseUrl: string ) {
    }

    getGridsColumns( gridName: string, fiscalYear: number ): Observable<{ data: IGridsColumnsSet[] }> {
        const url = `${ this.gridColumnsUrl }gridcolumns?gridName=${ gridName }&year=${ fiscalYear }`;
        return this.http.get<IGetGridColumnsResponse>( url ).pipe(
            map( res => ( { data: res.data.map( s => ( { name: s.name, columns: s.columns, setColor: s.setColor } ) ) } ) ),
        );
    }

    saveGridsColumns( gridName: string, fiscalYear: number, sets: IGridsColumnsSet[] ): Observable<IGetGridColumnsResponse> {
        const url = `${ this.gridColumnsUrl }gridcolumns/sets`;
        return this.http.put<IGetGridColumnsResponse>( url, { gridName, year: fiscalYear, sets } );
    }

    getGridsNames(): Observable<string[]> {
        const url = `${ this.gridColumnsUrl }gridcolumns/grids-names`;
        return this.http.get<{ grids: string[] }>( url ).pipe(
            map( res => res.grids )
        );
    }
}
