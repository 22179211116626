import { BaseMessage } from '@core/services/stream-craft-client/models/BaseMessage';
import { SocketMessage } from '@core/services/stream-craft-client/models/SocketMessage';

interface MessageContent {
    id: string;
    name: string;
    description?: string;
    processedValue?: number;
}

export class TestMessage extends BaseMessage {
    id: string;
    name: string;
    description?: string;
    processedValue?: number;

    constructor( msg: SocketMessage ) {
        super( msg.source, msg.type, msg.content );

        const res = JSON.parse( msg.content ) as MessageContent;
        this.id = res.id;
        this.name = res.name;
        this.description = res.description;
        this.processedValue = res.processedValue;
    }
}
