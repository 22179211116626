/* eslint-disable @typescript-eslint/member-ordering */
// noinspection JSUnusedGlobalSymbols

import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ApiContrattiService } from '../../routes/contratti/services/api-contratti.service';
import { Store } from '@ngrx/store';
import { ContrattiActions, ContrattiSelectors } from '@store/contratti-store/index';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ContrattiEffects {
    constructor( private action$: Actions, private store: Store, private api: ApiContrattiService ) {
    }

    /**
     * Load a list of contracts for the viewing office
     */
    loadContratti = createEffect( () => this.action$.pipe(
        ofType( ContrattiActions.loadContratti, ContrattiActions.listFilterChanged ),
        concatLatestFrom( () => [ this.store.select( ContrattiSelectors.selectViewingOffice ), this.store.select( ContrattiSelectors.selectListFilter ) ] ),
        switchMap( ( [ , o, s ] ) => this.api.getListaContratti( o, s ).pipe(
            map( res => ContrattiActions.contrattiLoaded( { contratti: res } ) ),
            catchError( err => {
                console.error( 'Error in loading contratti', o, err );
                return of( ContrattiActions.contrattiLoadFailure() );
            } ),
        ) ),
    ) );
}
