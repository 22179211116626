<div fxFlex fxLayout="column">
    <div class="title">
        <p>Esporta i dati della tabella</p>
    </div>
    <mat-form-field>
        <mat-label>Seleziona il formato</mat-label>
        <mat-select [(ngModel)]="formato">
            <mat-option value="xlsx" class="wnd-grid-export-option"><img class="option-icon" src="../../../../../../assets/images/xlsx_ico.svg" alt="Excel icon" /> Excel </mat-option>
            <mat-option value="csv" class="wnd-grid-export-option"><img class="option-icon" src="../../../../../../assets/images/csv.svg" alt="CSV icon" /> Separati da virgola </mat-option>
        </mat-select>
    </mat-form-field>
    <button fxFlexAlign="end" mat-button [disabled]="(working$ | async) || !formato" (click)="exportExcel()">Esporta</button>
    <mat-progress-bar mode="indeterminate" *ngIf="working$ | async"></mat-progress-bar>

    <div style="display: flex; flex-direction: column;" class="m-t-16" *ngIf="customExport">
        <div class="title">
            <p>{{customExportTitle}}</p>
        </div>
        <button fxFlexAlign="end" mat-button [disabled]="customWorking" (click)="exportCustomExcel()">Esporta</button>
        <mat-progress-bar mode="indeterminate" *ngIf="customWorking"></mat-progress-bar>
    </div>
</div>
