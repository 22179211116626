import { NdDocument } from './../interfaces/nd_document';
import { Observable } from 'rxjs/internal/Observable';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, map } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { BASE_URL } from '@shared/models/urls-constants';

@Injectable( {
    providedIn: 'root',
} )
export class ServerCommunicationService {
    constructor(
        private router: Router,
        private http: HttpClient,
        @Inject( BASE_URL ) private baseUrl: string,
        private toastr: ToastrService,
    ) {
    }

    private _saveItem(
        edit: boolean,
        tempItem: any,
        url: string,
        showToastr: boolean,
    ): Observable<any> {
        if( !edit ) {
            return this.http.post<any>( url, tempItem ).pipe(
                map( res => {
                    if( showToastr ) {
                        this.toastr.success( 'Your changes has been saved successfully' );
                    }
                    return res.id;
                } ),
                catchError( error => {
                    if( showToastr ) {
                        this.toastr.error( 'An error has occurred' );
                    }
                    return of( undefined );
                } ),
            );
        } else {
            return this.http.put<any>( url + '/' + tempItem.id, tempItem ).pipe(
                map( () => {
                    if( showToastr ) {
                        this.toastr.success( 'Your changes has been saved successfully' );
                    }
                    return undefined;
                } ),
                catchError( error => {
                    if( showToastr ) {
                        this.toastr.error( 'An error has occurred' );
                    }
                    return of( undefined );
                } ),
            );
        }
    }

    saveStep( edit: boolean, tempStep: any, showToastr: boolean ): Observable<any> {
        const url = this.baseUrl + 'wonder-src-workflow-api/Steps';
        return this._saveItem( edit, tempStep, url, showToastr );
    }

    saveCommunication( edit: boolean, tempCom: any, showToastr: boolean ): Observable<any> {
        const url = this.baseUrl + 'wonder-src-workflow-api/Comunications';
        return this._saveItem( edit, tempCom, url, showToastr );
    }

    deleteCommunication( id: number, showToastr = false ): Observable<any> {
        const url = this.baseUrl + 'wonder-src-workflow-api/Comunications/' + id;
        return this.http.delete<any>( url ).pipe(
            map( res => {
                if( showToastr ) {
                    this.toastr.success( 'Your changes has been saved successfully' );
                }
                return res.id;
            } ),
            catchError( error => {
                if( showToastr ) {
                    this.toastr.error( 'An error has occurred' );
                }
                return of( undefined );
            } ),
        );
    }

    deleteDocument( id: number, showToastr = false ): Observable<any> {
        const url = this.baseUrl + 'wonder-src-workflow-api/Documents/' + id;
        return this.http.delete<any>( url ).pipe(
            map( res => {
                if( showToastr ) {
                    this.toastr.success( 'Your changes has been saved successfully' );
                }
                return res.id;
            } ),
            catchError( error => {
                if( showToastr ) {
                    this.toastr.error( 'An error has occurred' );
                }
                return of( undefined );
            } ),
        );
    }

    downloadDocument( id: number ): Observable<Blob> {
        const url = this.baseUrl + 'wonder-src-workflow-api/Documents/Download/' + id;
        return this.http.get( url, { responseType: 'blob' } ).pipe( map( res => res as Blob ) );
    }

    getStep( activityId: string, stepName: string ): Observable<any> {
        const url = this.baseUrl + 'wonder-src-workflow-api/Steps/GetStepByName/' + activityId + '/' + stepName;
        return this.http.get<any>( url );
    }

    getCommunicationsByStep( stepId: number ): Observable<any[]> {
        const url = this.baseUrl + 'wonder-src-workflow-api/Comunications/GetCommunicationsByStep/' + stepId;
        return this.http.get<any[]>( url );
    }

    dummyDocReq(): Observable<void> {
        const url = this.baseUrl + 'wonder-src-workflow-api/Documents/Dummy';
        return this.http.get<void>( url );
    }

    getDocsByCategory( category: string, commId ): Observable<NdDocument[]> {
        const url = this.baseUrl + 'wonder-src-workflow-api/Documents/GetDocumentbyCategory/' + category + '/' + commId;
        return this.http.get<any>( url ).pipe( catchError( ( error: HttpErrorResponse ) => {
            if( error.status === 404 ) {
                return of( undefined );
            }
            return throwError( error );
        } ) );
    }

    // extraflow
    saveExtraFlowData( edit: boolean, tempCom: any, showToastr: boolean ): Observable<any> {
        const url = this.baseUrl + 'api/extraFlowData';
        return this._saveItem( edit, tempCom, url, showToastr );
    }

    // Download Activities import template
    downloadActivitiesDownloadTemplate( id: number ): Observable<Blob> {
        const url = this.baseUrl + 'wonder-src-workflow-api/Activitiestemplate/Download/' + id;
        return this.http.get( url, { responseType: 'blob' } ).pipe( map( res => res as Blob ) );
    }
}
