import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component( {
    selector: 'app-stick-expansion-panel',
    templateUrl: './stick-expansion-panel.component.html',
    styleUrls: [ './stick-expansion-panel.component.scss' ],
    encapsulation: ViewEncapsulation.None
} )
export class StickExpansionPanelComponent implements OnInit {
    panelOpenState: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

}
