/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-return */
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export class FormValidators {

    // Formly validators
    static ValidDate( control: AbstractControl, _field: FormlyFieldConfig, _options = {} ): ValidationErrors {

        const today = new Date();

        // control.value is the value of the bound input
        return control.value >= today ? null : { date: true };
    }

    // Formly validators messages
    static DateValidationMessage( _err: any, field: FormlyFieldConfig ) {
        return `"${ field.formControl.value as string }" data non valida`;
    }

    static dateValidator( control: AbstractControl, _field: FormlyFieldConfig, options: any = {} ) {
        if( !options.fieldTocheck ) {
            return null;
        }
        return FormValidators.compareParams( options, control );
    }

    // compare every couple of date to match and build error to return
    private static compareParams( options: any, control: AbstractControl ) {
        const toFilter = options.fieldTocheck.map( ( couple: any ) => {
            const fromControl = control.get( couple.fromDate );
            const toControl = control.get( couple.toDate );

            // Prevent error if one of date control doesn't exist. For example because of conditional
            // visibility.
            if( !fromControl || !toControl ) {
                return null;
            }

            const from = new Date( fromControl.value );
            const to = new Date( toControl.value );
            // prevent error when params are empty
            if( !from || !to ) {
                this.resetErrors( couple, toControl );
                return null;
            }
            // prevent error when getTime() return 0
            if( from.getTime() === 0 || to.getTime() === 0 ) {
                this.resetErrors( couple, toControl );
                return null;
            }
            // check if fromDate is before toDate
            if( from.getTime() <= to.getTime() ) {
                this.resetErrors( couple, toControl );
                return null;
            }
            return {
                [ couple.toDate as string ]: {
                    message: couple.errorText,
                    errorPath: couple.toDate,
                },
            };
        } );
        let result: any;
        // build error to return
        toFilter.forEach( ( element: any ) => {
            result = { ...result, ...element };
        } );
        return result;
    }

    private static resetErrors( couple: any, toControl: AbstractControl ) {
        if( toControl.hasError( couple.toDate ) ) {
            delete toControl.errors[ couple.toDate ];
            toControl.updateValueAndValidity();
        }
    }
}
