import { FilesContainerService, RemoteFile } from '@shared/services/files-container.service';
import { AuthData, AuthzData } from './../authentication/interfaces/models';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@shared/services/storage.service';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { AppSettings, defaults } from '../settings';
import { Guid } from 'guid-typescript';
import { map, tap } from 'rxjs/operators';
import { initializerEnvironment } from '@env/environment';
import { TokenService } from '@core/authentication/token.service';
import { MenuService } from './menu.service';
import { Router } from '@angular/router';

export const USER_KEY = 'wonder-user-model';

export interface User {
    name?: string;
    username: string;
    authData: AuthData;
    authzData: AuthzData;
    policies: string[];
}


export interface Avatar {
    data: string;
    guid: string;
}

const DEFAULT_AVATAR = './assets/images/userIcons.svg';
const AVATAR_KEY = 'wonder-avatar';

@Injectable( {
    providedIn: 'root',
} )
export class SettingsService {
    private remoteAvatarFile: RemoteFile;
    private filterSet = false;
    private storeUserOnLocalStorage = false;
    private options = defaults;
    private avatarSubj = new BehaviorSubject<string>( this.currentAvatar.data );
    private _localUser: User;
    private notify$ = new BehaviorSubject<any>( {} );

    constructor( private store: LocalStorageService, @Inject( 'AVATAR_FILE_CONTAINER' ) private fileContainerService: FilesContainerService,
                 private menu: MenuService, private tokenService: TokenService, private router: Router ) {
        this.tokenService.tokenServiceLogoutRequest().subscribe( () => this._logout( true ) );
    }

    private set currentAvatar( value: Avatar ) {

        this.store.set( AVATAR_KEY, value );

        this.avatarSubj.next( value.data );

    }

    private get currentAvatar(): Avatar {
        let stored: Avatar = this.store.get( AVATAR_KEY ) as Avatar;
        if( !stored ) {
            stored = { data: DEFAULT_AVATAR, guid: Guid.create().toString() };
            this.store.set( AVATAR_KEY, stored );
        }
        return stored;
    }

    private set localUser( value: User ) {
        this._localUser = value;
        if( this.storeUserOnLocalStorage ) {
            this.store.set( USER_KEY, value );
        }
    }

    private get localUser() {
        if( this.storeUserOnLocalStorage && !this._localUser ) {
            return this.store.get( USER_KEY ) as User;
        }
        return this._localUser;
    }

    get notify(): Observable<any> {
        return this.notify$.asObservable();
    }


    getAvatar$() {
        return this.avatarSubj.asObservable();
    }

    hasAvatar$() {
        return merge(
            of( true ),
            this.getAvatar$().pipe( map( a => a !== DEFAULT_AVATAR ) ),
        );
    }

    public logout() {
        this._logout();
    }

    deleteRemoteAvatarFile() {
        return this.remoteAvatarFile.delete().pipe(
            tap( () => this.refreshAvatarFromRemote() ),
        );
    }

    refreshAvatarFromRemote() {
        if( !this.filterSet ) {
            this.fileContainerService.setFilter( {
                entity: 'avatar',
                username: this.localUser.username,
            } );
            this.filterSet = true;
        }
        this.fileContainerService.refreshCollection().subscribe( res => {
            if( res.length > 0 ) {
                res = res.sort( ( a, b ) => ( a.uploadDate >= b.uploadDate ) ? -1 : 1 );
                const myFile = res[ 0 ];
                for( let i = 1; i < res.length; i++ ) {
                    res[ i ].delete().subscribe();
                }
                this.remoteAvatarFile = myFile;
                if( myFile.metadata.guid !== this.currentAvatar.guid ) {
                    myFile.getSrc().subscribe( blob => {

                        const reader = new FileReader();
                        reader.addEventListener( 'loadend', () => {
                            const image = 'data:image/png;base64,' + ( ( reader.result as string ).split( ',' ) )[ 1 ];

                            this.currentAvatar = { data: image, guid: myFile.metadata.guid };
                        } );
                        reader.readAsDataURL( blob );
                    } );
                }

            } else {
                this.cleanLocalAvatar();
            }
        } );
    }


    // noinspection JSUnusedGlobalSymbols
    cleanAllRemoteFile() {
        this.fileContainerService.refreshCollection().subscribe( res => {
            if( res ) {
                res.forEach( r => r.delete().subscribe() );
            }
        } );
    }


    // noinspection JSUnusedGlobalSymbols
    setLayout( options?: AppSettings ): AppSettings {
        this.options = Object.assign( defaults, options );
        return this.options;
    }

    setNavState( type: string, value: boolean ) {
        this.notify$.next( { type, value } as any );
    }

    getOptions(): AppSettings {
        return this.options;
    }

    /** User information */

    get user(): User {

        return this.localUser;
    }

    setUser( value: User ) {
        this.localUser = value;
        this.refreshAvatarFromRemote();
    }

    removeUser() {
        this.localUser = undefined;
        this.store.remove( USER_KEY );
    }


    public userChange() {
        return this.tokenService.currentIdentityData().pipe(
            map( user => {
                const policies = user.authzData.policies;
                //console.log( 'User changed', user );
                this.menu.filterByPolicy( policies );
                //console.log( 'User menu', this.menu );
                this.setUser( {
                    name: user.authData.name,
                    username: user.authData.username,
                    authData: user.authData,
                    authzData: user.authzData,
                    policies,
                } );
                if( user.authzData.roles.length === 0 ) {
                    void this.router.navigate( [ 'landing' ] );
                }
                return this.user;
            } ),
        );
    }


    isUserAuthenticated() {
        return this.tokenService.isUserAuthenticated();
    }

    /** System language */

    get language() {
        return this.options.language;
    }

    setLanguage( lang: string ) {
        this.options.language = lang;
        this.notify$.next( { lang } );
    }

    private clearStorage() {
        this.store.remove( AVATAR_KEY );
        this.store.remove( USER_KEY );
    }

    private cleanLocalAvatar() {
        this.remoteAvatarFile = undefined;
        this.store.remove( AVATAR_KEY );
        this.avatarSubj.next( this.currentAvatar.data );
    }

    private _logout( isFromTokenService = false ) {
        if( !isFromTokenService ) {
            this.tokenService.clearStorage();
        }
        this.clearStorage();
        //window.location.href = initializerEnvironment.idpSettings.redirectUrl;
        window.location.href = initializerEnvironment.idpSettings.logoutUrl;
    }

}
