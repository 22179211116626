import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component( {
    selector: 'custom-details-list',
    templateUrl: './custom-details-list.component.html',
    styleUrls: [ './custom-details-list.component.scss' ],
} )
export class CustomDetailsListComponent implements OnInit {
    @Input() item: any;
    @Input() itemKeys: string[][];

    constructor( private cdr: ChangeDetectorRef ) {
    }

    ngOnInit(): void {
    }

    expansionPanelClickEvent() {
        this.cdr.detectChanges();
    }
}
