import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WndGridColumnService } from '../../services/wnd-grid-column-service';

@Component( {
    selector: 'wnd-filter-host',
    templateUrl: './filter-host.component.html',
    styleUrls: [ './filter-host.component.scss' ],
    //providers: [WndGridColumnService]
} )
export class FilterHostComponent implements OnInit {

    @Output() closeEvent = new EventEmitter<string>();

    constructor( public columnService: WndGridColumnService ) {
    }

    ngOnInit(): void {
    }

    close() {
        this.closeEvent.emit( 'close' );
    }

    preventCloseHandler( event: boolean ) {
        this.closeEvent.emit( event ? 'forbidden' : 'allowed' );
    }
}
