import { BaseMessage } from '@core/services/stream-craft-client/models/BaseMessage';
import { SocketMessage } from '@core/services/stream-craft-client/models/SocketMessage';

interface MessageContent {
    id: number;
    newState: string;
    username: string;
}

export class PlanStateChangedMessage extends BaseMessage {
    private msg: MessageContent;
    get id() { return this.msg.id; }
    get newState() { return this.msg.newState; }
    get username()  { return this.msg.username; }

    constructor( msg: SocketMessage ) {
        super( msg.source, msg.type, msg.content );

        this.msg = JSON.parse( msg.content ) as MessageContent;
    }
}
