import { Action, createReducer, on } from '@ngrx/store';
import { ContrattiActions, ContrattiState } from '@store/contratti-store/index';

const ContrattiReducers = createReducer( ContrattiState.initialState,
    on( ContrattiActions.loadContratti, ( state ) => ( { ...state, loading: true } ) ),
    on( ContrattiActions.contrattiLoaded, ( state, { contratti } ) => ( { ...state, contratti: contratti.reduce( ( s, c ) => ( { ...s, [ c.idContratto ]: c } ), {} ), loading: false } ) ),
    on( ContrattiActions.contrattiLoadFailure, ( state ) => ( { ...state, loading: false } ) ),
    on( ContrattiActions.setViewingOffice, ( state, { office } ) => ( { ...state, viewingOffice: office } ) ),
    on( ContrattiActions.listFilterChanged, ( state, { listFilter } ) => ( { ...state, listFilter, loading: true } ) ),
);

export const reducers = ( state: ContrattiState.State | undefined, action: Action ): ContrattiState.State => ContrattiReducers( state, action );
