import { OsmDrawMapComponent } from '@shared/components/osm-draw-map/osm-draw-map.component';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Inject, Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Struttura } from '../../../../interfaces/struttura';
import { CandidateMetaData } from './models/candidate-meta-data';
import { ToastrService } from 'ngx-toastr';
import { FeatureGroup, featureGroup, Marker } from 'leaflet';
import { map } from 'rxjs/operators';
import { CandidateBox } from './candidate-box';
import { BASE_URL } from '@shared/models/urls-constants';

export class CandidateActivity {
    private postApi = this.baseUrl + 'wonder-src-workflow-api/Candidates/PostCandidateRange/';
    private getApi = this.baseUrl + 'wonder-src-workflow-api/Candidates/GetCandidateRange/';
    private _counter = 0;
    private _showSpostSrtuttError: string[] = [];

    public disableInput = true;
    public mapRef: OsmDrawMapComponent;
    public cdr: ChangeDetectorRef = this.injector.get( ChangeDetectorRef );
    public toastr: ToastrService = this.injector.get( ToastrService );
    candidatesBoxes: CandidateBox[];
    candidatesReady = new Subject<void>();
    candidatesList: any[];
    _featureGroup: FeatureGroup;

    public validationTrg = new BehaviorSubject<any>( 0 );


    constructor(
        private http: HttpClient,
        @Inject( BASE_URL ) private baseUrl: string,
        public readonly activity: any,
        public injector: Injector,
        public templateOptions: any,
        _clusterFix: Subject<OsmDrawMapComponent>,
        public readonly structSelected$?: Observable<Struttura>,
    ) {
        _clusterFix.subscribe( m => {
            this.mapRef = m;
            this.candidatesBoxes.forEach( b => {
                if( b.candidate.idStruttura ) {
                    b.clusterMarker = this.mapRef.markerClusterMetaData.filter(
                        l => l.id === b.candidate.idStruttura,
                    )[ 0 ].instance;
                    this.mapRef.markerClusterGroup.removeLayer( b.clusterMarker );
                }
            } );
        } );
        this._initit();
        if( structSelected$ ) {
            structSelected$.subscribe( struct => {
                if(
                    this.candidatesBoxes.filter( c => c.candidate.idStruttura === struct.id + '' ).length === 0
                ) {
                    const candidate = new CandidateMetaData( struct );
                    const candidateBox = CandidateBox.crateCandidateBoxInstance( this, candidate );
                    if( candidateBox ) {
                        this.candidatesBoxes.push( candidateBox );
                        console.log( 'Popup opened in Candidate activity constructor' );
                        candidateBox.openPopUp().subscribe( saved => {
                            if( !saved ) {
                                candidateBox.feature = null;
                            } else {
                                candidateBox.clusterMarker = this.mapRef.markerClusterMetaData.filter(
                                    c => c.id === candidateBox.candidate.idStruttura,
                                )[ 0 ].instance;
                                this.mapRef.markerClusterGroup.removeLayer( candidateBox.clusterMarker );
                            }

                            this.refreshOnMap();
                            this.candidatesReady.next();
                        } );
                    }
                }
            } );
        }
    }

    public validate() {
        const errors: any = {};
        if( ( this.templateOptions.scope as any[] ).includes( 'population' ) ) {
            let min = this.templateOptions?.scope?.map?.numberOfItems?.min;
            min = min ? min : 1;
            if( this.candidatesBoxes.length < min ) {
                errors.notEnoughtItems = true;
            }
        }
        if( ( this.templateOptions.scope as any[] ).includes( 'selection' ) ) {
            if( this.candidatesBoxes.filter( b => b.accepted ).length !== 1 ) {
                errors.notAccepted = true;
            }
        }
        if( Object.keys( errors ).length === 0 ) {
            this.validationTrg.next( null );
        } else {
            this.validationTrg.next( errors );
        }
    }

    // noinspection JSUnusedGlobalSymbols
    get leafletIds() {
        return this.candidatesBoxes.map( b => b.leafletId );
    }

    private _refactorPriorOnDelete() {
        const oldPrios = this.candidatesBoxes
            .filter( d => d.state !== 'KO' )
            .map( d => {
                return { uniqueId: d.uniqueId, prio: d.priority };
            } );
        oldPrios.sort( ( a, b ) => {
            return a.prio - b.prio;
        } );
        const newPrios = oldPrios.map( ( o, i ) => {
            return { uniqueId: o.uniqueId, prio: i + 1 };
        } );
        this.candidatesBoxes
            .filter( d => d.state !== 'KO' )
            .forEach( b => {
                b.candidate.priority = newPrios.filter( n => n.uniqueId === b.uniqueId )[ 0 ].prio;
            } );
    }

    get featureGroup() {
        return this._featureGroup;
    }

    set featureGroup( val: FeatureGroup ) {
        let delet = false;
        const valfts = val.getLayers();
        this.candidatesBoxes.forEach( f => {
            if( valfts.filter( ts => ts === f.feature ).length === 0 ) {
                if( f.clusterMarker ) {
                    this.mapRef.markerClusterGroup.addLayer( f.clusterMarker );
                }

                delet = true;
                f.feature = null;
            }
        } );
        if( delet ) {
            this._refactorPriorOnDelete();
        }
        let tryAdded = false;
        let added = false;
        valfts.forEach( v => {
            const old = this.getLayerByLId( val.getLayerId( v ) );
            if( old ) {
                if( old.candidate.codiciAltroOperatore && old.revertFeature() ) {
                    this._showSpostSrtuttError.push( old.candidate.gestore_cod );
                } else {
                    old.feature = v;
                }
            } else {
                tryAdded = true;
                const candidateBox = CandidateBox.crateCandidateBoxInstance( this );
                if( candidateBox ) {
                    added = true;
                    candidateBox.feature = v;
                    this.candidatesBoxes.push( candidateBox );
                    console.log( 'Popup opened in Candidate activity feature group', candidateBox );
                    candidateBox.openPopUp( true ).subscribe( saved => {
                        if( !saved ) {
                            candidateBox.feature = null;
                        }
                        this.refreshOnMap();
                        this.candidatesReady.next();
                    } );
                }
            }
        } );
        if( delet || ( tryAdded && !added ) ) {
            this.refreshOnMap();
            this.candidatesReady.next();
        }
    }

    enableAllClick() {
        this.candidatesBoxes.forEach( b => b.addMarkerClick() );
    }

    disableAllClick() {
        this.candidatesBoxes.forEach( b => b.removeMarkerClick() );
    }

    public refreshOnMap() {
        this.candidatesBoxes.forEach( ( box, i ) => {
            box.candidate.description = String.fromCharCode( 65 + i );
        } );
        const fs = this._featureGroup.getLayers();
        this.candidatesBoxes.forEach( c => {
            const m = c.feature as Marker;
            if( m.getTooltip() ) {
                m.unbindTooltip();
            }
            m.bindTooltip( c.candidate.description, {
                permanent: true,
                direction: 'center',
                className: 'candidate-label',
            } );
        } );
        this._featureGroup = featureGroup( fs );
        this.enableAllClick();
        this.refreshCandidatesList();
        this.cdr.detectChanges();
        this._showStructEditError();
    }

    public refreshCandidatesList() {
        this.candidatesBoxes = this.candidatesBoxes.sort( ( a, b ) => {
            return a.uniqueId - b.uniqueId;
        } );
        this.candidatesList = this.candidatesBoxes.map( b => ( { ...b.candidate, uniqueId: b.uniqueId } ) );
        this.validate();
    }

    get priorityRange() {
        return [ ...Array( this.candidatesBoxes.filter( c => c.state !== 'KO' ).length ).keys() ].map(
            n => n + 1,
        );
    }

    getLayerByLId( id: number ) {
        const els = this.candidatesBoxes.filter( b => b.leafletId === id );
        return els.length > 0 ? els[ 0 ] : null;
    }

    get getUniqueId() {
        return this._counter++;
    }

    private _showStructEditError() {
        if( this._showSpostSrtuttError.length > 0 ) {
            this.toastr.warning(
                'Le seguenti strutture esistenti non possono essere spostate:  ' +
                this._showSpostSrtuttError.join( '  - ' ),
            );
        }
        this._showSpostSrtuttError = [];
    }

    private _initit() {
        this.http.get<CandidateMetaData[]>( this.getApi + this.activity.id ).subscribe( cs => {
            this.candidatesBoxes = [];
            this._featureGroup = featureGroup();
            this.candidatesBoxes = cs.map( c => {
                return CandidateBox.crateCandidateBoxInstance( this, c );
            } );
            this.refreshOnMap();
            this.candidatesReady.next();
        } );
    }

    post() {
        console.log( 'Saving', this.candidatesBoxes.map( c => c.candidate ) );
        return this.http
            .post(
                this.postApi + this.activity.id,
                this.candidatesBoxes.map( c => c.candidate ),
            )
            .pipe(
                map( () => this._initit() ),
            );
    }
}
