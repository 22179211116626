// noinspection SpellCheckingInspection,JSUnusedLocalSymbols,DuplicatedCode

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivityConfig } from './activity_config';
import { ambitoNormativo, okKo, tagliaSito, tipologiaApparati } from './arrayXSelect';
import { extraAccessiSito, extraAdeguamentoPassive, extraFlowAnagraficaNew, extraFlowAnagraficaTIM, extraFlowForecast, extraFlowNPlus, extraImpiantiRisalita, } from './extraFlow';


//extraFlow
//sono stati inseriti su extraflow.ts


// workFlow
export const fornitorePrivilegiato: ActivityConfig = {
    extraFlowConfig: [
        {
            name: 'Ext_New Forecast',
            displayName: 'New Forecast',
            fields: extraFlowForecast,
        },
        {
            name: 'Ext_Anagrafica New',
            displayName: 'Anagrafica New',
            fields: extraFlowAnagraficaNew,
        },
        // {
        //   name: 'Ext_Anagrafica Old',
        //   displayName: 'Ext_Anagrafica Old',
        //   fields: extraFlowAnagraficaOld,
        // },
        {
            name: 'Ext_Anagrafica TIM',
            displayName: 'Anagrafica TIM',
            fields: extraFlowAnagraficaTIM,
        },
        {
            name: 'Ext_Legame NPlus',
            displayName: 'Legame NPlus',
            fields: extraFlowNPlus,
        },
        {
            name: 'Ext_Accessi al sito',
            displayName: 'Accessi al sito',
            fields: extraAccessiSito,
        },
        {
            name: 'Ext_Adeguamento Passive',
            displayName: 'Adeguamento Passive',
            fields: extraAdeguamentoPassive,
        },
        {
            name: 'Ext_CertImpRisalita',
            displayName: 'Certificazione Impianto Risalita',
            fields: extraImpiantiRisalita,
        },
    ],
    name: 'fornitorePrivilegiato',
    displayName: 'Fornitore Privilegiato',
    stepsConfig: [
        {
            displayName: 'SAR e SARF',
            name: '010_SAR e SARF',
            isEntryStep: true,
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            name: 'searchArea',
                            key: 'searchArea',
                            type: 'searchArea',
                            templateOptions: {
                                required: true,
                                disableIfCommited: true,
                                custom: true,
                                map: {
                                    errorMessage: 'You can\'t submit more than one area.',
                                },
                            },

                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data Invio SARF INWIT',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Data Invio SARF 3^ Parte',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            name: 'registrazioneStruttura',
                            key: 'candidate',
                            type: 'candidate',
                            templateOptions: {
                                isValutazioneMSA: true,
                                required: true,
                                disableIfCommited: true,
                                custom: true,
                                scope: [ 'population', 'selection' ],
                                map: {
                                    numberOfItems: {
                                        min: 1,
                                        max: 3,
                                    },
                                    errorMessage: 'You can\'t submit more than 3 candidate.',
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'communication',
                            key: 'communication',
                            templateOptions: {
                                custom: true,
                                required: false,
                                label: 'Comunicazione KO candidato a Inwit',
                                communicationConfig: {
                                    fields: [
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-6',
                                                    type: 'input',
                                                    key: 'string1',
                                                    templateOptions: {
                                                        required: false,
                                                        label: 'Candidato',
                                                        rows: 1,
                                                    },
                                                },
                                                {
                                                    className: 'col-6',
                                                    type: 'datepicker',
                                                    key: 'date1',
                                                    templateOptions: {
                                                        required: true,
                                                        label: 'Data Invio INWIT',
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-12',
                                                    type: 'textarea',
                                                    key: 'string2',
                                                    templateOptions: {
                                                        required: false,
                                                        label: 'Note',
                                                        rows: 3,
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'checkbox',
                            key: 'flag1',
                            templateOptions: {
                                //disableIfCommited: true,
                                //required: true,
                                label: 'Necessaria last Call vs Inwit?',
                                change: m => {
                                    if( !m.model.flag1 ) {
                                        ( ( m.parent.parent.formControl as UntypedFormGroup ).controls.date5 as UntypedFormControl ).setValue( null );
                                        ( ( m.parent.parent.formControl as UntypedFormGroup ).controls.date6 as UntypedFormControl ).setValue( null );
                                        ( ( m.parent.parent.formControl as UntypedFormGroup ).controls.string3 as UntypedFormControl ).setValue( null );
                                    }
                                }
                            },
                        },
                    ]
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Data invio Last Call',
                            },
                            //hideExpression: 'model.flag1 != true',
                            expressionProperties: {
                                'templateOptions.disabled': 'model.flag1 != true',
                                'templateOptions.required': 'model.flag1 === true',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'select',
                            key: 'string3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Esito Last Call',
                                options: [
                                    { label: null, value: null },
                                    { label: 'Offerta ricevuta', value: 'Offerta ricevuta' },
                                    { label: 'Offerta non ricevuta', value: 'Offerta non ricevuta' },
                                ],
                            },
                            //hideExpression: 'model.flag1 != true',
                            expressionProperties: {
                                'templateOptions.disabled': 'model.flag1 != true',
                                'templateOptions.required': 'model.flag1 === true',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date6',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Data risposta Last Call',
                            },
                            //hideExpression: 'model.flag1 != true',
                            expressionProperties: {
                                'templateOptions.disabled': 'model.flag1 != true',
                                'templateOptions.required': 'model.flag1 === true',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {

                const candidateAccepted = m.candidate.filter( x => x.accepted )[ 0 ];
                //console.log(candidateAccepted);
                //console.log ("Owner: "+ candidateAccepted.owner + " Tipo: " + candidateAccepted.tipoGestore);

                if( candidateAccepted.owner === 'Inwit' && candidateAccepted.tipoGestore === 'TowerCo' ) {
                    return '050_Emissione Scheda Radio';
                } else {
                    return '100_Sopralluogo';
                }
            },
        },
        {
            displayName: 'Emissione Scheda Tecnica',
            name: '050_Emissione Scheda Radio',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data Emissione Scheda Tecnica',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'EmissioneSchedaRadio',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload Emissione Scheda Tecnica',
                                custom: true,
                                required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '060_Comunicazione INWIT';
            },
        },
        {
            displayName: 'Comunicazione INWIT',
            name: '060_Comunicazione INWIT',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data Comunicazione Approvazione Candidato e Invio Scheda Tecnica',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'ComunicazioneApprovazioneCandidato',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload Comunicazione Approvazione Candidato',
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '100_Sopralluogo_Inwit';
            },
        },
        // procedura su INWIT
        {
            displayName: 'Sopralluogo',
            name: '100_Sopralluogo_Inwit',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Data primo sopralluogo',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '200_Predisposizione pacchetto e progettazione_Inwit';
            },
        },
        {
            // progettazione in carico a INWIT
            displayName: 'Predisposizione pacchetto e progettazione',
            name: '200_Predisposizione pacchetto e progettazione_Inwit',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string2',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Tipologia struttura',
                                options: [
                                    { label: '', value: '' },
                                    { label: 'Esistente', value: 'Esistente' },
                                    { label: 'Nuova', value: 'Nuova' },
                                ],
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'flag2',
                            templateOptions: {
                                disableIfCommited: true,
                                //required: true,
                                label: 'Sito vincolato',
                            },
                        },
                    ],
                },

                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Tipologia presentazione con INWIT',
                                change: m => {
                                    if( m.model.string1 !== 'Congiunta' ) {
                                        ( ( m.parent.parent.formControl as UntypedFormGroup ).controls.date1 as UntypedFormControl ).setValue( null );
                                        ( ( m.parent.parent.formControl as UntypedFormGroup ).controls.date2 as UntypedFormControl ).setValue( null );
                                    }
                                },
                                options: [
                                    { label: '', value: '' },
                                    { label: 'Congiunta', value: 'Congiunta' },
                                    { label: 'Non Congiunta', value: 'Non Congiunta' },
                                ],
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'flag1',
                            templateOptions: {
                                disableIfCommited: true,
                                //required: true,
                                label: 'Presentazione con TIM',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'select',
                            key: 'string3',
                            templateOptions: {
                                disableIfCommited: true,
                                //required: true,
                                label: 'Indicazione della potenza massima ai connettori d\'antenna ',
                                options: [
                                    { label: '', value: '' },
                                    { label: 'Potenza come da Scheda Tecnica', value: 'Potenza come da Scheda Tecnica' },
                                    { label: 'Potenza inferiore a Scheda Tecnica', value: 'Potenza inferiore a Scheda Tecnica' },
                                ],
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione AIE Vodafone > Ricezione AIE da INWIT'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Approvazione AIE Vodafone > Ricezione PDM da INWIT'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                //required: true,
                                label: 'Ricezione AIE da INWIT',
                            },
                            //hideExpression: 'model.string1 != "Congiunta"',
                            expressionProperties: {
                                'templateOptions.disabled': 'model.string1 != "Congiunta"',
                                'templateOptions.required': 'model.string1 === "Congiunta"',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                //required: true,
                                label: 'Approvazione AIE Vodafone',
                            },
                            // hideExpression: 'model.string1 != "Congiunta"',
                            expressionProperties: {
                                'templateOptions.disabled': 'model.string1 != "Congiunta"',
                                'templateOptions.required': 'model.string1 === "Congiunta"',
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        //disableIfCommited: true,
                        //required: true,
                        custom: true,
                        //label: 'Valutazione PDM',
                        label: 'Richiesta modifiche PDM',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                //label: 'Approvazione',
                                                label: 'Motivazione',
                                                options: [
                                                    // {
                                                    //   label: 'OK',
                                                    //   value: 'OK',
                                                    // },
                                                    {
                                                        label: 'Reinvio per richiesta modifiche lievi',
                                                        value: 'Reinvio per richiesta modifiche lievi',
                                                    },
                                                    {
                                                        label: 'Reinvio per richiesta modifiche rilevanti',
                                                        value: 'Reinvio per richiesta modifiche rilevanti',
                                                    },
                                                ]
                                            }
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta a Inwit > Data Ricezione PDM da INWIT'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Ricezione PDM da INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta a Inwit',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },

                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },

                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ricezione PDM definitivo da INWIT',
                            },
                            // hideExpression: 'model.string1 != "Congiunta"',
                            expressionProperties: {
                                //'templateOptions.disabled': 'model.string1 != "Congiunta"',
                                //'templateOptions.required': 'model.string1 === "Congiunta"',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                // required: true,
                                label: 'Approvazione PDM definitivo Vodafone',
                            },
                            // hideExpression: 'model.string1 === "Congiunta"',
                            expressionProperties: {
                                //'templateOptions.disabled': 'model.string1 != "Congiunta"',
                                //'templateOptions.required': 'model.string1 === "Congiunta"',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'singleFile',
                            key: 'ComunicazioneApprovazionePDM',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload Comunicazione Approvazione PDM',
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                if( m.string1 === 'Congiunta' ) {
                    return '210_Presentazione Permessi Congiunta_Inwit';
                } else {
                    return '210_Presentazione Permessi INWIT_Inwit';
                }
            },
        },
        {
            displayName: 'Presentazione Permessi Congiunta',
            name: '210_Presentazione Permessi Congiunta_Inwit',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione AIE Vodafone > Ricezione AIE da INWIT'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Approvazione AIE Vodafone > Ricezione PDM da INWIT'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione AIE',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso AIE',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione PDM',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso PDM',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '300_Room Ready INWIT_Inwit';
            },
        },
        {
            displayName: 'Presentazione Permessi INWIT',
            name: '210_Presentazione Permessi INWIT_Inwit',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Ottenimento Permesso AIE Inwit > Presentazione AIE Inwit'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Ottenimento Permesso PDM Inwit > Presentazione PDM Inwit'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione AIE Inwit',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso AIE Inwit',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione PDM Inwit',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso PDM Inwit',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '220_Progettazione_operatore';
            },
        },
        {
            displayName: 'Progettazione operatore',
            name: '220_Progettazione_operatore',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione scheda radio > Assegnazione scheda radio'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Approvazione PDM > Ricezione PDM'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                label: 'Assegnazione scheda radio (AIE)',
                                disableIfCommited: false,
                                required: true,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                label: 'Approvazione scheda radio (AIE)',
                                disableIfCommited: false,
                                required: true,
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                label: 'Predisposizione PDM',
                                disableIfCommited: true,
                                required: true,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                label: 'Approvazione PDM',
                                disableIfCommited: true,
                                required: true,
                            },
                        },
                    ],
                },

                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                label: 'Data di Assenso alla Presentazione',
                                disableIfCommited: false,
                                required: true,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'VerbaleAssensoPresentazione',
                            templateOptions: {
                                label: 'Verbale di Assenso alla Presentazione',
                                disableIfCommited: false,
                                custom: true,
                                required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },

            ],
            nextStep: m => {
                return '230_Presentazione_permessi_operatore';
            },
        },
        {
            displayName: 'Presentazione permessi operatore',
            name: '230_Presentazione_permessi_operatore',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                label: 'Presentazione AIE',
                                disableIfCommited: false,
                                required: true,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                label: 'Presentazione PDM',
                                disableIfCommited: false,
                                required: true,
                            },
                        },
                    ],
                },

            ],
            nextStep: m => {
                return '300_Ottenimento_Permessi_Room_Ready_e_VIC';
            },
        },
        {
            // Room Ready in forecast extraflow + separazione step presentazione permessi e Room Ready
            displayName: 'Room Ready Inwit & VIC',
            name: '300_Room Ready INWIT_Inwit',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'VIC - Verbale Inizio Condivisione > Room Ready Inwit'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Room Ready Inwit',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'VIC - Verbale Inizio Condivisione',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'singleFile',
                            key: 'VerbaleInizioCondivisione',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'Upload VIC - Verbale Inizio Condivisione',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '400_Selezione Ambito Normativo_Inwit';
            },
        },
        {
            displayName: 'Ottenimento Permessi, Room Ready e VIC',
            name: '300_Ottenimento_Permessi_Room_Ready_e_VIC',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso AIE Vodafone',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso PDM Vodafone',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Room Ready Inwit',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'VIC - Verbale Inizio Condivisione',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'singleFile',
                            key: 'VerbaleInizioCondivisione',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'Upload VIC - Verbale Inizio Condivisione',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '400_Selezione Ambito Normativo_Inwit';
            },
        },
        {
            displayName: 'Selezione Ambito Normativo',
            name: '400_Selezione Ambito Normativo_Inwit',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Ambito Normativo',
                                options: ambitoNormativo,
                            },
                        },

                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data definizione ambito normativo',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                if( m.string1 === 'Tit. IV' ) {
                    //return 'Progetto Esecutivo (Tit. IV)';
                    return '410_Progetto Esecutivo (Tit. IV)_Inwit';
                } else {
                    //return 'Progetto Esecutivo (Art.26)';
                    return '410_Progetto Esecutivo (Art.26)_Inwit';
                }
            },
        },
        {
            displayName: 'Progetto Esecutivo (Tit. IV)',
            name: '410_Progetto Esecutivo (Tit. IV)_Inwit',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione PE a Ufficio Tecnico > Consegna PE a Ufficio Tecnico'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Consegna PE a Ufficio Tecnico',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Approvazione PE a Ufficio Tecnico',
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        //disableIfCommited: true,
                        required: true,
                        custom: true,
                        label: 'Richiesta Approvazione progetto a INWIT',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Parere INWIT',
                                                options: okKo,
                                            }
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta > Data Richiesta'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },

                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            ],
            nextStep: m => {
                return '420_Cantiere (Tit. IV)_Inwit';
            },
        },
        {
            displayName: 'Progetto Esecutivo (Art.26)',
            name: '410_Progetto Esecutivo (Art.26)_Inwit',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Consegna PE a NI',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Approvazione PE a NI',
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        //disableIfCommited: true,
                        custom: true,
                        label: 'Richiesta Approvazione progetto a INWIT',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Parere INWIT',
                                                options: okKo,
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta > Data Richiesta'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },

                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            ],
            nextStep: m => {
                return '420_Cantiere (Art.26)_Inwit';
            },
        },
        {
            displayName: 'Cantiere (Tit. IV)',
            name: '420_Cantiere (Tit. IV)_Inwit',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Scelta Tipologia Apparati',
                                options: tipologiaApparati,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'flag1',
                            templateOptions: {
                                disableIfCommited: false,
                                //required: true,
                                label: 'Variante in corso d\'opera',
                            },
                        },

                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Data Inizio Lavori > Data Consegna Area'
                                        },
                                        {
                                            fromDate: 'date2',
                                            toDate: 'date3',
                                            errorText: 'Data Fine Lavori > Data Inizio Lavori'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Data Chiusura Enti > Data Fine Lavori'
                                        },
                                        {
                                            fromDate: 'date4',
                                            toDate: 'date5',
                                            errorText: 'Data Riconsegna Area > Data Chiusura Enti'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Consegna Area',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FileConsegnaArea',
                            templateOptions: {
                                label: 'Upload File Consegna Area',
                                //disableIfCommited: true,
                                custom: true,
                                required: false,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InizioLavori',
                            templateOptions: {
                                label: 'Upload File Inizio Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FineLavori',
                            templateOptions: {
                                label: 'Upload File Fine Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        //disableIfCommited: true,
                        custom: true,
                        required: true,
                        label: 'Comunicazioni Collaudo Tecnico Amministrativo',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Esito',
                                                options: okKo,
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta > Data Richiesta'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Chiusura cantieri Enti',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'ChiusuraCantieri',
                            templateOptions: {
                                label: 'Upload File Chiusura cantieri Enti',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Riconsegna Area e Accettazione',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'RiconsegnaArea',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload File Riconsegna Area e Accettazione',
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
            ],

            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
        {
            displayName: 'Cantiere (Art.26)',
            name: '420_Cantiere (Art.26)_Inwit',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Inizio Lavori > Consegna Area'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date24',
                                            errorText: 'Inizio Lavori > Consegna Area'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Scelta Tipologia Apparati',
                                options: tipologiaApparati,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'flag1',
                            templateOptions: {
                                disableIfCommited: false,
                                //required: true,
                                label: 'Variante in corso d\'opera',
                            },
                        },

                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Consegna Area',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FileConsegnaArea',
                            templateOptions: {
                                label: 'Upload File Consegna Area',
                                //disableIfCommited: true,
                                custom: true,
                                required: false,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InizioLavori',
                            templateOptions: {
                                //disableIfCommited: true,
                                label: 'Upload File Inizio Lavori',
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FineLavori',
                            templateOptions: {
                                label: 'Upload File Fine Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        custom: true,
                        label: 'Comunicazioni Collaudo Tecnico Amministrativo',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                //disableIfCommited: true,
                                                label: 'Esito',
                                                options: okKo,
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta > Data Richiesta'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                //disableIfCommited: true,
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                //disableIfCommited: true,
                                                required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                //disableIfCommited: true,
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Chiusura cantieri Enti',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'ChiusuraCantieri',
                            templateOptions: {
                                label: 'Upload File Chiusura cantieri Enti',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Riconsegna Area e Accettazione',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'RiconsegnaArea',
                            templateOptions: {
                                label: 'Upload File Riconsegna Area e Accettazione',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
        {
            displayName: 'Chiusura attività',
            name: '999_Chiusura attività',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Tipologia Office',
                                options: tagliaSito,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Data On Air attività',
                            },
                        },
                    ],
                },
            ],
        },
        ///////////////////////////////////////////////////////
        {
            displayName: 'Sopralluogo',
            name: '100_Sopralluogo',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                required: false,
                                label: 'Data sopralluogo',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '210_Presentazione permessi';
            },
        },
        {
            displayName: 'Presentazione permessi',
            name: '210_Presentazione permessi',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Ottenimento Permesso AIE > Presentazione AIE'
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Ottenimento Permesso PDM > Presentazione PDM'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Presentazione AIE',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso AIE',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Presentazione PDM',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso PDM',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                //return '300_Room Ready';
                return '400_Selezione Ambito Normativo';
            },
        },
        // {
        //   displayName: 'Room Ready',
        //   name: '300_Room Ready',
        //   fields: [
        //     {
        //       fieldGroupClassName: 'row',
        //       fieldGroup: [
        //         {
        //           className: 'col-sm-12',
        //           type: 'datepicker',
        //           key: 'date1',
        //           templateOptions: {
        //             disableIfCommited: true,
        //             required: true,
        //             label: 'Pronto Locale',
        //           },
        //         },
        //       ],
        //     },
        //   ],
        //   nextStep: m => {
        //     return '400_Selezione Ambito Normativo';
        //   },
        // },
        {
            displayName: 'Selezione Ambito Normativo',
            name: '400_Selezione Ambito Normativo',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Ambito Normativo',
                                options: ambitoNormativo,
                            },
                        },

                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Data definizione ambito normativo',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                if( m.string1 === 'Tit. IV' ) {
                    return '410_Progetto Esecutivo (Tit. IV)';

                } else {
                    return '410_Progetto Esecutivo (Art.26)';

                }
            },
        },
        {
            displayName: 'Progetto Esecutivo (Tit. IV)',
            name: '410_Progetto Esecutivo (Tit. IV)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione PE Ufficio Tecnico > Consegna PE Ufficio Tecnico',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Consegna PE Ufficio Tecnico',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Approvazione PE Ufficio Tecnico',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '420_Cantiere (Tit. IV)';
            },
        },
        {
            displayName: 'Progetto Esecutivo (Art.26)',
            name: '410_Progetto Esecutivo (Art.26)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione PE a NI > Consegna PE a NI',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Consegna PE a NI',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Approvazione PE a NI',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '420_Cantiere (Art.26)';
            },
        },
        {
            displayName: 'Cantiere (Tit. IV)',
            name: '420_Cantiere (Tit. IV)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Scelta Tipologia Apparati',
                                options: tipologiaApparati,
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Fine Lavori > Inizio Lavori',
                                        },
                                        {
                                            fromDate: 'date2',
                                            toDate: 'date3',
                                            errorText: 'Chiusura cantieri Enti > Fine Lavori',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'inizioLavori',
                            templateOptions: {
                                label: 'Upload File Inizio Lavori',
                                disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'fineLavori',
                            templateOptions: {
                                label: 'Upload File Fine Lavori',
                                disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],

                    // {
                    //   className: 'col-sm-6',
                    //   type: 'datepicker',
                    //   key: 'date2',
                    //   templateOptions: {
                    //     required: true,
                    //     label: 'Chiusura cantieri Enti',
                    //   },
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Chiusura cantieri Enti',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'chiusuraCantieri',
                            templateOptions: {
                                label: 'Upload File Chiusura cantieri Enti',
                                disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ],
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
        {
            displayName: 'Cantiere (Art.26)',
            name: '420_Cantiere (Art.26)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Scelta Tipologia Apparati',
                                options: tipologiaApparati,
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Fine Lavori > Inizio Lavori',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InizioLavori',
                            templateOptions: {
                                label: 'Upload File Inizio Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FineLavori',
                            templateOptions: {
                                label: 'Upload File Fine Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
    ],
};
