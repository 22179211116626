/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Inject, Injectable } from '@angular/core';
import { IPlanDTO } from '@piani/models/plan-dto';
import { IPlanWriteResponse } from '@piani/models/plan-write-response';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IOfficePlansResponse } from '@piani/models/office-plans-response';
import { ElementsFilterDTO, IElementsFilterDTO } from '@piani/models/elements-filter-dto';
import { IGetElementsResponse } from '@piani/models/get-elements-response';
import { IPlansFilterFunctionDTO } from '@piani/models/plans-filter-function-dto';
import { IBaselineElementDTO } from '@piani/models/baseline-element-dto';
import { map } from 'rxjs/operators';
import { BASE_URL, PIANI_URL } from '@shared/models/urls-constants';
import { IPlanDeleteResponse } from '@piani/models/plan-delete-response';
import { FunctionType } from '@piani/models/function-type';
import { CountPriorityParams } from '@piani/models/count-priority-params';
import { CountPriorityResponse } from '@piani/models/count-priority-response';

@Injectable( {
    providedIn: 'root',
} )
export class ApiPlansService {

    private readonly baseUrl: string;
    private readonly dustUrl: string;
    private readonly elementsUrl: string;

    constructor( private http: HttpClient, @Inject( BASE_URL ) baseUrl: string, @Inject( PIANI_URL ) pianiUrl: string ) {
        this.baseUrl = `${ pianiUrl }piani`;
        this.elementsUrl = `${ pianiUrl }piani/filtered`;
        this.dustUrl = `${ baseUrl }wonder-src-dust-api/api/v1`;
    }

    static convertPlanToElementsFilter( p: IPlanDTO, zone: string | null, subZone: string | null, high: boolean, low: boolean ): IElementsFilterDTO {
        return new ElementsFilterDTO( {
            idPlan: p.id ?? 0,
            driver: p.driver,
            subDriver: p.isTotals ? null : p.name,
            frequency: p.isTotals ? null : p.frequency,
            high,
            low,
            technology: p.technology,
            type: p.isTotals ? null : p.type,
            zone,
            subZone,
        } );
    }

    addPlans( plan: IPlanDTO ): Observable<IPlanWriteResponse> {
        return this.http.post<IPlanWriteResponse>( `${ this.baseUrl }`, plan );
    }

    modifyPlans( id: number, plan: IPlanDTO ): Observable<IPlanWriteResponse> {
        const url = `${ this.baseUrl }/${ id }`;
        return this.http.put<IPlanWriteResponse>( url, plan );
    }

    deletePlan( id: number ): Observable<IPlanDTO[]> {
        const url = `${ this.baseUrl }/${ id }`;
        return this.http.delete<IPlanDeleteResponse>( url ).pipe( map( res => res.data ) );
    }


    getOfficePlans( fiscalYear: number, office: string ): Observable<IOfficePlansResponse> {
        const url = `${ this.baseUrl }/officePlans?fiscalYear=${ fiscalYear }&office=${ office.toUpperCase() }`;
        return this.http.get<IOfficePlansResponse>( url );
    }

    getElements( filter: IElementsFilterDTO, pageIndex: number, pageSize: number, sortColumn: string | null, sortOrder: string | null ): Observable<IGetElementsResponse> {
        console.log( 'Loading filtered element', filter );
        let url = `${ this.baseUrl }/elements?idPlan=${ filter.idPlan }&technology=${ filter.technology }&driver=${ filter.driver }&pageIndex=${ pageIndex }&pageSize=${ pageSize }`;
        if( filter.type ) {
            url += `&type=${ filter.type }`;
        }
        if( filter.subDriver && filter.subDriver.toLowerCase() !== 'totals' ) {
            url += `&subDriver=${ filter.subDriver }`;
        }
        if( filter.frequency ) {
            url += `&frequency=${ filter.frequency }`;
        }
        if( filter.zone ) {
            url += `&zone=${ filter.zone }`;
        }
        if( filter.subZone ) {
            url += `&subZone=${ filter.subZone }`;
        }
        url += `&high=${ filter.high }`;
        url += `&low=${ filter.low }`;
        if( sortColumn ) {
            url += `&sortColumn=${ sortColumn }&sortOrder=${ sortOrder }`;
        }

        return this.http.get<IGetElementsResponse>( url );
    }

    getFilteredFunctions( type: FunctionType ): Observable<IPlansFilterFunctionDTO[]> {
        const url = `${ this.dustUrl }/PlansFilterFunction/${ type }`;
        return this.http.get<IPlansFilterFunctionDTO[]>( url );
    }

    getStruttureBaseline( fiscalYear: number = 2023 ): Observable<IBaselineElementDTO[]> {
        const url = `${ this.dustUrl }/baseline/elements?fiscalYear=${ fiscalYear }`;
        return this.http.get<IBaselineElementDTO[]>( url );
    }

    addBaselineElement( element: IBaselineElementDTO ): Observable<number> {
        const url = `${ this.dustUrl }/baseline/elements`;
        return this.http.post<{ id: number }>( url, element ).pipe(
            map( res => res.id ),
        );
    }

    updateBaselineElement( element: IBaselineElementDTO ): Observable<number> {
        const url = `${ this.dustUrl }/baseline/elements/${ element.id }`;
        return this.http.put<{ id: number }>( url, element ).pipe(
            map( res => res.id ),
        );
    }

    getElementsEndpoint(): string {
        return this.elementsUrl;
    }

    countPriority( params: CountPriorityParams ): Observable<CountPriorityResponse> {
        return this.http.get<CountPriorityResponse>( `${ this.baseUrl }/countPriority`, {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            params: Object.keys( params ).filter( k => params[ k ] !== null && params[ k ] !== undefined && params[ k ] !== '' ).reduce( ( p, c ) => ( { ...p, [ c ]: params[ c ] } ), {} ),
        } );
    }
}
