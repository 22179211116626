<div class="wnd-filter-menu-wrapper" fxLayout="column">
    <mat-form-field floatLabel="never" class="wnd-grid-filter-type-wrapper">
        <mat-select
                (openedChange)="openedChange($event)"
                [formControl]="filterSelection"
                disableOptionCentering
                panelClass="filter-type-panel"
        >
            <mat-option *ngFor="let filterType of filters" [value]="filterType">
                {{ column.type === 'bool' ? 'Uguale' : filterType.displayName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <form fxLayout="column">
        <wnd-filter-main-field
                [firstArg]="firstArg"
                [placeholder]="argsPlaceHolder().first"
                [inputType]="columnService.inputType()"
                [inRangeActive]="columnService.columnSearch.inRange"
                (preventClose)="preventCloseHandler($event)"
        ></wnd-filter-main-field>
        <mat-form-field
                *ngIf="columnService.columnSearch.inRange && column.type !== 'date' && column.type !== 'boolean'"
                floatLabel="never"
                class="wnd-grid-input-wrapper"
        >
            <mat-label>{{ argsPlaceHolder().second }}</mat-label>
            <input
                    (focus)="open()"
                    (blur)="close()"
                    [formControl]="this.secondArg"
                    [type]="columnService.inputType()"
                    matInput
            />
        </mat-form-field>

        <mat-form-field
                *ngIf="columnService.columnSearch.inRange && column.type === 'date'"
                floatLabel="never"
                class="wnd-grid-input-wrapper"
        >
            <mat-label>Seleziona un intervallo</mat-label>
            <mat-date-range-input [rangePicker]="pickerRange">
                <input
                        (focus)="open()"
                        (blur)="onDateBlur('start')"
                        matStartDate
                        placeholder="Da"
                        (dateChange)="dateChange('start',$event)"
                        [value]="firstArg.value"
                        (input)="onDateInput('start',$event.target.value)"
                />
                <input
                        (focus)="open()"
                        (blur)="onDateBlur('end')"
                        matEndDate
                        placeholder="A"
                        (dateChange)="dateChange('end', $event)"
                        [value]="secondArg.value"
                        (input)="onDateInput('end', $event.target.value)"
                />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerRange"></mat-datepicker-toggle>
            <mat-date-range-picker
                    (opened)="open()"
                    (closed)="close()"
                    #pickerRange
            ></mat-date-range-picker>
        </mat-form-field>

        <mat-error
                class="wnd-grid-mat-error"
                *ngIf="
        (this.firstArg.dirty && this.secondArg.dirty) ||
        !columnService.columnSearch.neverOpenedBefore
      "
        >
            {{ columnService.columnSearch.errorMsg.asObservable() | async }}
        </mat-error>
    </form>
</div>
