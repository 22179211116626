<form [formGroup]="form" [fxLayout]="flexDirection" [fxLayoutAlign]="layoutAlign">
    <mat-form-field *ngIf='showZone'>
        <mat-label>Zona</mat-label>
        <mat-select [formControlName]="placeholderZona">
            <mat-option *ngFor="let zona of zonaOptions" [value]="zona">
                {{zona}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <wonder-autocomplete
            [filter]="filterRegione"
            [formGroup]="form"
            [name]="placeholderRegione"
            [isRequired]="regioneRequired"
            (choiceChange)='regioneChoice($event)'
            [disabled]='disabled'
            [fullWidth]="fullWidth"
            [addControl]="addAutocompleteControl"
            [control]="!addAutocompleteControl && controlRegione"
            [animations]="animations"
            [emitEvent]="emitAutocompleteEvent"
            formName="regione"
            class="autocomplete"
    ></wonder-autocomplete>
    <wonder-autocomplete
            [filter]="filterProvincia"
            [formGroup]="form"
            [name]="placeholderProvincia"
            [isRequired]="provinciaRequired"
            (choiceChange)='provinciaChoice($event)'
            [disabled]='disabled'
            [fullWidth]="fullWidth"
            [addControl]="addAutocompleteControl"
            [control]="!addAutocompleteControl && controlProvincia"
            [animations]="animations"
            [emitEvent]="emitAutocompleteEvent"
            formName="provincia"
            class="autocomplete"
    ></wonder-autocomplete>
    <wonder-autocomplete
            [filter]="filterComune"
            [formGroup]="form"
            [name]="placeholderComune"
            [isRequired]="comuneRequired"
            (choiceChange)='comuneChoice($event)'
            [disabled]='disabled'
            [fullWidth]="fullWidth"
            [addControl]="addAutocompleteControl"
            [control]="!addAutocompleteControl && controlComune"
            [animations]="animations"
            [emitEvent]="emitAutocompleteEvent"
            formName="comune"
            class="autocomplete"
    ></wonder-autocomplete>
</form>
