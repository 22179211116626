import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService, StartupService } from '@core';

@Component( {
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: [ './login.component.scss' ],
} )
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private router: Router,
        private startup: StartupService,
        private settings: SettingsService,
        private toastr: ToastrService,
    ) {
        this.loginForm = this.fb.group( {
            username: [ '', [ Validators.required ] ],
            password: [ '', [ Validators.required ] ],
        } );
    }

    ngOnInit() {
    }

    get username() {
        return this.loginForm.get( 'username' );
    }

    get password() {
        return this.loginForm.get( 'password' );
    }

    login() {
        // this.startup.load(this.username.value, this.password.value).then(res => {
        //   if (res === 'Valid credentials') {
        //     if(this.settings.user.roles.length > 1) {
        //       this.router.navigateByUrl('/');
        //     } else {
        //       this.router.navigateByUrl('/landing');
        //     }
        //   }
        //   if (res === 'Invalid credentials') {
        //     this.toastr.warning('Credenziali non corrette');
        //   }
        // });
    }

    register() {
        this.router.navigateByUrl( '/auth/register' );
    }
}
