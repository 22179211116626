<mat-card class="wnd-grid-filter-host-card">
    <div (click)="close()" fxLayout="row" fxLayoutAlign="center center" class="wnd-grid-filter-host-header">
        <mat-icon class="wnd-grid-filter-icon dark">filter_list</mat-icon>
    </div>
    <mat-card-content>
        <wnd-text-filter (preventClose)="preventCloseHandler($event)" *ngIf="columnService.type === 'text' || columnService.type === 'icon'"></wnd-text-filter>
        <wnd-date-filter (preventClose)="preventCloseHandler($event)" *ngIf="columnService.type === 'date'"></wnd-date-filter>
        <wnd-boolean-filter (preventClose)="preventCloseHandler($event)" *ngIf="columnService.type === 'boolean'"></wnd-boolean-filter>
        <wnd-number-filter (preventClose)="preventCloseHandler($event)" *ngIf="columnService.type === 'number'"></wnd-number-filter>

    </mat-card-content>
</mat-card>
