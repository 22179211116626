// noinspection SpellCheckingInspection,JSUnusedLocalSymbols,DuplicatedCode

import { ActivityConfig } from './activity_config';
import { ambitoNormativo, okKo, tagliaSito, tipologiaApparati } from './arrayXSelect';
import { extraAccessiSito, extraAdeguamentoPassive, extraFlowAnagraficaNewCg, extraFlowAnagraficaTIM, extraFlowForecast, extraFlowNPlus, } from './extraFlow';


//extraFlow
//sono stati inseriti su extraflow.ts


// workFlow
export const commonGridB: ActivityConfig = {
    extraFlowConfig: [
        {
            name: 'Ext_New Forecast',
            displayName: 'New Forecast',
            fields: extraFlowForecast,
        },
        {
            name: 'Ext_Anagrafica New',
            displayName: 'Anagrafica New',
            fields: extraFlowAnagraficaNewCg,
        },
        {
            name: 'Ext_Anagrafica TIM',
            displayName: 'Anagrafica TIM',
            fields: extraFlowAnagraficaTIM,
        },
        {
            name: 'Ext_Legame NPlus',
            displayName: 'Legame NPlus',
            fields: extraFlowNPlus,
        },
        {
            name: 'Ext_Accessi al sito',
            displayName: 'Accessi al sito',
            fields: extraAccessiSito,
        },
        {
            name: 'Ext_Adeguamento Passive',
            displayName: 'Adeguamento Passive',
            fields: extraAdeguamentoPassive,
        },
        // {
        //   name: 'Ext_Rinegoziazione Inwit',
        //   displayName: 'Ext_Rinegoziazione Inwit',
        //   fields: extraRinegoziazioneInwit,
        // },
    ],
    name: 'commonGridB',
    displayName: 'commonGridB',
    stepsConfig: [
        {
            displayName: 'Registrazione Struttura',
            name: '010_Registrazione Struttura',
            isEntryStep: true,
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data',
                            },
                        },
                    ],
                },
                {
                    // registrare o selezionare struttura su mappa
                    // campi struttura: lat, long, tipo (rooftop,rawland), indirizzo, foglio, mappale, tipo gestore, gestore, codice gestore
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            name: 'registrazioneStruttura',
                            key: 'candidate',
                            type: 'candidate',
                            templateOptions: {
                                required: true,
                                disableIfCommited: true,
                                custom: true,
                                scope: [ 'population', 'selection' ],
                                map: {
                                    numberOfItems: {
                                        min: 1,
                                        max: 1,
                                    },
                                    errorMessage: 'You can\'t submit more than 1 candidates.',
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                // da valutare se next step con TIM
                return '020_Comunicazione TIM e condivisione Scheda Radio';
            },
        },
        {
            displayName: 'Comunicazione TIM e condivisione Scheda Radio',
            name: '020_Comunicazione TIM e condivisione Scheda Radio',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data Proposta TIM',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FileDataPropostaTIM',
                            templateOptions: {
                                label: 'Upload File/Email Data Proposta TIM',
                                disableIfCommited: false,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data Emissione scheda radio',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'EmissioneSchedaRadio',
                            templateOptions: {
                                label: 'Upload File Scheda Radio',
                                disableIfCommited: false,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '100_Sopralluogo';
                //return '03_Predisposizione pacchetto e progettazione';
            },
        },
        {
            displayName: 'Sopralluogo',
            name: '100_Sopralluogo',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Data primo sopralluogo',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '200_Predisposizione pacchetto e progettazione';
            },
        },

        {
            displayName: 'Predisposizione pacchetto e progettazione',
            name: '200_Predisposizione pacchetto e progettazione',
            fields: [
                {
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Data di Permesso Ottenuto > Permesso Presentato',
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Data di Pronto Locale > Permesso Ottenuto',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ricezione AIE', //aie consegnata da TIM
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Approvazione AIE Vodafone',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ricezione PDM',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Approvazione PDM Vodafone',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '210_Presentazione permessi';
            },
        },
        {
            displayName: 'Presentazione permessi',
            name: '210_Presentazione permessi',
            fields: [
                {
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Data di Permesso Ottenuto > Permesso Presentato',
                                        },
                                        {
                                            fromDate: 'date3',
                                            toDate: 'date4',
                                            errorText: 'Data di Pronto Locale > Permesso Ottenuto',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione AIE',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso AIE',
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Presentazione PDM',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Ottenimento Permesso PDM',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '300_Room Ready TIM';
            },
        },
        {
            displayName: 'Room Ready TIM',
            name: '300_Room Ready TIM',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'VIC - Verbale Inizio Condivisione > Room Ready TIM',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Room Ready TIM',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'VIC - Verbale Inizio Condivisione',
                            },
                        },
                        {
                            className: 'col-sm-4',
                            type: 'singleFile',
                            key: 'VerbaleInizioCondivisione',
                            templateOptions: {
                                //disableIfCommited: true,
                                custom: true,
                                label: 'Upload VIC - Verbale Inizio Condivisione',
                                //required: true,
                                fileProperties: {
                                    id: '1',
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                return '400_Selezione Ambito Normativo';
            },
        },
        {
            displayName: 'Selezione Ambito Normativo',
            name: '400_Selezione Ambito Normativo',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Ambito Normativo',
                                options: ambitoNormativo,
                            },
                        },

                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Data definizione ambito normativo',
                            },
                        },
                    ],
                },
            ],
            nextStep: m => {
                if( m.string1 === 'Tit. IV' ) {
                    //return 'Progetto Esecutivo (Tit. IV)';
                    return '410_Progetto Esecutivo (Tit. IV)';
                } else {
                    //return 'Progetto Esecutivo (Art.26)';
                    return '410_Progetto Esecutivo (Art.26)';
                }
            },
        },


        {
            displayName: 'Progetto Esecutivo (Tit. IV)',
            name: '410_Progetto Esecutivo (Tit. IV)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Approvazione PE a Ufficio Tecnico > Consegna PE a Ufficio Tecnico'
                                        },
                                    ]
                                }
                            }
                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Consegna PE a Ufficio Tecnico',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Approvazione PE a Ufficio Tecnico',
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        custom: true,
                        label: 'Richiesta Approvazione progetto a INWIT',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Parere INWIT',
                                                options: okKo,
                                            }
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta INWIT > Data Invio INWIT'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            ],
            nextStep: m => {
                return '420_Cantiere (Tit. IV)';
            },
        },
        {
            displayName: 'Progetto Esecutivo (Art.26)',
            name: '410_Progetto Esecutivo (Art.26)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Consegna PE a NI',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Approvazione PE a NI',
                            },
                        },
                    ],
                },
                {
                    type: 'communication',
                    key: 'communication',
                    templateOptions: {
                        custom: true,
                        label: 'Richiesta Approvazione progetto a INWIT',
                        communicationConfig: {
                            fields: [
                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'select',
                                            key: 'string1',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Parere INWIT',
                                                options: okKo,
                                            },
                                        },
                                    ],
                                },
                                {
                                    fieldGroupClassName: 'row',
                                    validators: {
                                        validation: [
                                            {
                                                name: 'fieldDateMatch',
                                                options: {
                                                    fieldTocheck: [
                                                        {
                                                            fromDate: 'date1',
                                                            toDate: 'date2',
                                                            errorText: 'Data Risposta INWIT > Data Invio INWIT'
                                                        },
                                                    ]
                                                }
                                            }
                                        ],
                                    },
                                    fieldGroup: [
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date1',
                                            templateOptions: {
                                                required: true,
                                                label: 'Data Invio INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                        {
                                            className: 'col-sm-6',
                                            type: 'datepicker',
                                            key: 'date2',
                                            templateOptions: {
                                                // required: true,
                                                label: 'Data Risposta INWIT',
                                            },
                                            expressionProperties: {
                                                'templateOptions.required': 'model.string1',
                                            },
                                        },
                                    ],
                                },

                                {
                                    fieldGroupClassName: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-12',
                                            type: 'textarea',
                                            key: 'string2',
                                            templateOptions: {
                                                required: false,
                                                label: 'Note',
                                                rows: 3,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            ],
            nextStep: m => {
                return '420_Cantiere (Art.26)';
            },
        },
        {
            displayName: 'Cantiere (Tit. IV)',
            name: '420_Cantiere (Tit. IV)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Scelta Tipologia Apparati',
                                options: tipologiaApparati,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'checkbox',
                            key: 'flag1',
                            templateOptions: {
                                disableIfCommited: false,
                                //required: true,
                                label: 'Variante in corso d\'opera',
                            },
                        },

                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Consegna Area',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FileConsegnaArea',
                            templateOptions: {
                                label: 'Upload File Consegna Area',
                                //disableIfCommited: true,
                                custom: true,
                                required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    validators: {
                        validation: [
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date2',
                                            toDate: 'date3',
                                            errorText: 'Fine Lavori > Inizio Lavori'
                                        },
                                    ]
                                }
                            },
                            {
                                name: 'fieldDateMatch',
                                options: {
                                    fieldTocheck: [
                                        {
                                            fromDate: 'date1',
                                            toDate: 'date2',
                                            errorText: 'Inizio Lavori > Consegna Area'
                                        },
                                    ]
                                }
                            }

                        ],
                    },
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InizioLavori',
                            templateOptions: {
                                label: 'Upload File Inizio Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FineLavori',
                            templateOptions: {
                                label: 'Upload File Fine Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'communication',
                            key: 'communication',
                            templateOptions: {
                                custom: true,
                                label: 'Comunicazioni Collaudo Tecnico Amministrativo',
                                communicationConfig: {
                                    fields: [
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'select',
                                                    key: 'string1',
                                                    templateOptions: {
                                                        // required: true,
                                                        label: 'Esito',
                                                        options: okKo,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            fieldGroupClassName: 'row',
                                            validators: {
                                                validation: [
                                                    {
                                                        name: 'fieldDateMatch',
                                                        options: {
                                                            fieldTocheck: [
                                                                {
                                                                    fromDate: 'date1',
                                                                    toDate: 'date2',
                                                                    errorText: 'Data Risposta INWIT > Data Invio INWIT'
                                                                },
                                                            ]
                                                        }
                                                    }
                                                ],
                                            },
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'datepicker',
                                                    key: 'date1',
                                                    templateOptions: {
                                                        required: true,
                                                        label: 'Data Invio INWIT',
                                                    },
                                                },
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'datepicker',
                                                    key: 'date2',
                                                    templateOptions: {
                                                        // required: true,
                                                        label: 'Data Risposta INWIT',
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-12',
                                                    type: 'textarea',
                                                    key: 'string2',
                                                    templateOptions: {
                                                        required: false,
                                                        label: 'Note',
                                                        rows: 3,
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Chiusura cantieri Enti',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'ChiusuraCantieri',
                            templateOptions: {
                                label: 'Upload File Chiusura cantieri Enti',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Riconsegna Area e Accettazione',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'RiconsegnaArea',
                            templateOptions: {
                                label: 'Upload File Riconsegna Area e Accettazione',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],

            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
        {
            displayName: 'Cantiere (Art.26)',
            name: '420_Cantiere (Art.26)',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Scelta Tipologia Apparati',
                                options: tipologiaApparati,
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Consegna Area',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FileConsegnaArea',
                            templateOptions: {
                                label: 'Upload File Consegna Area',
                                //disableIfCommited: true,
                                custom: true,
                                required: false,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date2',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Inizio Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'InizioLavori',
                            templateOptions: {
                                label: 'Upload File Inizio Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date3',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: true,
                                label: 'Fine Lavori',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'FineLavori',
                            templateOptions: {
                                label: 'Upload File Fine Lavori',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-12',
                            type: 'communication',
                            key: 'communication',
                            templateOptions: {
                                custom: true,
                                label: 'Comunicazioni Collaudo Tecnico Amministrativo',
                                communicationConfig: {
                                    fields: [
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'select',
                                                    key: 'string1',
                                                    templateOptions: {
                                                        // required: true,
                                                        label: 'Esito',
                                                        options: okKo,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            fieldGroupClassName: 'row',
                                            validators: {
                                                validation: [
                                                    {
                                                        name: 'fieldDateMatch',
                                                        options: {
                                                            fieldTocheck: [
                                                                {
                                                                    fromDate: 'date1',
                                                                    toDate: 'date2',
                                                                    errorText: 'Data Risposta INWIT > Data Invio INWIT'
                                                                },
                                                            ]
                                                        }
                                                    }
                                                ],
                                            },
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'datepicker',
                                                    key: 'date1',
                                                    templateOptions: {
                                                        required: true,
                                                        label: 'Data Invio INWIT',
                                                    },
                                                },
                                                {
                                                    className: 'col-sm-6',
                                                    type: 'datepicker',
                                                    key: 'date2',
                                                    templateOptions: {
                                                        // required: true,
                                                        label: 'Data Risposta INWIT',
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            fieldGroupClassName: 'row',
                                            fieldGroup: [
                                                {
                                                    className: 'col-sm-12',
                                                    type: 'textarea',
                                                    key: 'string2',
                                                    templateOptions: {
                                                        required: false,
                                                        label: 'Note',
                                                        rows: 3,
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date4',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Chiusura cantieri Enti',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'ChiusuraCantieri',
                            templateOptions: {
                                label: 'Upload File Chiusura cantieri Enti',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date5',
                            templateOptions: {
                                //disableIfCommited: true,
                                required: false,
                                label: 'Riconsegna Area e Accettazione',
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'singleFile',
                            key: 'RiconsegnaArea',
                            templateOptions: {
                                label: 'Upload File Riconsegna Area e Accettazione',
                                //disableIfCommited: true,
                                custom: true,
                                //required: true,
                                fileProperties: {
                                    allowedExstension: [ '.pdf', '.p7m', '.doc', '.docx', '.xlsx' ]
                                },
                            },
                        },
                    ],
                },
            ],

            nextStep: m => {
                return '999_Chiusura attività';
            },
        },
        {
            displayName: 'Chiusura attività',
            name: '999_Chiusura attività',
            fields: [
                {
                    fieldGroupClassName: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6',
                            type: 'select',
                            key: 'string1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Tipologia Office',
                                options: tagliaSito,
                            },
                        },
                        {
                            className: 'col-sm-6',
                            type: 'datepicker',
                            key: 'date1',
                            templateOptions: {
                                disableIfCommited: true,
                                required: true,
                                label: 'Data On Air attività',
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
