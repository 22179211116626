import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Office } from '@shared/models/office';
import { map } from 'rxjs/operators';
import { IOffice } from '@shared/interfaces/office';
import { OFFICES_URL } from '@shared/models/urls-constants';
import { MOCKED_DATA } from './api-offices.mock';

@Injectable( {
    providedIn: 'root',
} )
export class ApiOfficesService {
    mocked = false;

    constructor( private http: HttpClient, @Inject( OFFICES_URL ) private baseUrl: string ) {
    }

    search( term: string ): Observable<Office[]> {
        // const filter = `\`field\`office\`searchType\`CONTAINS\`arguments\`${term}`;
        const url = `${ this.baseUrl }api/Struttura/byTerms/${ term }`;

        let params = new HttpParams();

        // Begin assigning parameters
        params = params.append( 'pageSize', '10' );
        // params = params.append('filters', filter);

        return this.http.get<Office[]>( url, { params } ).pipe(
            map( res => res ),
        );
    }

    getOffice( office: string ): Observable<IOffice> {
        const url = `${ this.baseUrl }api/v1/offices/${ office }`;
        return ( this.mocked ? of( MOCKED_DATA ) : this.http.get<IOffice>( url ) ).pipe(
            //map( res => ObjectsConversions.iOfficeFromOfficeDB( res ) )
            map( res => {
                const officeRes = { ...res };

                officeRes.tabs = officeRes.tabs ? officeRes.tabs.map( tab => {
                    tab.sections = tab.sections?.map( section => {
                        const data: { [key: string ]: string }[] =  [];
                        let dataIndex = 0;

                        section.columns?.forEach( column => {
                            data[ dataIndex ] = data[ dataIndex ] || {};

                            if( data[ dataIndex ][ column.header ] ) {
                                dataIndex++;
                            }

                            data[ dataIndex ] = data[ dataIndex ] || {};

                            data[ dataIndex ][ column.header ] = column.value;
                        } );

                        section.data = data;

                        return section;
                    } );

                    return tab;
                } ) : null;

                return officeRes;
            } )
        );
    }

    getSubregions( zone?: number ): Observable<string[]> {
        const url = `${ this.baseUrl }api/subregions/distinct${ zone ? `?zone=${ zone }` : '' }`;
        return this.http.get<string[]>( url );
    }
}
