<div class="m-b-16">
    <!-- <button (click)="prova()" mat-icon-button>
      <mat-icon class="icon-24">check</mat-icon>
    </button> -->
    <mat-form-field (click)="menuMapOpened()"
                    class="example-full-width map-form-input  cursor-pointer"
                    [ngClass]="{ 'mat-form-field-invalid': !formControl.valid && mapTouched }"
    >
        <mat-label>{{getFormControlLabel()}}</mat-label>
        <input type="mapInput" readonly class="map-form-input  cursor-pointer" matInput/>
        <button matSuffix mat-icon-button>
            <mat-icon class="icon-18" id="candidatesClick">map</mat-icon>
        </button>
    </mat-form-field>
    <mat-expansion-panel
            class="map-input"
            (opened)="mapOpened()"
            (closed)="mapClosed()"
            (afterExpand)='mapExpanded()'
            [expanded]="panelOpenState"
            hideToggle
    >
        <div *ngIf="candidateService.candidateActivity" class="matero-row" fxLayout="row wrap">
            <div
                    class="m-t-8"
                    [hidden]="mapWidthPercentage === 100"
                    [fxFlex.gt-sm]="100 - mapWidthPercentage"
                    fxFlex="100"
            >
                <mtx-grid
                        class="wnd-grid-toolbar activities-list-grid"
                        [data]="candidateList"
                        [columns]="columns"
                        [showPaginator]="false"
                        [cellTemplate]="{ priority: priorityTpl, state: stateTpl, accepted: acceptedTpl }"
                >
                </mtx-grid>

                <ng-template #priorityTpl let-row let-index="index" let-col="colDef">
                    <mat-select
                            class="in-grid-select"
                            [disabled]="candidateService.candidateActivity.candidatesBoxes[index].state === 'KO' || disabled"
                            [(ngModel)]="candidateService.candidateActivity.candidatesBoxes[index].priority"
                    >
                        <mat-option
                                *ngFor="let val of candidateService.candidateActivity.priorityRange"
                                [value]="val"
                        >{{ val }}</mat-option
                        >
                    </mat-select>
                </ng-template>

                <ng-template #stateTpl let-row let-index="index" let-col="colDef">
                    <mat-select
                            class="in-grid-select"
                            [disabled]="disabled"
                            [(ngModel)]="candidateService.candidateActivity.candidatesBoxes[index].state"
                    >
                        <mat-option *ngFor="let val of stateRange" [value]="val">{{ val }}</mat-option>
                    </mat-select>
                </ng-template>

                <ng-template #acceptedTpl let-row let-index="index" let-col="colDef">
                    <mat-checkbox
                            class="sm-checkbox"
                            [disabled]="candidateService.candidateActivity.candidatesBoxes[index].state === 'KO' || disabled"
                            [(ngModel)]="candidateService.candidateActivity.candidatesBoxes[index].accepted"
                    >
                    </mat-checkbox>
                </ng-template>
            </div>
            <div [fxFlex.gt-sm]="mapWidthPercentage" fxFlex="100">
                <osm-draw-map
                        *ngIf="show"
                        [maxWidth]="maxWidth"
                        [mapHeight]="mapHeight"
                        #map
                        (click)="$event.stopPropagation()"
                        [featureCollection]="candidateService.candidateActivity.featureGroup"
                        (featureGroupChange)="drawItemGroupChange($event)"
                        [drawConfig]="drawConfig"
                        (changingModeChange)="onMapChangeMode($event)"
                        [center]="currentCenter"
                ></osm-draw-map>
            </div>
        </div>
    </mat-expansion-panel>
</div>
