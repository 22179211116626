import { AfterViewInit, Component, OnDestroy, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@core';
import { Subscription } from 'rxjs';

import { DashboardService } from './dashboard.service';

@Component( {
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: [ './dashboard.component.scss' ],
    styles: [
        `
      .mat-raised-button {
        margin-right: 8px;
        margin-top: 8px;
      }
    `,
    ],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ DashboardService ],
} )
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

    dataSource = this.dashboardSrv.getData();
    processi = this.dashboardSrv.getProcessi();
    zone = this.dashboardSrv.getZone();

    notifySubscription: Subscription;
    SelectedZona: any;

    constructor(
        private dashboardSrv: DashboardService,
        private settings: SettingsService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.notifySubscription = this.settings.notify.subscribe( res => {
            console.log( res );
        } );
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {

    }


}
