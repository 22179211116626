import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DUST_URL } from '@shared/models/urls-constants';
import { Observable } from 'rxjs';
import { IStruttura } from '@shared/models/struttura';

@Injectable( {
    providedIn: 'root'
} )
export class ApiDustService {

    constructor( private http: HttpClient, @Inject( DUST_URL ) private baseUrl: string ) {
    }

    getStruttura( id: string ): Observable<IStruttura> {
        const url = `${ this.baseUrl }strutture/byoffice/${ id }`;
        return this.http.get<IStruttura>( url );
    }

    getTrasferimento( id: string ): Observable<IStruttura> {
        const url = `${ this.baseUrl }trasferimenti/byoffice/${ id }`;
        return this.http.get<IStruttura>( url );
    }

    getStrutturaById( id: number ): Observable<IStruttura[]> {
        const headers: { [ id: string ]: string } = {
            'No-Show-Error': 'true'
        };
        const url = `${ this.baseUrl }strutture/byid/${ id }`;
        return this.http.get<IStruttura[]>( url, { headers } );
    }
}
