import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import screenfull from 'screenfull';
import { environment } from '@env/environment';
import { ApiCacheService } from '@shared/services/api-cache.service';

@Component( {
    selector: 'app-header',
    host: {
        class: 'matero-header',
    },
    templateUrl: './header.component.html',
    styleUrls: [ './header.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class HeaderComponent {
    @Input() showToggle = true;
    @Input() showBranding = false;
    @Input() unreadNotifications = 0;

    @Output() toggleSidenav = new EventEmitter<void>();
    @Output() toggleSidenavNotice = new EventEmitter<void>();

    protected readonly environment = environment;

    get notificationsCount() {
        return this.unreadNotifications > 50 ? '50+' : this.unreadNotifications;
    }

    toggleFullscreen() {
        if( screenfull.isEnabled ) {
            void screenfull.toggle();
        }
    }

}
