import { Component, OnInit } from '@angular/core';
import { SettingsService, User } from '@core';

@Component( {
    selector: 'app-user-panel',
    template: `
        <div class='matero-user-panel' fxLayout='column' fxLayoutAlign='center center'>
            <img
                class='matero-user-panel-avatar'
                src='{{getAvatar$() | async}}'
                alt='avatar'
                width='64'
            />
            <h4 class='matero-user-panel-name' fxLayoutAlign='center center' ><p class='ellipsis'>{{user.name || user.username}}</p></h4>
            <div class='matero-user-panel-icons'>
                <a routerLink='/user-settings' mat-icon-button matTooltip="Vai alla pagina con le tue informazioni utente">
                    <mat-icon>account_circle</mat-icon>
                </a>
                <a (click)='handleLogout()' mat-icon-button matTooltip="Esegui il logout dall'applicazione">
                    <mat-icon>exit_to_app</mat-icon>
                </a>
            </div>
        </div>
    `,
    styleUrls: [ './user-panel.component.scss' ],
} )
export class UserPanelComponent implements OnInit {
    user: User;

    constructor( private settings: SettingsService, ) {
        this.user = this.settings.user;
    }

    ngOnInit(): void {
    }

    getAvatar$() {
        return this.settings.getAvatar$();
    }

    handleLogout() {
        this.settings.logout();
    }

}
