import { remedies } from './remedies';
import { ActivityConfig } from './activity_config';
import { upgradeInwit } from './upgradeInwit';
import { fornitorePrivilegiato } from './fornitorePrivilegiato';
//import { fornitorePrivilegiatoNewSite } from './fornitorePrivilegiatoNewSite';
//import { fornitorePrivilegiatoTrasferimento } from './fornitorePrivilegiatoTrasferimento';
import { debug } from './debug';
import { commitment } from './commitment';

import { commonGridB } from './commonGridB';
import { commonGridD1 } from './commonGridD1';
import { predisposizioneInfra } from './predisposizioneInfra';
import { commitmentTrasferimento } from './commitmentTrasferimento';
import { fornitorePrivilegiatoTrasferimento } from './fornitorePrivilegiatoTrasferimento';
import { dismissione } from './dismissione';
import { dismissioneInwit } from './dismissioneInwit';

export class ProcessSelector {
    private static process: ActivityConfig[] = [
        // check documentazione
        debug,                                       // [-] [-]  procedura per i test...sarà da cancellare
        upgradeInwit,                                // [-] [X]  procedura upgrade su struttura INWIT (NO Predisposizione INFRA)
        // upgradeGenerico,                           // [-] [-]  procedura upgrade su struttura non Inwit
        // dismissioneOffice,                         // [-] [-]
        predisposizioneInfra,                        // [-] [X]  procedura upgrade su struttura INWIT (attivita` di Predisposizione INFRA)
        remedies,                                    // [-] [X]  (stesso processo di commitment) in dismissione (ora commitment)
        fornitorePrivilegiato,                       // [-] [X]  sarà aperta su codice fittizio
        fornitorePrivilegiatoTrasferimento,          // [-] [-]  stessa procedura di fornitorePrivilegiato , ma sarà aperta su old_office,ma al termine sarà aperta su old_office la dismissione (... se non gia presente)
        commitment,                                  // [-] [X]  sarà aperta su codice fittizio
        commitmentTrasferimento,                     // [-] [X]  stessa procedura di commitment, ma sarà aperta su old_office ( al termine crea dismissione old... se non gia presente)
        commonGridB,                                 // [-] [X]  SSI commonGrid
        commonGridD1,                                // [-] [X]  SSI commonGrid
        dismissione,
        dismissioneInwit
    ];

    //private static process: ActivityConfig[] = [debug];

    public static getTemplate( templateName: string ): ActivityConfig {
        return this.process.filter( p => p.name === templateName )[ 0 ];
    }

    public static getAllProcess() {
        return this.process;
    }
}
