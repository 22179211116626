import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UploadingFileStatuses } from '@shared/models/uploading-file-statuses';
import { TusSingleFileUploader, UploadService } from '@shared/services/upload.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component( {
    selector: 'wnd-uploader-manager',
    templateUrl: './uploader-manager.component.html',
    styleUrls: [ './uploader-manager.component.scss' ],
    encapsulation: ViewEncapsulation.None,
} )
export class UploaderManagerComponent implements OnInit {
    opened = false;

    elements$: Observable<TusSingleFileUploader[]>;

    public visible = false;

    constructor( public uploadService: UploadService ) {
    }

    ngOnInit() {
        this.elements$ = this.uploadService.activeUploadersSub.asObservable().pipe(
            map( uploaders => uploaders.uploaders ),
        );
    }

    setVisible() {
        this.uploadService.activeUploadersSub.asObservable()
            .subscribe( uploaders => {
                this.visible = uploaders.uploaders.length > 0;
            } );

    }

    openPanel( _event: MouseEvent ) {
        this.opened = true;
    }

    closePanel() {
        this.opened = false;
    }

    closePanelWhenEmpty() {
        this.uploadService.activeUploadersSub.asObservable()
            .subscribe( uploaders => {
                if( uploaders.uploaders.length === 0 ) {
                    this.closePanel();
                }
            } );
    }

    togglePanel() {
        this.opened = !this.opened;
    }

    abortDownload( file: TusSingleFileUploader ) {
        file.abort();
    }

    pauseResumeDownload( file: TusSingleFileUploader ) {
        console.log( 'Pause resume pressed. State ', file.fileStatus.state );
        if( file.fileStatus.state === UploadingFileStatuses.PAUSED ) {
            file.resume();
        } else {
            file.pause();
        }
    }
}
