import { APP_INITIALIZER, Provider } from '@angular/core';

import { TranslateLangService } from './bootstrap/translate-lang.service';
import { TokenService } from './authentication/token.service';
import { StartupService } from './bootstrap/startup.service';
import { StreamCraftClientService } from '@core/services/stream-craft-client/stream-craft-client.service';

export const TranslateLangServiceFactory = ( translateLangService: TranslateLangService ) => () => translateLangService.load();

export const TokenServiceFactory = ( tokenService: TokenService ) => () => tokenService.init();

export const StartupServiceFactory = ( startupService: StartupService ) => () => startupService.load();
export const StartupStreamCraftClientFactory = ( streamcraftService: StreamCraftClientService ) => () => streamcraftService;

export const appInitializerProviders: Provider[] = [
    {
        provide: APP_INITIALIZER,
        useFactory: TranslateLangServiceFactory,
        deps: [ TranslateLangService ],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: TokenServiceFactory,
        deps: [ TokenService ],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: StartupServiceFactory,
        deps: [ StartupService ],
        multi: true,
    },
    {
        provide:APP_INITIALIZER,
        useFactory: StartupStreamCraftClientFactory,
        deps: [ StreamCraftClientService ],
        multi: true,
    }
];
