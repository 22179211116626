import { BehaviorSubject } from 'rxjs';
import { transformJsonToLayer, transformLayerToJson, } from '@shared/components/osm-draw-map/custom-leaflet-function';
import { FeatureGroup, featureGroup } from 'leaflet';

export interface SearchArea {
    id?: string;
    name: string;
    description?: string;
    idActivity?: string;
    group: L.FeatureGroup | any;
}

export class SearchAreaImpl {
    statusChange = new BehaviorSubject<void>( null );
    id?: string;
    name: string;
    description?: string;
    idActivity?: string;
    private _group: any;
    set group( val: any ) {
        this._group = val;
        this._featureGroup = featureGroup( transformJsonToLayer( val ).getLayers() );
        this.statusChange.next();
    }

    get group() {
        return this._group;
    }

    private _featureGroup = featureGroup();
    set featureGroup( val: FeatureGroup ) {
        this._featureGroup = featureGroup( val.getLayers() );
        this._group = transformLayerToJson( val );
        this.statusChange.next();
    }

    get featureGroup() {
        return this._featureGroup;
    }

    get searchArea(): SearchArea {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            idActivity: this.idActivity,
            group: this.group,
        };
    }


    set searchArea( val: SearchArea ) {
        this.id = val.id;
        this.name = val.name;
        this.description = val.description;
        this.idActivity = val.idActivity;
        this.group = val.group;
    }
}
