<div class='flex'>
    <div class='flex-item'>
        <div style='display: block;'>
            <canvas baseChart width='400' height='400'
                    #theChart="base-chart"
                    [datasets]='chartData'
                    [labels]='chartLabels'
                    [options]='chartOptions'
                    [legend]='chartLegend'
                    [type]='chartType'
                    (chartHover)='chartHovered($event)'
                    (chartClick)='chartClicked($event)'
            ></canvas>
        </div>
    </div>
</div>

