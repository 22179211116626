<div class="sticky-header">
    <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            class="mat-elevation-z stick-expansion-panel-mat-expansion-panel"
            [expanded]="true"
    >
        <mat-expansion-panel-header
                [ngClass]="panelOpenState ? 'stick-expansion-panel-mat-expansion-panel-header-open' : 'stick-expansion-panel-mat-expansion-panel-header-closed'"
        ></mat-expansion-panel-header>
        <ng-content></ng-content>
    </mat-expansion-panel>
</div>
